import TextMessageResponse from './TextMessageResponse';
import ImageMessageResponse from './ImageMessageResponse';
import FileMessageResponse from './FileMessageResponse';
import { auth } from '../../../../../firebase/config';
import { useTranslation } from 'react-i18next';

export default function ResponseMessage({ message, contacts }) {
	const uid = auth.currentUser?.uid;
	const { t } = useTranslation();

	return (
		<div className={'responseMessage'}>
			<p className={'senderName'}>
				{message.sender === uid
					? t('translation.you')
					: contacts?.find((item) => item.id === message.sender)?.name}
			</p>
			{message.type === 'text' ? (
				<TextMessageResponse value={message.contents} />
			) : message.type === 'picture' || message.type === 'video' ? (
				<ImageMessageResponse message={message} />
			) : message.type === 'file' ? (
				<FileMessageResponse name={message.fileName} type={message.fileType} />
			) : null}
		</div>
	);
}
