import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './_followUp.scss';
import TicketOpening from './Steps/TicketOpening';
import TicketSolving from './Steps/TicketSolving';
import TicketValidation from './Steps/TicketValidation';
import { joinPaths } from '../../../../../../firebase/utils';
import { firestore } from '../../../../../../firebase/config';
import { Paths } from '../../../../../../firebase/paths';
import { useProjectContext } from '../../../../../../contexts/projectContext';

export const TICKET_STATUS = {
	new: 'new',
	pendingProcess: 'pending_process',
	inProgress: 'in_progress',
	pendingApproval: 'pending_approval',
	refused: 'refused',
	approved: 'approved',
};

export const REVIEW_RESULT = {
	approved: 'approved',
	refused: 'refused',
};

export default function FollowUp({ ticket }) {
	const [project] = useProjectContext();

	const [resolutions, setResolutions] = useState([]);

	const listRef = useRef(null);

	const scrollToBottom = () => {
		if (listRef.current) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		scrollToBottom();
	}, [resolutions]);

	useEffect(() => {
		if (ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.RESOLUTIONS))
				.orderBy('createdAt', 'asc')
				.onSnapshot((snapshot) => {
					if (snapshot && snapshot.docs.length > 0) {
						setResolutions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
					} else {
						setResolutions([]);
					}
				});
			return () => unsubscribe();
		}
	}, [ticket?.id]);

	return (
		<div className={'followUp'} ref={listRef}>
			<TicketOpening ticket={ticket} />
			{resolutions.map((item, index) => {
				const isRefused = item.reviews?.some((review) => review.result === REVIEW_RESULT.refused);
				const isApproved = ticket?.properties?.reviewers?.every((reviewer) =>
					item.reviews?.some(
						(review) => review.companyId === reviewer && review.result === REVIEW_RESULT.approved
					)
				);

				return (
					<React.Fragment key={index}>
						<TicketSolving ticket={ticket} isNewResolution={false} resolution={item} />
						{!isRefused && !isApproved && (
							<TicketValidation
								ticket={ticket}
								isNewResolution={false}
								reviewers={ticket?.properties?.reviewers}
								reviews={item.reviews}
							/>
						)}
					</React.Fragment>
				);
			})}
			{(resolutions?.length < 1 ||
				resolutions?.[resolutions?.length - 1]?.reviews?.some(
					(review) => review.result === REVIEW_RESULT.refused
				)) && (
				<>
					<TicketSolving ticket={ticket} isNewResolution={true} resolution={null} />
					<TicketValidation
						ticket={ticket}
						isNewResolution={true}
						reviewers={ticket?.properties?.reviewers}
						reviews={[]}
					/>
				</>
			)}
			<div style={{ height: 100, width: 200 }} />
		</div>
	);
}

FollowUp.propTypes = {
	ticket: PropTypes.object.isRequired,
};
