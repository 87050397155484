import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';

export default function FooterJoinProject() {
	const { t } = useTranslation();
	const [widthLeft, setWidthLeft] = useState(100 + 'vw');

	return (
		<div className={'footerJoinProject'} style={{ width: widthLeft }}>
			<div style={{ display: 'flex', width: 'calc(50vw - 10px)', justifyContent: 'space-between' }}>
				<div className={'row'}>
					<a href="https://opusoft.fr" target={'_blank'}>
						<p className={'textThirdPart'}>©OPUS</p>
					</a>

					<a href="https://opusoft.fr/contact" target={'_blank'}>
						<p className={'textThirdPart'}>{t('common.contact')}</p>
					</a>

					<a
						href="https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c"
						target={'_blank'}>
						<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
					</a>

					<a
						href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
						target={'_blank'}>
						<p className={'textThirdPart'}>{t('legals.cgu')}</p>
					</a>

					<a
						href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
						target={'_blank'}>
						<p className={'textThirdPart'}>{t('legals.cgv')}</p>
					</a>
				</div>

				<div style={{ marginTop: -2, marginRight: 15 }}>
					<LanguageSelector />
				</div>
			</div>
		</div>
	);
}
