import React, { useState, useEffect } from 'react';
import rectangle from '../../../../../../../assets/rectangle.svg';
import twoRectangles from '../../../../../../../assets/twoRectangles.svg';
import threeRectangles from '../../../../../../../assets/threeRectangles.svg';
import threeDots from '../../../../../../../assets/threeDots.svg';
import ContextMenu from '../../../../../../../components/ContextMenu/ContextMenu';
import { useTranslation } from 'react-i18next';
import sun from '../../../../../../../assets/sun.svg';
import mappin from '../../../../../../../assets/mappin.svg';
import twoPeopleIcon from '../../../../../../../assets/twoPeopleIcon.svg';
import clock from '../../../../../../../assets/clock.svg';
import hourglass from '../../../../../../../assets/hourglass.svg';
import docTicket from '../../../../../../../assets/docTicket.svg';
import textIconTickets from '../../../../../../../assets/textIconTickets.svg';
import hammer from '../../../../../../../assets/hammer.svg';
import Building from '../../../../../../../assets/Building.svg';

export default function TicketsDisplayFilter({
	isStatusShown,
	setIsStatusShown,
	isLotShown,
	setIsLotShown,
	isLocationShown,
	setIsLocationShown,
	isTicketOwnerShown,
	setIsTicketOwnerShown,
	isCompanyInChargeShown,
	setIsCompanyInChargeShown,
	isDueDateShown,
	setIsDueDateShown,
	isWorkDurationShown,
	setIsWorkDurationShown,
	isLinkedFileShown,
	setIsLinkedFileShown,
	isDescriptionShown,
	setIsDescriptionShown,
	isSnagging,
}) {
	const { t } = useTranslation();
	const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
	const [showMenu, setShowMenu] = useState(false);
	const [displayType, setDisplayType] = useState(localStorage.getItem('ticketsDisplayType') || 'middle');

	useEffect(() => {
		applyDisplaySettings(displayType);
	}, []);

	function applyDisplaySettings(type) {
		switch (type) {
			case 'little':
				handleClickLittle();
				break;
			case 'middle':
				handleClickMiddle();
				break;
			case 'big':
				handleClickBig();
				break;
			default:
				handleClickMiddle();
		}
	}

	function handleClickLittle() {
		setDisplayType('little');
		setIsStatusShown(true);
		setIsLotShown(false);
		setIsLocationShown(false);
		setIsTicketOwnerShown(false);
		setIsCompanyInChargeShown(true);
		setIsDueDateShown(false);
		setIsWorkDurationShown(false);
		setIsLinkedFileShown(false);
		setIsDescriptionShown(false);
		localStorage.setItem('ticketsDisplayType', 'little');
	}

	function handleClickMiddle() {
		setDisplayType('middle');
		setIsStatusShown(true);
		setIsLotShown(true);
		setIsLocationShown(true);
		setIsTicketOwnerShown(false);
		setIsCompanyInChargeShown(true);
		setIsDueDateShown(false);
		setIsWorkDurationShown(false);
		setIsLinkedFileShown(false);
		setIsDescriptionShown(true);
		localStorage.setItem('ticketsDisplayType', 'middle');
	}

	function handleClickBig() {
		setDisplayType('big');
		setIsStatusShown(true);
		setIsLotShown(true);
		setIsLocationShown(true);
		setIsTicketOwnerShown(true);
		setIsCompanyInChargeShown(true);
		setIsDueDateShown(true);
		setIsWorkDurationShown(true);
		setIsLinkedFileShown(true);
		setIsDescriptionShown(true);
		localStorage.setItem('ticketsDisplayType', 'big');
	}

	const options = [
		{
			label: t('translation.description'),
			icon: textIconTickets,
			state: isDescriptionShown,
			action: () => setIsDescriptionShown((prev) => !prev),
		},
		{
			label: t('translation.lotsConcernés'),
			icon: hammer,
			state: isLotShown,
			action: () => setIsLotShown((prev) => !prev),
		},
		{
			label: t('translation.locationOnSite'),
			icon: mappin,
			state: isLocationShown,
			action: () => setIsLocationShown((prev) => !prev),
		},
		{
			label: t('translation.dueDate'),
			icon: clock,
			state: isDueDateShown,
			action: () => setIsDueDateShown((prev) => !prev),
		},
		{
			label: t('translation.workDuration'),
			icon: hourglass,
			state: isWorkDurationShown,
			action: () => setIsWorkDurationShown((prev) => !prev),
		},
		{
			label: t('translation.status'),
			icon: sun,
			state: isStatusShown,
			action: () => setIsStatusShown((prev) => !prev),
		},
		{
			label: t('translation.ticketOwner'),
			icon: twoPeopleIcon,
			state: isTicketOwnerShown,
			action: () => setIsTicketOwnerShown((prev) => !prev),
		},
		{
			label: t('translation.companyInCharge'),
			icon: Building,
			state: isCompanyInChargeShown,
			action: () => setIsCompanyInChargeShown((prev) => !prev),
		},
		{
			label: t('translation.linkedElements'),
			icon: docTicket,
			state: isLinkedFileShown,
			action: () => setIsLinkedFileShown((prev) => !prev),
		},
	];

	const optionsMissions = [
		{
			label: t('translation.description'),
			icon: textIconTickets,
			state: isDescriptionShown,
			action: () => setIsDescriptionShown((prev) => !prev),
		},
		{
			label: t('translation.dueDate'),
			icon: clock,
			state: isDueDateShown,
			action: () => setIsDueDateShown((prev) => !prev),
		},
		{
			label: t('translation.workDuration'),
			icon: hourglass,
			state: isWorkDurationShown,
			action: () => setIsWorkDurationShown((prev) => !prev),
		},
		{
			label: t('translation.status'),
			icon: sun,
			state: isStatusShown,
			action: () => setIsStatusShown((prev) => !prev),
		},
		{
			label: t('translation.ticketOwner'),
			icon: twoPeopleIcon,
			state: isTicketOwnerShown,
			action: () => setIsTicketOwnerShown((prev) => !prev),
		},
		{
			label: t('translation.linkedElements'),
			icon: docTicket,
			state: isLinkedFileShown,
			action: () => setIsLinkedFileShown((prev) => !prev),
		},
	];

	const handleContextMenu = (event) => {
		event.preventDefault();
		setMenuPosition({ x: event.pageX - 170, y: event.pageY + 20 });
		setTimeout(() => {
			setShowMenu(true);
		}, 100);
	};

	return (
		<>
			<div style={{ display: 'flex', gap: 10, marginLeft: 5, marginRight: 35 }}>
				<img src={threeRectangles} alt="" className={'imgRectangle hover'} onClick={handleClickLittle} />
				<img src={twoRectangles} alt="" className={'imgRectangle hover'} onClick={handleClickMiddle} />
				<img src={rectangle} alt="" className={'imgRectangle hover'} onClick={handleClickBig} />

				<div className={'containerDots hover'} onClick={handleContextMenu}>
					<img src={threeDots} alt="" className={'dots '} />
				</div>
			</div>

			{showMenu && (
				<ContextMenu
					x={menuPosition.x}
					y={menuPosition.y}
					options={isSnagging ? options : optionsMissions}
					check={true}
					onClose={() => setShowMenu(false)}
				/>
			)}
		</>
	);
}
