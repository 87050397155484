import React, { useState, useContext, useCallback, useMemo } from 'react';
import importIcon from '../../../../../../../assets/importIcon.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { REVIEW_RESULT } from '../FollowUp';
import { firestore, auth } from '../../../../../../../firebase/config';
import TypeContext from '../../../../../../../contexts/typeContext';
import AgencyContext from '../../../../../../../contexts/agencyContext';
import { joinPaths } from '../../../../../../../firebase/utils';
import { Paths } from '../../../../../../../firebase/paths';
import { createdByConstructor } from '../../../../../utils';
import { useProjectContext } from '../../../../../../../contexts/projectContext';
import { useDropzone } from 'react-dropzone';
import { generateUniqueFirestoreId, uploadOnStorage } from '../../../../../../../firebase/utils';
import UploadContext from '../../../../../../../contexts/uploadContext';
import { File } from '../../Ticket Documents/TicketDocument';
import ModalPreviewDocument from '../../../../../Documents/components/Modal Preview Document/ModalPreviewDocument';
import { arrayUnion } from 'firebase/firestore';
import { TICKET_STATUS } from '../FollowUp';
import { formatDateNumericallyWithPastDayKey, formatDateHourly } from '../../../../../../../useful/date';
import { useProjectUsersContext } from '../../../../../../../contexts/projectUsersContext';
import AuthDataContext from '../../../../../../../contexts/authDataContext';
import { desescapeSpecialChars } from '../../../../../../../useful/UsefulFunctions';
import { useClientsContext } from 'src/contexts/clientsContext';
import { TICKET_LOG_TYPE } from 'src/pages/Fiche Projet/Tickets/data';
import { resizeImage } from 'src/useful/image';

export default function TicketSolving({ ticket, isNewResolution, resolution }) {
	const { t, i18n } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);
	const [project] = useProjectContext();
	const [_, setUploadProgress] = useContext(UploadContext);
	const [users] = useProjectUsersContext();
	const [clients] = useClientsContext();

	const [readyToSend, setReadyToSend] = useState(false);

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [isDragging, setIsDragging] = useState(false);
	const [pendingFiles, setPendingFiles] = useState([]);
	const [loadingResolution, setLoadingResolution] = useState(false);

	const [reviewTitle, setReviewTitle] = useState('');
	const [reviewDescription, setReviewDescription] = useState('');
	const [newDeadline, setNewDeadline] = useState('');
	const [loadingApproval, setLoadingApproval] = useState(false);

	const [modalPreview, setModalPreview] = useState(false);
	const [previewFile, setPreviewFile] = useState(null);

	const isRefused = resolution?.reviews?.some((review) => review.result === REVIEW_RESULT.refused);
	const isApproved = ticket?.properties?.reviewers?.every((reviewer) =>
		resolution?.reviews?.some((review) => review.companyId === reviewer && review.result === REVIEW_RESULT.approved)
	);

	// ================================================ DROPPING FILES ================================================

	const handleFiles = async (files) => {
		const processedFiles = await Promise.all(
			files.map(async (file) => ({
				id: generateUniqueFirestoreId(),
				name: file.name,
				size: file.size,
				type: file.type,
				mimeType: file.type,
				file: await resizeImage(file),
			}))
		);

		setPendingFiles((prev) => [...prev, ...processedFiles]);
	};

	const onDrop = useCallback(
		(acceptedFiles) => {
			setIsDragging(false);
			if (readyToSend) {
				handleFiles(acceptedFiles);
			}
		},
		[readyToSend]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: true,
		onDragEnter: () => setIsDragging(true),
		onDragLeave: () => setIsDragging(false),
	});

	// ================================================ PREVIEW FILE ================================================

	const handlePreviewFile = (file) => {
		setPreviewFile({ ...file, type: file.mimeType });
		setModalPreview(true);
	};

	// ================================================ SUBMIT RESOLUTION ================================================

	const handleSubmitResolution = async () => {
		if (!loadingResolution && window.confirm(t('translation.confirmSubmitResolution'))) {
			setLoadingResolution(true);

			const uploadedFiles = [];

			for (const file of pendingFiles) {
				const fileId = file.id;

				const fileData = {
					id: fileId,
					name: file.name,
					type: file.type.startsWith('image/') ? 'image' : 'file',
					mimeType: file.type,
					size: file.size,
					url: '',
				};

				try {
					const downloadURL = await uploadOnStorage(
						fileId,
						file.file,
						{ ...fileData, type: file.type, page: 'tickets' },
						`projects/${project.id}/tickets/${ticket.id}/resolutions`,
						setUploadProgress,
						`${t('translation.tickets')} - ${ticket.name}}`
					);

					uploadedFiles.push({ ...fileData, url: downloadURL });
				} catch (error) {
					console.error(`Error uploading file:`, error);
				}
			}

			await firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.RESOLUTIONS))
				.add({
					title: title,
					description: description,
					createdBy: createdByConstructor(uid, userType, agencyId),
					createdAt: new Date().toISOString(),
					modifiedAt: new Date().toISOString(),
					files: uploadedFiles,
				})
				.catch((error) => {
					console.error('Error adding document: ', error);
					window.alert(t('translation.errorSubmitResolution'));
				});

			await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id)).update({
				issueStatus: TICKET_STATUS.pendingApproval,
			});

			setLoadingResolution(false);
			setReadyToSend(false);
			setPendingFiles([]);

			firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.LOGS)).add({
				type: TICKET_LOG_TYPE.solved,
				name: authData.surname + ' ' + authData.name,
				createdAt: new Date().toISOString(),
				createdBy: createdByConstructor(uid, userType, agencyId),
			});
		}
	};

	// ================================================ REVIEW ================================================

	const handleApproval = async (status) => {
		const isClient = userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (!loadingApproval && (isClient || agencyId) && window.confirm(t('translation.confirmChoice'))) {
			setLoadingApproval(true);

			const ticketApproved =
				status === REVIEW_RESULT.approved &&
				ticket?.properties?.reviewers?.every(
					(reviewer) =>
						!resolution?.reviews ||
						resolution?.reviews?.some(
							(review) =>
								(review.companyId === reviewer && review.result === REVIEW_RESULT.approved) ||
								review.companyId === agencyId ||
								(review.companyId === 'clients' && isClient)
						)
				);

			const uploadedFiles = [];

			for (const file of pendingFiles) {
				const fileId = file.id;

				const fileData = {
					id: fileId,
					name: file.name,
					type: file.type.startsWith('image/') ? 'image' : 'file',
					mimeType: file.type,
					size: file.size,
					url: '',
				};

				try {
					const downloadURL = await uploadOnStorage(
						fileId,
						file.file,
						{ ...fileData, type: file.type, page: 'tickets' },
						`projects/${project.id}/tickets/${ticket.id}/resolutions`,
						setUploadProgress,
						`${t('translation.tickets')} - ${ticket.name}}`
					);

					uploadedFiles.push({ ...fileData, url: downloadURL });
				} catch (error) {
					console.error(`Error uploading file:`, error);
				}
			}

			await firestore
				.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.RESOLUTIONS, resolution.id))
				.update({
					reviews: arrayUnion({
						companyId: isClient ? 'clients' : agencyId,
						result: status,
						title: reviewTitle,
						description: reviewDescription,
						createdBy: createdByConstructor(uid, userType, agencyId),
						createdAt: new Date().toISOString(),
						files: uploadedFiles,
					}),
				});

			if (status === REVIEW_RESULT.refused) {
				let updatedProperties = {
					issueStatus: TICKET_STATUS.refused,
					companiesReviewed: isClient ? ['clients'] : [agencyId],
					companiesViewedRefused: isClient ? ['clients'] : [agencyId],
				};

				const date = new Date(newDeadline);
				if (!isNaN(date.valueOf())) {
					updatedProperties = { ...updatedProperties, 'properties.dueAt': date.toISOString() };
				}

				await firestore
					.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id))
					.update(updatedProperties);
			} else if (ticketApproved) {
				await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id)).update({
					issueStatus: TICKET_STATUS.approved,
				});
			} else {
				await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id)).update({
					companiesReviewed: arrayUnion(isClient ? 'clients' : agencyId),
				});
			}

			setLoadingApproval(false);

			firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.LOGS)).add({
				type: status === REVIEW_RESULT.approved ? TICKET_LOG_TYPE.approved : TICKET_LOG_TYPE.refused,
				name: authData.surname + ' ' + authData.name,
				createdAt: new Date().toISOString(),
				createdBy: createdByConstructor(uid, userType, agencyId),
			});
		}
	};

	// ==================================== Reaction date ====================================
	const resolutionDate = useMemo(() => {
		const date = formatDateNumericallyWithPastDayKey(resolution?.createdAt, i18n.language);
		const time = formatDateHourly(resolution?.createdAt, i18n.language);

		if (date === 'today') {
			return t('translation.todayAt', { time });
		} else if (date === 'yesterday') {
			return t('translation.yesterdayAt', { time });
		} else if (date === 'monday') {
			return t('translation.mondayAt', { time });
		} else if (date === 'tuesday') {
			return t('translation.tuesdayAt', { time });
		} else if (date === 'wednesday') {
			return t('translation.wednesdayAt', { time });
		} else if (date === 'thursday') {
			return t('translation.thursdayAt', { time });
		} else if (date === 'friday') {
			return t('translation.fridayAt', { time });
		} else if (date === 'saturday') {
			return t('translation.saturdayAt', { time });
		} else if (date === 'sunday') {
			return t('translation.sundayAt', { time });
		} else {
			return t('translation.theAt', { date, time });
		}
	}, [resolution?.createdAt, i18n.language]);

	return (
		<div className={'followUpStep'}>
			<div className={'bar'}>
				<div
					className={'frontBarFollowUp'}
					style={{
						height: isNewResolution ? '50%' : '100%',
						backgroundColor: isRefused
							? '#ff0000'
							: isApproved
								? '#59c425'
								: !isNewResolution || ticket?.properties?.companies?.includes(agencyId)
									? '#1C57DD'
									: '#DEDEDEFF',
					}}
				/>
			</div>
			<div>
				<p className={'titleStep'}>
					{isNewResolution && !readyToSend
						? t('translation.waitingForTicketSolving')
						: t('translation.ticketSolvingProposal')}
				</p>
				{readyToSend && (
					<div className={'formulaireSubmitTicket'}>
						<input
							type="text"
							placeholder={t('translation.title')}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
						<textarea
							name=""
							id=""
							cols="30"
							rows="10"
							placeholder={t('translation.describeResolution')}
							value={description}
							onChange={(e) => setDescription(e.target.value)}></textarea>

						{pendingFiles.length > 0 && (
							<div className="uploadedFiles">
								{pendingFiles.map((file) => (
									<File
										key={file.id}
										file={file}
										handleDeleteFile={() =>
											setPendingFiles((prev) => prev.filter((f) => f.id !== file.id))
										}
										canModify={true}
									/>
								))}
							</div>
						)}
						<label htmlFor="fileInput" className={'buttonAddItems'} {...getRootProps()}>
							<img src={importIcon} alt="" />
							<div>{t('translation.joinFilesAndPictures')}</div>

							<input {...getInputProps()} />
							{isDragging && readyToSend && (
								<div className="dropzone-overlay">
									<p>{t('translation.dropFilesHere')}</p>
								</div>
							)}
						</label>
						<input
							id="fileInput"
							type="file"
							multiple
							style={{ display: 'none' }}
							onChange={(e) => handleFiles(Array.from(e.target.files))}
						/>
					</div>
				)}

				{!isNewResolution && (
					<div className={'resolutionSubmitted'}>
						<span className={'whoPropos'}>
							{' '}
							<span className={'boldBlue'}>
								{[authData, ...users]?.find((user) => user.id === resolution?.createdBy.userId)
									?.surname +
									' ' +
									[authData, ...users]?.find((user) => user.id === resolution?.createdBy.userId)
										?.name}
							</span>{' '}
							{t('translation.hasProposed')} :
						</span>
						<p className={'date'}>{desescapeSpecialChars(resolutionDate)}</p>

						<p className={'titleResolution'}>{resolution?.title}</p>
						<p className={'descriptionResolution'}>{resolution?.description}</p>

						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{resolution?.files?.map((file) => (
								<File
									key={file.id}
									file={file}
									canModify={false}
									handlePreviewFile={handlePreviewFile}
								/>
							))}
						</div>
					</div>
				)}

				{resolution?.reviews?.map((review) => (
					<ReviewComponent
						key={review.id}
						review={review}
						resolution={resolution}
						handlePreviewFile={handlePreviewFile}
					/>
				))}

				{isNewResolution && ticket?.properties?.companies?.includes(agencyId) && (
					<div style={{ display: 'flex' }}>
						<div
							className={'buttonInTicket hover'}
							onClick={() => {
								if (readyToSend) {
									handleSubmitResolution();
								} else {
									setReadyToSend(true);
								}
							}}>
							{loadingResolution
								? t('common.loading')
								: readyToSend
									? t('translation.send')
									: t('translation.submitTicketSoling')}
						</div>
					</div>
				)}

				{!isNewResolution &&
					(ticket?.properties?.reviewers?.includes(agencyId) ||
						(ticket?.properties?.reviewers?.includes('clients') &&
							(userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId)))) &&
					!resolution?.reviews?.some((review) => review.companyId === agencyId) &&
					!resolution?.reviews?.some((review) => review.result === REVIEW_RESULT.refused) &&
					!(
						(userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId)) &&
						resolution?.reviews?.some((review) => review.companyId === 'clients')
					) && (
						<div className={'formulaireSubmitTicket'}>
							<p className={'titleStep'}>{t('translation.yourResponse')}</p>
							<input
								type="text"
								placeholder={t('translation.title')}
								value={reviewTitle}
								onChange={(e) => setReviewTitle(e.target.value)}
							/>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span className={'labelInput'}>{t('translation.newDeadline')}</span>
								<input
									style={{ paddingRight: 12 }}
									type="date"
									placeholder={t('translation.title')}
									value={newDeadline}
									onChange={(e) => setNewDeadline(e.target.value)}
								/>
							</div>
							<textarea
								name=""
								id=""
								cols="30"
								rows="10"
								placeholder={t('translation.description')}
								value={reviewDescription}
								onChange={(e) => setReviewDescription(e.target.value)}
							/>

							{pendingFiles.length > 0 && (
								<div className="uploadedFiles">
									{pendingFiles.map((file) => (
										<File
											key={file.id}
											file={file}
											handleDeleteFile={() =>
												setPendingFiles((prev) => prev.filter((f) => f.id !== file.id))
											}
											canModify={true}
										/>
									))}
								</div>
							)}
							<label htmlFor="fileInput" className={'buttonAddItems'} {...getRootProps()}>
								<img src={importIcon} alt="" />
								<div>{t('translation.joinFilesAndPictures')}</div>

								<input {...getInputProps()} />
								{isDragging && readyToSend && (
									<div className="dropzone-overlay">
										<p>{t('translation.dropFilesHere')}</p>
									</div>
								)}
							</label>
							<input
								id="fileInput"
								type="file"
								multiple
								style={{ display: 'none' }}
								onChange={(e) => handleFiles(Array.from(e.target.files))}
							/>

							<div className={'buttonApproval'}>
								<div
									className={'buttonResponse hover'}
									style={{
										backgroundColor: 'rgba(60,165,4,0.1)',
										borderColor: '#3CA504',
										color: '#3CA504',
									}}
									onClick={() => handleApproval(REVIEW_RESULT.approved)}>
									{loadingApproval ? t('common.loading') : t('translation.approve')}
								</div>
								<div
									className={'buttonResponse hover'}
									style={{
										backgroundColor: 'rgba(255,0,0,0.1)',
										borderColor: '#ff0000',
										color: '#ff0000',
									}}
									onClick={() => handleApproval(REVIEW_RESULT.refused)}>
									{loadingApproval ? t('common.loading') : t('translation.refuse')}
								</div>
							</div>
						</div>
					)}
			</div>

			<ModalPreviewDocument
				modalPreview={modalPreview}
				setModalPreview={setModalPreview}
				files={resolution?.files?.map((file) => ({ ...file, type: file.mimeType }))}
				previewFile={previewFile}
				setPreviewFile={setPreviewFile}
			/>
		</div>
	);
}

function ReviewComponent({ review, resolution, handlePreviewFile }) {
	const { t, i18n } = useTranslation();

	const authData = useContext(AuthDataContext);
	const [users] = useProjectUsersContext();

	const reviewDate = useMemo(() => {
		const date = formatDateNumericallyWithPastDayKey(review?.createdAt, i18n.language);
		const time = formatDateHourly(review?.createdAt, i18n.language);

		if (date === 'today') {
			return t('translation.todayAt', { time });
		} else if (date === 'yesterday') {
			return t('translation.yesterdayAt', { time });
		} else if (date === 'monday') {
			return t('translation.mondayAt', { time });
		} else if (date === 'tuesday') {
			return t('translation.tuesdayAt', { time });
		} else if (date === 'wednesday') {
			return t('translation.wednesdayAt', { time });
		} else if (date === 'thursday') {
			return t('translation.thursdayAt', { time });
		} else if (date === 'friday') {
			return t('translation.fridayAt', { time });
		} else if (date === 'saturday') {
			return t('translation.saturdayAt', { time });
		} else if (date === 'sunday') {
			return t('translation.sundayAt', { time });
		} else {
			return t('translation.theAt', { date, time });
		}
	}, [review?.createdAt, i18n.language]);

	return (
		<div className={'resolutionSubmitted'} key={resolution?.id}>
			<span className={'whoPropos'}>
				{' '}
				<span
					className={'boldBlue'}
					style={{
						color: review.result === REVIEW_RESULT.refused ? '#ff0000' : '#3CA504',
					}}>
					{review.result === REVIEW_RESULT.refused ? t('translation.refusedBy') : t('translation.approvedBy')}
				</span>{' '}
				<span className={'boldBlue'}>
					{[authData, ...users]?.find((user) => user.id === review.createdBy.userId)?.surname +
						' ' +
						[authData, ...users]?.find((user) => user.id === review.createdBy.userId)?.name}
				</span>
			</span>
			<p className={'date'}>{desescapeSpecialChars(reviewDate)}</p>

			<p className={'titleResolution'}>{review.title}</p>
			<p className={'descriptionResolution'}>{review.description}</p>

			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{review.files?.map((file) => (
					<File key={file.id} file={file} canModify={false} handlePreviewFile={handlePreviewFile} />
				))}
			</div>
		</div>
	);
}

TicketSolving.propTypes = {
	ticket: PropTypes.object.isRequired,
	isNewResolution: PropTypes.bool.isRequired,
	resolution: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		createdAt: PropTypes.string,
		createdBy: PropTypes.shape({
			userId: PropTypes.string,
			userType: PropTypes.string,
			agencyId: PropTypes.string,
		}),
		files: PropTypes.arrayOf(PropTypes.object),
		reviews: PropTypes.arrayOf(
			PropTypes.shape({
				companyId: PropTypes.string,
				createdAt: PropTypes.string,
				result: PropTypes.string,
				title: PropTypes.string,
				description: PropTypes.string,
				createdBy: PropTypes.shape({
					userId: PropTypes.string,
					userType: PropTypes.string,
					agencyId: PropTypes.string,
				}),
			})
		),
	}).isRequired,
};
