import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export default function DropZoneFile({ isDragging, handleDrop, actualPath }) {
	const { t } = useTranslation();

	const onDropCallback = useCallback(
		(acceptedFiles) => {
			handleDrop(acceptedFiles, actualPath);
		},
		[actualPath, handleDrop]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropCallback });

	return (
		<>
			{isDragging ? (
				<div {...getRootProps()} className={'dropZone'} style={{ zIndex: 2 }}>
					<input {...getInputProps()} />
					<div className={'containerTextDropZone'}>
						<p style={{ color: 'white', fontSize: 22, marginBottom: 0 }}>{t('translation.deposeFiles')}</p>
						<p style={{ color: 'white', fontSize: 13 }}>{t('translation.dropZonePlaceholder')}</p>
					</div>
				</div>
			) : null}
		</>
	);
}
