import React from 'react';
import '../../../messaging.scss';
import { chooseIconFile } from '../../../../Fiche Projet/Documents/utils';

export default function FileMessageResponse({ name, type }) {
	return (
		<div className={'fileMessage'}>
			<img src={chooseIconFile(type)} alt="" className={'fileTypeIcon'} />
			{name}
		</div>
	);
}
