import React, { useContext } from 'react';
import './_partsList.scss';
import ItemPartnerFilter from './ItemPartnerFilter';
import { useTranslation } from 'react-i18next';
import { usePartnersContext } from '../../../../../contexts/partnersContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { useClientsContext } from '../../../../../contexts/clientsContext';

export default function PartnerFilter({
	projectId,
	setSelectedPart,
	selectedPart,
	isClientInterface,
	research,
	setResearch,
}) {
	const { t } = useTranslation();
	const agencyId = useContext(AgencyContext);
	const [partners] = usePartnersContext();
	const [clients] = useClientsContext();

	return (
		<div className={'partList'}>
			{/*
			// TODO: [Medium] Search files
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img
					src={searchIcon}
					alt=""
					className={'searchIconRechercheFichiers'}
					style={{ zIndex: 1, opacity: research.length > 0 ? 1 : 0.5 }}
				/>
				<input
					style={{ width: research.length > 0 ? 325 : 80 }}
					autoComplete={'off'}
					type={'text'}
					placeholder={t('translation.research')}
					className={'rechercheFichiers'}
					value={research}
					onChange={(e) => setResearch(e.target.value)}
				/>
			</div>
			*/}
			{research.length < 1 && (
				<>
					<p className={'title'}>{t('translation.visibilite')}</p>
					<ItemPartnerFilter
						projectId={projectId}
						partnerId={agencyId}
						agencyId={agencyId}
						vous={true}
						selectedPart={selectedPart}
						setSelectedPart={setSelectedPart}
						isClientInterface={isClientInterface}
					/>

					<div className={'littleBar'} />

					{!isClientInterface && clients?.length === 0 && partners.length === 0 && (
						<p className={'noPartners'}>{t('translation.addMoAndPartners')}</p>
					)}

					{!isClientInterface &&
						clients?.length > 0 &&
						!clients?.map((item) => item.id).includes(agencyId) && (
							<ItemPartnerFilter
								projectId={projectId}
								partnerId={'clients'}
								agencyId={agencyId}
								selectedPart={selectedPart}
								setSelectedPart={setSelectedPart}
								isClientInterface={isClientInterface}
							/>
						)}

					{!isClientInterface && clients?.length > 0 && partners.length === 0 && (
						<p className={'noPartners'}>{t('translation.addPartners')}</p>
					)}

					{partners.map((it) => (
						<ItemPartnerFilter
							key={it.id}
							projectId={projectId}
							setSelectedPart={setSelectedPart}
							selectedPart={selectedPart}
							partnerId={it.id}
							agencyId={agencyId}
							isClientInterface={isClientInterface}
						/>
					))}
				</>
			)}
		</div>
	);
}
