import React, { useContext, useEffect, useState, useMemo } from 'react';
import searchIcon from '../../assets/searchIcon.png';
import CardOnBoardingProjet from './Cards/CardOnBoardingProjet';
import ProjetCard from './Cards/ProjetCard';
import gerySearchIcon from '../../assets/gerySearchIcon.png';
import WidgetExpirationCarte from './Widgets/WidgetExpirationCarte';
import WidgetEchecPaiement from './Widgets/WidgetEchecPaiement';
import ModauxAddProject from './Modaux/ModauxAddProject';
import plusSquare from '../../assets/plusSquare.svg';
import integrerProjetVisuel from '../../assets/integrerProjetVisuel.png';
import printer from '../../assets/printer.svg';
import AgencyContext from '../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import TypeContext from '../../contexts/typeContext';
import ModalAddProjectClient from './Modaux/ModalAddProjectClient';
import { firestore, auth } from '../../firebase/config';
import { NEW_USER_COLLECTION, NOTIFICATION_COLLECTION, Paths } from '../../firebase/paths';
import filterIcon from '../../assets/filter.svg';
import { useProjectsContext } from '../../contexts/projectsContext';
import { useOnlyAgencyProjectsContext } from '../../contexts/onlyAgencyProjectsContext';
import AuthDataContext from '../../contexts/authDataContext';
import { transformString } from '../../useful/UsefulFunctions';
import ProjectSynthesisReport from '../Reports/Project Synthesis Report/ProjectSynthesisReport';
import { joinPaths } from 'src/firebase/utils';
import { mergeAndOrder } from 'src/useful/utils';
import { PR_ID } from 'src/useful/constants';

export default function ProjectList({
	auth,
	baniereExpirationCarte,
	setBaniereExpirationCarte,
	baniereEchecPaiement,
	logoUrl,
	modalAddProject,
	setModalAddProject,
	setModalWaitForApproval,
	setModalAccessDenied,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const [projects] = useProjectsContext();
	const [allProjects] = useOnlyAgencyProjectsContext();

	const isAdmin = authData?.subtype === 'administrator';

	const [modalProjectSynthesisReport, setModalProjectSynthesisReport] = useState(false);
	const [reportProjects, setReportProjects] = useState([]);
	const [reportIsLoading, setReportIsLoading] = useState(false);

	const [modalAddProjectClient, setModalAddProjectClient] = useState(false);

	// Sous-menu

	const [enCours, setEnCours] = useState(true);
	const [passes, setPasses] = useState(false);

	// Research
	const [research, setResearch] = useState('');

	// List Height

	const [isThereCurrentNotification, setIsThereNotification] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);

	const filteredProjects = useMemo(() => {
		return projects?.filter(
			(item) =>
				showNotifications ||
				((enCours ? item.active !== false : item.active === false) &&
					transformString(item.name + item.tag + item.clientName).includes(transformString(research)))
		);
	}, [projects, showNotifications, enCours, research]);

	const allProjectsFiltered = useMemo(() => {
		return allProjects?.filter(
			(item) =>
				showNotifications ||
				((enCours ? item.active !== false : item.active === false) &&
					transformString(item.name + item.tag + item.clientName).includes(transformString(research)))
		);
	}, [allProjects, showNotifications, enCours, research]);

	useEffect(() => {
		if (auth) {
			const subscriber = firestore
				.collection(`${NEW_USER_COLLECTION}/${auth}/${NOTIFICATION_COLLECTION}`)
				.where('isUnread', '==', true)
				.limit(1)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot && querySnapshot.size > 0) {
						setIsThereNotification(true);
					} else {
						setIsThereNotification(false);
					}
				});

			return () => subscriber();
		}
	}, [auth]);

	function handleClickNewProject() {
		if (type === 'clients') {
			setModalAddProjectClient(true);
		} else {
			setModalAddProject(true);
		}
	}

	const handleReadAllNotifications = () => {
		firestore
			.collection(`${NEW_USER_COLLECTION}/${auth}/${NOTIFICATION_COLLECTION}`)
			.where('isUnread', '==', true)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const batch = firestore.batch();
					querySnapshot.forEach((doc) => {
						batch.update(doc.ref, { isUnread: false });
					});
					batch.commit();
					setShowNotifications(false);
				}
			});
	};

	const handleOpenReport = async () => {
		setReportIsLoading(true);

		const reportProjects = [];

		await Promise.all(
			projects.map(
				(project) =>
					new Promise((resolve) => {
						const getMissionsAndCollaborators = async () => {
							let missions = (
								await firestore
									.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
									.where('properties.type', '==', 'mission')
									.where('properties.visibility', '!=', 'private')
									.get()
							).docs.map((doc) => ({ ...doc.data(), id: doc.id }));
							missions = missions
								?.filter(
									(mission) =>
										mission.properties?.owner?.userId === auth ||
										mission.properties?.users?.includes(auth)
								)
								.sort((a, b) => {
									if (!a.properties?.dueAt && !b.properties?.dueAt) return 0;
									if (!a.properties?.dueAt) return 1;
									if (!b.properties?.dueAt) return -1;
									return new Date(a.properties.dueAt) - new Date(b.properties.dueAt);
								});

							let collaborators = (
								await firestore
									.collection(joinPaths(Paths.PROJECTS, project.id, Paths.ACCOUNTS))
									.where('type', '==', 'collaborators')
									.where('agency', '==', agency)
									.get()
							).docs.map((doc) => ({ ...doc.data(), id: doc.id }));

							const collaboratorsData = [];
							await Promise.all(
								collaborators.map(
									async (collaborator) =>
										new Promise((resolve) => {
											firestore
												.doc(
													joinPaths(
														Paths.AGENCIES,
														agency,
														Paths.COLLABORATORS,
														collaborator.id
													)
												)
												.get()
												.then((doc) => {
													collaboratorsData.push({ ...collaborator, ...doc.data() });
													resolve();
												});
										})
								)
							);

							reportProjects.push({
								...project,
								missions,
								collaborators: collaboratorsData.filter(
									(collaborator) => collaborator.subtype !== 'administrator'
								),
							});

							resolve();
						};

						getMissionsAndCollaborators();
					})
			)
		);

		setReportProjects(
			mergeAndOrder(
				(a, b) => {
					const aName = (a?.tag ?? '') + ' ' + (a?.name ?? '');
					const bName = (b?.tag ?? '') + ' ' + (b?.name ?? '');
					return aName.localeCompare(bName);
				},
				'id',
				[],
				reportProjects
			)
		);
		setModalProjectSynthesisReport(true);
		setReportIsLoading(false);
	};

	function ButtonTab({ active, title, onClick }) {
		return (
			<div
				onClick={onClick}
				className={'buttonListProjets hover'}
				style={{
					fontWeight: active ? 700 : 700,
					color: active ? '#4867EC' : '#9f9f9f',
				}}>
				{title}
				<div className={'blueBar'} style={{ zIndex: modalAddProject ? 0 : 10, opacity: active ? 1 : 0 }} />
			</div>
		);
	}

	return (
		<div className="liste">
			<div className={'inner'}>
				{baniereExpirationCarte && (
					<WidgetExpirationCarte setBaniereExpirationCarte={setBaniereExpirationCarte} />
				)}
				{baniereEchecPaiement && <WidgetEchecPaiement />}

				<div className={'headerProjectList'}>
					<div className={'projectWrapper'}>
						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex', alignItems: 'center', width: 515, marginLeft: 44 }}>
								<p className={'titleProjects'}>{t('translation.myProject')}</p>

								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={searchIcon}
										alt=""
										className={'searchIconRechercheProjet'}
										style={{ opacity: research.length > 0 ? 1 : 0.5 }}
									/>
									<input
										style={{ width: research.length > 0 ? 255 : 148 }}
										autoComplete={'off'}
										type={'text'}
										placeholder={t('projects_page.research_a_project')}
										className={'rechercheProjetEnCour'}
										value={research}
										onChange={(e) => setResearch(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className={'containerChoiceRow'}>
							<ButtonTab
								active={enCours}
								title={t('projects_page.ongoing')}
								onClick={() => {
									setEnCours(true);
									setPasses(false);
								}}
							/>
							<ButtonTab
								active={passes}
								title={t('projects_page.completed')}
								onClick={() => {
									setEnCours(false);
									setPasses(true);
								}}
							/>
						</div>
						<div className={'containerNewProjectButtonAndBar'}>
							{type !== 'clients' && (
								<div className={'containerNewProjectButton'}>
									{isAdmin && agency === PR_ID && (
										<div onClick={handleOpenReport} className={'hover newProjectButton'}>
											<img className={'plusSquare'} src={printer} alt="iconPlus" />
											<p>{t('translation.printReport')}</p>
										</div>
									)}

									{showNotifications && (
										<span className={' markAllAsRead hover'} onClick={handleReadAllNotifications}>
											{t('projects_page.read_all')}
										</span>
									)}
									<div
										onClick={() => setShowNotifications((prev) => !prev)}
										className={'hover newProjectButton'}>
										<img className={'plusSquare'} src={filterIcon} alt="iconPlus" />
										<p>
											{' '}
											{showNotifications
												? t('projects_page.show_all_projects')
												: t('projects_page.show_notified_projects')}
										</p>
										{isThereCurrentNotification && (
											<div
												style={{
													marginRight: -14,
													marginTop: -4,
													width: 11,
													height: 11,
													borderRadius: '50%',
													backgroundColor: 'red',
													border: '1px solid #fff',
												}}
											/>
										)}
									</div>
									<div onClick={handleClickNewProject} className={'hover newProjectButton'}>
										<img className={'plusSquare'} src={plusSquare} alt="iconPlus" />
										<p>{t('projects_page.add_a_project')}</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>

				{!enCours && filteredProjects.length === 0 && (
					<div className={'placeholderProjetAcheve'}>
						<img src={integrerProjetVisuel} alt="" className={'integrerProjetVisuel'} />
						{t('translation.noPastProjects')} <br /> <br /> {t('translation.changeProjectStatus')}
					</div>
				)}
				{enCours && projects.length === 0 && authData ? (
					<div style={{ display: 'flex', width: '100vw', flexWrap: 'wrap' }}>
						{[1, 2, 3, 4].map((number) => (
							<CardOnBoardingProjet key={number} nom={`Project ${number}`} number={number} />
						))}
					</div>
				) : (
					<div style={{ display: 'flex', width: '100vw', flexWrap: 'wrap', justifyContent: 'center' }}>
						{filteredProjects?.map((item, index) => (
							<ProjetCard
								key={item.id}
								id={item.id}
								waitingForApproval={item.waitingStatus === 'pending'}
								setModalWaitForApproval={setModalWaitForApproval}
								accessDenied={item.waitingStatus === 'denied'}
								setModalAccessDenied={setModalAccessDenied}
								projectData={item}
								isMandataire={item.creator === agency}
								isModeLecture={false}
								showNotifications={showNotifications}
							/>
						))}
						<div className={'projetCard'} style={{ opacity: 0, height: 1 }} />
					</div>
				)}

				{authData?.waitingStatus !== 'pending' &&
					authData?.waitingStatus !== 'denied' &&
					!showNotifications && (
						<>
							{allProjectsFiltered?.filter(
								(item) =>
									(enCours ? item.active !== false : item.active === false) &&
									transformString(item.name + item.tag + item.clientName).includes(
										transformString(research)
									)
							).length > 0 && (
								<div className={'headerProjectList'} style={{ marginTop: 50 }}>
									<div className={'projectWrapper'}>
										<div style={{ display: 'flex' }}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													width: 500,
													marginLeft: 44,
												}}>
												<p className={'titleProjects'}>{t('translation.allProjects')}</p>
											</div>
										</div>
									</div>
								</div>
							)}
							<div
								style={{ display: 'flex', width: '100vw', flexWrap: 'wrap', justifyContent: 'center' }}>
								{allProjectsFiltered?.map((item, index) => (
									<ProjetCard
										key={item.id}
										id={item.id}
										waitingForApproval={item.waitingStatus === 'pending'}
										setModalWaitForApproval={setModalWaitForApproval}
										accessDenied={item.waitingStatus === 'denied'}
										setModalAccessDenied={setModalAccessDenied}
										projectData={item}
										isMandataire={item.creator === agency}
										isModeLecture={true}
										showNotifications={showNotifications}
									/>
								))}
								<div className={'projetCard'} style={{ opacity: 0, height: 1 }} />
							</div>
						</>
					)}

				<div style={{ height: 50 }} />

				{research && filteredProjects?.length === 0 && allProjectsFiltered?.length === 0 && (
					<div className="noResultResearchComponent">
						<img src={gerySearchIcon} alt="Search Icon" />
						<div>
							<p>{research}</p>
						</div>
						<p className="primaryText">{t('research_project.no_result_1')}</p>
						<p className="secondaryText">{t('research_project.no_result_2')}</p>
					</div>
				)}
			</div>
			<ModauxAddProject auth={auth} modalAddProject={modalAddProject} setModalAddProject={setModalAddProject} />
			<div style={{ zIndex: 1 }}>
				<ModalAddProjectClient
					modalAddProjectClient={modalAddProjectClient}
					setModalAddProjectClient={setModalAddProjectClient}
				/>
			</div>

			<ProjectSynthesisReport
				isOpen={modalProjectSynthesisReport}
				onClose={() => setModalProjectSynthesisReport(false)}
				projects={reportProjects}
			/>

			{reportIsLoading && (
				<div className="deletion-overlay">
					<div className="deletion-spinner"></div>
					<p>Chargement du rapport...{/* TODO: translate */}</p>
				</div>
			)}
		</div>
	);
}
