import langIcon from '../../assets/langIcon.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './LanguageSelector.css';
import { useTranslation } from 'react-i18next';
import { auth, firestore } from '../../firebase/config';
import TypeContext from '../../contexts/typeContext';
import AgencyContext from '../../contexts/agencyContext';
import ModalChooseLanguages from '../Main/Modal Choose Languages/ModalChooseLanguages';

export default function LanguageSelector({ openBottom = false }) {
	const { i18n } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [opened, setOpened] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		if (ref?.current) {
			const handleClickOutside = (event) => {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpened(false);
				}
			};

			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [ref]);

	const changeLanguage = async (lang) => {
		await i18n.changeLanguage(lang);
		setOpened(false);
		if (uid) {
			await firestore.doc(`accounts/${uid}`).update({ language: lang });
			if (type === 'collaborators' && agencyId) {
				await firestore.doc(`agencies/${agencyId}/collaborators/${uid}`).update({ language: lang });
			} else if (type === 'clients') {
				await firestore.doc(`clients/${uid}`).update({ language: lang });
			}
		}
	};

	return (
		<div className={'languageSelector'} ref={ref}>
			<div
				className={`languageSelectorPreview ${opened ? `previewOpened ${openBottom ? 'previewOpenedBottom' : ''}` : ''}`}
				onClick={() => setOpened((p) => !p)}>
				<div className={'row'} style={{ alignItems: 'center' }}>
					<img
						style={{
							width: 18,
							marginRight: 8,
						}}
						src={langIcon}
						alt=""
					/>
					<p
						style={{
							fontWeight: 600,
							fontSize: 14,
							margin: 0,
							marginTop: -1,
						}}>
						{Languages[i18n.language] ?? Languages.default}
					</p>
				</div>
			</div>

			<ModalChooseLanguages isOpen={opened} onClose={() => setOpened(false)} changeLanguage={changeLanguage} />
		</div>
	);
}

const Languages = {
	ar: 'العربية',
	en: 'English',
	es: 'Español',
	fr: 'Français',
	hi: 'हिन्दी',
	sv: 'Svenska',
	default: 'English',
};
