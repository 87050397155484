import React, { useContext, useEffect, useRef, useState } from 'react';
import infoAccount from '../../../../assets/infoAccount.svg';
import editAccountBleu from '../../../../assets/editAccountBleu.svg';
import { dateString, stringSubtype, transformString } from '../../../../useful/UsefulFunctions';
import { auth, firestore } from '../../../../firebase/config';
import AgencyContext from '../../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';

export default function AccountItem({
	setIsInfoContactOpen,
	isInfosContactOpen,
	isCreationCompte,
	setIsCreationCompte,
	uid,
	type,
	collaboratorData,
	selectedAccount,
	setSelectedAccount,
	research,
	setResearchNumber,
	setIsModifContactOpen,
	isModifContactOpen,
	agencyProjects,
	canDelete,
	canCreate,
}) {
	const { t } = useTranslation();

	const authId = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);

	const [accountData, setAccountData] = useState(collaboratorData);
	const [nbProject, setNbProject] = useState(0);

	useEffect(() => {
		if (type === 'collaborators') {
			setAccountData(collaboratorData);
		} else {
			firestore
				.doc(`${type}/${uid}`)
				.get()
				.then((doc) => {
					if (doc && doc.exists) {
						setAccountData({ ...doc.data(), id: doc.id });
					}
				});
			const subscriber = firestore.collection(`${type}/${uid}/projects`).onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					let nb = 0;
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists && agencyProjects.includes(doc.id)) {
							nb++;
						}
					});
					setNbProject(nb);
				}
			});
			return () => subscriber();
		}
	}, [uid, type, collaboratorData, agencyProjects]);

	const [lastConnexion, setLastConnexion] = useState(null);
	const [oldResearch, setOldResearch] = useState(false);

	useEffect(() => {
		if (accountData && accountData.name) {
			if (
				transformString(accountData.surname + accountData.name).includes(transformString(research)) &&
				!oldResearch
			) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (
				!transformString(accountData.surname + accountData.name).includes(transformString(research)) &&
				oldResearch
			) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
		if (accountData) {
			setLastConnexion(new Date(accountData.lastConnexion).getTime());
		}
	}, [research, accountData]);

	// HandleClick on Item except on the editIcon

	const refItem = useRef();
	const refEditIcon = useRef();

	function handleClickOutside(event) {
		if (
			refItem.current &&
			refEditIcon.current &&
			refItem.current.contains(event.target) &&
			!refEditIcon.current.contains(event.target)
		) {
			if (isInfosContactOpen && selectedAccount?.uid === accountData.uid) {
				setIsInfoContactOpen(false);
				setSelectedAccount(null);
			} else {
				setIsCreationCompte(false);
				setIsModifContactOpen(false);
				setSelectedAccount({ ...accountData, nbProject });
				setIsInfoContactOpen(true);
			}
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refEditIcon, refItem, accountData, isInfosContactOpen, selectedAccount]);

	return (
		<>
			{accountData &&
				(!research ||
					(accountData.surname &&
						accountData.name &&
						transformString(accountData.surname + accountData.name).includes(transformString(research)))) &&
				authId !== accountData.uid && (
					<div>
						<div
							className={'itemCollaborateur'}
							style={{
								width: isInfosContactOpen || isCreationCompte || isModifContactOpen ? 800 : 1280,
								backgroundColor: selectedAccount?.uid === accountData.uid ? '#F4F6FA' : null,
							}}
							ref={refItem}>
							<div
								style={{
									marginLeft: 20,
									width: 260,
									display: 'flex',
									alignItems: 'center',
								}}>
								{type === 'agencies' ? (
									<div className={'profilePictureBleuClair'} style={{ borderRadius: 7 }}>
										<p
											style={{
												letterSpacing: 1,
												fontSize: 15,
												margin: 0,
											}}>
											{accountData?.diminutifAgency?.charAt(0)}
										</p>
									</div>
								) : (
									<div className={type === 'clients' ? 'profilePictureVert' : 'profilePictureBleu'}>
										<p
											style={{
												letterSpacing: 1,
												fontSize: 15,
												margin: 0,
											}}>
											{accountData?.surname?.charAt(0)?.toUpperCase()}
											{accountData?.name?.charAt(0)?.toUpperCase()}
										</p>
									</div>
								)}

								<p className={'nomCompte'}>
									{accountData.surname} {accountData.name}
								</p>
							</div>

							<div
								className={'transitionItemAccount'}
								style={{
									marginLeft: 30,
									width: isInfosContactOpen || isCreationCompte || isModifContactOpen ? 0 : 240,
									overflow: 'hidden',
									display: 'flex',
									justifyContent: 'center',
								}}>
								<p className={'dateCompte'}>{dateString(t, accountData.date)}</p>
							</div>

							<div
								style={{
									marginLeft: 30,
									width: 240,
									display: 'flex',
									justifyContent: 'center',
								}}>
								{type === 'collaborators' ? (
									<div
										className={'statutContainer'}
										style={
											accountData.deletion
												? {
														color: '#b04b4b',
														backgroundColor: '#faf4f4',
														borderColor: '#f8d2d2',
												  }
												: null
										}>
										{accountData.deletion
											? t('project_card.in_process_of_deletion')
											: stringSubtype(accountData.subtype, t)}
									</div>
								) : (
									<div className={'statutContainer'}>
										{nbProject} {t('common.projet') + (nbProject > 1 ? 's' : '')}
									</div>
								)}
							</div>

							<div
								className={'transitionItemAccount'}
								style={{
									marginLeft: 30,
									width: isInfosContactOpen || isCreationCompte || isModifContactOpen ? 0 : 240,
									overflow: 'hidden',
									display: 'flex',
									justifyContent: 'center',
								}}>
								<div className={'connexionContainer'}>
									{lastConnexion
										? new Date().getTime() - lastConnexion < 86400000
											? t('common.today')
											: new Date().getTime() - lastConnexion < 172800000
											  ? t('common.yesterday')
											  : new Date().getTime() - lastConnexion < 259200000
											    ? t('common.twoDays')
											    : Math.round((new Date().getTime() - lastConnexion) / 86400000) < 7
											      ? Math.round((new Date().getTime() - lastConnexion) / 86400000) +
											        ' ' +
											        t('common.days')
											      : Math.round((new Date().getTime() - lastConnexion) / 604800000) < 2
											        ? t('common.a_week')
											        : Math.round((new Date().getTime() - lastConnexion) / 604800000) < 2
											          ? Math.round((new Date().getTime() - lastConnexion) / 604800000) +
											            t('common.weeks')
											          : t('modal_accounts.not_recently')
										: t('modal_accounts.not_recently')}
								</div>
							</div>

							<div
								style={{
									marginLeft: 30,
									width: 140,
									height: 30,
									display: 'flex',
									justifyContent: 'flex-end',
								}}>
								<img src={infoAccount} alt="" className={'editAccountBleu'} />
								<img
									style={{
										opacity:
											(type === 'collaborators' && canCreate) ||
											(type !== 'collaborators' && canCreate && canDelete)
												? 1
												: 0.4,
									}}
									src={editAccountBleu}
									alt=""
									className={'deuxPointsGris'}
									ref={refEditIcon}
									onClick={() => {
										if (isModifContactOpen && selectedAccount?.uid === accountData.uid) {
											setIsModifContactOpen(false);
											setSelectedAccount(null);
										} else {
											if (
												(type === 'collaborators' && canCreate) ||
												(type !== 'collaborators' && canCreate && canDelete)
											) {
												setIsCreationCompte(false);
												setIsInfoContactOpen(false);
												setSelectedAccount(accountData);
												setIsModifContactOpen(true);
											}
										}
									}}
								/>
							</div>
						</div>
					</div>
				)}
		</>
	);
}
