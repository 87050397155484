import React, { useState, useEffect, useRef } from 'react';
import './_modalLots.scss';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import trashIconCircleRed from '../../../../assets/trashIconCircleRed.svg';
import plusCircle from '../../../../assets/plusCircle.svg';
import horisontalGradient from '../../../../assets/horisontalGradient.png';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useProjectLots } from '../../../../contexts/projectLotsContext';
import { firestore } from '../../../../firebase/config';
import { Paths } from '../../../../firebase/paths';
import { joinPaths } from '../../../../firebase/utils';
import { useProjectContext } from '../../../../contexts/projectContext';

export default function ModalLots({ isOpen, onClose }) {
	const { t } = useTranslation();

	const [lots, setLots] = useProjectLots();
	const [project] = useProjectContext();

	const [loadingStatus, setLoadingStatus] = useState('idle');

	const addRef = useRef(null);

	// ==================================== Create Lot ====================================

	const handleCreateLot = async (index, name) => {
		setLoadingStatus('loading');

		try {
			await firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.LOTS)).add({ index, name });
		} catch (e) {
			console.log(e);
			setLoadingStatus('error');
		} finally {
			setLoadingStatus('success');
		}
	};

	// ==================================== Update Lot ====================================

	const handleUpdateLot = async (id, index, name) => {
		setLoadingStatus('loading');

		try {
			await firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.LOTS))
				.doc(id)
				.update({ index, name });
		} catch (e) {
			console.log(e);
			setLoadingStatus('error');
		} finally {
			setLoadingStatus('success');
		}
	};

	// ==================================== Delete Lot ====================================

	const handleDeleteLot = async (id) => {
		setLoadingStatus('loading');

		try {
			await firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.LOTS))
				.doc(id)
				.delete();
		} catch (e) {
			console.log(e);
			setLoadingStatus('error');
		} finally {
			setLoadingStatus('success');
		}
	};

	// ==================================== Render ====================================

	return (
		<Modal
			isOpen={isOpen}
			className={'modalLots'}
			overlayClassName={'overlayModalAlbumPhoto'}
			onRequestClose={onClose}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img src={crossIcon} alt="" className={'crossIcon hover'} onClick={onClose} />
			<p className={'titleModal'}>{t('translation.projectLots')}</p>
			<p className={'subtitleModal'}>{t('translation.referenceLotsProjet')}</p>
			<div className={'barModal'} />
			<div className={'listItemLots'}>
				{lots.map((lot, index) => (
					<ItemLot
						key={lot.id}
						index={lot.index}
						name={lot.name}
						handleDeleteLot={() => handleDeleteLot(lot.id)}
						handleUpdateLot={(index, name) => handleUpdateLot(lot.id, index, name)}
						isLast={index === lots.length - 1}
						addFocus={() => addRef.current?.focus()}
					/>
				))}
			</div>
			<img src={horisontalGradient} alt="" className={'horisontalGradient'} style={{ zIndex: 10 }} />
			<p className={'newLot'} style={{ zIndex: 15 }}>
				{t('translation.newLot')}
			</p>
			<ItemLot isAdd={true} addRef={addRef} handleCreateLot={handleCreateLot} />
		</Modal>
	);
}

function ItemLot({ isAdd, addRef, isLast, index, name, handleCreateLot, handleDeleteLot, handleUpdateLot, addFocus }) {
	const { t } = useTranslation();

	const [numberLot, setNumberLot] = useState(index || '');
	const [nameLot, setNameLot] = useState(name || '');

	const nameRef = useRef(null);

	useEffect(() => {
		setNumberLot(index || '');
		setNameLot(name || '');
	}, [index, name]);

	return (
		<div className={'itemLot'} style={{ opacity: isAdd && numberLot.length === 0 && nameLot.length === 0 && 0.6 }}>
			<p>Lot</p>
			<input
				ref={addRef}
				type={'text'}
				min="0"
				step="1"
				placeholder={'0'}
				style={{ width: 40 }}
				value={numberLot}
				onChange={(e) => {
					const sanitizedValue = e.target.value
						.replace(/[^a-zA-Z0-9]/g, '')
						.toUpperCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '');
					setNumberLot(sanitizedValue);
				}}
				onBlur={() => {
					if (!isAdd) {
						if (numberLot?.length > 0) {
							handleUpdateLot(numberLot, nameLot);
						} else {
							setNumberLot(index);
						}
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						nameRef.current?.focus();
					}
				}}
			/>
			<p>•</p>
			<input
				ref={nameRef}
				type={'text'}
				placeholder={t('translation.lotName')}
				style={{ width: 295 }}
				value={nameLot}
				onChange={(e) => setNameLot(e.target.value)}
				onBlur={() => {
					if (!isAdd) {
						if (nameLot?.length > 0) {
							handleUpdateLot(numberLot, nameLot);
							nameRef.current?.blur();
						} else {
							setNameLot(name);
						}
					}
				}}
				onKeyDown={async (e) => {
					if (e.key === 'Enter') {
						if (isAdd) {
							if (numberLot?.length > 0 && nameLot?.length > 0) {
								await handleCreateLot(numberLot, nameLot);
								setNumberLot('');
								setNameLot('');
							}
						} else if (isLast) {
							addFocus();
						} else {
							nameRef.current?.blur();
						}
					}
				}}
			/>
			<img
				src={isAdd ? plusCircle : trashIconCircleRed}
				alt=""
				className={'trashIcon hover'}
				onClick={async () => {
					if (isAdd) {
						if (numberLot && nameLot) {
							await handleCreateLot(numberLot, nameLot);
							setNumberLot('');
							setNameLot('');
						}
					} else {
						await handleDeleteLot();
					}
				}}
			/>
		</div>
	);
}

ModalLots.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

ItemLot.propTypes = {
	opacity: PropTypes.number,
	isAdd: PropTypes.bool,
};
