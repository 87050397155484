import React, { useState, useEffect, useContext } from 'react';
import './companyOrPersonItem.scss';
import Building from '../../../../../../assets/Building.svg';
import checkVert from '../../../../../../assets/checkVert.svg';
import { usePartnersContext } from '../../../../../../contexts/partnersContext';
import AgencyDataContext from '../../../../../../contexts/agencyDataContext';
import { useProjectUsersContext } from '../../../../../../contexts/projectUsersContext';
import AuthDataContext from '../../../../../../contexts/authDataContext';
import { useTranslation } from 'react-i18next';
import { getFirstLetters } from 'src/useful/UsefulFunctions';

export default function CompanyOrPersonItem({
	userId,
	userType,
	agencyId,
	name,
	company,
	hasViewed,
	unClickable,
	emoji,
	maxWidth,
	isSelected,
	onClick,
	refused,
	disabled,
	initials,
	color,
	onlyDiminutif,
}) {
	const { t } = useTranslation();

	const authData = useContext(AuthDataContext);
	const agencyData = useContext(AgencyDataContext);
	const [users] = useProjectUsersContext();
	const [partners] = usePartnersContext();

	const [newName, setNewName] = useState('');

	const isClient = userId === 'clients' || agencyId === 'clients';

	useEffect(() => {
		if (name && name.length > 0) {
			setNewName(name);
		} else if (isClient) {
			setNewName(t('translation.mo'));
		} else if (company && agencyId) {
			if (agencyId === agencyData?.id)
				setNewName(
					onlyDiminutif ? (agencyData.diminutifAgency ?? getFirstLetters(agencyData.name)) : agencyData.name
				);
			else if (partners)
				setNewName(
					onlyDiminutif
						? (partners.find((partner) => partner.id === agencyId)?.diminutifAgency ??
								getFirstLetters(partners.find((partner) => partner.id === agencyId)?.name))
						: partners.find((partner) => partner.id === agencyId)?.name
				);
		} else if (!company && userId) {
			const user = [authData, ...users]?.find((user) => user?.id === userId);
			setNewName(user?.surname + ' ' + user?.name);
		}
	}, [userId, users, name, company, partners, agencyData, authData, isClient]);

	return (
		<div
			className={
				!emoji
					? unClickable
						? 'companyOrPersonItemDropDownUnclickable'
						: `companyOrPersonItemDropDown ${isSelected ? 'selected' : ''}`
					: 'companyOrPersonItem'
			}
			style={disabled ? { opacity: 0.5, backgroundColor: '#ffffff', cursor: 'default' } : {}}
			onClick={unClickable || disabled ? null : onClick}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					whiteSpace: 'nowrap',
					maxWidth: maxWidth,
					overflow: 'hidden',
				}}>
				<div style={{ display: 'flex' }}>
					<div
						className={'initiales'}
						style={{
							backgroundColor: isClient ? '#83d98a' : color && color,
							color: isClient ? '#FFF' : color && '#FFF',
							borderRadius: isClient ? 5 : color && 5,
						}}>
						{isClient ? (
							t('translation.clientLittle')
						) : initials ? (
							initials
						) : company ? (
							<img src={Building} alt="" className={'buildingIcon'} />
						) : (
							newName?.split(' ')?.[0]?.[0]
						)}
					</div>
				</div>
				{newName}
			</div>
			{isSelected && <img src={checkVert} alt="" className={'checkVert'} />}
			{emoji && <div style={{ fontSize: 13 }}>{refused ? '❌' : hasViewed ? '✅' : '⏳'}</div>}
		</div>
	);
}
