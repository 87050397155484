import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FilterOptions({ setFilterBy, filterBy }) {
	const { t } = useTranslation();

	function FilterOption({ title, tag }) {
		return (
			<div className={filterBy === tag ? 'sortOptionActive' : 'sortOption'} onClick={() => setFilterBy(tag)}>
				{title}
			</div>
		);
	}

	return (
		<div className={'sortOptions'}>
			<FilterOption title={t('translation.all')} tag={'all'} />
			<FilterOption title={t('translation.unread')} tag={'unread'} />
			<FilterOption title={t('common.clients')} tag={'clients'} />
			<FilterOption title={t('translation.projects')} tag={'groups'} />
		</div>
	);
}
