import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../../../../contexts/agencyContext';

export default function ContainerParticipants({ isGeneral, setModalNewGroup, participants }) {
	const { t } = useTranslation();

	return (
		<div className={'containerParticipants'} style={{ paddingBottom: 10, minHeight: 0 }}>
			<div className={'containerHeader'}>
				<p>{t('translation.groupMembers')}</p>
				{!isGeneral && (
					<p className={'all hover'} onClick={() => setModalNewGroup(true)}>
						{t('common.edit')}
					</p>
				)}
			</div>

			{participants.map(
				(participant) => participant && <ItemParticipant key={participant.id} data={participant} />
			)}
		</div>
	);
}

function ItemParticipant({ data }) {
	const agencyId = useContext(AgencyContext);

	return (
		<div className={'itemParticipant'}>
			<div
				className={
					data.userType === 'clients'
						? 'profilePictureVertLittle'
						: data.userAgencyId === agencyId || data.isGroup
						  ? 'profilePictureBleuLittle'
						  : 'profilePictureBleuClairLittle'
				}
				style={{ transform: 'scale(0.8)' }}>
				{data.firstName?.at(0)?.toUpperCase() + data.lastName?.at(0)?.toUpperCase()}
			</div>
			{data.name}
		</div>
	);
}
