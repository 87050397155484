import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import CollaboratorItemListActors from '../../../aaOld App/Intervenants/ListItem/CollaboratorItemListActors';
import AgencyContext from '../../../contexts/agencyContext';
import searchIcon from '../../../assets/searchIcon.png';
import gerySearchIcon from '../../../assets/gerySearchIcon.png';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../firebase/config';

export default function ModalChooseCollab({
	auth,
	projectId,
	modal,
	setModal,
	setModalFelecitation,
	projectName,
	projectTag,
	withoutButtonSave,
	mandataireUid,
	needActualisation,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);

	const [collaborators, setCollaborators] = useState();
	const [actors, setActors] = useState();
	const [researchNumber, setResearchNumber] = useState(0);
	const [research, setResearch] = useState('');

	useEffect(() => {
		firestore
			.collection(`agencies/${agency}/collaborators`)
			.orderBy('name')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const collaborators = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							collaborators.push(documentSnapshot.data());
						}
					});
					setCollaborators(collaborators);
				}
			});
		const subscriber1 = firestore.collection(`projects/${projectId}/accounts`).onSnapshot((querySnapshot) => {
			if (querySnapshot) {
				const actors = [];
				querySnapshot.forEach((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						actors.push(documentSnapshot.data().uid);
					}
				});
				setActors(actors);
			}
		});
		const subscriber2 = firestore.doc(`projects/${projectId}`).onSnapshot((documentSnapshot) => {
			if (documentSnapshot && documentSnapshot.exists) {
			}
		});
		return () => {
			subscriber1();
			subscriber2();
		};
	}, [agency, projectId]);

	return (
		<Modal
			isOpen={modal}
			className={'modalNewAccount'}
			overlayClassName="OverlayModalNewAccount"
			closeTimeoutMS={300}>
			<h2 className={'title'}>{t('modal_choose_collab.participants')}</h2>

			<p
				style={{
					marginTop: -13,
					marginLeft: 25,
					marginRight: 25,
					fontSize: 14,
					fontWeight: 500,
					marginBottom: 30,
					opacity: 0.7,
				}}>
				{t('modal_choose_collab.infos_modal')}
			</p>

			<div style={{ display: 'flex', marginRight: 90 }}>
				<input
					type={'text'}
					placeholder={t('modal_choose_collab.search_for_a_collab')}
					className={'rechercheParticipants'}
					value={research}
					onChange={(e) => setResearch(e.target.value)}
				/>
				<img src={searchIcon} alt="" className={'searchIcon'} />
			</div>

			<div className={'listeModal'}>
				<div className={'listModalItemDashboard'} style={{ maxHeight: 'calc(100vh - 290px)', paddingTop: 20 }}>
					<div>
						{collaborators &&
							collaborators.map((co) => (
								<CollaboratorItemListActors
									setResearchNumber={setResearchNumber}
									projectId={projectId}
									type={'collaborators'}
									uid={co.uid}
									actors={actors}
									research={research}
									isActive={true}
									projectName={projectName}
									projectTag={projectTag}
									key={co.uid}
									mandataireUid={mandataireUid}
								/>
							))}
					</div>
					{research && researchNumber === 0 && (
						<div
							style={{
								width: 510,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 20,
							}}>
							<img
								src={gerySearchIcon}
								alt=""
								style={{
									width: 30,
								}}
							/>
							<p
								style={{
									fontSize: 13,
									color: '#8c8c8c',
									fontWeight: 600,
									marginBottom: 8,
								}}>
								{t('modal_choose_collab.no_result')}
							</p>
							<p
								style={{
									margin: 0,
									fontSize: 12,
									color: '#8c8c8c',
									fontWeight: 500,
								}}>
								{t('modal_choose_collab.try_again')}
							</p>
						</div>
					)}
				</div>
			</div>

			{!withoutButtonSave && (
				<div
					onClick={() => {
						if (mandataireUid === agency) {
							setModal(false);
							setModalFelecitation(true);
						}
					}}
					className={'hover'}
					style={{
						height: 35,
						borderRadius: 7,
						width: 470,
						marginLeft: 20,
						display: 'flex',
						position: 'absolute',
						bottom: 0,
						marginBottom: 20,
						backgroundColor: '#D2E7F8',
						color: '#4B5FB0',
						fontWeight: 600,
						alignItems: 'center',
						fontSize: 15,
						justifyContent: 'center',
					}}>
					{t('common.save')}
				</div>
			)}
		</Modal>
	);
}
