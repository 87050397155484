import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../assets/crossIcon.png';
import clockLock from '../../../assets/clockLock.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { firestore } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';
import AgencyContext from '../../../contexts/agencyContext';
import { AGENCY_COLLECTION, PROJECT_COLLECTION, USER_COLLECTION } from '../../../firebase/paths';
import { useIntercom } from 'react-use-intercom';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import { ProfileNotification, TextContentNotification } from '../../Fiche Projet/Notifications/NotificationComponents';
import { desescapeSpecialChars, getFirstLetters } from '../../../useful/UsefulFunctions';
import { getNotificationObject, timeSince } from '../../Fiche Projet/Notifications/utils';
import AuthDataContext from '../../../contexts/authDataContext';

export default function ModalWaitForApproval({ modalWaitForApproval, setModalWaitForApproval }) {
	const location = useLocation();
	const { t } = useTranslation();
	const { show } = useIntercom();

	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const type = useContext(TypeContext);
	const userData = useContext(AuthDataContext);

	const [projectId, setProjectId] = useState('');
	const [projectName, setProjectName] = useState('');
	const [projectMandataireId, setProjectMandataireId] = useState('');
	const [partnerIds, setPartnerIds] = useState([]);
	const [agencyNameToNotify, setAgencyNameToNotify] = useState('');
	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		if (location.state) {
			setModalWaitForApproval(location.state.waitForApproval ? location.state.projectId : false);
			setProjectId(location.state.projectId);
		}
	}, [location]);

	useEffect(() => {
		setProjectId(modalWaitForApproval);
	}, [modalWaitForApproval]);

	useEffect(() => {
		if (projectId) {
			firestore
				.doc(`${PROJECT_COLLECTION}/${projectId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setProjectMandataireId(doc.data().creator);
						setProjectName(doc.data().name);
					}
				});
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${USER_COLLECTION}`)
				.where('type', '==', 'agencies')
				.get()
				.then((query) => {
					const entreprises = [];
					query.forEach((doc) => {
						if (doc.exists) {
							entreprises.push(doc.id);
						}
					});
					setPartnerIds(entreprises);
					setDataLoading(false);
				});
		}
	}, [projectId]);

	useEffect(() => {
		if (partnerIds && agencyId && partnerIds.includes(agencyId)) {
			setAgencyNameToNotify(agencyData?.name);
		} else if (projectMandataireId) {
			firestore
				.doc(`${AGENCY_COLLECTION}/${projectMandataireId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setAgencyNameToNotify(doc.data().name);
					}
				});
		}
	}, [partnerIds, projectMandataireId, agencyId, agencyData]);

	const mockNotification = {
		source: 'joinProject',
		senderName:
			type !== 'clients' && !partnerIds?.includes(agencyId)
				? agencyData?.name
				: userData?.surname + ' ' + userData?.name,
		createdAt: new Date().toISOString(),
		isCompany: type !== 'clients' && !partnerIds?.includes(agencyId),
	};

	return (
		<Modal
			isOpen={modalWaitForApproval}
			className={'modalJoinProject projectToJoin'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setModalWaitForApproval(false);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalWaitForApproval(false);
				}}
			/>

			<img src={clockLock} alt="" className={'clockLock'} />

			<p className={'titleModal'} style={{ marginTop: 30 }}>
				{t('translation.waitForApproval')}
			</p>

			<p className={'subtitleModal'}>
				{desescapeSpecialChars(
					t('translation.agencyCollabsHaveBeenNotified', {
						agencyProject: agencyNameToNotify,
						projectName,
					})
				)}
			</p>

			<p className={'subtitleModal'} style={{ marginTop: 0 }}>
				{desescapeSpecialChars(
					t('translation.canAskAgency', {
						agencyProject: agencyNameToNotify,
					})
				)}
			</p>

			<div className={'notificationItemBlue'}>
				<div className={'notificationItemContent'}>
					<ProfileNotification
						initiales={getFirstLetters(mockNotification.senderName)}
						entreprise={mockNotification.isCompany}
					/>
					<div className={'rightPartNotification'}>
						<TextContentNotification
							object={getNotificationObject(mockNotification)}
							initializer={mockNotification.senderName}
							sameAgency={true}
						/>
						<p className={'notifDate'}>{timeSince(t, mockNotification.createdAt)}</p>
						<div className={'containerButtons'}>
							<button className={'acceptButton'} disabled={true}>
								{t('translation.accept')}
							</button>
							<button className={'refuseButton'} disabled={true}>
								{t('translation.refuse')}{' '}
							</button>
						</div>
					</div>
					<div className={'pastille'} style={{ display: 'flex' }} />
				</div>
			</div>

			<p className={'subtitleModal'} style={{ marginTop: 30, marginBottom: 0 }}>
				{t('translation.opusAndSecurity')}
			</p>

			<button className={'containerButton'} onClick={show}>
				<div className={'blueButton'}>{t('translation.contactAdvisor')}</div>
			</button>
		</Modal>
	);
}
