import React from 'react';
import './_onboardingStep.scss';
import { BlueButton, GoBack, HeaderStep, SingleInput } from '../components/ComponentsForm';
import { STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';
export default function SecurityProfileStep({
	setStep,
	password,
	setPassword,
	confirmPassword,
	setConfirmPassword,
	handleNextStep,
	marginTop,
	lastStep,
	projectName,
}) {
	const { t } = useTranslation();

	return (
		<form
			className={'onboardingStep projectToJoin'}
			style={{ marginTop: marginTop }}
			onSubmit={(e) => {
				e.preventDefault();
				if (password === confirmPassword && password.length > 5) {
					handleNextStep(STEPS.selectRole);
				}
			}}>
			<GoBack lastStep={lastStep} setStep={setStep} />
			<HeaderStep
				title={t('translation.joinProjectTitleStep3')}
				subtitle={t('translation.joinProjectSubtitleStep3', { projectName: projectName })}
			/>

			<SingleInput
				value={password}
				setValue={setPassword}
				title={t('common.password')}
				placeholder={t('common.password')}
				type={'password'}
			/>

			<SingleInput
				value={confirmPassword}
				setValue={setConfirmPassword}
				title={t('settings_safety.confirm_password')}
				placeholder={t('settings_safety.confirm_password')}
				type={'password'}
			/>
			<p
				style={{ opacity: confirmPassword.length > 1 && confirmPassword !== password ? 1 : 0 }}
				className={'notIdenticalPassword'}>
				{t('settings_safety.passwords_must_be_the_same')}
			</p>

			<p
				style={{ opacity: password.length > 0 && password.length < 6 ? 1 : 0 }}
				className={'notIdenticalPassword'}>
				{t('settings_safety.passwords_must_be_long')}
			</p>

			<BlueButton text={t('common.next')} />
		</form>
	);
}
