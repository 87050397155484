import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import Modal from 'react-modal';
import './_modalTickets.scss';
import EventLogs from './Event Logs/EventLogs';
import TicketPropertyItem from './Ticket Property Item/TicketPropertyItem';
import { useTranslation } from 'react-i18next';
import TicketTodolist from './Ticket Todolist/TicketTodolist';
import TicketDescription from './Ticket Description/TicketDescription';
import blur from '../../../../../assets/blur.jpg';
import crossIcon from '../../../../../assets/crossIcon.png';
import printer from '../../../../../assets/printer.svg';
import TicketDocument from './Ticket Documents/TicketDocument';
import { MISSIONS_STATUS, TICKET_LOG_TYPE, useTicketProperties } from '../../data';
import FollowUp, { TICKET_STATUS } from './Follow Up/FollowUp';
import PropTypes from 'prop-types';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { auth, firestore, storage } from '../../../../../firebase/config';
import { generateUniqueFirestoreId, joinPaths } from '../../../../../firebase/utils';
import { Paths } from '../../../../../firebase/paths';
import { desescapeSpecialChars, normalizeString } from '../../../../../useful/UsefulFunctions';
import { formatDateNumericallyWithPastDayKey, formatDateHourly } from '../../../../../useful/date';
import { useDropzone } from 'react-dropzone';
import { createdByConstructor } from '../../../utils';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { uploadOnStorage } from '../../../../../firebase/utils';
import UploadContext from '../../../../../contexts/uploadContext';
import { mergeAndOrder } from '../../../../../useful/utils';
import { useClientsContext } from 'src/contexts/clientsContext';
import AuthDataContext from 'src/contexts/authDataContext';
import { resizeImage } from 'src/useful/image';

const REOPEN_THRESHOLD_MS = 5 * 60 * 1000; // 5 minutes in milliseconds

export default function ModalTicket({ isOpen, data, onClose, openModalLots, isSnagging, setModalPrintTicket }) {
	const { t, i18n } = useTranslation();
	const { properties } = useTicketProperties();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [_, setUploadProgress] = useContext(UploadContext);
	const [clients] = useClientsContext();
	const authData = useContext(AuthDataContext);

	const inputTicketNameRef = useRef();

	const [menu, setMenu] = useState('follow');

	const [ticketName, setTicketName] = useState('');
	const [ticketProperties, setTicketProperties] = useState({});
	const [ticketContent, setTicketContent] = useState([]);
	const [files, setFiles] = useState([]);
	const [images, setImages] = useState([]);

	const [loadingStatus, setLoadingStatus] = useState('success');
	const lastUpdateRef = useRef(null);
	const successTimeoutRef = useRef(null);
	const [deletingStatus, setDeletingStatus] = useState('none');

	const [isDragging, setIsDragging] = useState(false);
	const [showPrintMessage, setShowPrintMessage] = useState(false);

	const printRef = useRef();

	const [lastOpenTime, setLastOpenTime] = useState({});

	const canModifyTicket = useMemo(() => {
		return (
			data?.properties?.owner?.userId === uid ||
			data?.properties?.owner?.agencyId === agencyId ||
			((data?.properties?.owner?.userType === 'client' ||
				clients?.map((client) => client.id)?.includes(data?.properties?.owner?.agencyId)) &&
				(project?.creator === agencyId ||
					userType === 'clients' ||
					clients?.map((client) => client.id)?.includes(agencyId)))
		);
	}, [data?.properties?.owner, uid, agencyId, isOpen, clients, project?.creator, userType]);

	// ==================================== Modal open ====================================

	useEffect(() => {
		if (isOpen) {
			setLoadingStatus('success');
			setDeletingStatus('none');
		}
	}, [isOpen]);

	useEffect(() => {
		if (data?.id && data?.properties?.type === 'issue' && authData) {
			const now = Date.now();
			const lastOpen = lastOpenTime[data.id] || 0;

			if (now - lastOpen > REOPEN_THRESHOLD_MS && process.env.NODE_ENV !== 'development') {
				firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.LOGS)).add({
					type: TICKET_LOG_TYPE.opened,
					name: authData.surname + ' ' + authData.name,
					createdAt: new Date().toISOString(),
					createdBy: createdByConstructor(uid, userType, agencyId),
				});

				setLastOpenTime((prev) => ({
					...prev,
					[data.id]: now,
				}));
			}
		}
	}, [data?.id, authData]);

	// ==================================== Get content ====================================

	useEffect(() => {
		if (data?.id) {
			setTicketContent([]);
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
				.where('type', '==', 'text')
				.orderBy('createdAt', 'asc')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const content = [];
						querySnapshot.forEach((doc) => {
							content.push({ ...doc.data(), id: doc.id });
						});
						if (canModifyTicket) {
							content.push({ type: 'text', title: '', text: '' });
						}
						setTicketContent(content);
					}
				});

			return () => unsubscribe();
		}
	}, [data?.id, canModifyTicket]);

	// ==================================== Get files ====================================

	useEffect(() => {
		if (project?.id && data?.id) {
			setFiles([]);
			setImages([]);
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
				.where('type', 'in', ['file', 'image'])
				.orderBy('createdAt', 'asc')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const files = [];
						const images = [];
						querySnapshot.docs.forEach((doc) => {
							if (doc.data().type === 'image') images.push({ ...doc.data(), id: doc.id });
							else files.push({ ...doc.data(), id: doc.id });
						});
						setFiles((prev) =>
							mergeAndOrder((a, b) => a.createdAt.localeCompare(b.createdAt), 'id', files, prev)
						);
						setImages((prev) =>
							mergeAndOrder((a, b) => a.createdAt.localeCompare(b.createdAt), 'id', images, prev)
						);

						firestore
							.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
							.update({ numFiles: files.length + images.length })
							.catch((error) => console.error('Error updating numFiles:', error));
					}
				});

			return () => unsubscribe();
		}
	}, [project?.id, data?.id]);

	// ==================================== Update data ====================================

	useEffect(() => {
		if (data) {
			setTicketName(data.name || '');
			setTicketProperties(data.properties || {});
		}
	}, [data]);

	// ==================================== Click outside ====================================

	const handleClickOutside = useCallback((event) => {
		if (isOpen && !event.target.closest('.modalTicket')) {
			onClose();
		}
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	// ==================================== On Change Input ====================================

	// FIXME: @QGROpus Update ticket status if there is a change in companies or reviewers
	const onChange = useCallback(
		(property, value) => {
			if (canModifyTicket) {
				const { key, type, multiple } = property;
				const updateId = Date.now();
				lastUpdateRef.current = updateId;

				// Clear any existing timeout
				if (successTimeoutRef.current) {
					clearTimeout(successTimeoutRef.current);
				}

				setLoadingStatus('loading');

				if (key === 'name') {
					setTicketName(value);

					if (project?.id && data?.id) {
						firestore
							.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
							.doc(data.id)
							.update({
								name: value,
								normalizedName: normalizeString(value),
							})
							.then(() => {
								successTimeoutRef.current = setTimeout(() => {
									if (lastUpdateRef.current === updateId) {
										setLoadingStatus('success');
									}
								}, 1000);
							})
							.catch((error) => {
								console.error('Error updating ticket name:', error);
								if (lastUpdateRef.current === updateId) {
									setLoadingStatus('error');
								}
							});
					}
				} else {
					setTicketProperties((prev) => {
						let updatedProperties;
						if (multiple) {
							updatedProperties = {
								...prev,
								[key]: prev[key]?.includes(value)
									? prev[key].filter((v) => v !== value)
									: [...(prev[key] ?? []), value],
							};
						} else if (type === 'date') {
							const date = new Date(value);
							if (!isNaN(date.valueOf())) {
								updatedProperties = { ...prev, [key]: date.toISOString() };
							} else {
								updatedProperties = prev;
							}
						} else if (key === 'visibility') {
							updatedProperties = { ...prev, visibility: value, users: [] };
						} else {
							updatedProperties = { ...prev, [key]: value };
						}

						if (project?.id && data?.id) {
							firestore
								.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
								.doc(data.id)
								.update({
									properties: updatedProperties,
									modifiedAt: new Date().toISOString(),
								})
								.then(() => {
									successTimeoutRef.current = setTimeout(() => {
										if (lastUpdateRef.current === updateId) {
											setLoadingStatus('success');
										}
									}, 1000);
								})
								.catch((error) => {
									console.error('Error updating ticket properties:', error);
									if (lastUpdateRef.current === updateId) {
										setLoadingStatus('error');
									}
								});
						}

						return updatedProperties;
					});
				}
			}
		},
		[project, data?.id, canModifyTicket, lastUpdateRef, successTimeoutRef]
	);

	useEffect(() => {
		return () => {
			if (successTimeoutRef.current) {
				clearTimeout(successTimeoutRef.current);
			}
		};
	}, []);

	// ==================================== On Change Content ====================================

	const onChangeTitle = (index, title) => {
		setTicketContent((prev) => {
			const newContent = [...prev];
			newContent[index].title = title;
			return newContent;
		});
	};

	const onChangeText = (index, text) => {
		setTicketContent((prev) => {
			const newContent = [...prev];
			newContent[index].text = text;
			return newContent;
		});
	};

	const onBlurTitle = useCallback(
		(index, title) => {
			if (canModifyTicket) {
				const updateId = Date.now();
				lastUpdateRef.current = updateId;

				if (successTimeoutRef.current) {
					clearTimeout(successTimeoutRef.current);
				}

				setLoadingStatus('loading');

				if (!project?.id || !data.id) {
					console.error('Project ID or Ticket ID is missing');
					setLoadingStatus('error');
					return;
				}

				if (ticketContent[index].id && ticketContent[index].id !== '') {
					firestore
						.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
						.doc(ticketContent[index].id)
						.update({
							title: title,
							modifiedAt: new Date().toISOString(),
						})
						.then(() => {
							firestore
								.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
								.update({
									modifiedAt: new Date().toISOString(),
								})
								.then(() => {
									setTimeout(() => {
										setLoadingStatus('success');
									}, 1000);
								})
								.catch((error) => {
									console.error('Error updating ticket content:', error);
									setLoadingStatus('error');
								});
						})
						.catch((error) => {
							console.error('Error updating ticket content:', error);
							setLoadingStatus('error');
						});
				} else {
					firestore
						.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
						.doc(ticketContent[index].id)
						.set({
							type: 'text',
							title: title,
							text: '',
							createdBy: createdByConstructor(uid, userType, agencyId),
							createdAt: new Date().toISOString(),
							modifiedAt: new Date().toISOString(),
						})
						.then(() => {
							firestore
								.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
								.update({
									modifiedAt: new Date().toISOString(),
								})
								.then(() => {
									setTimeout(() => {
										setLoadingStatus('success');
									}, 1000);
								})
								.catch((error) => {
									console.error('Error updating ticket content:', error);
									setLoadingStatus('error');
								});
						})
						.catch((error) => {
							console.error('Error updating ticket content:', error);
							setLoadingStatus('error');
						});
				}
			}
		},
		[project, data?.id, ticketContent, canModifyTicket, lastUpdateRef, successTimeoutRef]
	);

	const onBlurText = useCallback(
		(index, text) => {
			if (canModifyTicket) {
				const updateId = Date.now();
				lastUpdateRef.current = updateId;

				if (successTimeoutRef.current) {
					clearTimeout(successTimeoutRef.current);
				}

				setLoadingStatus('loading');

				if (ticketContent[index].id && ticketContent[index].id !== '') {
					firestore
						.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
						.doc(ticketContent[index].id)
						.update({
							text: text,
							modifiedAt: new Date().toISOString(),
						})
						.then(() => {
							firestore
								.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
								.update({
									modifiedAt: new Date().toISOString(),
								})
								.then(() => {
									setTimeout(() => {
										setLoadingStatus('success');
									}, 1000);
								})
								.catch((error) => {
									console.error('Error updating ticket content:', error);
									setLoadingStatus('error');
								});
						})
						.catch((error) => {
							console.error('Error updating ticket content:', error);
							setLoadingStatus('error');
						});
				} else {
					firestore
						.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
						.doc(ticketContent[index].id)
						.set({
							type: 'text',
							title: '',
							text: text,
							createdBy: createdByConstructor(uid, userType, agencyId),
							createdAt: new Date().toISOString(),
							modifiedAt: new Date().toISOString(),
						})
						.then(() => {
							firestore
								.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
								.update({
									modifiedAt: new Date().toISOString(),
								})
								.then(() => {
									setTimeout(() => {
										setLoadingStatus('success');
									}, 1000);
								})
								.catch((error) => {
									console.error('Error updating ticket content:', error);
									setLoadingStatus('error');
								});
						})
						.catch((error) => {
							console.error('Error updating ticket content:', error);
							setLoadingStatus('error');
						});
				}
			}
		},
		[project, data?.id, ticketContent, canModifyTicket, lastUpdateRef, successTimeoutRef]
	);

	useEffect(() => {
		if (canModifyTicket) {
			if (
				ticketContent?.[ticketContent.length - 2]?.title === '' &&
				ticketContent?.[ticketContent.length - 2]?.text === '' &&
				ticketContent?.[ticketContent.length - 1]?.title === '' &&
				ticketContent?.[ticketContent.length - 1]?.text === ''
			) {
				setTicketContent((prev) => {
					const newContent = [...prev];

					const updateId = Date.now();
					lastUpdateRef.current = updateId;

					if (successTimeoutRef.current) {
						clearTimeout(successTimeoutRef.current);
					}

					setLoadingStatus('loading');

					firestore
						.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
						.doc(newContent[newContent.length - 1].id)
						.delete()
						.then(() => {
							firestore
								.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
								.update({
									modifiedAt: new Date().toISOString(),
								})
								.then(() => {
									setTimeout(() => {
										setLoadingStatus('success');
									}, 1000);
								})
								.catch((error) => {
									console.error('Error deleting ticket content:', error);
									setLoadingStatus('error');
								});
						})
						.catch((error) => {
							console.error('Error deleting ticket content:', error);
							setLoadingStatus('error');
						});

					newContent.pop();

					return newContent;
				});
			} else if (
				ticketContent?.[ticketContent.length - 1]?.title !== '' ||
				ticketContent?.[ticketContent.length - 1]?.text !== ''
			) {
				setTicketContent((prev) => {
					const newContent = [...prev];
					newContent.push({ type: 'text', title: '', text: '' });
					return newContent;
				});
			}
		}
	}, [ticketContent, canModifyTicket, lastUpdateRef, successTimeoutRef]);

	// ==================================== Upload File ====================================

	const uploadFile = useCallback(
		async (file) => {
			if (!project?.id || !data?.id) return;

			const fileId = generateUniqueFirestoreId();
			const fileType = file.type.startsWith('image/') ? 'image' : 'file';

			try {
				const updateId = Date.now();
				lastUpdateRef.current = updateId;

				if (successTimeoutRef.current) {
					clearTimeout(successTimeoutRef.current);
				}

				setLoadingStatus('loading');

				// Process image only if it's an image and needs resizing
				const processedFile = fileType === 'image' ? await resizeImage(file) : file;

				const fileData = {
					id: fileId,
					name: file.name,
					type: fileType,
					size: processedFile.size,
					mimeType: file.type,
					url: URL.createObjectURL(processedFile),
					createdAt: new Date().toISOString(),
					createdBy: createdByConstructor(uid, userType, agencyId),
					modifiedAt: new Date().toISOString(),
				};

				if (fileType === 'image') {
					setImages((prev) => [...prev, fileData]);
				} else {
					setFiles((prev) => [...prev, fileData]);
				}

				const downloadURL = await uploadOnStorage(
					fileId,
					processedFile,
					{ ...fileData, page: 'tickets' },
					`projects/${project.id}/tickets/${data.id}/files`,
					setUploadProgress,
					`${t('translation.tickets')} - ${ticketName}`
				);

				await firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
					.doc(fileId)
					.set({ ...fileData, url: downloadURL });

				await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id)).update({
					modifiedAt: new Date().toISOString(),
				});

				setLoadingStatus('success');
			} catch (error) {
				console.error(`Error uploading ${fileType === 'image' ? 'image' : 'file'}:`, error);
				setLoadingStatus('error');
			}
		},
		[project, data, uid, userType, agencyId, lastUpdateRef, successTimeoutRef, ticketName]
	);

	// ==================================== Dropzone ====================================

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (canModifyTicket) {
				setIsDragging(false);
				acceptedFiles.forEach(uploadFile);
			}
		},
		[uploadFile, canModifyTicket]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: true,
		onDragEnter: () => setIsDragging(true),
		onDragLeave: () => setIsDragging(false),
	});

	// ==================================== Delete file ====================================

	const handleDeleteFile = async (file) => {
		if (window.confirm(t('translation.deleteConfirmationFile'))) {
			const updateId = Date.now();
			lastUpdateRef.current = updateId;

			if (successTimeoutRef.current) {
				clearTimeout(successTimeoutRef.current);
			}

			setLoadingStatus('loading');

			try {
				if (file.type === 'image') {
					setImages((prev) => prev.filter((f) => f.id !== file.id));
				} else {
					setFiles((prev) => prev.filter((f) => f.id !== file.id));
				}

				try {
					await storage.refFromURL(file.url).delete();
				} catch (error) {
					console.error(error);
				}

				await firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
					.doc(file.id)
					.delete();

				await firestore
					.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id))
					.update({ modifiedAt: new Date().toISOString() });
			} catch (error) {
				console.error(error);
				setLoadingStatus('error');
			} finally {
				setLoadingStatus('success');
			}
		}
	};

	// ==================================== Delete Ticket ====================================

	const onDelete = async () => {
		// TODO: @QGROpus Recently deleted
		// TODO: @QGROpus Delete logs
		if (window.confirm(t('translation.deleteConfirmationTicket'))) {
			const updateId = Date.now();
			lastUpdateRef.current = updateId;

			if (successTimeoutRef.current) {
				clearTimeout(successTimeoutRef.current);
			}

			setDeletingStatus('loading');

			try {
				const batch = firestore.batch();

				batch.delete(firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id)));

				const content = await firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.CONTENT))
					.get();

				content.forEach(async (doc) => {
					if (doc.data().type === 'file' || doc.data().type === 'image') {
						try {
							await storage.refFromURL(doc.data().url).delete();
						} catch (error) {
							console.error(error);
						}
					}

					batch.delete(doc.ref);
				});

				await batch.commit();
				onClose();
			} catch (error) {
				console.error(error);
				setDeletingStatus('error');
			} finally {
				setTimeout(() => {
					setDeletingStatus('none');
				}, 1000);
			}
		}
	};

	// ==================================== Archive Ticket ====================================

	const onFinishIssue = () => {
		if (window.confirm(t('translation.archiveConfirmationTicket'))) {
			firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id)).update({
				issueStatus: TICKET_STATUS.approved,
				modifiedAt: new Date().toISOString(),
			});

			firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id, Paths.LOGS)).add({
				type: TICKET_LOG_TYPE.approved,
				name: authData.surname + ' ' + authData.name,
				createdAt: new Date().toISOString(),
				createdBy: createdByConstructor(uid, userType, agencyId),
			});
		}
	};

	const onFinishMission = async () => {
		const ticketRef = firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, data.id));

		const updatedStatus =
			data?.missionStatus !== MISSIONS_STATUS.finished ? MISSIONS_STATUS.finished : MISSIONS_STATUS.ongoing;

		try {
			await ticketRef.update({
				missionStatus: updatedStatus,
				modifiedAt: new Date().toISOString(),
			});
		} catch (error) {
			console.error('Erreur lors de la mise à jour du statut :', error);
		}
	};

	// ==================================== Resize input title ====================================

	useEffect(() => {
		const adjustHeight = () => {
			const textarea = inputTicketNameRef.current;
			if (textarea) {
				textarea.style.height = 'auto';
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		};

		adjustHeight();
		window.addEventListener('resize', adjustHeight);

		return () => window.removeEventListener('resize', adjustHeight);
	}, [ticketName]);

	// ==================================== Print Last Edit ====================================

	const lastEditDate = useMemo(() => {
		const date = formatDateNumericallyWithPastDayKey(data?.modifiedAt, i18n.language);
		const time = formatDateHourly(data?.modifiedAt, i18n.language);

		if (date === 'today') {
			return t('translation.lastEditTodayAt', { time });
		} else if (date === 'yesterday') {
			return t('translation.lastEditYesterdayAt', { time });
		} else if (date === 'monday') {
			return t('translation.lastEditMondayAt', { time });
		} else if (date === 'tuesday') {
			return t('translation.lastEditTuesdayAt', { time });
		} else if (date === 'wednesday') {
			return t('translation.lastEditWednesdayAt', { time });
		} else if (date === 'thursday') {
			return t('translation.lastEditThursdayAt', { time });
		} else if (date === 'friday') {
			return t('translation.lastEditFridayAt', { time });
		} else if (date === 'saturday') {
			return t('translation.lastEditSaturdayAt', { time });
		} else if (date === 'sunday') {
			return t('translation.lastEditSundayAt', { time });
		} else {
			return t('translation.lastEditTheAt', { date, time });
		}
	}, [data?.modifiedAt, i18n.language]);

	// ==================================== Render ====================================

	return (
		<Modal
			isOpen={isOpen}
			className={'modalTicket'}
			overlayClassName={'overlayModalAlbumPhoto'}
			onRequestClose={() => {
				setMenu('follow');
				onClose();
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt=""
				className={'crossIcon hover'}
				onClick={() => {
					setMenu('follow');
					onClose();
				}}
			/>

			<div ref={printRef} className="row">
				<div className={'leftPartModalTicket'} {...getRootProps()}>
					<input {...getInputProps()} />
					{isDragging && canModifyTicket && (
						<div className="dropzone-overlay">
							<p>{t('translation.dragAndDropFilesInThisZone')}</p>
						</div>
					)}
					<div className={'headerTicket'} style={{ backgroundImage: `url(${blur})` }}>
						<div className={'buttonPrint hover'} onClick={() => setModalPrintTicket(true)}>
							<img src={printer} alt="" />
							{t('translation.print')}
						</div>
						{showPrintMessage && <div className="print-message">{t('translation.soonAvailable')}</div>}
					</div>

					<textarea
						ref={inputTicketNameRef}
						placeholder={isSnagging ? t('translation.newSnag') : t('translation.newMission')}
						className={'ticketName'}
						value={ticketName}
						onChange={(e) => {
							setTicketName(e.target.value);
							e.target.style.height = 'auto';
							e.target.style.height = `${e.target.scrollHeight}px`;
						}}
						onBlur={(e) => onChange({ key: 'name' }, e.target.value.trim())}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								e.target.blur();
							}
						}}
						rows={1}
						readOnly={!canModifyTicket}
					/>

					{data?.modifiedAt && (
						<p className={'dataStatusTicket'}>
							{loadingStatus === 'loading'
								? t('translation.saveInProgress')
								: loadingStatus === 'success'
									? desescapeSpecialChars(lastEditDate)
									: loadingStatus === 'error'
										? t('translation.errorDuringSave')
										: ''}
						</p>
					)}

					<div className={'ticketProperties'}>
						{Object.values(properties)
							.filter((property) =>
								ticketProperties.type === 'mission'
									? property.type !== 'company' &&
										property.key !== 'lots' &&
										property.key !== 'locations' &&
										(ticketProperties.visibility !== 'private' ||
											(property.type !== 'people' && property.type !== 'company') ||
											property.fixed)
									: property.key !== 'visibility'
							)
							.map((property, index) => (
								<TicketPropertyItem
									key={index}
									ticket={data}
									property={property}
									label={property.label}
									value={ticketProperties[property.key]}
									onChange={(value) => onChange(property, value)}
									openModalLots={openModalLots}
									canModifyTicket={canModifyTicket}
								/>
							))}
					</div>

					{canModifyTicket && (
						<div style={{ display: 'flex', gap: 0, marginBottom: 10 }}>
							<div style={{ display: 'flex' }} onClick={onDelete}>
								<div className={'deleteTicket'} style={{ marginRight: -12 }}>
									{t('translation.delete')}
								</div>
							</div>

							{data?.properties?.type === 'issue' && data?.issueStatus !== TICKET_STATUS.approved && (
								<div style={{ display: 'flex' }} onClick={onFinishIssue}>
									<div className={'archived'}>{t('translation.markAsResolved')}</div>
								</div>
							)}

							{data?.properties?.type !== 'issue' && (
								<div style={{ display: 'flex' }} onClick={onFinishMission}>
									<div className={'archived'}>
										{data?.missionStatus !== MISSIONS_STATUS.finished
											? t('translation.markAsFinished')
											: t('translation.markAsInProgress')}
									</div>
								</div>
							)}
						</div>
					)}

					{ticketContent?.map((item, index) => (
						<TicketDescription
							key={index}
							title={item.title}
							text={item.text}
							onChangeTitle={(title) => onChangeTitle(index, title)}
							onChangeText={(text) => onChangeText(index, text)}
							onBlurTitle={(title) => onBlurTitle(index, title)}
							onBlurText={(text) => onBlurText(index, text)}
							canModifyTicket={canModifyTicket}
						/>
					))}

					<TicketDocument
						files={files}
						images={images}
						uploadFile={uploadFile}
						handleDeleteFile={handleDeleteFile}
						canModifyTicket={canModifyTicket}
					/>

					<div style={{ height: 25 }} />
				</div>

				<div className={'rightPartModalTicket'}>
					<p className={'partName'}>
						{data?.properties?.type === 'issue' && (
							<>
								<span
									onClick={() => setMenu('follow')}
									className={'hover'}
									style={{ opacity: menu === 'follow' ? 1 : 0.5 }}>
									{t('translation.suivi')}
								</span>{' '}
								<span style={{ marginRight: 4, marginLeft: 4 }}>•</span>
								<span
									onClick={() => setMenu('logs')}
									className={'hover'}
									style={{ opacity: menu === 'logs' ? 1 : 0.5 }}>
									{t('translation.logs')}
								</span>
							</>
						)}
						{!isSnagging && (
							<span
								onClick={() => setMenu('todoList')}
								className={'hover'}
								style={{ opacity: menu !== 'logs' ? 1 : 0.5 }}>
								{t('translation.todoList')}
							</span>
						)}
						{/*<div className={'pastille'} style={{ backgroundColor: '#1C57DD' }}>
						3
					</div>*/}
					</p>

					<div className={'barModal'} />

					{data?.properties.type === 'issue' ? (
						menu === 'follow' ? (
							<FollowUp ticket={data} />
						) : (
							<EventLogs ticket={data} />
						)
					) : menu === 'todoList' || menu === 'follow' ? (
						<TicketTodolist
							rightPart={true}
							ticketId={data?.id}
							ticketTodos={data?.todos}
							setLoadingStatus={setLoadingStatus}
						/>
					) : (
						<EventLogs ticket={data} />
					)}
				</div>
			</div>

			{deletingStatus === 'loading' && (
				<div className="deletion-overlay">
					<div className="deletion-spinner"></div>
					<p>{t('translation.deletingTicket')}</p>
				</div>
			)}
		</Modal>
	);
}

ModalTicket.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	data: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	openModalLots: PropTypes.func.isRequired,
};
