import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import bigEditIcon from '../../../../assets/bigEditIcon.svg';
import { signOut } from 'firebase/auth';
import { auth, firestore } from '../../../../firebase/config';
import { useUsersContext } from '../../../../contexts/usersContext';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	MEDIA_COLLECTION,
	PROJECT_COLLECTION,
	USER_COLLECTION,
} from '../../../../firebase/paths';
import { useProjectContext } from '../../../../contexts/projectContext';
import { useTranslation } from 'react-i18next';

export default function AnnotationsPart({ selectedPhoto, setSelectedPhoto, setPhotos }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const [project] = useProjectContext();

	const [isAnnotationMode, setIsAnnotationMode] = useState(false);
	const [text, setText] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (selectedPhoto) {
			setText(selectedPhoto.comments?.find((comment) => comment.userId === uid)?.content || '');
		}
	}, [selectedPhoto]);

	const handlePublishAnnotation = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const newComments =
				(
					await firestore
						.doc(`${PROJECT_COLLECTION}/${project.id}/${MEDIA_COLLECTION}/${selectedPhoto.id}`)
						.get()
				).data().comments ?? [];
			const index = newComments.findIndex((comment) => comment.userId === uid);
			if (index === -1) {
				newComments.push({ userId: uid, content: text });
			} else {
				newComments[index].content = text;
			}
			setSelectedPhoto({ ...selectedPhoto, comments: newComments });
			setPhotos((photos) => {
				const newPhotos = [...photos];
				newPhotos[newPhotos.findIndex((photo) => photo.id === selectedPhoto.id)] = selectedPhoto;
				return newPhotos;
			});
			await firestore.doc(`${PROJECT_COLLECTION}/${project.id}/${MEDIA_COLLECTION}/${selectedPhoto.id}`).update({
				comments: newComments,
			});
			setIsLoading(false);
			setIsAnnotationMode(false);
		}
	};

	return (
		<div className={'modalAnnotations'}>
			<p className={'littleTitle'}>{t('translation.annotations')}</p>

			{isAnnotationMode ? (
				<textarea
					name=""
					id=""
					cols="30"
					rows="10"
					className={'textarea'}
					placeholder={t('translation.myAnnotation')}
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
			) : (
				<div className={'listAnnotations'}>
					{selectedPhoto?.comments?.length > 0 ? (
						selectedPhoto?.comments?.map((comment, index) => (
							<AnnotationItem key={index} userId={comment.userId} content={comment.content} />
						))
					) : (
						<div className={'placeholderAnnotations'}>
							{t('translation.noAnnotation')} <br /> {t('translation.noAnnotation2')}
						</div>
					)}
				</div>
			)}

			{isAnnotationMode ? (
				<div className={'publishAnnotation hover'} onClick={handlePublishAnnotation}>
					{isLoading ? t('common.loading') : t('translation.publishAnnotation')}
				</div>
			) : (
				<div className={'editAnnotation hover'} onClick={() => setIsAnnotationMode(true)}>
					{t('translation.addPersonalAnnotations')} <img src={bigEditIcon} alt="" className={'bigEditIcon'} />
				</div>
			)}
		</div>
	);
}

function AnnotationItem({ userId, content }) {
	const [users, setUsers] = useUsersContext();
	const [name, setName] = useState('');

	useEffect(() => {
		if (userId) {
			if (!users.find((user) => user.id === userId)) {
				firestore
					.collection(USER_COLLECTION)
					.doc(userId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							if (doc.data().type === 'collaborators') {
								firestore
									.collection(AGENCY_COLLECTION)
									.doc(doc.data().agency)
									.collection(COLLABORATOR_COLLECTION)
									.doc(userId)
									.get()
									.then((doc1) => {
										if (doc1.exists) {
											setUsers((users) => [
												...users,
												{ id: userId, ...doc.data(), ...doc1.data() },
											]);
											setName(doc1.data().surname + ' ' + doc1.data().name);
										}
									});
							} else {
								firestore
									.collection(CLIENT_COLLECTION)
									.doc(userId)
									.get()
									.then((doc1) => {
										if (doc1.exists) {
											setUsers((users) => [
												...users,
												{ id: userId, ...doc.data(), ...doc1.data() },
											]);
											setName(doc1.data().surname + ' ' + doc1.data().name);
										}
									});
							}
						}
					});
			} else {
				setName(
					users.find((user) => user.id === userId)?.surname +
						' ' +
						users.find((user) => user.id === userId)?.name || ''
				);
			}
		}
	}, [userId]);

	return (
		<div className={'annotationItem'}>
			<p className={'name'}>{name}</p>
			<p className={'annotationText'}>{content}</p>
		</div>
	);
}
