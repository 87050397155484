import React from 'react';
import CompanyOrPersonItem from '../../../Little Components/Company Or Person Item/CompanyOrPersonItem';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { REVIEW_RESULT } from '../FollowUp';

export default function TicketValidation({ ticket, isNewResolution, reviewers, reviews }) {
	const { t } = useTranslation();

	const isRefused = reviews?.some((review) => review.result === REVIEW_RESULT.refused);
	const isApproved = reviewers?.every((reviewer) =>
		reviews?.some((review) => review.companyId === reviewer && review.result === REVIEW_RESULT.approved)
	);

	return (
		<div className={'followUpStep'}>
			<div
				className={'bar'}
				style={{
					backgroundColor: isRefused
						? '#ff0000'
						: isApproved
							? '#59c425'
							: isNewResolution
								? '#DEDEDEFF'
								: '#ff9900',
				}}
			/>
			<div>
				<p className={'titleStep'}>
					{isRefused
						? t('translation.ticketRefused')
						: !isNewResolution
							? t('translation.waitingForApproval')
							: t('translation.ticketValidation')}
				</p>

				{!isNewResolution && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 10,
							marginBottom: 5,
							marginTop: 10,
						}}>
						{ticket?.properties?.reviewers?.map((companyId) => (
							<CompanyOrPersonItem
								key={companyId}
								company={true}
								agencyId={companyId}
								hasViewed={reviews?.some(
									(review) =>
										review.companyId === companyId && review.result === REVIEW_RESULT.approved
								)}
								refused={reviews?.some(
									(review) =>
										review.companyId === companyId && review.result === REVIEW_RESULT.refused
								)}
								emoji={true}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

TicketValidation.propTypes = {
	ticket: PropTypes.object.isRequired,
	isNewResolution: PropTypes.bool.isRequired,
	reviewers: PropTypes.array.isRequired,
	reviews: PropTypes.array.isRequired,
};
