import { createContext, useContext } from 'react';

const ProjectLotsContext = createContext();

export function useProjectLots() {
	const context = useContext(ProjectLotsContext);
	if (!context) {
		throw new Error('useProjectLots must be used within a ProjectLotsProvider');
	}
	return context;
}

export default ProjectLotsContext;
