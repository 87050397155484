import replacePictures from '../../../../assets/replacePictures.svg';
import { auth, firestore } from '../../../../firebase/config';
import doubleArrows from '../../../../assets/doubleArrows.svg';
import plusSquare from '../../../../assets/plusSquare.svg';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../contexts/projectContext';
import ModalReplaceTemplate from '../../components/ModalReplaceTemplate';
import { ProgressionStatus } from '../utils';
import './ProgressionHeader.scss';
import TagComponent from '../Tags/TagComponent';
import TypeContext from '../../../../contexts/typeContext';
import AgencyContext from '../../../../contexts/agencyContext';

export default function ProgressionHeader({
	phases,
	canModify,
	isReorganizeMode,
	setIsReorganizeMode,
	setModalNewPhase,
	allTags,
	selectedTags,
	setSelectedTags,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [currentProject] = useProjectContext();
	const projectId = currentProject?.id;

	const [modalReplaceTemplate, setModalReplaceTemplate] = useState(false);

	useEffect(() => {
		if (!selectedTags || selectedTags.length === 0) {
			setSelectedTags([t('translation.allWord')]);
		} else {
			if (selectedTags.length > 1 && selectedTags.includes(t('translation.allWord'))) {
				setSelectedTags(selectedTags.filter((item) => item !== t('translation.allWord')));
			}
		}
	}, [selectedTags]);

	async function deleteProgression() {
		const batch = firestore.batch();

		(await firestore.collection(`projects/${projectId}/progression`).get()).docs.forEach((doc) => {
			batch.delete(doc.ref);
		});

		await batch.commit();
	}

	async function replaceByTemplate(project) {
		try {
			await deleteProgression();

			const batch = firestore.batch();

			const items = (
				await firestore.collection(`projects/${project.id}/progression`).orderBy('date').get()
			).docs.map((doc) => ({ ...doc.data(), id: doc.id }));

			for (const item of items) {
				batch.set(firestore.doc(`projects/${projectId}/progression/${item.id}`), {
					...item,
					status: ProgressionStatus.NOT_STARTED,
					uid,
					agency: type === 'clients' ? 'clients' : agencyId,
				});
			}

			await batch.commit();
		} catch (error) {
			console.error(error);
			alert(t('templates.error'));
		}
	}

	const onClickTag = (name) => {
		setSelectedTags((oldValue) => {
			if (name === t('translation.allWord')) return [name];

			if (oldValue?.includes(name)) {
				return oldValue.filter((item) => item !== name);
			} else {
				return [...oldValue, name];
			}
		});
	};

	return (
		<>
			<div className={'progressionHeader'}>
				<p className={'progressionTitle'}>{t('progression_page.progression')}</p>

				<div className={'progressionButtonContainer'}>
					{canModify && !isReorganizeMode && (
						<div onClick={() => setModalReplaceTemplate(true)} className={'progressionButton hover'}>
							<img src={replacePictures} alt="iconPlus" />
							<p>{t('progression_page.replace_by_a_template')}</p>
						</div>
					)}
					{canModify && (
						<div
							onClick={async () => {
								if (isReorganizeMode) {
									setIsReorganizeMode(false);
									const batch = firestore.batch();
									let index = 0;
									for (const item of phases) {
										batch.update(firestore.doc(`projects/${projectId}/progression/${item.id}`), {
											date: new Date(new Date().getTime() + index * 10).toISOString(),
										});
										index++;
									}
									await batch.commit();
								} else {
									setIsReorganizeMode(true);
								}
							}}
							style={{ backgroundColor: isReorganizeMode ? '#d6f8d2' : '#eeeeee' }}
							className={'progressionButton hover'}>
							{!isReorganizeMode && <img src={doubleArrows} alt="iconPlus" />}
							<p>
								{isReorganizeMode
									? t('progression_page.valid_the_order')
									: t('progression_page.reorganize_phases')}
							</p>
						</div>
					)}

					{canModify && !isReorganizeMode && (
						<div onClick={() => setModalNewPhase(true)} className={'progressionButton hover'}>
							<img src={plusSquare} alt="iconPlus" />
							<p>{t('progression_page.add_a_phase')}</p>
						</div>
					)}
				</div>
			</div>

			<div className={'progressionHeaderLine'} />

			{allTags?.length > 0 && (
				<>
					<div className={'progressionTagsList'}>
						<p className={'progressionTagsTitle'}>{t('common.filter')}</p>
						<TagComponent
							name={t('translation.allWord')}
							isSelected={selectedTags?.includes(t('translation.allWord'))}
							onClick={onClickTag}
						/>

						<div className={'progressionTagsVerticalBar'} />

						{allTags?.map((item) => (
							<TagComponent name={item} isSelected={selectedTags?.includes(item)} onClick={onClickTag} />
						))}
					</div>
					<div className={'progressionHeaderLine'} />
				</>
			)}

			<ModalReplaceTemplate
				modalReplaceTemplate={modalReplaceTemplate}
				setModalReplaceTemplate={setModalReplaceTemplate}
				handleReplaceTemplate={replaceByTemplate}
			/>
		</>
	);
}
