import React, { useState, useEffect, useContext } from 'react';
import './_ticketCard.scss';
import docTicket from '../../../../../assets/docTicket.svg';
import todoTickets from '../../../../../assets/todoTickets.svg';
import blur from '../../../../../assets/blur.jpg';
import mappin from '../../../../../assets/mappin.svg';
import hammer from '../../../../../assets/hammer.svg';
import CompanyOrPersonItem from '../Little Components/Company Or Person Item/CompanyOrPersonItem';
import { useTranslation } from 'react-i18next';
import { formatDateNumericallyWithTodayOrYesterdayOrTomorrow } from '../../../../../useful/date';
import { MISSIONS_STATUS, useTicketProperties } from '../../data';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { auth, firestore } from '../../../../../firebase/config';
import { Paths } from '../../../../../firebase/paths';
import { joinPaths } from '../../../../../firebase/utils';
import { File } from '../Modal Ticket/Ticket Documents/TicketDocument';
import { TICKET_STATUS } from '../Modal Ticket/Follow Up/FollowUp';
import AgencyContext from '../../../../../contexts/agencyContext';
import { getTicketStatus } from '../../utils';
import { useClientsContext } from 'src/contexts/clientsContext';
import TypeContext from 'src/contexts/typeContext';

export default function TicketCard({
	ticket,
	isStatusShown,
	isLotShown,
	isLocationShown,
	isTicketOwnerShown,
	isCompanyInChargeShown,
	isDueDateShown,
	isWorkDurationShown,
	isLinkedFileShown,
	isDescriptionShown,
	isIssueTicket,
	isSnagging,
}) {
	const { t, i18n } = useTranslation();

	const { properties } = useTicketProperties();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();

	const [description, setDescription] = useState('');
	const [images, setImages] = useState([]);
	const [files, setFiles] = useState([]);
	const [status, setStatus] = useState(null);

	const sharedWithClient =
		type !== 'clients' &&
		!clients?.map((item) => item.id)?.includes(agencyId) &&
		(ticket.properties.reviewers?.includes('clients') ||
			ticket.properties.companies?.includes('clients') ||
			ticket.properties.users?.includes('clients') ||
			ticket.properties.owner?.userType === 'client' ||
			clients?.map((item) => item.id).includes(ticket.properties.owner?.agencyId));

	// ================================================ STATUS ================================================

	useEffect(() => {
		const isClient = type === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (ticket?.properties?.type === 'issue' && (isClient || agencyId)) {
			setStatus(getTicketStatus(ticket, isClient ? 'clients' : agencyId));
		}
	}, [agencyId, ticket]);

	// ================================================ DESCRIPTION ================================================

	useEffect(() => {
		if (ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.CONTENT))
				.where('type', '==', 'text')
				.orderBy('createdAt', 'asc')
				.limit(1)
				.onSnapshot((snapshot) => {
					if (snapshot && snapshot.docs.length > 0) {
						setDescription(snapshot.docs[0].data().text);
					}
				});
			return () => unsubscribe();
		}
	}, [ticket?.id]);

	// ================================================ FILES ================================================

	useEffect(() => {
		if (project?.id && ticket?.id && isLinkedFileShown) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.CONTENT))
				.where('type', 'in', ['image', 'file'])
				.onSnapshot((querySnapshot) => {
					const images = [];
					const files = [];
					querySnapshot.docs.forEach((doc) => {
						if (doc.data().type === 'image') images.push({ ...doc.data(), id: doc.id });
						else files.push({ ...doc.data(), id: doc.id });
					});
					setImages(images);
					setFiles(files);
				});

			return () => unsubscribe();
		}
	}, [project?.id, ticket?.id, isLinkedFileShown]);

	return (
		<div className={'ticketCard'}>
			{isSnagging && (
				<span className={'profileOnTicket'}>
					{ticket?.properties?.owner?.agencyId === agencyId ||
					ticket?.properties?.owner?.userId === uid ||
					((type === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)) &&
						(ticket?.properties?.owner?.userType === 'client' ||
							clients?.map((item) => item.id)?.includes(ticket?.properties?.owner?.agencyId)))
						? t('translation.creator')
						: ticket?.properties?.companies?.includes(agencyId) ||
							  ((type === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)) &&
									ticket?.properties?.companies?.includes('clients'))
							? t('translation.inCharge')
							: ticket.properties.reviewers?.includes(agencyId) ||
								  ((type === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)) &&
										ticket.properties.reviewers?.includes('clients'))
								? t('translation.reviewer')
								: t('translation.viewer')}
				</span>
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
				}}>
				<p className={'ticketName'}>
					{ticket.name ? ticket.name : isSnagging ? t('translation.newSnag') : t('translation.newMission')}
				</p>
				{!ticket?.companiesViewed?.includes(uid) && !isSnagging && <div className={'pastilleOrange'}>1</div>}
			</div>

			{isDescriptionShown && description && <p className={'ticketDescription'}>{description}</p>}

			{isLotShown && ticket.properties.lots?.length > 0 && (
				<div className={'locationOnSite'}>
					<img src={hammer} alt="" />
					<div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginLeft: 5 }}>
						{ticket.properties.lots.map((lot, index) => (
							<div style={{ display: 'flex', alignItems: 'center', gap: 5 }} key={index}>
								<p style={{ margin: 0 }}>
									{properties.lots.options.find((option) => option.key === lot)?.label}
								</p>
							</div>
						))}
					</div>
				</div>
			)}
			{isLocationShown && ticket.properties.locations?.length > 0 && (
				<div className={'locationOnSite'}>
					<img src={mappin} alt="" />
					<div style={{ display: 'flex', gap: 5, flexWrap: 'wrap', marginLeft: 5 }}>
						{ticket.properties.locations.map((location, index) => (
							<div style={{ display: 'flex', alignItems: 'center', gap: 5 }} key={index}>
								<p style={{ margin: 0 }}>
									{properties.locations.options.find((option) => option.key === location)?.label}
								</p>
								{index < ticket.properties.locations.length - 1 && <p style={{ margin: 0 }}>•</p>}
							</div>
						))}
					</div>
				</div>
			)}

			{(isDueDateShown || isWorkDurationShown) &&
				(ticket.properties.dueAt || ticket.properties.expectingDuration) && (
					<div className={'containerTiming'}>
						{isDueDateShown && ticket.properties.dueAt && (
							<div
								className={
									new Date(ticket.properties.dueAt) < new Date() &&
									ticket.issueStatus !== TICKET_STATUS.approved &&
									ticket.missionStatus !== MISSIONS_STATUS.finished
										? 'timingItem timingItemOverdue'
										: 'timingItem'
								}>
								{new Date(ticket.properties.dueAt) < new Date() &&
									ticket.issueStatus !== TICKET_STATUS.approved &&
									ticket.missionStatus !== MISSIONS_STATUS.finished &&
									t('translation.overdue')}{' '}
								{formatDateNumericallyWithTodayOrYesterdayOrTomorrow(
									ticket.properties.dueAt,
									i18n.language,
									t
								)}
							</div>
						)}
						{isWorkDurationShown && ticket.properties.expectingDuration && (
							<div className={'timingItem'}>{ticket.properties.expectingDuration}</div>
						)}
					</div>
				)}

			{isStatusShown && !isSnagging && (
				<div>
					<p className={'progressTitle'}>
						{isIssueTicket ? t('translation.status') : t('translation.progress')}
					</p>
					<div className={'progressContainer'}>
						<div className={'progressElem'}>
							<img
								className={'progressElem'}
								src={blur}
								style={{
									width: `${
										ticket.missionStatus === MISSIONS_STATUS.finished
											? 100
											: ticket.todos?.length > 0
												? (ticket.todos?.reduce(
														(acc, todo) =>
															acc +
															(todo.completed
																? 1
																: Math.min(
																		todo.subTodos.filter(
																			(subTodo) => subTodo.completed
																		).length
																	) /
																	(todo.subTodos.length + 1)),
														0
													) /
														ticket.todos?.length) *
													100
												: 0
									}%`,
									marginBottom: 50,
								}}
							/>
						</div>
					</div>
				</div>
			)}
			{isLinkedFileShown && images.length > 1 && (
				<div className={'numberPhotos'} style={{ zIndex: 2 }}>
					{images.length}
				</div>
			)}
			{isLinkedFileShown && images.length > 0 && <img src={images[0].url} alt="" className={'ticketImage'} />}

			{isLinkedFileShown && files.length > 1 && (
				<div className={'numberPhotos'} style={{ zIndex: 2, marginBottom: -53, marginTop: 15 }}>
					{files.length}
				</div>
			)}

			{isLinkedFileShown && files.length > 0 && <File file={files[0]} disabled={true} />}

			<div>
				{(isTicketOwnerShown || (isCompanyInChargeShown && ticket.properties?.companies?.length > 0)) && (
					<div>
						{isTicketOwnerShown && (
							<CompanyOrPersonItem
								userId={ticket.properties.owner.userId}
								userType={ticket.properties.owner.userType}
								agencyId={ticket.properties.owner.agencyId}
								unClickable={true}
								maxWidth={300}
							/>
						)}
						{isCompanyInChargeShown &&
							ticket.properties?.companies?.map((company, index) => (
								<CompanyOrPersonItem
									key={index}
									agencyId={company}
									unClickable={true}
									maxWidth={300}
									company={true}
								/>
							))}
					</div>
				)}

				{sharedWithClient && (
					<CompanyOrPersonItem
						name={t('translation.sharedWithTheClient')}
						unClickable={true}
						maxWidth={300}
						initials={t('translation.clientLittle')}
						color={'#83d98a'}
					/>
				)}
			</div>

			{(ticket.numFiles > 0 || ticket.todos?.length > 0) && (
				<div className={'footerTicket'}>
					<div className={'numbers'}>
						{ticket.numFiles > 0 && (
							<div className={'numberItem'}>
								<img src={docTicket} className={'numberIcon'} alt="" /> {ticket.numFiles}
							</div>
						)}
						{ticket.todos?.length > 0 && (
							<div className={'numberItem'}>
								<img src={todoTickets} className={'numberIcon'} alt="" /> {ticket.todos?.length}
							</div>
						)}
					</div>
				</div>
			)}

			{isSnagging
				? isStatusShown &&
					(status === TICKET_STATUS.new ? (
						<div
							className={'tagStatus'}
							style={{ color: '#ff6a00', backgroundColor: 'rgba(255, 153, 0, 0.1)' }}>
							{t('translation.newSnag')}
						</div>
					) : status === TICKET_STATUS.pendingProcess ? (
						<div
							className={'tagStatus'}
							style={{ color: '#1C57DD', backgroundColor: 'rgba(28,87,221,0.1)' }}>
							{t('translation.waitingForProcessing')}
						</div>
					) : status === TICKET_STATUS.inProgress ? (
						ticket?.properties?.companies?.includes(agencyId) ? (
							<div
								className={'tagStatus'}
								style={{
									color: '#ff6a00',
									backgroundColor: 'rgba(255, 153, 0, 0.1)',
								}}>
								{t('translation.waitingForYourResolution')}
							</div>
						) : (
							<div
								className={'tagStatus'}
								style={{
									color: '#1C57DD',
									backgroundColor: 'rgba(28,87,221,0.1)',
								}}>
								{t('translation.inProgress')}
							</div>
						)
					) : status === TICKET_STATUS.pendingApproval ? (
						<div
							className={'tagStatus'}
							style={{
								color: ticket?.properties?.companies?.includes(agencyId) ? '#1C57DD' : '#ff6a00',
								backgroundColor: ticket?.properties?.companies?.includes(agencyId)
									? 'rgba(28,87,221,0.1)'
									: 'rgba(255, 153, 0, 0.1)',
							}}>
							{t('translation.waitingForApproval')}
						</div>
					) : status === TICKET_STATUS.refused ? (
						ticket?.companiesViewedRefused?.includes(agencyId) ? (
							ticket?.properties?.companies?.includes(agencyId) ? (
								<div
									className={'tagStatus'}
									style={{ color: '#ff6a00', backgroundColor: 'rgba(255,153,0,0.1)' }}>
									{t('translation.waitingForYourResolution')}
								</div>
							) : (
								<div
									className={'tagStatus'}
									style={{ color: '#1C57DD', backgroundColor: 'rgba(28,87,221,0.1)' }}>
									{t('translation.inProgress')}
								</div>
							)
						) : (
							<div
								className={'tagStatus'}
								style={{ color: '#ff0000', backgroundColor: 'rgba(255,0,0,0.1)' }}>
								{t('translation.proposalRefused')}
							</div>
						)
					) : status === TICKET_STATUS.approved ? (
						<div
							className={'tagStatus'}
							style={{ color: '#3CA504', backgroundColor: 'rgba(60,165,4,0.1)' }}>
							{t('translation.snagResolved')}
						</div>
					) : null)
				: ticket.missionStatus === MISSIONS_STATUS.finished && (
						<div
							className={'tagStatus'}
							style={{ color: '#3CA504', backgroundColor: 'rgba(60,165,4,0.1)' }}>
							{t('translation.missionFinished')}
						</div>
					)}
		</div>
	);
}
