import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../../../firebase/config';
import { AGENCY_COLLECTION, FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../firebase/paths';
import { useTranslation } from 'react-i18next';
import redWarning from '../../../../../assets/redWarning.svg';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';

export default function ItemPartnerFilter({ projectId, partnerId, vous, selectedPart, setSelectedPart }) {
	const agencyId = useContext(AgencyContext);
	const userType = useContext(TypeContext);
	const uid = auth.currentUser?.uid;

	const { t } = useTranslation();

	const [name, setName] = useState('');
	const [diminutif, setDiminutif] = useState('');
	const [noDocShared, setNoDocShared] = useState(false);

	useEffect(() => {
		if (partnerId) {
			firestore
				.collection(AGENCY_COLLECTION)
				.doc(partnerId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setName(doc.data().name);
						setDiminutif(doc.data().diminutifAgency);
					}
				});
		}
	}, [partnerId]);

	useEffect(() => {
		if (projectId && partnerId && partnerId !== agencyId) {
			if (partnerId === 'clients') {
				if (agencyId) {
					const listener = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('createdBy.agencyId', '==', agencyId)
						.where('clientPermission', '==', true)
						.limit(1)
						.onSnapshot((querySnapshot) => {
							if (querySnapshot.size === 0) {
								setNoDocShared(true);
							} else {
								setNoDocShared(false);
							}
						});
					return () => listener();
				}
			} else {
				if (userType === 'clients') {
					const listener = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('createdBy.userId', '==', uid)
						.where('permissions', 'array-contains', partnerId)
						.limit(1)
						.onSnapshot((querySnapshot) => {
							if (querySnapshot.size === 0) {
								setNoDocShared(true);
							} else {
								setNoDocShared(false);
							}
						});
					return () => listener();
				} else if (agencyId) {
					const listener = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('createdBy.agencyId', '==', agencyId)
						.where('permissions', 'array-contains', partnerId)
						.limit(1)
						.onSnapshot((querySnapshot) => {
							if (querySnapshot.size === 0) {
								setNoDocShared(true);
							} else {
								setNoDocShared(false);
							}
						});
					return () => listener();
				}
			}
		}
	}, [projectId, partnerId, agencyId]);

	return (
		<div
			style={{ display: 'flex' }}
			onClick={() => {
				setSelectedPart(partnerId);
			}}>
			<div className={selectedPart === partnerId ? 'partItemSelected' : 'partItem'}>
				{partnerId === 'clients'
					? t('common.mo')
					: partnerId === agencyId
					  ? t('common.you')
					  : partnerId === selectedPart
					    ? name
					    : !diminutif
					      ? getFirstLetters(name)
					      : diminutif}
				{!vous && noDocShared && (
					<div style={{ color: '#ff0000' }}>
						<img src={redWarning} alt="" className={'warning'} />
						{selectedPart === partnerId && t('translation.noFolderShared')}
					</div>
				)}
			</div>
		</div>
	);
}

function getFirstLetters(str) {
	return str
		? str
				.split(' ')
				.map((word) => word[0])
				.join('')
		: '';
}
