import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext();

export function ProjectContextProvider({ children }) {
	const [project, setProject] = useState({});

	return <ProjectContext.Provider value={[project, setProject]}>{children}</ProjectContext.Provider>;
}

export function useProjectContext() {
	return useContext(ProjectContext);
}
