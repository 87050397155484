import React, { useContext, useEffect, useState } from 'react';
import '../_contributorAccountsContainer.scss';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import searchIcon from '../../../../../assets/searchIcon.png';
import ItemListCollabPartner from '../List Item/ItemListCollabPartner';
import { firestore } from '../../../../../firebase/config';
import gerySearchIcon from '../../../../../assets/gerySearchIcon.png';
import { useTranslation } from 'react-i18next';
import { deleteUserInProject } from '../../../../../useful/UsefulForProject';
import AgencyContext from '../../../../../contexts/agencyContext';
import { rolesWithMOA } from '../../../utils';
import leftArrow from '../../../../../assets/leftArrow.svg';
import ModalPartnerPermissions from '../../../../../components/Modal Partner Permissions/ModalPartnerPermissions';
import { joinPaths } from '../../../../../firebase/utils';

export default function ModalPartnerCompany({
	modalPartnerCompany,
	setModalPartnerCompany,
	accounts,
	uid,
	name,
	client,
	vous,
	projectId,
	isMandataire,
}) {
	const [searchFill, setSearchFill] = useState('');
	const [statusPartnerCompany, setStatusPartnerCompany] = useState('contributor');
	const { t } = useTranslation();
	const localAgencyId = useContext(AgencyContext);

	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState(0);

	const [agencyData, setAgencyData] = useState();
	const [roleIds, setRoleIds] = useState(['']);

	const [modalPermissions, setModalPermissions] = useState(false);

	useEffect(() => {
		if (!client) {
			firestore
				.doc(`agencies/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setAgencyData(documentSnapshot.data());
					}
				});
		}
	}, [uid, client]);

	useEffect(() => {
		if (uid) {
			if (client) {
				setRoleIds(['0']);
			} else {
				firestore
					.doc(`projects/${projectId}/accounts/${uid}`)
					.get()
					.then((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							setRoleIds(documentSnapshot.data().roleIds ?? ['']);
						}
					});
			}
		}
	}, [projectId, uid]);

	const removePartnerInProject = async () => {
		if (window.confirm(t('translation.wantToDeleteAgency'))) {
			setModalPartnerCompany(false);
			firestore
				.collection(`projects/${projectId}/accounts`)
				.where('agency', '==', uid)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						querySnapshot.forEach(async (documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								await deleteUserInProject(
									documentSnapshot.id,
									'collaborators',
									uid,
									projectId,
									localAgencyId
								);
							}
						});
					}
				});
			await firestore.doc(`projects/${projectId}/accounts/${uid}`).delete();
			await firestore.doc(`agencies/${uid}/projects/${projectId}`).delete();
		}
	};

	const saveRole = async (newRole) => {
		let newRoles = [newRole];
		setRoleIds(newRoles);
		await firestore.doc(`projects/${projectId}/accounts/${uid}`).update({
			roleIds: newRoles,
		});
	};

	const handleManagePermissions = () => {
		setModalPermissions(true);
	};

	return (
		<Modal
			isOpen={modalPartnerCompany}
			className={'modalProjectAccounts modalPartnerCompany'}
			overlayClassName="overlayModalProjectAccounts"
			onRequestClose={() => {
				setModalPartnerCompany(false);
				setResearch('');
				setResearchNumber(0);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt=""
				className={'crossIcon'}
				onClick={() => {
					setModalPartnerCompany(false);
					setResearch('');
					setResearchNumber(0);
				}}
			/>

			<p className={'titleModal'}>{agencyData?.name}</p>

			<div className={'containerSearchButton'}>
				<div className={'searchContainer'}>
					<img src={searchIcon} alt="" className={'searchIconAccounts'} />
					<input
						value={research}
						onChange={(e) => setResearch(e.target.value)}
						type="email"
						className={'searchInput'}
						placeholder={t('modal_partner_company.search_placeholder')}
					/>
					<img
						src={crossIcon}
						alt=""
						className={'crossIconSearch hover'}
						style={{ opacity: searchFill.length > 1 ? 1 : 0 }}
						onClick={() => setSearchFill('')}
					/>
				</div>
				{isMandataire && (
					<button onClick={handleManagePermissions} className={'buttonManagePermissions'}>
						{t('permissions.management_permissions')}
					</button>
				)}
			</div>

			<div className={'barModalAccounts'} />

			{isMandataire ? (
				<div className={'scrollableList'}>
					<p className={'roleTitle'}>{t('modal_partner_company.role_title')}</p>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<select
							className={'select'}
							value={roleIds[0] || ''}
							onChange={(e) => saveRole(e.target.value)}>
							<option value="">{t('modal_partner_company.select_role_placeholder')}</option>
							{rolesWithMOA(t).map((role) => (
								<option value={role.id}>{role.name}</option>
							))}
						</select>

						<img src={leftArrow} alt="" className={'leftArrow'} />
					</div>
					<div style={{ display: 'flex', height: 10, marginTop: 18 }}>
						<div className={'barModalAccounts'} />
					</div>
					{accounts &&
						accounts.map((item, index) => (
							<div key={index}>
								<ItemListCollabPartner
									uid={item.uid}
									type={client ? 'clients' : 'collaborators'}
									agency={uid}
									research={research}
									setResearchNumber={setResearchNumber}
								/>
							</div>
						))}
					{researchNumber === 0 && research.length > 1 && (
						<div className={'noResult'} style={{ marginTop: 40 }}>
							<img
								src={gerySearchIcon}
								alt=""
								style={{
									width: 30,
								}}
							/>
							<p className={'noResultFound'}>{t('common.no_result_found')}</p>
							<p className={'noResultFound'}>{t('research_project.no_result_2')}</p>
						</div>
					)}
					<div style={{ display: 'flex', marginTop: 20, marginBottom: 15 }}>
						<div className={'barModalAccounts'} />
					</div>
					<div style={{ display: 'flex' }} onClick={removePartnerInProject}>
						<div className={'buttonRemovePartnerCompany hover'}>
							{t('translation.extirpTo')} <strong> {agencyData?.name} </strong>{' '}
							{t('translation.projectAccess')}
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div className={'comble'} />
					</div>
				</div>
			) : (
				<div className={'scrollableList'}>
					{accounts &&
						accounts.map((item, index) => (
							<div key={index}>
								<ItemListCollabPartner
									uid={item.uid}
									type={client ? 'clients' : 'collaborators'}
									agency={uid}
									research={research}
									setResearchNumber={setResearchNumber}
								/>
							</div>
						))}
					{researchNumber === 0 && research.length > 1 && (
						<div className={'noResult'} style={{ marginTop: 40 }}>
							<img
								src={gerySearchIcon}
								alt=""
								style={{
									width: 30,
								}}
							/>
							<p className={'noResultFound'}>{t('common.no_result_found')}</p>
							<p className={'noResultFound'}>{t('research_project.no_result_2')}</p>
						</div>
					)}
				</div>
			)}

			<ModalPartnerPermissions
				isOpen={modalPermissions}
				onClose={() => setModalPermissions(false)}
				partnerId={uid}
				partnerName={agencyData?.name}
			/>
		</Modal>
	);
}
