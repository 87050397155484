import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../firebase/config';
import { removeDuplicates } from '../../useful/UsefulFunctions';
import AgencyContext from '../../contexts/agencyContext';
import { useProjectContext } from '../../contexts/projectContext';
import TypeContext from '../../contexts/typeContext';
import PartnersContext from '../../contexts/partnersContext';
import ClientsContext from '../../contexts/clientsContext';
import { rolesWithMOA } from './utils';
import { useTranslation } from 'react-i18next';
import PagePermissionsContext from '../../contexts/pagePermissions';
import { joinPaths } from '../../firebase/utils';
import { Paths } from '../../firebase/paths';
import ProjectLotsContext from '../../contexts/projectLotsContext';
import ProjectLocationsContext from '../../contexts/projectLocationsContext';
import ProjectUsersContext from '../../contexts/projectUsersContext';

export default function WrapperContext({ children, pagePermissions, setPagePermissions }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();

	const [partners, setPartners] = useState([]);
	const [clients, setClients] = useState([]);
	const [lots, setLots] = useState([]);
	const [locations, setLocations] = useState([]);
	const [users, setUsers] = useState([]);

	useEffect(() => {
		if (project?.id && uid && (type === 'clients' || agencyId)) {
			const subscriber1 = firestore.collection(`projects/${project.id}/accounts`).onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					const users = [];
					const partners = [];
					const clients = [];
					const promisesPartners = [];
					const promisesClients = [];
					const promisesUsers = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							if (doc.data().type === 'agencies') {
								const promise = firestore
									.doc(`agencies/${doc.id}`)
									.get()
									.then((doc1) => {
										if (doc1 && doc1.exists) {
											if (
												doc.data().roleIds &&
												doc.data().roleIds.includes(rolesWithMOA(t)[0].id)
											) {
												clients.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
											} else {
												if (doc.id !== agencyId) {
													partners.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
												}
											}
										}
									});
								if (doc.data().roleIds && doc.data().roleIds.includes(rolesWithMOA(t)[0].id)) {
									promisesClients.push(promise);
								} else {
									promisesPartners.push(promise);
								}
							} else if (doc.data().type === 'clients') {
								if (doc.id !== uid) {
									const promise = firestore
										.doc(`clients/${doc.id}`)
										.get()
										.then((doc1) => {
											if (doc1 && doc1.exists) {
												clients.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
												users.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
											}
										});
									promisesClients.push(promise);
								}
							} else {
								if (doc.id !== uid) {
									const promise = firestore
										.doc(`agencies/${doc.data().agency}/collaborators/${doc.id}`)
										.get()
										.then((doc1) => {
											if (doc1 && doc1.exists) {
												users.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
											}
										});
									promisesUsers.push(promise);
								}
							}
						}
					});
					Promise.all(promisesPartners).then(() => {
						setPartners(partners);
					});
					Promise.all([...promisesClients, ...promisesUsers]).then(() => {
						setClients((prevState) => removeDuplicates([...(prevState ?? []), ...clients], 'id'));
						setUsers(users);
					});
				}
			});

			const subscriber2 = firestore.collection(`projects/${project.id}/lots`).onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					const lots = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							lots.push({ ...doc.data(), id: doc.id });
						}
					});

					const compareAlphaNumeric = (a, b) => {
						const aNumber = a.index;
						const bNumber = b.index;

						const aParts = aNumber?.match(/(\d+|\D+)/g);
						const bParts = bNumber?.match(/(\d+|\D+)/g);

						for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
							const aValue = aParts[i];
							const bValue = bParts[i];

							if (aValue !== bValue) {
								if (!isNaN(aValue) && !isNaN(bValue)) {
									return parseInt(aValue) - parseInt(bValue);
								}
								return aValue?.localeCompare(bValue, undefined, { sensitivity: 'base' });
							}
						}

						return aParts.length - bParts.length;
					};

					lots.sort(compareAlphaNumeric);

					setLots(lots);
				} else {
					setLots([]);
				}
			});

			const subscriber3 = firestore
				.collection(`projects/${project.id}/locations`)
				.orderBy('createdAt', 'desc')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const locations = [];
						querySnapshot.forEach((doc) => {
							if (doc && doc.exists) {
								locations.push({ ...doc.data(), id: doc.id });
							}
						});

						setLocations(locations);
					} else {
						setLocations([]);
					}
				});

			return () => {
				subscriber1();
				subscriber2();
				subscriber3();
			};
		}
	}, [project?.id, agencyId, type, uid]);

	useEffect(() => {
		if (project?.id && agencyId) {
			const subscriber = firestore
				.doc(joinPaths(Paths.PROJECTS, project.id, Paths.ACCOUNTS, agencyId))
				.onSnapshot((doc) => {
					if (doc && doc.exists) {
						setPagePermissions(doc.data().permissions ?? { progression: 'all', finances: 'all' });
					}
				});
			return () => subscriber();
		}
	}, [project?.id, agencyId]);

	return (
		<ProjectUsersContext.Provider value={[users, setUsers]}>
			<PartnersContext.Provider value={[partners, setPartners]}>
				<ClientsContext.Provider value={[clients, setClients]}>
					<ProjectLotsContext.Provider value={[lots, setLots]}>
						<ProjectLocationsContext.Provider value={[locations, setLocations]}>
							<PagePermissionsContext.Provider value={[pagePermissions, setPagePermissions]}>
								{children}
							</PagePermissionsContext.Provider>
						</ProjectLocationsContext.Provider>
					</ProjectLotsContext.Provider>
				</ClientsContext.Provider>
			</PartnersContext.Provider>
		</ProjectUsersContext.Provider>
	);
}
