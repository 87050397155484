import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import redCross from '../../../assets/redCross.svg';
import redPeople from '../../../assets/redPeople.svg';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../../contexts/agencyContext';

export default function ModalNeedToPay({ modalNeedToPay }) {
	const { t } = useTranslation();
	const agencyId = useContext(AgencyContext);

	const [analyse, setAnalyse] = useState(false);

	return (
		<Modal
			isOpen={modalNeedToPay}
			className={'modalNeedToPay'}
			overlayClassName="OverlayModal"
			closeTimeoutMS={300}>
			<img src={redCross} alt="" className={'redCross'} />
			<p className={'titleModal'}>{t('translation.needToPay')}</p>
			<p className={'subtitleModal'}>{t('translation.changeInfosPay')}</p>
			<div className={'redWidget'}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img src={redPeople} alt="" className={'redPeople'} />
					{t('translation.prelevementRefuse')}
				</div>
				<div>7 days ago</div>
			</div>
			<div className={'buttonModal'}>{t('translation.regularizeSituation')}</div>
		</Modal>
	);
}
