import React, { useRef, useEffect } from 'react';
import './_ticketDescription.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function TicketDescription({
	title,
	text,
	onChangeTitle,
	onChangeText,
	onBlurTitle,
	onBlurText,
	canModifyTicket,
	isLarge,
}) {
	const { t } = useTranslation();

	const titleRef = useRef();
	const inputRef = useRef();

	useEffect(() => {
		if (inputRef?.current) {
			inputRef.current.style.height = 'auto';
			inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
		}
	}, [text, inputRef]);

	useEffect(() => {
		if (titleRef?.current) {
			titleRef.current.style.height = 'auto';
			titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
		}
	}, [title, titleRef]);

	return (
		<div className={'ticketDescription'} style={{ pointerEvents: !canModifyTicket && 'none' }}>
			<textarea
				ref={titleRef}
				className={'titleDescription'}
				style={{ marginLeft: isLarge && 0, width: isLarge && '100%' }}
				type="text"
				placeholder={t('translation.newTitle')}
				value={title}
				onChange={(e) => onChangeTitle(e.target.value)}
				onBlur={(e) => onBlurTitle(e.target.value.trim())}
				rows={1}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.preventDefault();
						inputRef?.current?.focus();
					}
				}}
				readOnly={!canModifyTicket}
			/>
			<textarea
				ref={inputRef}
				className={'descriptionTicket'}
				style={{ marginLeft: isLarge && 0, width: isLarge && '100%' }}
				placeholder={t('translation.newDescription')}
				autoCapitalize={'true'}
				value={text}
				onChange={(e) => {
					onChangeText(e.target.value);
				}}
				rows={1}
				onBlur={(e) => onBlurText(e.target.value.trim())}
				readOnly={!canModifyTicket}
			/>
		</div>
	);
}

TicketDescription.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	onChangeTitle: PropTypes.func.isRequired,
	onChangeText: PropTypes.func.isRequired,
	onBlurTitle: PropTypes.func.isRequired,
	onBlurText: PropTypes.func.isRequired,
	canModifyTicket: PropTypes.bool,
};
