import React, { useEffect, useState } from 'react';
import checkBleu from '../../assets/lightGreenCheckIcon.svg';
import blueRightIcon from '../../assets/blueRightIcon.png';
import loginBlue from '../../assets/loginBlue.png';
import OnBoardingClient1 from '../../assets/OnBoardingClient1.png';
import OnBoardingClient2 from '../../assets/OnBoardingClient2.png';
import OnBoardingClient3 from '../../assets/OnBoardingClient3.png';
import OnBoardingClient4 from '../../assets/OnBoardingClient4.png';
import flecheBleuBig from '../../assets/flecheBleuBig.png';
import blueLeftIcon from '../../assets/blueLeftIcon.png';
import dotted from '../../assets/dotted.png';
import { useNavigate } from 'react-router-dom';
import { passwordIsStrong, withMajuscule, withMinuscule, withNumber, withSymbol } from '../../useful/UsefulFunctions';
import O_Opus from '../../assets/O_Opus.png';
import VideoProjet from '../../assets/VideoProjet.mp4';
import { generateUniqueFirestoreId } from '../../firebase/utils';
import { useTranslation } from 'react-i18next';
import { auth, firestore, functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';

export default function OnBoarding() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	// Pages du OnBoardingAdministrator

	const [secteurActivite, setSecteurActivite] = useState(true);
	const [infosEntreprise, setInfosEntreprise] = useState(false);
	const [infosPersonnelles, setInfosPersonnelles] = useState(false);
	const [securite, setSecurite] = useState(false);

	const [opacityBienvenue, setOpacityBienvenue] = useState(0);
	const [opacitySubtitleBienvenue, setOpacitySubtitleBienvenue] = useState(0);

	const [marginTopPro, setMarginTopPro] = useState(60);
	const [opacityPro, setOpacityPro] = useState(0);
	const [marginTopClient, setMarginTopClient] = useState(60);
	const [opacityClient, setOpacityClient] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setOpacityBienvenue(1);
			setTimeout(() => {
				setOpacitySubtitleBienvenue(1);
				setTimeout(() => {
					setMarginTopPro(30);
					setOpacityPro(1);
					setTimeout(() => {
						setMarginTopClient(30);
						setOpacityClient(1);
					}, 300);
				}, 100);
			}, 300);
		}, 500);
	}, [setOpacityBienvenue]);

	// Choix du métier

	const [architectureSelected, setArchitectureSelected] = useState(true);
	const [designSelected, setDesignSelected] = useState(false);
	const [maitreOeuvreSelected, setMaitreOeuvreSelected] = useState(false);

	const [isClient, setIsClient] = useState(false);

	// Infos entreprise

	const noBusinessInfos = {
		name: '',
		address: '',
		phone: '',
		site: '',
		diminutifAgency: '',
	};
	const [businessInfos, setBusinessInfos] = useState(noBusinessInfos);
	function businessInfosChange(e) {
		e.preventDefault();
		const { id, value } = e.target;

		setBusinessInfos((prevState) => {
			return {
				...prevState,
				[id]: value,
			};
		});
	}

	// Infos personnelles

	const noPersonnalInfos = {
		name: '',
		surname: '',
		sex: '',
		phone: '',
		email: '',
		job: '',
	};
	const [personnalInfos, setPersonnalInfos] = useState(noPersonnalInfos);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

	function personnalInfosChange(e) {
		e.preventDefault();
		setPersonnalInfos({ ...personnalInfos, [e.target.id]: e.target.value });
	}

	const [loading, setLoading] = useState(false);

	function createAccounts() {
		setLoading(true);

		fetchSignInMethodsForEmail(auth, personnalInfos.email).then(async (task) => {
			if (task.length === 0) {
				await createAccountsAfterVerification();
			} else {
				window.alert(t('onboarding.email_already_use'));
			}
		});
	}

	async function createAccountsAfterVerification() {
		const uidAgency = generateUniqueFirestoreId();
		const date = new Date().toISOString();

		await firestore.doc(`accounts/${uidAgency}`).set({
			uid: uidAgency,
			type: 'agencies',
		});
		await firestore.doc(`agencies/${uidAgency}`).set({
			...businessInfos,
			uid: uidAgency,
			date,
			imgUrl: '',
			logoUrl: '',
			subtype: maitreOeuvreSelected
				? "Maître d'oeuvre"
				: architectureSelected
					? 'Architecture'
					: designSelected
						? 'Design'
						: null,
			subscription: false,
			customerId: null,
			subscriptionId: null,
			vip: false,
		});

		createUserWithEmailAndPassword(auth, personnalInfos.email, password).then(async (authUser) => {
			const uidUser = authUser.user.uid;

			await firestore
				.doc(`messaging/${uidAgency}`)
				.set({ id: uidAgency, parentId: uidAgency, parentType: 'agency', isGeneral: true });

			await firestore.doc(`accounts/${uidUser}`).set({
				agency: uidAgency,
				uid: uidUser,
				tokens: [],
				type: 'collaborators',
			});

			await firestore.doc(`agencies/${uidAgency}/collaborators/${uidUser}`).set({
				...personnalInfos,
				agency: uidAgency,
				date,
				imgUrl: O_Opus,
				uid: uidUser,
				subtype: 'administrator',
				canCreateProject: false,
				canDeleteProject: false,
				canCreateCollaborator: false,
				canDeleteCollaborator: false,
				canCreateClient: false,
				canDeleteClient: false,
				canCreatePartnerAgency: false,
				canDeletePartnerAgency: false,
				canAccesBilling: false,
				language: i18n.language,
				lastConnexion: date,
				active: true,
			});

			await firestore.doc(`agencies/${uidAgency}`).set({ firstAccount: uidUser }, { merge: true });

			await firestore.doc(`messaging/${uidAgency}/participants/${uidUser}`).set({
				id: uidUser,
				uid: uidUser,
			});

			await firestore.doc(`users/${uidUser}/messaging/${uidAgency}`).set({
				id: uidAgency,
				lastClickAt: null,
				lastMessageAt: new Date().toISOString(),
				isDeleted: false,
				isGroup: true,
				isNotificationsOff: false,
				parentId: uidAgency,
				isUnread: false,
				firstName: '',
				lastName: '',
				name: '',
				email: '',
				phone: '',
				userAgencyId: '',
				userType: '',
				isGeneral: true,
			});

			navigate('/projets', { firstTime: true });

			const send = httpsCallable(functions, 'sendEmail');
			await send({
				id: 4,
				email: personnalInfos.email,
				data: {
					receiver: personnalInfos.surname + ' ' + personnalInfos.name,
				},
			});
		});
	}

	function Connexion() {
		navigate('/');
	}

	const [count, setCount] = useState(false);
	const [opacityForm1, setOpacityForm1] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 500);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setOpacityForm1(1);
			}
		}, 0);
	}, [count]);

	// Bar etapes animation

	const [dottedWidth, setDottedWidth] = useState(343);
	const [beigeBarWidth, setBeigeBarWidth] = useState(0);
	const [opacityChoice1, setOpacityChoice1] = useState(0);
	const [opacityChoice2, setOpacityChoice2] = useState(0);

	const [colorEntreprise, setColorEntreprise] = useState('#000000');
	const [colorProfil, setColorProfil] = useState('#9b9b9b');
	const [colorSecurite, setColorSecurite] = useState('#9b9b9b');

	useEffect(() => {
		if (infosEntreprise) {
			setTimeout(() => {
				setBeigeBarWidth(75);
			}, 1000);
		}
	}, [setBeigeBarWidth, infosEntreprise]);

	function ChangePathToProfil() {
		setTimeout(() => {
			setDottedWidth(208);
			setBeigeBarWidth(210);
		}, 500);

		setTimeout(() => {
			setOpacityChoice1(1);
			setColorEntreprise('#4fa139');
			if (colorProfil === '#9b9b9b') {
				setColorProfil('#000000');
			}
		}, 750);
	}

	function ChangePathToSecurite() {
		setTimeout(() => {
			setDottedWidth(73);
			setBeigeBarWidth(345);
		}, 500);

		setTimeout(() => {
			setOpacityChoice2(1);
			setColorEntreprise('#4fa139');
			setColorProfil('#4fa139');
		}, 750);
	}

	const SecteurActivite = (
		<div className={'secteurActivite'}>
			<div style={{ display: 'flex' }}>
				<div className={'leftPart'} style={{ minHeight: 650, width: isClient ? '100vw' : '50vw' }}>
					<div className={'firstPart'}>
						<div>
							<p className={'opus'} style={{ width: 75 }} onClick={() => setIsClient(false)}>
								OPUS
							</p>
						</div>

						<div style={{ marginLeft: 20 }} className={'dot'} />
						<p className={'onBoarding'}>{t('onboarding.onboarding')}</p>
					</div>

					<div className={'secondPart'} style={{ minHeight: 550 }}>
						<div>
							<div style={{ opacity: opacityForm1 }} className={'opacityForm'}>
								<h2
									className={'title'}
									style={{
										marginTop: 0,
										opacity: opacityBienvenue,
										height: isClient ? 0 : null,
										overflow: 'hidden',
									}}>
									{t('onboarding.welcome_coma')}
								</h2>
								<p
									className={'subtitle'}
									style={{
										opacity: opacityBienvenue,
										height: isClient ? 0 : null,
										overflow: 'hidden',
										width: 400,
									}}>
									{t('onboarding.talk_about_you_situation')}
								</p>

								<div style={{ marginTop: 40 }}>
									<div
										className={'cardSecteur'}
										style={{
											opacity: opacityPro,
											marginTop: marginTopPro,
											overflow: 'hidden',
											height: isClient ? 0 : 100,
										}}
										onClick={() => {
											setDottedWidth(343);
											setMarginTopPro(600);
											setOpacityBienvenue(0);
											setOpacityClient(0);
											setOpacityPro(0);
											setOpacitySubtitleBienvenue(0);
											setTimeout(() => {
												setSecteurActivite(false);
												setInfosEntreprise(true);
												setBeigeBarWidth(75);
											}, 800);
										}}>
										<div>
											<p className={'littleTitleSecteur'}>{t('onboarding.you_are')}</p>
											<p className={'titleSecteur'}>{t('onboarding.professional')}</p>
											<p className={'littleTitleSecteur'}>{t('onboarding.architect_etc')}</p>
										</div>

										<img
											src={blueRightIcon}
											alt=""
											className={'blueRightIcon'}
											style={{ marginLeft: 165 }}
										/>
									</div>

									<div
										className={'cardSecteur'}
										style={{
											opacity: opacityClient,
											marginTop: isClient ? -15 : marginTopClient,
											width: isClient ? 1300 : null,
											height: isClient ? 700 : null,
											marginBottom: isClient ? 40 : null,
											marginLeft: isClient ? 20 : null,
											cursor: isClient ? 'inherit' : 'pointer',
											justifyContent: isClient ? 'center' : null,
											alignItems: isClient ? 'flex-start' : 'center',
										}}
										onClick={() => {
											if (!isClient) {
												setOpacityClient(0);
												setOpacityBienvenue(0);
												setOpacityPro(0);
												setTimeout(() => {
													setIsClient(true);
													setTimeout(() => {
														setOpacityClient(1);
													}, 500);
												}, 600);
											}
										}}>
										{isClient && (
											<img
												src={blueLeftIcon}
												alt=""
												className={'blueLeftIcon'}
												onClick={() => {
													setOpacityClient(0);
													setTimeout(() => {
														setTimeout(() => {
															setOpacityClient(1);
															setOpacityBienvenue(1);
															setOpacityPro(1);
														}, 500);
														setIsClient(false);
													}, 500);
												}}
											/>
										)}

										{!isClient && (
											<div>
												<p className={'littleTitleSecteur'}>{t('onboarding.you_are')}</p>
												<p className={'titleSecteur'}>{t('onboarding.client')}</p>
												<p className={'littleTitleSecteur'}>{t('onboarding.of_a_project')}</p>
											</div>
										)}
										{!isClient && (
											<img
												src={blueRightIcon}
												alt=""
												className={'blueRightIcon'}
												style={{ marginLeft: 268 }}
											/>
										)}

										{isClient && (
											<div className={'contentClientCardBig'}>
												<p className={'titleComposant'} onClick={() => setIsClient(false)}>
													{t('onboarding.discover_OPUS')}
												</p>
												<p className={'subttitleComposant'} onClick={() => setIsClient(false)}>
													{t('onboarding.slogan')}
												</p>

												<div style={{ display: 'flex' }}>
													<div className={'composantOnboardinClient'}>
														<p className={'titleOnboardingClient'}>
															{t('onboarding.download')} <br />{' '}
															<span style={{ color: '#4B5FB0' }}>OPUS Mobile.</span>
														</p>
														<img
															src={OnBoardingClient1}
															alt=""
															className={'photoOnbaording'}
														/>
													</div>

													<img src={flecheBleuBig} alt="" className={'flecheBleuBig'} />

													<div className={'composantOnboardinClient'}>
														<p className={'titleOnboardingClient'}>
															{t('onboarding.clic_on')}
															<br />{' '}
															<span style={{ color: '#4B5FB0' }}>
																{t('onboarding.discover_er')}.
															</span>
														</p>
														<img
															src={OnBoardingClient2}
															alt=""
															className={'photoOnbaording'}
														/>
													</div>

													<img src={flecheBleuBig} alt="" className={'flecheBleuBig'} />

													<div className={'composantOnboardinClient'}>
														<p className={'titleOnboardingClient'}>
															{t('onboarding.let_us')} <br />{' '}
															<span style={{ color: '#4B5FB0' }}>
																{t('onboarding.guide')}.
															</span>
														</p>
														<img
															src={OnBoardingClient3}
															alt=""
															className={'photoOnbaording'}
														/>
													</div>

													<img src={flecheBleuBig} alt="" className={'flecheBleuBig'} />

													<div className={'composantOnboardinClient'}>
														<p className={'titleOnboardingClient'}>
															{t('onboarding.discover')} <br />{' '}
															<span style={{ color: '#4B5FB0' }}>OPUS !</span>
														</p>
														<img
															src={OnBoardingClient4}
															alt=""
															className={'photoOnbaording'}
														/>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'thirdPart'}>
						<a href={'https://opusoft.fr'} target="_blank">
							<p className={'textThirdPart'}>©OPUS</p>
						</a>

						<a href={'https://opusoft.fr/contact'} target="_blank">
							<p className={'textThirdPart'}>{t('common.contact')}</p>
						</a>

						<a
							href={
								'https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c'
							}
							target="_blank">
							<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgu')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgv')}</p>
						</a>
					</div>
				</div>

				<div className={'rightPart'} style={{ minHeight: 650, width: isClient ? '0vw' : '50vw' }}>
					<img src={loginBlue} alt="logo" className="loginBlue" style={{ minHeight: 650 }} />
				</div>
			</div>
		</div>
	);

	const InfosEntreprise = (
		<div className={'infosEntreprise'}>
			<div style={{ display: 'flex' }}>
				<div className={'leftPart'} style={{ minHeight: 680 }}>
					<div className={'firstPart'}>
						<p className={'opus'} style={{ width: 75 }} onClick={Connexion}>
							OPUS
						</p>

						<div className={'dot'} />
						<p className={'onBoarding'}>{t('onboarding.onboarding')}</p>
					</div>

					<div className={'secondPart'} style={{ minHeight: 580 }}>
						<div style={{ height: 540 }} className={'transition'}>
							<div className={'pathEtapes'} style={{ marginTop: 0 }}>
								<div style={{ display: 'flex', marginTop: -17 }}>
									<p
										className={'pathPasse'}
										style={{ marginLeft: -14, marginRight: 79 }}
										onClick={() => {
											setSecteurActivite(true);
											setInfosEntreprise(false);
										}}>
										{t('onboarding.sector')}
									</p>
									<p className={'pathEnCours'} style={{ marginRight: 80, color: colorEntreprise }}>
										{t('onboarding.company')}
									</p>
									<p className={'pathAVenir'} style={{ marginRight: 92, color: colorProfil }}>
										{t('settings.profile')}
									</p>
									<p className={'pathAVenir'} style={{ marginRight: 0 }}>
										{t('settings.safety')}
									</p>
								</div>
								<div style={{ display: 'flex' }}>
									<div className={'beigeBar'} style={{ width: beigeBarWidth }} />
									<img src={dotted} alt="" className={'dotted'} style={{ width: dottedWidth }} />
								</div>
								<div style={{ display: 'flex', marginTop: -17 }}>
									<img src={checkBleu} alt="" className={'choicePath'} />
									<div className={'noChoice'}>
										<img
											src={checkBleu}
											alt=""
											className={'choicePath'}
											style={{ opacity: opacityChoice1, marginTop: -1, marginLeft: -1 }}
										/>
									</div>
									<div className={'noChoice'}>
										<img
											src={checkBleu}
											alt=""
											className={'choicePath'}
											style={{ opacity: opacityChoice2, marginTop: -1, marginLeft: -1 }}
										/>
									</div>
									<div className={'noChoice'} style={{ marginRight: 0 }} />
								</div>
							</div>

							<div>
								<h2 className={'title'} style={{ marginTop: 50 }}>
									{t('onboarding.your_company')}
								</h2>
								<p className={'subtitle'}>{t('onboarding.let_us_know')}</p>
								<div style={{ height: 10 }} />
								<form
									onSubmit={(e) => {
										e.preventDefault();
										setInfosEntreprise(false);
										setInfosPersonnelles(true);
										ChangePathToProfil();
									}}>
									<div className={'inputGroup'}>
										<p className={'inputTitle'}>{t('onboarding.company_name')}</p>
										<input
											id={'name'}
											type="text"
											className={'input'}
											style={{ fontWeight: 500, fontSize: 13 }}
											placeholder={'OPUS A&D'}
											value={businessInfos.name}
											onChange={businessInfosChange}
											required
										/>
									</div>

									<div className={'inputGroup'}>
										<p className={'inputTitle'}>{t('onboarding.company_mini_name')}</p>
										<div style={{ display: 'flex' }}>
											<input
												id={'diminutifAgency'}
												type="text"
												className={'input'}
												style={{ fontWeight: 500, fontSize: 13 }}
												placeholder={t('onboarding.company_mini_name_five')}
												value={businessInfos.diminutifAgency}
												maxLength={5}
												onChange={businessInfosChange}
												required
											/>
											<p
												style={{
													marginLeft: 10,
													marginTop: 11,
													marginBottom: 0,
													fontSize: 14,
													fontWeight: '700',
													color:
														businessInfos.diminutifAgency.length === 0
															? 'rgba(0,0,0,0.7)'
															: businessInfos.diminutifAgency.length < 6
																? '#19a81f'
																: '#b41111',
												}}>
												{businessInfos.diminutifAgency.length}
											</p>
										</div>
									</div>

									<button className={'buttonSuivant'}>
										<p className={'titleButtonSuivant'}>{t('common.next')}</p>
									</button>
								</form>
							</div>
						</div>
					</div>

					<div className={'thirdPart'}>
						<a href={'https://opusoft.fr'} target="_blank">
							<p className={'textThirdPart'}>©OPUS</p>
						</a>

						<a href={'https://opusoft.fr/contact'} target="_blank">
							<p className={'textThirdPart'}>{t('common.contact')}</p>
						</a>

						<a
							href={
								'https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c'
							}
							target="_blank">
							<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgu')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgv')}</p>
						</a>
					</div>
				</div>

				<div className={'rightPart'} style={{ minHeight: 680 }}>
					<img src={loginBlue} alt="logo" className="loginBlue" style={{ minHeight: 680 }} />
				</div>
			</div>
		</div>
	);

	const InfosPersonnelles = (
		<div className={'infosPersonnelles'}>
			<div style={{ display: 'flex' }}>
				<div className={'leftPart'} style={{ minHeight: 680 }}>
					<div className={'firstPart'}>
						<p className={'opus'} style={{ width: 75 }} onClick={Connexion}>
							OPUS
						</p>
						<div className={'dot'} />
						<p className={'onBoarding'}>{t('onboarding.onboarding')}</p>
					</div>

					<div className={'secondPart'} style={{ minHeight: 580, marginLeft: 0 }}>
						<div style={{ height: 540 }}>
							<div className={'pathEtapes'} style={{ marginTop: 0 }}>
								<div style={{ display: 'flex', marginTop: -17 }}>
									<p
										className={'pathPasse'}
										style={{ marginLeft: -14, marginRight: 79 }}
										onClick={() => {
											setInfosPersonnelles(false);
											setSecteurActivite(true);
										}}>
										{t('onboarding.sector')}
									</p>
									<p
										className={'pathPasse'}
										style={{ marginRight: 80, color: colorEntreprise }}
										onClick={() => {
											setInfosPersonnelles(false);
											setInfosEntreprise(true);
											setBeigeBarWidth(75);
											setDottedWidth(343);
										}}>
										{t('onboarding.company')}
									</p>
									<p className={'pathAVenir'} style={{ marginRight: 92, color: colorProfil }}>
										{t('settings.profile')}
									</p>
									<p className={'pathAVenir'} style={{ marginRight: 0 }}>
										{t('settings.safety')}
									</p>
								</div>

								<div style={{ display: 'flex' }}>
									<div className={'beigeBar'} style={{ width: beigeBarWidth }} />
									<img src={dotted} alt="" className={'dotted'} style={{ width: dottedWidth }} />
								</div>
								<div style={{ display: 'flex', marginTop: -17 }}>
									<img src={checkBleu} alt="" className={'choicePath'} />
									<div className={'noChoice'}>
										<img
											src={checkBleu}
											alt=""
											className={'choicePath'}
											style={{ opacity: opacityChoice1, marginTop: -1, marginLeft: -1 }}
										/>
									</div>
									<div className={'noChoice'}>
										<img
											src={checkBleu}
											alt=""
											className={'choicePath'}
											style={{ opacity: opacityChoice2, marginTop: -1, marginLeft: -1 }}
										/>
									</div>
									<div className={'noChoice'} style={{ marginRight: 0 }} />
								</div>
							</div>
							<div>
								<h2 className={'title'} style={{ marginTop: 50 }}>
									{t('onboarding_collaborator.your_profile')}
								</h2>
								<p className={'subtitle'}>{t('onboarding_collaborator.let_us_identify_you')}</p>
								<div style={{ height: 10 }} />

								<form
									onSubmit={(e) => {
										e.preventDefault();
										setInfosPersonnelles(false);
										setSecurite(true);
										ChangePathToSecurite();
									}}>
									<div className={'inputGroup'}>
										<p className={'inputTitle'}>{t('common.last_name')}</p>
										<input
											id={'name'}
											type="text"
											className={'input'}
											style={{ fontWeight: 500, fontSize: 13 }}
											placeholder={'Durand'}
											value={personnalInfos.name}
											onChange={personnalInfosChange}
											required
										/>
									</div>

									<div className={'inputGroup'}>
										<p className={'inputTitle'}>{t('common.first_name')}</p>
										<input
											id={'surname'}
											type="text"
											className={'input'}
											style={{ fontWeight: 500, fontSize: 13 }}
											placeholder={'Louis'}
											value={personnalInfos.surname}
											onChange={personnalInfosChange}
											required
										/>
									</div>

									<div className={'inputGroup'}>
										<p className={'inputTitle'}>{t('onboarding.pro_email_address')}</p>
										<input
											id={'email'}
											type="email"
											className={'input'}
											style={{ fontWeight: 500, fontSize: 13 }}
											placeholder={'louis.durand@opusoft.fr'}
											value={personnalInfos.email}
											onChange={personnalInfosChange}
											required
										/>
									</div>

									<button className={'buttonSuivant'}>
										<p className={'titleButtonSuivant'}>{t('common.next')}</p>
									</button>
								</form>
							</div>
						</div>
					</div>

					<div className={'thirdPart'}>
						<a href={'https://opusoft.fr'} target="_blank">
							<p className={'textThirdPart'}>©OPUS</p>
						</a>

						<a href={'https://opusoft.fr/contact'} target="_blank">
							<p className={'textThirdPart'}>{t('common.contact')}</p>
						</a>

						<a
							href={
								'https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c'
							}
							target="_blank">
							<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgu')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgv')}</p>
						</a>
					</div>
				</div>

				<div className={'rightPart'} style={{ minHeight: 680 }}>
					<img src={loginBlue} alt="logo" className="loginBlue" style={{ minHeight: 680 }} />
				</div>
			</div>
		</div>
	);

	const Securite = (
		<div className={'infosPersonnelles'}>
			<div style={{ display: 'flex' }}>
				<div className={'leftPart'} style={{ minHeight: 680 }}>
					<div className={'firstPart'}>
						<p className={'opus'} style={{ width: 75 }} onClick={Connexion}>
							OPUS
						</p>
						<div className={'dot'} />
						<p className={'onBoarding'}>{t('onboarding.onboarding')}</p>
					</div>

					<div className={'secondPart'} style={{ minHeight: 580, marginLeft: 0 }}>
						<div style={{ height: 540 }}>
							<div className={'pathEtapes'} style={{ marginTop: 0 }}>
								<div style={{ display: 'flex', marginTop: -17 }}>
									<p
										className={'pathPasse'}
										style={{ marginLeft: -14, marginRight: 79 }}
										onClick={() => {
											setSecurite(false);
											setSecteurActivite(true);
										}}>
										{t('onboarding.sector')}
									</p>
									<p
										className={'pathPasse'}
										style={{ marginRight: 80, color: colorEntreprise }}
										onClick={() => {
											setSecurite(false);
											setInfosEntreprise(true);
											setBeigeBarWidth(75);
											setDottedWidth(343);
										}}>
										{t('onboarding.company')}
									</p>
									<p
										className={'pathPasse'}
										style={{ marginRight: 92, color: colorProfil }}
										onClick={() => {
											setSecurite(false);
											setInfosPersonnelles(true);
											setDottedWidth(208);
											setBeigeBarWidth(210);
										}}>
										{t('settings.profile')}
									</p>
									<p className={'pathAVenir'} style={{ marginRight: 0 }}>
										{t('settings.safety')}
									</p>
								</div>

								<div style={{ display: 'flex' }}>
									<div className={'beigeBar'} style={{ width: beigeBarWidth }} />
									<img src={dotted} alt="" className={'dotted'} style={{ width: dottedWidth }} />
								</div>
								<div style={{ display: 'flex', marginTop: -17 }}>
									<img src={checkBleu} alt="" className={'choicePath'} />
									<div className={'noChoice'}>
										<img
											src={checkBleu}
											alt=""
											className={'choicePath'}
											style={{ opacity: opacityChoice1, marginTop: -1, marginLeft: -1 }}
										/>
									</div>
									<div className={'noChoice'}>
										<img
											src={checkBleu}
											alt=""
											className={'choicePath'}
											style={{ opacity: opacityChoice2, marginTop: -1, marginLeft: -1 }}
										/>
									</div>
									<div className={'noChoice'} style={{ marginRight: 0 }} />
								</div>
							</div>

							<div>
								<h2 className={'title'} style={{ marginTop: 50 }}>
									{t('onboarding_collaborator.profile_safety')}
								</h2>
								<p className={'subtitle'}>{t('onboarding_collaborator.choose_a_password')}</p>
								<div style={{ height: 10 }} />

								<form
									onSubmit={async (e) => {
										e.preventDefault();
										if (password === confirmPassword) {
											setSecurite(false);
											setDottedWidth(0);
											setBeigeBarWidth(418);
											setColorSecurite('#4fa139');
										} else {
											setErrorConfirmPassword(true);
										}
									}}>
									<div>
										<div className={'inputGroup'}>
											<p className={'inputTitle'}>{t('common.password')}</p>
											<input
												type="password"
												className={'input'}
												style={{ fontWeight: 500, fontSize: 13 }}
												placeholder={t('common.password')}
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												required
											/>
										</div>

										<div className={'requires'}>
											<div className={password.length > 7 ? 'requireValid' : 'require'}>
												<p className={'textRequire'}>{t('common.huit_characters')}</p>
											</div>
											<div className={withMajuscule(password) ? 'requireValid' : 'require'}>
												<p className={'textRequire'}>{t('common.uppercase')}</p>
											</div>
											<div className={withMinuscule(password) ? 'requireValid' : 'require'}>
												<p className={'textRequire'}>{t('common.lowercase')}</p>
											</div>
											<div
												className={withNumber(password) ? 'requireValid' : 'require'}
												style={{ marginRight: 0 }}>
												<p className={'textRequire'}>{t('common.number')}</p>
											</div>
											<div className={withSymbol(password) ? 'requireValid' : 'require'}>
												<p className={'textRequire'}>{t('common.special_character')}</p>
											</div>
										</div>

										<div className={'inputGroup'}>
											<p className={'inputTitle'}>{t('settings_safety.confirm_password')}</p>
											<input
												type="password"
												className={'input'}
												style={{ fontWeight: 500, fontSize: 13 }}
												placeholder={t('settings_safety.confirm_password')}
												value={confirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
												required
											/>
										</div>
									</div>

									<p
										className={'mdpIdentiques'}
										style={{
											fontSize: 13,
											fontWeight: 500,
											color: '#ea2121',
											marginTop: 11,
											marginBottom: -26,
											marginLeft: 10,
											opacity:
												confirmPassword.length > 1 ? (password === confirmPassword ? 0 : 1) : 0,
										}}>
										{t('settings_safety.passwords_must_be_the_same')}
									</p>

									<button
										className={'buttonSuivant'}
										disabled={!passwordIsStrong(password) || password !== confirmPassword}>
										<p className={'titleButtonSuivant'}>{t('common.next')}</p>
									</button>
								</form>
							</div>
						</div>
					</div>

					<div className={'thirdPart'}>
						<a href={'https://opusoft.fr'} target="_blank">
							<p className={'textThirdPart'}>©OPUS</p>
						</a>

						<a href={'https://opusoft.fr/contact'} target="_blank">
							<p className={'textThirdPart'}>{t('common.contact')}</p>
						</a>

						<a
							href={
								'https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c'
							}
							target="_blank">
							<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgu')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgv')}</p>
						</a>
					</div>
				</div>

				<div className={'rightPart'} style={{ minHeight: 680 }}>
					<img src={loginBlue} alt="logo" className="loginBlue" style={{ minHeight: 680 }} />
				</div>
			</div>
		</div>
	);

	return (
		<div className={'OnBoarding'}>
			{secteurActivite ? (
				SecteurActivite
			) : infosEntreprise ? (
				InfosEntreprise
			) : infosPersonnelles ? (
				InfosPersonnelles
			) : securite ? (
				Securite
			) : (
				<div className={'infosPersonnelles'}>
					<div style={{ display: 'flex' }}>
						<div className={'leftPart'} style={{ minHeight: 750 }}>
							<div className={'firstPart'}>
								<p className={'opus'} onClick={Connexion}>
									OPUS
								</p>
								<div className={'dot'} />
								<p className={'onBoarding'}>{t('onboarding.onboarding')}</p>
							</div>

							<div className={'secondPart'} style={{ marginLeft: -10, minHeight: 650 }}>
								<div style={{ height: 300 }}>
									<div className={'pathEtapes'} style={{ marginTop: 0 }}>
										<div style={{ display: 'flex', marginTop: -17 }}>
											<p
												className={'pathPasse'}
												style={{ marginLeft: -14, marginRight: 79 }}
												onClick={() => {
													setSecurite(false);
													setSecteurActivite(true);
												}}>
												{t('onboarding.sector')}
											</p>
											<p
												className={'pathPasse'}
												style={{ marginRight: 80, color: colorEntreprise }}
												onClick={() => {
													setSecurite(false);
													setInfosEntreprise(true);
													setBeigeBarWidth(75);
													setDottedWidth(343);
												}}>
												{t('onboarding.company')}
											</p>
											<p
												className={'pathPasse'}
												style={{ marginRight: 92, color: colorProfil }}
												onClick={() => {
													setSecurite(false);
													setInfosPersonnelles(true);
													setDottedWidth(208);
													setBeigeBarWidth(210);
												}}>
												{t('settings.profile')}
											</p>
											<p
												className={'pathPasse'}
												style={{ marginRight: 0, color: colorSecurite }}
												onClick={() => {
													setSecurite(true);
													setDottedWidth(73);
													setBeigeBarWidth(345);
												}}>
												{t('settings.safety')}
											</p>
										</div>

										<div style={{ display: 'flex' }}>
											<div className={'beigeBar'} style={{ width: beigeBarWidth }} />
											<img
												src={dotted}
												alt=""
												className={'dotted'}
												style={{ width: dottedWidth }}
											/>
										</div>
										<div style={{ display: 'flex', marginTop: -17 }}>
											<img src={checkBleu} alt="" className={'choicePath'} />
											<div className={'noChoice'}>
												<img
													src={checkBleu}
													alt=""
													className={'choicePath'}
													style={{ opacity: opacityChoice1, marginTop: -1, marginLeft: -1 }}
												/>
											</div>
											<div className={'noChoice'}>
												<img
													src={checkBleu}
													alt=""
													className={'choicePath'}
													style={{ opacity: opacityChoice2, marginTop: -1, marginLeft: -1 }}
												/>
											</div>
											<div className={'noChoice'} style={{ marginRight: 0 }}>
												<img
													src={checkBleu}
													alt=""
													className={'choicePath'}
													style={{ opacity: opacityChoice2, marginTop: -1, marginLeft: -1 }}
												/>
											</div>
										</div>
									</div>
								</div>

								<div>
									<h2 className={'title'} style={{ marginTop: -220 }}>
										{t('common.welcome')} {personnalInfos.surname} {personnalInfos.name},
									</h2>
									<p className={'subtitle'} style={{ marginBottom: 40 }}>
										{t('onboarding.account_created_with_success')}
									</p>

									<p
										className={'subtitle'}
										style={{ marginBottom: 40, color: '#525252', fontWeight: 500, fontSize: 15 }}>
										{t('modal_bienvenue.subtitle_1')}
									</p>

									<div>
										<video
											autoPlay
											loop
											muted
											style={{
												marginTop: -55,
												marginLeft: 25,
												width: 400,
												height: 320,
												borderRadius: 20,
											}}>
											<source src={VideoProjet} type={'video/mp4'} />
										</video>
									</div>

									<button onClick={createAccounts} className={'boutonEntrerOPUS'} disabled={loading}>
										<p className={'textBoutonEntrerOPUS'}>
											{loading
												? t('onboarding_collaborator.prepare_your_opus')
												: t('onboarding_collaborator.enter_opus')}
										</p>
									</button>
									<p
										style={{
											fontSize: 14,
											width: 420,
										}}>
										{t('onboarding.by_continuing_you_accept')}{' '}
										<a
											href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
											target={'_blank'}>
											<span style={{ color: '#4867EC' }} className={'hover'}>
												{' '}
												{t('onboarding.general_conditions')}
											</span>
										</a>{' '}
									</p>
								</div>
							</div>

							<div className={'thirdPart'}>
								<a href={'https://opusoft.fr'} target="_blank">
									<p className={'textThirdPart'}>©OPUS</p>
								</a>

								<a href={'https://opusoft.fr/contact'} target="_blank">
									<p className={'textThirdPart'}>{t('common.contact')}</p>
								</a>

								<a
									href={
										'https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c'
									}
									target="_blank">
									<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
								</a>

								<a
									href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
									target={'_blank'}>
									<p className={'textThirdPart'}>{t('legals.cgu')}</p>
								</a>

								<a
									href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
									target={'_blank'}>
									<p className={'textThirdPart'}>{t('legals.cgv')}</p>
								</a>
							</div>
						</div>

						<div className={'rightPart'} style={{ minHeight: 750 }}>
							<img src={loginBlue} alt="logo" className="loginBlue" style={{ minHeight: 750 }} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
