import React from 'react';
import plusGreen from '../../../assets/plusGreen.svg';
import questionCircle from '../../../assets/questionCircle.svg';
import { useTranslation } from 'react-i18next';

export default function AddClientsComponent({ setModalNewClient }) {
	const { t } = useTranslation();

	return (
		<div className={'addEntreprisesComponent'}>
			<p className={'firstLine'}>
				{t('translation.invite')} <span style={{ color: '#1C57DD' }}>{t('common.clients').toLowerCase()}</span>{' '}
				{t('translation.intoTheProject')}{' '}
				<span style={{ color: '#1C57DD' }}>{t('translation.freeAccess')}</span>
			</p>

			<p className={'secondLine'}>{t('translation.addYourFirstClients')}</p>

			<div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
				<div className={'avatarOpus'} style={{ backgroundColor: '#83d98a' }}>
					O
				</div>
				<div className={'avatarOpus'} style={{ backgroundColor: '#83d98a' }}>
					P
				</div>
				<div className={'avatarOpus'} style={{ backgroundColor: '#83d98a' }}>
					U
				</div>
				<div className={'avatarOpus'} style={{ backgroundColor: '#83d98a' }}>
					S
				</div>
			</div>

			<div
				className={'containerButtonAddCompany hover'}
				style={{ marginBottom: -10 }}
				onClick={() => setModalNewClient(true)}>
				<img src={plusGreen} alt="" className={'icon'} />
				<p>{t('translation.addYourClients')}</p>
			</div>
		</div>
	);
}
