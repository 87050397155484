import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronRight, Plus } from 'lucide-react';
import filledCheck from '../../../../../../assets/filledCheck.svg';
import emptyCheck from '../../../../../../assets/emptyCheck.svg';
import trashIcon from '../../../../../../assets/trashIcon.svg';
import SubTodoItem from './SubTodoItem';

const TodoItem = ({
	todo,
	onToggle,
	onDelete,
	onAddSubTodo,
	onToggleSubTodo,
	onDeleteSubTodo,
	editTodoText,
	updateTodos,
	canModifyTicket,
}) => {
	const { t } = useTranslation();

	const todoInputRef = useRef(null);
	const newSubTodoInputRef = useRef(null);

	const [newSubTodo, setNewSubTodo] = useState('');
	const [isExpanded, setIsExpanded] = useState(!todo.completed);
	const [idSubTodoFocused, setIdSubTodoFocused] = useState();

	useEffect(() => {
		setIsExpanded(!todo.completed);
	}, [todo.completed]);

	const handleKeyDown = (event) => {
		if (event.key === 'Backspace' && todo.text === '') {
			event.preventDefault();
			onDelete(todo.id);
		} else if (event.key === 'Enter' || event.key === 'NumpadEnter') {
			setIdSubTodoFocused(todo.subTodos.length);
		}
	};

	const handleSubmitNewSubTodo = (e) => {
		e.preventDefault();
		if (newSubTodo.trim()) {
			onAddSubTodo(todo.id, newSubTodo.trim());
			setNewSubTodo('');
			if (idSubTodoFocused === -1) {
				setIdSubTodoFocused(1);
			} else {
				setIdSubTodoFocused(idSubTodoFocused + 1);
			}
		}
	};

	const handleKeyDownNewTodo = (event) => {
		if (event.key === 'Backspace' && newSubTodo === '') {
			event.preventDefault();
			setIdSubTodoFocused(todo.subTodos.length - 1);
		} else if ((event.key === 'Enter' || event.key === 'NumpadEnter') && !event.shiftKey) {
			event.preventDefault();
			handleSubmitNewSubTodo(event);
		}
	};

	useEffect(() => {
		if (idSubTodoFocused === todo.subTodos.length && newSubTodoInputRef.current) {
			newSubTodoInputRef.current.focus();
		}
	}, [idSubTodoFocused, newSubTodo]);

	useEffect(() => {
		if (idSubTodoFocused !== todo.subTodos.length && newSubTodo !== '') {
			onAddSubTodo(todo.id, newSubTodo.trim());
			setNewSubTodo('');
		}
	}, [idSubTodoFocused]);

	// ==================================== Resize todo ====================================

	useEffect(() => {
		const adjustHeight = () => {
			const textarea = todoInputRef.current;
			if (textarea) {
				textarea.style.height = '17px';
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		};

		adjustHeight();
		window.addEventListener('resize', adjustHeight);

		return () => window.removeEventListener('resize', adjustHeight);
	}, [todo.text]);

	// ==================================== Resize new subtodo ====================================

	useEffect(() => {
		const adjustHeight = () => {
			const textarea = newSubTodoInputRef.current;
			if (textarea) {
				textarea.style.height = '17px';
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		};

		adjustHeight();
		window.addEventListener('resize', adjustHeight);

		return () => window.removeEventListener('resize', adjustHeight);
	}, [newSubTodo]);

	// ==================================== Render ====================================

	return (
		<div className={'itemSubStep'}>
			<div style={{ display: 'flex', alignItems: 'flex-start' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<button onClick={() => setIsExpanded(!isExpanded)} className={'chevron'}>
						{isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} style={{ opacity: 0.6 }} />}
					</button>

					<img
						src={todo.completed ? filledCheck : emptyCheck}
						alt=""
						className={'checkBox'}
						onClick={() => onToggle(todo.id)}
					/>
				</div>

				<textarea
					style={{ marginTop: 1.5 }}
					ref={todoInputRef}
					className={'subTodoText'}
					value={todo.text}
					onKeyDown={handleKeyDown}
					onChange={(e) => {
						const newText = e.target.value;
						editTodoText(todo.id, newText, false, todo.id);
						e.target.style.height = 'auto';
						e.target.style.height = `${e.target.scrollHeight}px`;
					}}
					onBlur={(e) => {
						if (e.target.value !== '') {
							updateTodos();
						}
					}}
				/>
			</div>
			{isExpanded && (
				<div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginTop: 5 }}>
					{todo.subTodos.map((subTodo, index) => (
						<SubTodoItem
							key={index}
							index={index}
							todo={todo}
							editTodoText={editTodoText}
							onDeleteSubTodo={onDeleteSubTodo}
							onToggleSubTodo={onToggleSubTodo}
							subTodo={subTodo}
							setIdSubTodoFocused={setIdSubTodoFocused}
							idSubTodoFocused={idSubTodoFocused}
							updateTodos={updateTodos}
						/>
					))}

					<form
						style={{ display: 'flex', alignItems: 'center', marginLeft: 45, marginTop: 5 }}
						onSubmit={handleSubmitNewSubTodo}>
						{newSubTodo.length > 0 && (
							<img
								src={emptyCheck}
								alt=""
								className={'checkBox'}
								style={{ alignSelf: 'flex-start' }}
							/>
						)}

						<textarea
							style={{ marginTop: -2.5 }}
							ref={newSubTodoInputRef}
							value={newSubTodo}
							onChange={(e) => setNewSubTodo(e.target.value)}
							placeholder={t('translation.addASubTodo')}
							className={'addANewSubTodo'}
							onKeyDown={handleKeyDownNewTodo}
							onBlur={() => {
								if (newSubTodo.trim()) {
									onAddSubTodo(todo.id, newSubTodo.trim());
									setNewSubTodo('');
									setIdSubTodoFocused(null);
								}
							}}
							onClick={() => setIdSubTodoFocused(todo.subTodos.length)}
						/>
						{newSubTodo.length > 0 && (
							<button style={{ display: 'none' }} className={'addButton'}>
								<Plus size={16} />
							</button>
						)}
					</form>
				</div>
			)}
		</div>
	);
};

export default TodoItem;
