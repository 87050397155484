import React, { useContext, useEffect, useState } from 'react';
import './_choosePlanPage.scss';
import Menu from '../../../../components/Main/Menu';
import { auth, firestore } from '../../../../firebase/config';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../../../contexts/agencyContext';
import { onAuthStateChanged } from 'firebase/auth';
import HeaderChoosePlanPage from './components/HeaderChoosePlanPage';
import louis from './../../../../assets/louis.png';
import jules from './../../../../assets/jules.png';
import antoine from './../../../../assets/antoine.png';
import louka from './../../../../assets/louka.png';
import PlanChoices from './components/PlanChoices';
import TableauFunctionalities from './components/TableauFunctionalities';
import AgencyDataContext from '../../../../contexts/agencyDataContext';
import { useStripeContext } from '../../../../contexts/stripeContext';

export default function ChoosePlan() {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const stripe = useStripeContext();

	const [uid, setUid] = useState(null);
	const [userSession, setUserSession] = useState(null);

	const isCustom = !!agencyData?.billingPeriod;

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUid(user.uid);
			}
		});
		return () => {
			listener();
		};
	}, [userSession]);

	// Menu

	const [lastProject, setLastProject] = useState();

	useEffect(() => {
		const subscriber = firestore
			.collection(`agencies/${agency}/collaborators/${uid}/projects`)
			.orderBy('lastClick', 'desc')
			.limit(1)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							setLastProject(documentSnapshot.data().id);
						}
					});
				}
			});
		return () => subscriber();
	}, [uid, agency]);

	return (
		<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
			<div className="leftPart">
				<Menu margin={true} grasComptes={true} lastProject={lastProject} backToOrganisation={true} />
			</div>

			<div className={'choosePlanPage'}>
				<HeaderChoosePlanPage />
				<div className={'containerPlans'} style={{ justifyContent: 'center' }}>
					{isCustom && stripe.isFreeTrial && (
						<div className={'containerSales'}>
							<img
								src={
									agencyData.referentOpus === 'Jules'
										? jules
										: agencyData.referentOpus === 'Antoine'
											? antoine
											: agencyData.referentOpus === 'Louka'
												? louka
												: agencyData.referentOpus === 'Eloi'
													? ''
													: louis
								}
								alt=""
								className={'profilePicture'}
							/>
							<div>
								<p className={'imagineBy'}>{t('translation.proposedBy')}</p>
								<p className={'salesName'}>
									{agencyData.referentOpus === 'Louis'
										? 'Louis Richard'
										: agencyData.referentOpus === 'Jules'
											? 'Jules Andrieux'
											: agencyData.referentOpus === 'Antoine'
												? 'Antoine Pourcelot'
												: agencyData.referentOpus === 'Louka'
													? 'Louka Perchais'
													: agencyData.referentOpus === 'Eloi'
														? 'Eloi Lavalette'
														: ''}
								</p>
								<p className={'salesTitle'}>Customer Happiness Team</p>
							</div>
						</div>
					)}
					<PlanChoices isCustom={isCustom} />
				</div>

				<TableauFunctionalities />
			</div>
		</div>
	);
}
