import React, { useEffect, useState } from 'react';
import { adjustText, transformString } from '../../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../../firebase/config';

export default function ItemListCollabPartner({ uid, type, agency, research, setResearchNumber }) {
	const [data, setData] = useState();
	const [oldResearch, setOldResearch] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (type === 'collaborators') {
			firestore
				.doc(`agencies/${agency}/collaborators/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		} else {
			firestore
				.doc(`clients/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		}
	}, [uid, type, agency]);

	useEffect(() => {
		if (data) {
			if (transformString(data.name + data.surname).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(data.name + data.surname).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, data]);

	return (
		<>
			{transformString(data?.surname + data?.name + data?.email).includes(transformString(research)) && (
				<div className={'itemListUser'}>
					<div className={'leftPartItemUser'}>
						<div className={'profilePictureBleu'}>
							<p
								style={{
									letterSpacing: 1,
									fontSize: 15,
									margin: 0,
								}}>
								{data?.surname?.charAt(0)?.toUpperCase()}
								{data?.name?.charAt(0)?.toUpperCase()}
							</p>
						</div>

						<div className={'nameEmail'}>
							{data && <p className={'name'}>{adjustText(`${data?.surname} ${data?.name}`, 26)}</p>}
							{data && <p className={'email'}>{adjustText(data?.email, 32)}</p>}
						</div>
					</div>

					<div className={'rightPartItemUser'}>
						<div className={'phoneNumber'}>
							{data?.phone ? data?.phone : t('translation.noPhoneNumber')}
						</div>
						<div className={'status hover'}>
							{data?.subtype === 'administrator'
								? t('translation.admin')
								: data?.subtype === 'collaborator'
								  ? t('translation.collab')
								  : t('translation.personnalise')}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
