import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import arrowLeft from '../assets/arrowLeft.svg';
import leftIcon from '../assets/leftIcon.png';
import zoomOutIcon from '../assets/zoomOut.svg';
import zoomOriginal from '../assets/zoomOriginal.svg';
import zoomInIcon from '../assets/zoomIn.svg';

export default function ModalPicture({ isOpen, setIsOpen, uri, type, handleNext, handlePrevious, noPrevious, noNext }) {
	const { t } = useTranslation();

	const transformWrapperRef = useRef(null);

	const [zoomState, setZoomState] = useState(0);

	const zoomIn = () => {
		if (transformWrapperRef.current) {
			transformWrapperRef.current.zoomIn();
			setZoomState(zoomState + 1);
		}
	};

	const zoomOut = () => {
		if (transformWrapperRef.current) {
			transformWrapperRef.current.zoomOut();
			if (zoomState > 0) {
				setZoomState(zoomState - 1);
			}
		}
	};

	const resetTransform = () => {
		if (transformWrapperRef.current) {
			transformWrapperRef.current.resetTransform();
			if (zoomState > 0) {
				setZoomState(0);
			}
		}
	};

	useEffect(() => {
		if (isOpen) {
			const handleKeyPress = (event) => {
				switch (event.key) {
					case 'ArrowRight':
						nextFile();
						break;
					case 'ArrowLeft':
						previousFile();
						break;
					default:
						break;
				}
			};
			document.addEventListener('keydown', handleKeyPress);
			return () => {
				document.removeEventListener('keydown', handleKeyPress);
			};
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} className={'modalPhoto'} overlayClassName="overlayModalPhoto" closeTimeoutMS={300}>
			<div style={{ display: 'flex' }}>
				<div style={{ width: '100vw' }} className={'transition'}>
					{type === 'video' ? (
						<div
							className={'transition'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: 100,
								position: 'absolute',
								height: '100vh',
								width: '100vw',
							}}>
							<ReactPlayer
								className="react-player"
								url={uri}
								controls
								width="100%"
								height={'calc(100vh - 200px)'}
							/>
						</div>
					) : (
						<TransformWrapper ref={transformWrapperRef}>
							<TransformComponent>
								<div
									className={'transition'}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '100vh',
										marginTop: 0,
										width: '100vw',
									}}>
									<img
										src={uri}
										className={'photoOuvert transition'}
										style={{
											cursor: 'pointer',
											maxWidth: 'calc(100vw - 200px)',
										}}
										alt={''}
									/>
								</div>
							</TransformComponent>
						</TransformWrapper>
					)}
				</div>
			</div>

			<div className={'containerBackMenu'} style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
				<div className={'goBackButton'} onClick={() => setIsOpen(false)}>
					<img src={arrowLeft} alt="" className={'icon'} />
				</div>

				<div
					onClick={() => {
						window.open(uri, '_blank');
					}}
					className={'buttonMenuBigPhoto'}>
					{t('photos.download_image')}
				</div>
			</div>

			<div>
				{type === 'video' && <div style={{ height: 'calc(100vh - 90px)' }} />}

				<div
					className={'containerZoomNav transition'}
					style={{
						position: type === 'video' && 'relative',
						width: '100vw',
					}}>
					<div className={'previousNext'} style={{ opacity: noPrevious ? 0.5 : 1 }} onClick={handlePrevious}>
						<img src={leftIcon} alt="" className={'leftIcon'} />
					</div>

					{type !== 'video' && (
						<div className={'zoomInOut'}>
							<div className={'buttonZoom'} onClick={zoomOut}>
								<img src={zoomOutIcon} alt="" className={'zoomOutIcon'} />
							</div>
							<div className={'buttonZoom'} onClick={resetTransform}>
								<img src={zoomOriginal} alt="" className={'zoomOutIcon'} />
							</div>
							<div className={'buttonZoom'} onClick={zoomIn}>
								<img src={zoomInIcon} alt="" className={'zoomOutIcon'} />
							</div>
						</div>
					)}

					<div className={'previousNext'} style={{ opacity: noNext ? 0.5 : 1 }} onClick={handleNext}>
						<img src={leftIcon} alt="" className={'rightIcon'} />
					</div>
				</div>
			</div>
		</Modal>
	);
}
