import React from 'react';
import chevronDown from '../../../../assets/chevronDown.svg';
import chevronUp from '../../../../assets/chevronUp.svg';
import { firestore } from '../../../../firebase/config';
import { useProjectContext } from '../../../../contexts/projectContext';
import './SubStep.scss';
import { nextStatus, ProgressionStatus } from '../utils';
import Colors from '../../../../useful/Colors';
import deleteIcon from '../../../../assets/deleteIcon.png';
import { wait } from '../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import checkBlanc from '../../../../assets/checkBlanc.png';
import mixpanel from 'mixpanel-browser';

export default function SubStep({ subStepData, setPhases, isEdition, canModify }) {
	const { t } = useTranslation();

	const [project] = useProjectContext();
	const projectId = project?.id;

	const toggleStatus = async () => {
		const newStatus = nextStatus(subStepData.status);
		const batch = firestore.batch();

		const updates = [];

		setPhases((prevState) => {
			const nextState = [...prevState];

			const phase = nextState.find((phase) => phase.id === subStepData.phaseId);
			const step = phase.stepList?.find((step) => step.id === subStepData.stepId);
			const subStep = step.subStepList?.find((subStep) => subStep.id === subStepData.id);

			subStep.status = newStatus;
			updates.push({
				docRef: firestore.doc(`projects/${projectId}/progression/${subStep.id}`),
				status: newStatus,
			});

			switch (newStatus) {
				case ProgressionStatus.COMPLETED: {
					if (step.subStepList?.every((subStep) => subStep.status === ProgressionStatus.COMPLETED)) {
						step.status = ProgressionStatus.COMPLETED;
						updates.push({
							docRef: firestore.doc(`projects/${projectId}/progression/${step.id}`),
							status: ProgressionStatus.COMPLETED,
						});

						if (phase.stepList?.every((step) => step.status === ProgressionStatus.COMPLETED)) {
							phase.status = ProgressionStatus.COMPLETED;
							updates.push({
								docRef: firestore.doc(`projects/${projectId}/progression/${phase.id}`),
								status: ProgressionStatus.COMPLETED,
							});
						}
					}
					break;
				}
				default: {
					step.status = ProgressionStatus.IN_PROGRESS;
					phase.status = ProgressionStatus.IN_PROGRESS;
					updates.push({
						docRef: firestore.doc(`projects/${projectId}/progression/${step.id}`),
						status: ProgressionStatus.IN_PROGRESS,
					});
					updates.push({
						docRef: firestore.doc(`projects/${projectId}/progression/${phase.id}`),
						status: ProgressionStatus.IN_PROGRESS,
					});
					break;
				}
			}

			switch (newStatus) {
				case ProgressionStatus.COMPLETED: {
					mixpanel.track('Sub Step Completed', {
						'Project ID': projectId,
						Page: 'progression',
					});
					break;
				}
				case ProgressionStatus.IN_PROGRESS: {
					mixpanel.track('Sub Step In Progress', {
						'Project ID': projectId,
						Page: 'progression',
					});
					break;
				}
				case ProgressionStatus.NOT_STARTED: {
					mixpanel.track('Sub Step Not Started', {
						'Project ID': projectId,
						Page: 'progression',
					});
					break;
				}
			}

			return nextState;
		});

		await wait(100);

		updates.forEach(({ docRef, status }) => {
			batch.update(docRef, { status });
		});

		await batch.commit();
	};

	const changeName = (value) => {
		setPhases((prevState) => {
			const nextState = [...prevState];

			const phase = nextState.find((phase) => phase.id === subStepData.phaseId);
			const step = phase.stepList?.find((step) => step.id === subStepData.stepId);
			const subStep = step.subStepList?.find((subStep) => subStep.id === subStepData.id);

			subStep.name = value;

			return nextState;
		});
	};

	const endChangeName = async () => {
		await firestore.doc(`projects/${projectId}/progression/${subStepData.id}`).update({
			name: subStepData.name,
		});
	};

	const reorganizeSubStep = async (direction) => {
		const batch = firestore.batch();

		setPhases((prevState) => {
			const nextState = [...prevState];
			const phase = nextState.find((phase) => phase.id === subStepData.phaseId);
			const step = phase.stepList?.find((step) => step.id === subStepData.stepId);
			const subStep = step.subStepList?.find((subStep) => subStep.id === subStepData.id);
			const index = step.subStepList?.findIndex((item) => item.id === subStepData.id);

			if (direction === 'up' && index > 0) {
				const temp = step.subStepList[index - 1];
				step.subStepList[index - 1] = subStep;
				step.subStepList[index] = temp;
			} else if (direction === 'down' && index < step.subStepList.length - 1) {
				const temp = step.subStepList[index + 1];
				step.subStepList[index + 1] = subStep;
				step.subStepList[index] = temp;
			} else {
				return nextState;
			}

			step.subStepList.forEach((subStep, index) => {
				batch.update(firestore.doc(`projects/${projectId}/progression/${subStep.id}`), {
					date: new Date(new Date().getTime() + index).toISOString(),
				});
			});

			return nextState;
		});

		await wait(500);

		await batch.commit();
	};

	const deleteSubStep = async () => {
		const batch = firestore.batch();

		setPhases((prevState) => {
			const nextState = [...prevState];
			const phase = nextState.find((phase) => phase.id === subStepData.phaseId);
			const step = phase.stepList?.find((step) => step.id === subStepData.stepId);
			const subStep = step.subStepList?.find((subStep) => subStep.id === subStepData.id);
			const index = step.subStepList?.indexOf(subStep);

			step.subStepList?.splice(index, 1);
			batch.delete(firestore.doc(`projects/${projectId}/progression/${subStepData.id}`));

			if (
				step.subStepList?.length > 0 &&
				step.subStepList.every((subStep) => subStep.status === ProgressionStatus.COMPLETED)
			) {
				step.status = ProgressionStatus.COMPLETED;
				batch.update(firestore.doc(`projects/${projectId}/progression/${step.id}`), {
					status: ProgressionStatus.COMPLETED,
				});

				if (
					phase.stepList?.length > 0 &&
					phase.stepList.every((step) => step.status === ProgressionStatus.COMPLETED)
				) {
					phase.status = ProgressionStatus.COMPLETED;
					batch.update(firestore.doc(`projects/${projectId}/progression/${phase.id}`), {
						status: ProgressionStatus.COMPLETED,
					});
				}
			}

			return nextState;
		});

		await wait(500);

		await batch.commit();
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.target.blur();
		}
	};

	return (
		<div className={'subStep'}>
			<div
				className={`subStepCircleNameContainer ${canModify && !isEdition ? 'hover' : ''}`}
				onClick={canModify && !isEdition ? toggleStatus : null}>
				<div
					className={'subStepCircle'}
					style={{
						backgroundColor:
							subStepData.status === ProgressionStatus.COMPLETED
								? Colors.PROGRESSION_GREEN
								: subStepData.status === ProgressionStatus.IN_PROGRESS
									? Colors.PROGRESSION_BLUE
									: Colors.PROGRESSION_VERY_LIGHT_BLUE,
					}}>
					{subStepData.status === ProgressionStatus.COMPLETED && <img src={checkBlanc} alt="" />}
				</div>

				{isEdition ? (
					<input
						type={'text'}
						className={'subStepName progressionInput'}
						placeholder={t('progression_page.sub_step_name')}
						value={subStepData.name}
						onChange={(e) => changeName(e.target.value)}
						onBlur={endChangeName}
						onKeyDown={handleKeyDown}
					/>
				) : (
					<p className={'subStepName'}>{subStepData.name}</p>
				)}
			</div>

			{canModify && !isEdition && (
				<>
					<img
						src={chevronDown}
						alt=""
						className={'subStepReorganizeArrow'}
						onClick={() => reorganizeSubStep('down')}
					/>
					<img
						src={chevronUp}
						alt=""
						className={'subStepReorganizeArrow'}
						onClick={() => reorganizeSubStep('up')}
					/>
				</>
			)}

			{isEdition && (
				<img src={deleteIcon} alt={'delete'} className={'subStepDeleteIcon hover'} onClick={deleteSubStep} />
			)}
		</div>
	);
}
