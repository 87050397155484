import React from 'react';
import Modal from 'react-modal';
import './_modalDelete.scss';
import { useTranslation } from 'react-i18next';

export default function ModalDelete({ modal, setModal, onDelete, onDeleteSecond, firstDelete, secondDelete }) {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={modal}
			className={'modalDelete'}
			overlayClassName="overlayModalMoveItem"
			closeTimeoutMS={300}
			onRequestClose={() => setModal(false)}
			shouldCloseOnOverlayClick={true}>
			<div className={'titleModalDelete'}>{t('translation.areYouSureToDeleteIt')}</div>
			<div style={{ display: 'flex', gap: 10, marginLeft: 20, marginRight: 20 }}>
				<div
					onClick={onDelete}
					className={'buttonDelete hover'}
					style={{ backgroundColor: '#ff0000', color: '#FFF' }}>
					{firstDelete}
				</div>
				{onDeleteSecond ? (
					<div
						onClick={onDeleteSecond}
						className={'buttonDelete hover'}
						style={{ backgroundColor: '#ff0000', color: '#FFF' }}>
						{secondDelete}
					</div>
				) : (
					<div className={'buttonDelete hover'} style={{ backgroundColor: '#e8e8e8' }}>
						{t('common.cancel')}
					</div>
				)}
			</div>
			<div style={{ display: 'flex', marginLeft: 20, marginRight: 20, marginTop: 10, marginBottom: 20 }}>
				<div
					className={'buttonDelete hover'}
					style={{ backgroundColor: '#e8e8e8' }}
					onClick={() => setModal(false)}>
					{t('common.cancel')}
				</div>
			</div>
		</Modal>
	);
}
