import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import arrowLeft from '../../../../assets/arrowLeft.svg';
import leftIcon from '../../../../assets/leftIcon.png';
import zoomOutIcon from '../../../../assets/zoomOut.svg';
import zoomOriginal from '../../../../assets/zoomOriginal.svg';
import zoomInIcon from '../../../../assets/zoomIn.svg';
import Modal from 'react-modal';
import annotationIcon from '../../../../assets/annotationIcon.svg';
import AnnotationsPart from './AnnotationsPart';
import screenInfos from '../../../../assets/screenInfos.svg';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../contexts/projectContext';
import mixpanel from 'mixpanel-browser';

export default function ModalPhoto({
	modalPhoto,
	selectedPhoto,
	setSelectedPhoto,
	photos,
	index,
	setIndex,
	zoomIn,
	zoomOut,
	resetTransform,
	handleClick,
	transformWrapperRef,
	goBackButtonModalPhoto,
	albumSelected,
	agencyData,
	partnerAgencies,
	type,
	saveAsAlbumCover,
	deletePicture,
	loadingCouvertureAlbum,
	enregistree,
	height,
	width,
	opacity,
	heightContainer,
	widthContainer,
	enregistreeProjectCover,
	loadingProjectCover,
	saveAsProjectCover,
	downloadPhotos,
	setModalAnnotations,
	setPhotos,
	isModeLecture,
}) {
	const [bigScreen, setBigScreen] = useState(!!isModeLecture);
	const { t } = useTranslation();
	const [project] = useProjectContext();

	useEffect(() => {
		if (selectedPhoto) {
			mixpanel.track('Media View', {
				'Project ID': project.id,
				Page: 'albums',
				'Media type': selectedPhoto.type,
			});
		}
	}, [selectedPhoto]);

	const isAgencyMatch = (itemAgency) =>
		itemAgency === undefined
			? project?.creator === agencyData?.uid
			: (type === 'clients' && itemAgency === 'clients') || itemAgency === agencyData?.uid;

	const AgencyTag = ({ agency }) => (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					borderRadius: 13,
					marginTop: 3,
					height: 18,
					marginLeft: 0,
					color: '#3f5cf6',
					fontSize: 13,
					fontWeight: '700',
					padding: '3px 8px',
					letterSpacing: 0,
				}}>
				{agency === 'clients' ? 'Client' : partnerAgencies.get(agency)?.diminutifAgency}
			</div>
		</div>
	);

	return (
		<Modal isOpen={modalPhoto} className={'modalPhoto'} overlayClassName="overlayModalPhoto" closeTimeoutMS={300}>
			<div style={{ display: 'flex' }}>
				<div style={{ width: bigScreen ? '100vw' : 'calc(100vw - 400px)' }} className={'transition'}>
					{selectedPhoto &&
						(selectedPhoto.type === 'video' ? (
							<div
								className={'transition'}
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: 100,
									position: 'absolute',
									height: '100vh',
									width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
								}}>
								<ReactPlayer
									className="react-player"
									url={selectedPhoto.url}
									controls
									width="100%"
									height={'calc(100vh - 200px)'}
								/>
							</div>
						) : (
							<TransformWrapper ref={transformWrapperRef}>
								<TransformComponent>
									<div
										className={'transition'}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100vh',
											marginTop: 0,
											width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
										}}>
										<img
											src={selectedPhoto.url}
											className={'photoOuvert transition'}
											style={{
												cursor: 'pointer',
												maxWidth: bigScreen ? 'calc(100vw - 200px)' : 'calc(100vw - 500px)',
											}}
											alt={''}
										/>
									</div>
								</TransformComponent>
							</TransformWrapper>
						))}
				</div>

				{!isModeLecture && (
					<div
						style={{
							height: '100vh',
							width: bigScreen ? 0 : 400,
							backgroundColor: '#FFF',
							overflow: 'hidden',
						}}
						className={'transition'}>
						<div className={'containerAnnotationIcon'}>
							<div
								className={'transition hover'}
								style={{
									backgroundColor: '#FFF',
									marginLeft: bigScreen ? -570 : -360,
									display: 'flex',
									position: 'absolute',
									zIndex: 2,
									width: 45,
									height: 45,
									borderRadius: 10,
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<img
									onClick={() => {
										if (bigScreen) {
											setBigScreen(false);
										} else {
											setBigScreen(true);
										}
									}}
									src={screenInfos}
									className={'hover'}
									alt=""
									style={{
										width: 25,
										opacity: 0.7,
										cursor: 'pointer',
										left: 0,
									}}
								/>
							</div>
							{t('common.annotations')}
							<img className={'annotationIcon'} src={annotationIcon} alt="" />
							{selectedPhoto?.comments?.length > 0 && (
								<p className={'numberAnnotations'}>{selectedPhoto.comments.length}</p>
							)}
						</div>

						<AnnotationsPart
							selectedPhoto={selectedPhoto}
							setSelectedPhoto={setSelectedPhoto}
							setPhotos={setPhotos}
						/>
					</div>
				)}
			</div>

			{/* Back Button and Menu Container */}
			<div className={'containerBackMenu'} style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
				<div className={'goBackButton'} onClick={goBackButtonModalPhoto}>
					<img src={arrowLeft} alt="" className={'icon'} />
				</div>

				{!isModeLecture && (
					<div onClick={handleClick} className={'threeDots hover'}>
						<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
						<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
						<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
					</div>
				)}

				<div
					className={'containerMenuBigPhoto'}
					style={{ height: heightContainer, width: widthContainer, zIndex: 10 }}>
					<div style={{ width: 'calc(97vw - 400px)', height: '97vh' }} onClick={handleClick} />
					<div>
						<div className={'menuBigPhoto'} style={{ width, height, opacity }}>
							<div
								onClick={() => {
									downloadPhotos([selectedPhoto]);
								}}
								className={'buttonMenuBigPhoto'}>
								{t('photos.download_image')}
							</div>

							{/* Define as Cover Button */}
							<div
								style={{
									color: enregistreeProjectCover ? '#569F59' : null,
								}}
								onClick={saveAsProjectCover}
								className={'buttonMenuBigPhoto'}>
								{loadingProjectCover
									? t('photos.addition_in_progression')
									: enregistreeProjectCover
										? t('photos.saved')
										: t('photos.define_as_cover')}
							</div>

							{/* Add to Cover Button */}
							<div
								style={{
									color: enregistree ? '#569F59' : null,
									opacity: isAgencyMatch(albumSelected?.createdBy?.agencyId) ? 1 : 0.4,
								}}
								onClick={saveAsAlbumCover}
								className={'buttonMenuBigPhoto'}>
								{loadingCouvertureAlbum
									? t('photos.addition_in_progression')
									: enregistree
										? t('photos.saved')
										: t('photos.add_to_cover')}
								{albumSelected && !isAgencyMatch(albumSelected?.createdBy?.agencyId) && (
									<AgencyTag agency={albumSelected?.createdBy?.agencyId} />
								)}
							</div>

							{/* Delete Picture Button */}
							{albumSelected && (
								<div
									onClick={deletePicture}
									className={'buttonMenuBigPhoto'}
									style={{
										color: '#d94040',
										marginBottom: 10,
										opacity: isAgencyMatch(selectedPhoto?.createdBy?.agencyId) ? 1 : 0.4,
									}}>
									{t('photos.delete_pic')}
									{selectedPhoto && !isAgencyMatch(selectedPhoto?.createdBy?.agencyId) && (
										<AgencyTag agency={selectedPhoto?.createdBy?.agencyId} />
									)}
								</div>
							)}
						</div>
						<div style={{ height: 'calc(97vh - 250px)', width: '400px' }} onClick={handleClick} />
					</div>
				</div>
			</div>

			{/* Zoom and Navigation Controls */}

			<div>
				{selectedPhoto && selectedPhoto.type === 'video' && <div style={{ height: 'calc(100vh - 90px)' }} />}

				<div
					className={'containerZoomNav transition'}
					style={{
						position: selectedPhoto && selectedPhoto.type === 'video' && 'relative',
						width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
					}}>
					<div
						className={'previousNext'}
						style={{ opacity: index === 0 ? 0.5 : 1 }}
						onClick={() => {
							const img = photos?.[index - 1];
							if (img) {
								setSelectedPhoto(img);
								setIndex(index - 1);
								if (height > 0) handleClick();
							}
						}}>
						<img src={leftIcon} alt="" className={'leftIcon'} />
					</div>

					{selectedPhoto && selectedPhoto.type !== 'video' && (
						<div className={'zoomInOut'}>
							<div className={'buttonZoom'} onClick={zoomOut}>
								<img src={zoomOutIcon} alt="" className={'zoomOutIcon'} />
							</div>
							<div className={'buttonZoom'} onClick={resetTransform}>
								<img src={zoomOriginal} alt="" className={'zoomOutIcon'} />
							</div>
							<div className={'buttonZoom'} onClick={zoomIn}>
								<img src={zoomInIcon} alt="" className={'zoomOutIcon'} />
							</div>
						</div>
					)}

					<div
						className={'previousNext'}
						onClick={() => {
							const img = photos?.[index + 1];
							if (img) {
								setSelectedPhoto(img);
								setIndex(index + 1);
								if (height > 0) handleClick();
							}
						}}>
						<img src={leftIcon} alt="" className={'rightIcon'} />
					</div>
				</div>
			</div>
		</Modal>
	);
}
