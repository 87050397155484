export const Pages = {
	SNAGGING: 0,
	MISSIONS: 1,
	FICHIERS: 2,
	PHOTOS: 3,
	PROGRESSION: 4,
	FINANCES: 5,
	CONTRIBUTORS: 6,
	PARAMETRES_PROJET: 7,
};

export const createdByConstructor = (userId, userType, agencyId) => {
	const newUserType = userType === 'clients' ? 'client' : userType === 'collaborators' ? 'collaborator' : userType;
	return {
		userId,
		userType: newUserType,
		agencyId: newUserType === 'client' ? userId : (agencyId ?? null),
	};
};

export const rolesWithMOA = (t) => [
	{
		id: '0',
		acronym: t('translation.terms.0.acronym'),
		name: t('translation.terms.0.name'),
		category: t('translation.terms.0.category'),
	},
	...roles(t),
];

export const roles = (t) => [
	{
		id: '1',
		acronym: t('translation.terms.1.acronym'),
		name: t('translation.terms.1.name'),
		category: t('translation.terms.1.category'),
	},
	{
		id: '2',
		acronym: t('translation.terms.2.acronym'),
		name: t('translation.terms.2.name'),
		category: t('translation.terms.2.category'),
	},
	{
		id: '3',
		acronym: t('translation.terms.3.acronym'),
		name: t('translation.terms.3.name'),
		category: t('translation.terms.3.category'),
	},
	{
		id: '4',
		acronym: t('translation.terms.4.acronym'),
		name: t('translation.terms.4.name'),
		category: t('translation.terms.4.category'),
	},
	{
		id: '5',
		acronym: t('translation.terms.5.acronym'),
		name: t('translation.terms.5.name'),
		category: t('translation.terms.5.category'),
	},
	{
		id: '6',
		acronym: t('translation.terms.6.acronym'),
		name: t('translation.terms.6.name'),
		category: t('translation.terms.6.category'),
	},
	{
		id: '7',
		acronym: t('translation.terms.7.acronym'),
		name: t('translation.terms.7.name'),
		category: t('translation.terms.7.category'),
	},
	{
		id: '8',
		acronym: t('translation.terms.8.acronym'),
		name: t('translation.terms.8.name'),
		category: t('translation.terms.8.category'),
	},
	{
		id: '9',
		acronym: t('translation.terms.9.acronym'),
		name: t('translation.terms.9.name'),
		category: t('translation.terms.9.category'),
	},
	{
		id: '10',
		acronym: t('translation.terms.10.acronym'),
		name: t('translation.terms.10.name'),
		category: t('translation.terms.10.category'),
	},
	{
		id: '11',
		acronym: t('translation.terms.11.acronym'),
		name: t('translation.terms.11.name'),
		category: t('translation.terms.11.category'),
	},
	{
		id: '12',
		acronym: t('translation.terms.12.acronym'),
		name: t('translation.terms.12.name'),
		category: t('translation.terms.12.category'),
	},
	{
		id: '27',
		acronym: t('translation.terms.27.acronym'),
		name: t('translation.terms.27.name'),
		category: t('translation.terms.27.category'),
	},
	{
		id: '28',
		acronym: t('translation.terms.28.acronym'),
		name: t('translation.terms.28.name'),
		category: t('translation.terms.28.category'),
	},
	{
		id: '29',
		acronym: t('translation.terms.29.acronym'),
		name: t('translation.terms.29.name'),
		category: t('translation.terms.29.category'),
	},
	{
		id: '13',
		acronym: t('translation.terms.13.acronym'),
		name: t('translation.terms.13.name'),
		category: t('translation.terms.13.category'),
	},
	{
		id: '14',
		acronym: t('translation.terms.14.acronym'),
		name: t('translation.terms.14.name'),
		category: t('translation.terms.14.category'),
	},
	{
		id: '15',
		acronym: t('translation.terms.15.acronym'),
		name: t('translation.terms.15.name'),
		category: t('translation.terms.15.category'),
	},
	{
		id: '16',
		acronym: t('translation.terms.16.acronym'),
		name: t('translation.terms.16.name'),
		category: t('translation.terms.16.category'),
	},
	{
		id: '17',
		acronym: t('translation.terms.17.acronym'),
		name: t('translation.terms.17.name'),
		category: t('translation.terms.17.category'),
	},
	{
		id: '18',
		acronym: t('translation.terms.18.acronym'),
		name: t('translation.terms.18.name'),
		category: t('translation.terms.18.category'),
	},
	{
		id: '19',
		acronym: t('translation.terms.19.acronym'),
		name: t('translation.terms.19.name'),
		category: t('translation.terms.19.category'),
	},
	{
		id: '20',
		acronym: t('translation.terms.20.acronym'),
		name: t('translation.terms.20.name'),
		category: t('translation.terms.20.category'),
	},
	{
		id: '21',
		acronym: t('translation.terms.21.acronym'),
		name: t('translation.terms.21.name'),
		category: t('translation.terms.21.category'),
	},
	{
		id: '22',
		acronym: t('translation.terms.22.acronym'),
		name: t('translation.terms.22.name'),
		category: t('translation.terms.22.category'),
	},
	{
		id: '23',
		acronym: t('translation.terms.23.acronym'),
		name: t('translation.terms.23.name'),
		category: t('translation.terms.23.category'),
	},
	{
		id: '24',
		acronym: t('translation.terms.24.acronym'),
		name: t('translation.terms.24.name'),
		category: t('translation.terms.24.category'),
	},
	{
		id: '26',
		acronym: t('translation.terms.26.acronym'),
		name: t('translation.terms.26.name'),
		category: t('translation.terms.26.category'),
	},
	{
		id: '-1',
		acronym: t('translation.terms.-1.acronym'),
		name: t('translation.terms.-1.name'),
		category: t('translation.terms.-1.category'),
	},
];

export default function getRoleById(t, id) {
	if (id === undefined) return rolesWithMOA(t).find((role) => role.id === '-1');
	return rolesWithMOA(t).find((role) => role.id === id);
}
