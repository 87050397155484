import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../firebase/config';
import { transformString } from '../../../../useful/UsefulFunctions';

export default function ProfilePictureContributors({ uid, type, agency, research, setResearchNumber }) {
	const [data, setData] = useState();
	const [oldResearch, setOldResearch] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (type === 'collaborators') {
			firestore
				.doc(`agencies/${agency}/collaborators/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		} else {
			firestore
				.doc(`clients/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		}
	}, [uid, type, agency]);

	useEffect(() => {
		if (data) {
			if (transformString(data.name + data.surname).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(data.name + data.surname).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, data]);

	return (
		<div className={'photoProfilBleu'}>
			<p
				style={{
					letterSpacing: 1,
					fontSize: 15,
					margin: 0,
				}}>
				{data?.surname?.charAt(0)?.toUpperCase()}
				{data?.name?.charAt(0)?.toUpperCase()}
			</p>
		</div>
	);
}
