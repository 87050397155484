import heic2any from 'heic2any';

export const convertHeicToJpeg = async (url) => {
    if (url.startsWith('data:')) {
        return url;
    }

    const cache = await caches.open('heic-converted');
    const cachedResponse = await cache.match(url);

    if (cachedResponse) {
        return cachedResponse.url;
    }

    const response = await fetch(url);
    const blob = await response.blob();

    // Check for both 'image/heic' and files without a specific type
    if (blob.type === 'image/heic' || blob.type === '') {
        try {
            const convertedBlob = await heic2any({
                blob: blob,
                toType: 'image/jpeg',
                quality: 0.8
            });

            if (convertedBlob) {
                const convertedUrl = URL.createObjectURL(convertedBlob);

                if (!convertedUrl.startsWith('data:')) {
                    await cache.put(url, new Response(convertedBlob));
                }

                return convertedUrl;
            }
        } catch (error) {
            console.error('Error converting HEIC to JPEG:', error);
        }
    }

    return url;
};
