import { TICKET_STATUS } from './components/Modal Ticket/Follow Up/FollowUp';

export function getTicketStatus(ticket, agencyId) {
	let status = TICKET_STATUS.inProgress;

	if (!ticket?.companiesViewed?.includes(agencyId)) {
		status = TICKET_STATUS.new;
	} else if (!ticket?.properties?.companies?.some((company) => ticket?.companiesViewed?.includes(company))) {
		status = TICKET_STATUS.pendingProcess;
	} else if (ticket?.issueStatus === TICKET_STATUS.pendingApproval) {
		status = ticket?.companiesReviewed?.includes(agencyId)
			? TICKET_STATUS.inProgress
			: TICKET_STATUS.pendingApproval;
	} else if (ticket?.issueStatus === TICKET_STATUS.refused) {
		status =
			ticket?.properties?.companies?.includes(agencyId) || !ticket?.companiesViewedRefused?.includes(agencyId)
				? TICKET_STATUS.refused
				: TICKET_STATUS.inProgress;
	} else {
		status = ticket?.issueStatus ?? TICKET_STATUS.inProgress;
	}

	return status;
}
