import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import crossIcon from '../../../../assets/crossIcon.png';
import '../../messaging.scss';
import peopleTwo from '../../../../assets/peopleTwo.png';
import ItemParticipantGroup from './ItemParticipantGroup';
import SearchBarMessaging from '../SearchBarMessaging';
import AgencyContext from '../../../../contexts/agencyContext';
import { auth, firestore, functions } from '../../../../firebase/config';
import { generateUniqueFirestoreId, joinPaths } from '../../../../firebase/utils';
import TypeContext from '../../../../contexts/typeContext';
import { httpsCallable } from 'firebase/functions';
import AgencyDataContext from '../../../../contexts/agencyDataContext';
import { useProjectsContext } from '../../../../contexts/projectsContext';
import NoResult from '../../../../components/NoResult/NoResult';
import { filterConversations } from '../../utils';

export default function ModalNewGroup({
	modalNewGroup,
	setModalNewGroup,
	parentId,
	contacts,
	isEdit,
	group,
	oldParticipantIds,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const [projects] = useProjectsContext();

	const [name, setName] = useState('');
	const [accounts, setAccounts] = useState([]);
	const [participantIds, setParticipantIds] = useState([uid]);
	const [research, setResearch] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (isEdit && group) {
			setName(group.name);
			setParticipantIds(oldParticipantIds);
		}
	}, [isEdit, group, oldParticipantIds]);

	useEffect(() => {
		if (modalNewGroup && parentId) {
			firestore
				.collection(
					parentId === agencyId
						? joinPaths('agencies', agencyId, 'collaborators')
						: joinPaths('projects', parentId, 'accounts')
				)
				.onSnapshot((querySnapshot) => {
					const accounts = [];
					querySnapshot.forEach((doc) => {
						if (doc.exists && doc.id !== uid && !doc.data().isDeleted) {
							const account = contacts.find((contact) => contact.id === doc.id);
							if (account) {
								accounts.push(account);
							}
						}
					});
					setAccounts(accounts);
				});
		}
	}, [modalNewGroup, parentId, agencyId, contacts, uid]);

	const createGroup = async () => {
		if (name && participantIds.length > 0 && !isLoading) {
			setIsLoading(true);

			const id = generateUniqueFirestoreId();
			await firestore.doc(joinPaths('messaging', id)).set({
				name,
				date: new Date().toISOString(),
				lastMessageAt: new Date().toISOString(),
				agency: type === 'collaborators' ? agencyId : 'clients',
				uid,
				parentId: parentId,
			});

			const promises = [];
			for (const participantId of participantIds) {
				const promise1 = firestore
					.collection(`messaging`)
					.doc(id)
					.collection('participants')
					.doc(participantId)
					.set({ uid: participantId, id: participantId });
				promises.push(promise1);
				const path = firestore.doc(`users/${participantId}/messaging/${id}`);
				const promise2 = path.set({
					lastClickAt: new Date().toISOString(),
					lastMessageAt: new Date(new Date().getTime() + 1).toISOString(),
					isDeleted: false,
					isGroup: true,
					isNotificationsOff: false,
					parentId,
					isUnread: true,
					name: name,
				});
				promises.push(promise2);
				firestore
					.doc(`accounts/${participantId}`)
					.get()
					.then(async (documentSnapshot) => {
						const call = httpsCallable(functions, 'sendNotificationPush');
						call({
							tokens: documentSnapshot.data().tokens ?? [],
							title:
								parentId === agencyId
									? agencyData.name
									: projects.find((item) => item.id === parentId)?.name,
							body:
								name +
								'\n' +
								(documentSnapshot.data().language === 'fr'
									? `Vous avez été ajouté dans le salon "${name}".`
									: `You have been added to the "${name}" group.`),
							data: {
								page: 'messaging',
								conversation: id,
							},
						}).then((r) => {});
					});
			}
			await Promise.all(promises);

			setModalNewGroup(false);
			setIsLoading(false);
		}
	};

	const updateGroup = async () => {
		if (name && participantIds.length > 0 && !isLoading && group) {
			setIsLoading(true);

			if (name !== group.name) {
				await firestore.doc(joinPaths('messaging', group.id)).update({
					name,
				});
			}

			const promises = [];
			for (const participantId of participantIds.filter((id) => !oldParticipantIds.includes(id))) {
				const promise1 = firestore
					.collection(`messaging`)
					.doc(group.id)
					.collection('participants')
					.doc(participantId)
					.set({ uid: participantId, id: participantId });
				promises.push(promise1);
				const path = firestore.doc(`users/${participantId}/messaging/${group.id}`);
				const promise2 = path.set(
					{
						lastClickAt: new Date().toISOString(),
						lastMessageAt: new Date(new Date().getTime() + 1).toISOString(),
						isDeleted: false,
						isGroup: true,
						isNotificationsOff: false,
						parentId,
						isUnread: true,
						name: name,
					},
					{ merge: true }
				);
				promises.push(promise2);
				firestore
					.doc(`accounts/${participantId}`)
					.get()
					.then(async (documentSnapshot) => {
						const call = httpsCallable(functions, 'sendNotificationPush');
						call({
							tokens: documentSnapshot.data().tokens ?? [],
							title:
								parentId === agencyId
									? agencyData.name
									: projects.find((item) => item.id === parentId)?.name,
							body:
								name +
								'\n' +
								(documentSnapshot.data().language === 'fr'
									? `Vous avez été ajouté dans le salon "${name}".`
									: `You have been added to the "${name}" group.`),
							data: {
								page: 'messaging',
								conversation: group.id,
							},
						}).then((r) => {});
					});
			}

			for (const participantId of oldParticipantIds.filter((id) => !participantIds.includes(id))) {
				const promise1 = firestore
					.collection(`messaging`)
					.doc(group.id)
					.collection('participants')
					.doc(participantId)
					.delete();
				promises.push(promise1);
				const path = firestore.doc(`users/${participantId}/messaging/${group.id}`);
				const promise2 = path.delete();
				promises.push(promise2);
			}

			if (name !== group.name) {
				for (const participantId of participantIds.filter((id) => oldParticipantIds.includes(id))) {
					const path = firestore.doc(`users/${participantId}/messaging/${group.id}`);
					const promise2 = path.update({
						name,
					});
					promises.push(promise2);
				}
			}

			await Promise.all(promises);

			setModalNewGroup(false);
			setIsLoading(false);
		}
	};

	return (
		<Modal
			isOpen={modalNewGroup}
			className={'modalNewGroup'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setModalNewGroup(false);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img src={crossIcon} alt="crossIcon" className={'crossIcon'} onClick={() => setModalNewGroup(false)} />

			<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
				<div className={'groupPicture'}>
					<img src={peopleTwo} alt="" style={{ width: 26 }} />
				</div>
				<div style={{ width: 300 }}>
					<p className={'titleModalNewGroup'}>{t('translation.newGroup')}</p>
					<p className={'subtitleModalNewGroup'}>{t('translation.createNewGroup')}</p>
				</div>
			</div>
			<div className={'barModalNewGroup'} />

			<div className={'scrollView'}>
				<p className={'indicator'}>{t('translation.groupName')}</p>
				<div style={{ display: 'flex' }}>
					<input
						type="text"
						className={'inputGroupName'}
						placeholder={t('translation.work')}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<p className={'indicator'}>{t('translation.participants')}</p>
				<div style={{ marginBottom: 10 }}>
					<SearchBarMessaging research={research} setResearch={setResearch} />
				</div>
				<div className={'listParticipants'}>
					{filterConversations(accounts, research, 'all', '').map((contact) => (
						<ItemParticipantGroup
							data={contact}
							isAdded={participantIds.includes(contact.id)}
							onClick={() => {
								if (participantIds.includes(contact.id)) {
									setParticipantIds(participantIds.filter((id) => id !== contact.id));
								} else {
									setParticipantIds([...participantIds, contact.id]);
								}
							}}
						/>
					))}
					{research.length > 0 && filterConversations(accounts, research, 'all', '').length === 0 && (
						<NoResult />
					)}
					<div style={{ color: 'white', fontSize: 1 }}>Louis</div>
				</div>
			</div>

			<div className={'barModalNewGroup'} />

			<div className={'createGroup hover'} onClick={isEdit ? updateGroup : createGroup}>
				{isLoading ? t('common.loading') : isEdit ? t('common.save') : t('translation.createTheGroup')}
			</div>
		</Modal>
	);
}
