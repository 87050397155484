import React from 'react';
import '../../messaging.scss';
import ResponseMessage from './Response Message/ResponseMessage';

export default function TextMessage({ isMyMessage, isGroup, value, responseMessage, contacts }) {
	return (
		<div className={'textMessage'} style={{ paddingTop: !isMyMessage && isGroup ? 0 : 10 }}>
			{responseMessage && <ResponseMessage message={responseMessage} contacts={contacts} />}
			<div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>
		</div>
	);
}
