import React, { useContext, useEffect, useState } from 'react';
import { TICKET_STATUS } from '../../../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';
import { MISSIONS_STATUS, useTicketProperties } from '../../../../Fiche Projet/Tickets/data';
import { formatDateStringWithTodayOrYesterdayOrTomorrow } from '../../../../../useful/date';
import CompanyOrPersonItem from '../../../../Fiche Projet/Tickets/components/Little Components/Company Or Person Item/CompanyOrPersonItem';
import ResolutionProposal from '../Resolution Proposal/ResolutionProposal';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../../firebase/config';
import { joinPaths } from '../../../../../firebase/utils';
import { Paths } from '../../../../../firebase/paths';
import { useProjectContext } from '../../../../../contexts/projectContext';

export default function LittleSnagResolution({ ticket, status }) {
	const [resolution, setResolution] = useState(null);

	const [project] = useProjectContext();

	// ================================================ RESOLUTIONS ================================================

	useEffect(() => {
		if (ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.RESOLUTIONS))
				.orderBy('createdAt', 'asc')
				.limit(1)
				.onSnapshot((snapshot) => {
					if (snapshot && snapshot.docs.length > 0) {
						setResolution(snapshot.docs[0].data());
					} else {
						setResolution(null);
					}
				});
			return () => unsubscribe();
		}
	}, [ticket?.id, project.id]);

	const { t, i18n } = useTranslation();

	return (
		<div className={'containerResolution'}>
			<div className={'containerSnagNameCreator'}>
				<span className={'snagName'}>{t('translation.snagResolution')}</span>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{status !== TICKET_STATUS.approved && ticket.properties.dueAt && (
						<div
							className={
								new Date(ticket.properties.dueAt) < new Date() &&
								ticket.missionStatus !== MISSIONS_STATUS.completed
									? 'timingItem timingItemOverdue'
									: 'timingItem'
							}>
							{new Date(ticket.properties.dueAt) < new Date() &&
							ticket.missionStatus !== MISSIONS_STATUS.completed
								? t('translation.overdue')
								: t('translation.dueOn')}{' '}
							{formatDateStringWithTodayOrYesterdayOrTomorrow(ticket.properties.dueAt, i18n.language, t)}
						</div>
					)}
					{status === TICKET_STATUS.approved ? (
						<span
							className={'tagResolutionStatus'}
							style={{ color: '#3CA504', backgroundColor: 'rgba(60,165,4,0.1)' }}>
							{t('translation.snagResolved')}
						</span>
					) : (
						<span
							className={'tagResolutionStatus'}
							style={{ backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }}>
							{t('translation.waitingForProcessing')}
						</span>
					)}
				</div>
			</div>
			<div className={'creator'} style={{ gap: 0, marginTop: 10 }}>
				{ticket.properties?.companies?.map((company, index) => (
					<CompanyOrPersonItem
						key={index}
						agencyId={company}
						unClickable={true}
						maxWidth={300}
						company={true}
					/>
				))}
				<span style={{ marginLeft: -6 }}>{t('translation.isInCharge')}</span>
			</div>

			<ResolutionProposal resolution={resolution} />

			<div
				className={'containerApproval'}
				style={
					status === TICKET_STATUS.approved
						? {
								color: '#3CA504',
								backgroundColor: 'rgba(60,165,4,0.1)',
								padding: '0px 10px',
								borderRadius: 5,
							}
						: {
								backgroundColor: 'rgba(28, 87, 221, 0.1)',
								color: '#1C57DD',
								padding: '0px 10px',
								borderRadius: 5,
							}
				}>
				{status === TICKET_STATUS.approved
					? t('translation.resolutionApprovedBy')
					: t('translation.toBeApprovedBy')}{' '}
				{ticket?.properties?.reviewers?.map((companyId) => (
					<CompanyOrPersonItem
						key={companyId}
						company={true}
						agencyId={companyId}
						color={status === TICKET_STATUS.approved && 'rgba(60,165,4,0.1)'}
						onlyDiminutif={true}
					/>
				))}
			</div>
		</div>
	);
}
