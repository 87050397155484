import React, { useEffect, useState } from 'react';
import '../../messaging.scss';
import { useTranslation } from 'react-i18next';
import { storage } from '../../../../firebase/config';
import { getPathStorageFromUrl } from '../../../../firebase/utils';
import playSymbolVideo from '../../../../assets/playSymbolVideo.svg';

export default function ImageMessage({ message, selectPicture }) {
	const { t } = useTranslation();

	const [thumbnailUrl, setThumbnailUrl] = useState('');

	useEffect(() => {
		if (message && message.type === 'video') {
			const id = message.id;
			storage
				.ref(getPathStorageFromUrl(message?.contents?.replace(id, 'thumb_' + id + '.jpeg')))
				.getDownloadURL()
				.then((url) => {
					setThumbnailUrl(url);
				})
				.catch(() => {
					setThumbnailUrl('');
				});
		} else {
			setThumbnailUrl('');
		}
	}, [message]);

	return message?.type === 'video' ? (
		thumbnailUrl ? (
			<img src={message?.contents} alt="" className={'imageMessage'} onClick={selectPicture} />
		) : (
			<div onClick={selectPicture}>
				<video src={message.contents} className={'imageMessage'} />
				<img
					src={playSymbolVideo}
					alt=""
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '50px',
					}}
				/>
			</div>
		)
	) : (
		<img src={message?.contents} alt="" className={'imageMessage'} onClick={selectPicture} />
	);
}
