import React from 'react';
import './TagComponent.scss';

export default function TagComponent({ name, isSelected, onClick }) {
	return (
		<div className={isSelected ? 'tagComponentSelected' : 'tagComponentNotSelected'} onClick={() => onClick(name)}>
			<p>{name}</p>
		</div>
	);
}
