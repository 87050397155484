import React from 'react';
import './styles.css';

const LoadingDiv = ({ width, height, borderRadius }) => {
	const divStyle = {
		width: width || '100px', // Valeur par défaut
		height: height || '100px', // Valeur par défaut
		borderRadius: borderRadius || '0px', // Valeur par défaut
	};

	return <div className="loading-div" style={divStyle}></div>;
};

export default LoadingDiv;
