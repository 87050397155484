import React, { useContext, useState } from 'react';
import crossIcon from '../../../../assets/crossIcon.png';
import searchIcon from '../../../../assets/searchIcon.png';
import { auth, firestore, functions } from '../../../../firebase/config';
import AgencyContext from '../../../../contexts/agencyContext';
import { generateUniqueFirestoreId } from '../../../../firebase/utils';
import O_Opus from '../../../../assets/O_Opus.png';
import ModalPassword from '../../../../components/ModalPassword';
import chargement from '../../../../assets/chargement.png';
import ModalAddProjet from '../../ProjectComponents/ModalAddProjet';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, signInWithCredential, signOut } from 'firebase/auth';
import { createdByConstructor } from '../../../Fiche Projet/utils';

export default function ModalNewClient({
	isCreationCompte,
	setIsCreationCompte,
	setAccounts,
	authProjects,
	setModalFelicitationAddClient,
	modalFelicitationAddClient,
}) {
	const { t, i18n } = useTranslation();

	const agency = useContext(AgencyContext);
	const [uid, setUid] = useState();

	const [modalAddProjet, setModalAddProjet] = useState(false);
	const [modalPassword, setModalPassword] = useState(false);

	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [existingAccountId, setExistingAccountId] = useState('');
	const [existingAccountData, setExistingAccountData] = useState();

	const [projectsToAdd, setProjectsToAdd] = useState([]);
	const [newClientId, setNewClientId] = useState();
	const [passwordClient, setPasswordClient] = useState();

	async function createClient(cred) {
		setLoading(true);

		const password = generateUniqueFirestoreId().slice(0, 6);
		setPasswordClient(password);

		createUserWithEmailAndPassword(auth, email, password)
			.then(async (authUser) => {
				signOut(auth)
					.then(() => {
						signInWithCredential(auth, cred)
							.then(async () => {
								const uid = authUser.user.uid;
								setNewClientId(uid);

								firestore
									.doc(`accounts/${uid}`)
									.set({
										uid,
										tokens: [],
										type: 'clients',
										firstPassword: password,
									})
									.then(() => {
										firestore
											.doc(`agencies/${agency}/clients/${uid}`)
											.set({
												date: new Date().toISOString(),
												notes: '',
												uid,
											})
											.then(async () => {
												const data = {
													date: new Date().toISOString(),
													email,
													name,
													surname,
													uid,
													imgUrl: O_Opus,
													language: i18n.language,
													firstConnexion: false,
												};
												await firestore.doc(`clients/${uid}`).set(data);
												setUid(uid);
												setAccounts((prevState) => [data, ...prevState]);
											})
											.catch(() => {
												setLoading(false);
												window.alert(t('errors.error') + '(error 1006)');
											});
									})
									.catch(() => {
										setLoading(false);
										window.alert(t('errors.error') + '(error 1005)');
									});
							})
							.catch((error) => {
								setLoading(false);
								window.alert(t('errors.error') + '(error 1004)');
							});
					})
					.catch((error) => {
						setLoading(false);
						window.alert(t('errors.error') + '(error 1003)');
					});
			})
			.catch((error) => {
				setLoading(false);
				window.alert(t('errors.error') + '(error 1002)');
			});
		setModalFelicitationAddClient(true);
		setEmail('');
		setLoading(false);
	}

	function addExistingClient(e) {
		e.preventDefault();

		setLoading(true);

		firestore
			.doc(`agencies/${agency}/clients/${existingAccountId}`)
			.get()
			.then(async (documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					window.alert(t('new_partner_agency.this_account_already_linked'));
				} else {
					setNewClientId(existingAccountId);
					await firestore.collection(`agencies/${agency}/clients`).add({
						uid: existingAccountId,
						notes: '',
						date: new Date().toISOString(),
					});
					// setNewAccountCreated(v => !v);
				}
			});

		setExistingAccountId('');
		setLoading(false);
	}

	function searchClient() {
		firestore
			.doc(`clients/${existingAccountId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setExistingAccountData({ ...documentSnapshot.data(), id: doc.id });
				} else {
					window.alert(t('new_partner_agency.no_corresponding_account'));
				}
			});
	}

	async function addProjects() {
		if (!loading) {
			for (const item of projectsToAdd) {
				setLoading(true);

				const call = httpsCallable(functions, 'addUserToProject');
				call({
					createdBy: createdByConstructor(newClientId, 'client', null),
					projectId: item.id,
					parent: {},
					data: {
						name,
						surname,
						email,
						phone: '',
					},
					senderName: surname + ' ' + name,
					projectName: item.name,
					projectImgUrl: item.imgUrl,
				}).then((r) => {});
			}
		}
		setLoading(false);
		setModalFelicitationAddClient(false);
		setIsCreationCompte(false);
		setEmail('');
		setName('');
		setSurname('');
	}

	return (
		<div
			className={'modalCreationCompte'}
			style={{
				width: isCreationCompte ? 430 : 0,
				transform: isCreationCompte ? 'scale(1)' : 'scale(0.8)',
				height: modalFelicitationAddClient ? 390 : null,
			}}>
			{modalFelicitationAddClient ? (
				<div className={'modalFelicitationAddClient'}>
					{loading ? (
						<div className={'center'}>
							<p className={'felicitation'} style={{ marginTop: 90 }}>
								{t('new_collab_account.creation_in_progress')}
							</p>
							<p className={'detailFelicitation'} style={{ textAlign: 'center', lineHeight: 1.4 }}>
								{t('new_client.we_are_preparing_space')}
							</p>

							<div className={'chargementContainerFinanceProgression'} style={{ marginTop: -180 }}>
								<img src={chargement} alt="" className={'chargement'} />
							</div>
						</div>
					) : (
						<>
							<img
								src={crossIcon}
								alt="crossIcon"
								className={'crossIcon'}
								onClick={() => {
									setModalFelicitationAddClient(false);
									setEmail('');
									setName('');
									setSurname('');
								}}
							/>

							<div className={'center'}>
								<p className={'felicitation'}>{t('new_partner_agency.congrats')}</p>

								<div className={'emailContainer'}>
									<p className={'email'}>
										{surname} {name}
									</p>
								</div>

								<p className={'detailFelicitation'}>{t('new_client.has_been_successfully_added')}</p>

								<p className={'detailFelicitation'}>{t('new_client.no_email_sent')}</p>

								<div style={{ display: 'flex', marginTop: 30 }}>
									<div className={'terminer'} onClick={addProjects}>
										<p className={'textTerminer'}>{t('common.finish')}</p>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			) : (
				<div className={'contentCreationClient'} style={{ opacity: isCreationCompte ? 1 : 0 }}>
					<div style={{ display: 'flex' }}>
						<div style={{ width: 400 }}>
							<p className={'titleModal'}>{t('new_client.invite_a_new_client')}</p>
						</div>
						<img
							src={crossIcon}
							alt="crossIcon"
							className={'crossIcon'}
							style={{ marginTop: 0 }}
							onClick={() => setIsCreationCompte(false)}
						/>
					</div>
					<p className={'titleModal'} style={{ fontSize: 17, marginTop: 30 }}>
						{t('new_client.first_choice')}
					</p>

					<p className={'subtitleModal'}>{t('new_client.type_the_client_infos')}</p>

					<form
						onSubmit={(e) => {
							e.preventDefault();
							setModalPassword(true);
						}}>
						<div style={{ display: 'flex' }}>
							<input
								type="text"
								className={'inputNom'}
								placeholder={t('common.first_name')}
								value={surname}
								onChange={(e) => setSurname(e.target.value)}
								required
							/>
							<input
								type="text"
								className={'inputNom'}
								placeholder={t('common.last_name')}
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>

						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex', marginTop: 15 }}>
								<input
									type="email"
									className={'inputEmail'}
									placeholder={t('common.email_address')}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
							<button className={'buttonSuivant'} style={{ border: 'solid 0px' }}>
								<p className={'textSuivant'}>{t('common.next')}</p>
							</button>
						</div>
					</form>

					<p className={'titleModal'} style={{ fontSize: 17, marginTop: 35 }}>
						{t('new_client.second_choice')}
					</p>

					<p className={'subtitleModal'}>{t('new_client.type_the_client_id')}</p>

					<div style={{ display: 'flex' }}>
						<input
							type="text"
							className={'input'}
							placeholder={t('new_client.account_id')}
							value={existingAccountId}
							onChange={(e) => setExistingAccountId(e.target.value)}
						/>
						<div
							className={'searchButton'}
							disabled={existingAccountId.length !== 28}
							onClick={searchClient}>
							<img src={searchIcon} alt="whiteSearchIcon" className={'whiteSearchIcon'} />
						</div>
					</div>

					{existingAccountData ? (
						<div style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
							<img src={existingAccountData.imgUrl} className={'profilePicture'} />
							<div style={{ width: 185 }}>
								<div className={'blankName'}>
									{existingAccountData.surname} {existingAccountData.name}
								</div>
								<div className={'blankMailAdress'}>{existingAccountData.email}</div>
							</div>
							<button className={'blankButtonAdd'} onClick={addExistingClient}>
								{t('common.add')}
							</button>
						</div>
					) : (
						<div style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
							<div className={'profilePicture'} />
							<div style={{ width: 185 }}>
								<div className={'blankName'} />
								<div className={'blankMailAdress'} />
							</div>
							<div className={'blankButtonAdd'} />
						</div>
					)}
				</div>
			)}

			<ModalAddProjet
				modal={modalAddProjet}
				setModalAddProjet={setModalAddProjet}
				authProjects={authProjects}
				projectsToAdd={projectsToAdd}
				setProjectsToAdd={setProjectsToAdd}
			/>

			<ModalPassword modalPassword={modalPassword} setModalPassword={setModalPassword} toExecute={createClient} />
		</div>
	);
}
