import React, { useContext, useState } from 'react';
import './_settingsProjets.scss';
import Informations from './Informations/Informations';
import { useTranslation } from 'react-i18next';
import ProjectSynthesisReport from '../../Reports/Project Synthesis Report/ProjectSynthesisReport';
import { Paths } from 'src/firebase/paths';
import { joinPaths } from 'src/firebase/utils';
import { firestore } from 'src/firebase/config';
import printer from '../../../assets/printer.svg';
import AuthDataContext from 'src/contexts/authDataContext';
import AgencyContext from 'src/contexts/agencyContext';
import { PR_ID } from 'src/useful/constants';

export default function SettingsProjet({ auth, projectId, projectData, setProjectData }) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);
	const isAdmin = authData?.subtype === 'administrator';

	const [modalProjectSynthesisReport, setModalProjectSynthesisReport] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [missions, setMissions] = useState([]);
	const [collaborators, setCollaborators] = useState([]);

	const openReport = async () => {
		setIsLoading(true);

		let missions = (
			await firestore
				.collection(joinPaths(Paths.PROJECTS, projectId, Paths.TICKETS))
				.where('properties.type', '==', 'mission')
				.where('properties.visibility', '!=', 'private')
				.get()
		).docs.map((doc) => ({ ...doc.data(), id: doc.id }));
		missions = missions
			?.filter(
				(mission) => mission.properties?.owner?.userId === auth || mission.properties?.users?.includes(auth)
			)
			.sort((a, b) => {
				if (!a.properties?.dueAt && !b.properties?.dueAt) return 0;
				if (!a.properties?.dueAt) return 1;
				if (!b.properties?.dueAt) return -1;
				return new Date(a.properties.dueAt) - new Date(b.properties.dueAt);
			});

		let collaborators = (
			await firestore
				.collection(joinPaths(Paths.PROJECTS, projectId, Paths.ACCOUNTS))
				.where('type', '==', 'collaborators')
				.where('agency', '==', agencyId)
				.get()
		).docs.map((doc) => ({ ...doc.data(), id: doc.id }));

		const collaboratorsData = [];
		await Promise.all(
			collaborators.map(
				async (collaborator) =>
					new Promise((resolve) => {
						firestore
							.doc(joinPaths(Paths.AGENCIES, agencyId, Paths.COLLABORATORS, collaborator.id))
							.get()
							.then((doc) => {
								collaboratorsData.push({ ...collaborator, ...doc.data() });
								resolve();
							});
					})
			)
		);

		setMissions(missions);
		setCollaborators(collaboratorsData.filter((collaborator) => collaborator.subtype !== 'administrator'));

		setModalProjectSynthesisReport(true);
		setIsLoading(false);
	};

	return (
		<div className={'settingsProjet'}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<p className={'titlePage'}>{t('common.settings')}</p>

				{isAdmin && agencyId === PR_ID && (
					<div className={'hover printButton'} style={{ marginRight: 20 }} onClick={openReport}>
						<img src={printer} alt="" className={' printer'} />
						{t('translation.printSynthesis')}
					</div>
				)}
			</div>
			<p className={'subtitlePage'}>{t('translation.editSettings')}</p>
			<div className={'bar'} />
			<Informations uid={auth} projectId={projectId} projectData={projectData} setProjectData={setProjectData} />

			<ProjectSynthesisReport
				isOpen={modalProjectSynthesisReport}
				onClose={() => setModalProjectSynthesisReport(false)}
				projects={[{ ...projectData, missions, collaborators }]}
			/>

			{isLoading && (
				<div className="deletion-overlay">
					<div className="deletion-spinner"></div>
					<p>Chargement du rapport...{/* TODO: transalte */}</p>
				</div>
			)}
		</div>
	);
}
