import React, { useContext, useEffect, useState } from 'react';
import './messaging.scss';
import Menu from '../../components/Main/Menu';
import { auth, firestore } from '../../firebase/config';
import { useNavigate } from 'react-router-dom';
import AgencyContext from '../../contexts/agencyContext';
import TypeContext from '../../contexts/typeContext';
import { onAuthStateChanged } from 'firebase/auth';
import ConvsList from './List/ConvsList';
import ConvContainer from './Conversation/ConvContainer';
import { joinPaths } from '../../firebase/utils';
import { ProjectsContext } from '../../contexts/projectsContext';
import { mergeAndOrder } from '../../useful/utils';
import { transformString } from '../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';

export default function Messaging() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const uid = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);
	const [projects] = useContext(ProjectsContext);

	const [userSession, setUserSession] = useState();
	const [lastProject, setLastProject] = useState();

	const [conversations, setConversations] = useState([]);
	const [conversationsLoading, setConversationsLoading] = useState(true);
	const [selectedConvId, setSelectedConvId] = useState('');

	const [contacts, setContacts] = useState([]);

	useEffect(() => {
		const listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
			} else navigate('/');
		});
		return () => {
			listener();
		};
	}, [userSession]);

	useEffect(() => {
		const subscriber = firestore
			.collection(
				type === 'collaborators'
					? `agencies/${agency}/collaborators/${uid}/projects`
					: `${type}/${uid}/projects`
			)
			.orderBy('lastClick', 'desc')
			.limit(1)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							setLastProject(documentSnapshot.data().id);
						}
					});
				}
			});
		return () => subscriber();
	}, [agency, uid, type]);

	useEffect(() => {
		if (uid) {
			const subscriber = firestore
				.collection(joinPaths('users', uid, 'messaging'))
				.orderBy('lastMessageAt', 'desc')
				.onSnapshot((snapshot) => {
					if (snapshot && !snapshot.empty) {
						const conversations = [];
						const contacts = [];
						snapshot.forEach((doc) => {
							if (doc && doc.exists) {
								const data = {
									...doc.data(),
									id: doc.id,
									name: doc.data().isGroup
										? doc.data().isGeneral
											? t('translation.general')
											: doc.data().name
										: doc.data().firstName +
											(doc.data().firstName && doc.data().lastName ? ' ' : '') +
											doc.data().lastName,
								};

								if (data.name) {
									if (!data.isGroup) {
										contacts.push(data);
									}
									if (data.lastMessageAt) {
										conversations.push(data);
									}
								}
							}
						});

						setConversations(conversations);
						setContacts(
							mergeAndOrder(
								(a, b) =>
									transformString(a.lastName + a.firstName).localeCompare(
										transformString(b.lastName + b.firstName)
									),
								'id',
								[],
								contacts
							)
						);
						setConversationsLoading(false);
					}
				});
			return () => subscriber();
		}
	}, [uid]);

	return (
		<div>
			<Menu margin={true} grasMessagerie={true} lastProject={lastProject} />

			<div className={'messaging'}>
				<ConvsList
					conversations={conversations}
					isLoading={conversationsLoading}
					selectedConvId={selectedConvId}
					setSelectedConvId={setSelectedConvId}
					contacts={contacts}
				/>
				<ConvContainer
					conversations={mergeAndOrder(
						(a, b) => a.lastMessageAt - b.lastMessageAt,
						'id',
						conversations,
						contacts
					)}
					selectedConvId={selectedConvId}
				/>
			</div>
		</div>
	);
}
