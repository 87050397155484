import React from 'react';
import '../../messaging.scss';
import { chooseIconFile } from '../../../Fiche Projet/Documents/utils';
import { useTranslation } from 'react-i18next';

export default function FileMessage({ message, selectFile }) {
	const { t } = useTranslation();

	return (
		<div className={'fileMessage'} onClick={selectFile}>
			<img src={chooseIconFile(message.fileType)} alt="" className={'fileTypeIcon'} />
			{message.fileName}
		</div>
	);
}
