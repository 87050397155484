import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Progression.scss';
import chevronDown from '../../../../assets/chevronDown.svg';
import chevronUp from '../../../../assets/chevronUp.svg';

export default function PhaseReorganize({ phaseData, setPhases, indexPhase }) {
	const { t } = useTranslation();

	return (
		<div className={'phase phaseReorganize'} style={{ marginBottom: 20 }}>
			<div className={'phaseReorganizeContainer'}>
				<img
					src={chevronUp}
					alt=""
					className={'phaseReorganizeArrow'}
					onClick={() =>
						setPhases((prevState) => {
							const newPhases = [...prevState];
							const newPhase = newPhases[indexPhase];
							newPhases[indexPhase] = newPhases[indexPhase - 1];
							newPhases[indexPhase - 1] = newPhase;
							return newPhases;
						})
					}
				/>
				<img
					src={chevronDown}
					alt=""
					className={'phaseReorganizeArrow'}
					onClick={() => {
						setPhases((prevState) => {
							const newPhases = [...prevState];
							const newPhase = newPhases[indexPhase];
							newPhases[indexPhase] = newPhases[indexPhase + 1];
							newPhases[indexPhase + 1] = newPhase;
							return newPhases;
						});
					}}
				/>
			</div>
			<div className={'phaseStickyContainer'}>
				<div className={'phaseDetail'}>
					<p className={'phaseName'}>
						{t('progression_page.phase')} {indexPhase + 1} :{' '}
						{phaseData.name ? phaseData.name : t('progression_page.no_name')}
					</p>
				</div>
			</div>
		</div>
	);
}
