import React from 'react';
import '../../messaging.scss';
import { dateString, stringTime } from '../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { isToday } from '../../../../useful/utils';

export default function MessageDate({ date }) {
	const { t } = useTranslation();
	const language = i18n.language;

	return (
		<div className={'messageDate'}>
			{!isToday(date) && dateString(t, date, true, true, true)}
			<span style={{ fontWeight: 500, marginLeft: 4, marginBottom: 0 }}>{stringTime(language, date)}</span>
		</div>
	);
}
