import React, { useContext } from 'react';
import pdfIcon from '../../../../assets/pdfIcon.svg';
import { dateString } from '../../../../useful/UsefulFunctions';
import { auth, firestore, storage } from '../../../../firebase/config';
import { getPathStorageFromUrl } from '../../../../firebase/utils';
import AgencyContext from '../../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import TypeContext from '../../../../contexts/typeContext';
import '../_finances.scss';

export default function DocItem({ projectId, expenseId, fileData, mandataire, isNew, setFiles, expenseData }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);

	return (
		<div className={'docItem hover'}>
			<div
				style={{ display: 'flex' }}
				onClick={() => {
					if (!isNew) {
						window.open(fileData.url, '_blank');
					}
				}}>
				<img src={pdfIcon} alt="" className={'pdfIcon'} />
				<div>
					<p className={'nomDoc'}>{fileData.name}</p>
					<p className={'dateDoc'}>{dateString(t, isNew ? new Date() : fileData.date)}</p>
				</div>
			</div>

			{type !== 'clients' && (
				<div style={{ display: 'flex', marginTop: -10 }}>
					<div style={{ width: 600, height: 1 }} />
					<div
						className={'buttonSupprimerDoc'}
						onClick={async () => {
							if (isNew) {
								setFiles((prevState) => {
									const newState = prevState.filter((file) => file.name !== fileData.name);
									return newState ? newState : [];
								});
							} else if (window.confirm(t('modal_edit_expense_item.want_to_delete_doc'))) {
								if (fileData.url) {
									await storage.ref(getPathStorageFromUrl(fileData.url)).delete();
								}
								await firestore
									.doc(`projects/${projectId}/expenses/${expenseId}/files/${fileData.id}`)
									.delete();
							}
						}}>
						{t('common.delete')}
					</div>
				</div>
			)}
		</div>
	);
}
