import React from 'react';
import crossIcon from '../../../assets/crossIcon.png';
import checkVert from '../../../assets/checkVert.svg';
import Modal from 'react-modal';
import './_modalChooseLanguages.scss';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../firebase/config';

export default function ModalChooseLanguages({ isOpen, onClose, changeLanguage }) {
	const { t, i18n } = useTranslation();

	const Languages = {
		ar: 'العربية',
		en: 'English',
		es: 'Español',
		fr: 'Français',
		hi: 'हिन्दी',
		sv: 'Svenska',
		default: 'English',
	};

	return (
		<Modal
			isOpen={isOpen}
			className={'modalChooseLanguages'}
			overlayClassName={'overlayModalAlbumPhoto'}
			onRequestClose={() => {
				onClose();
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt=""
				className={'crossIcon hover'}
				onClick={() => {
					onClose();
				}}
			/>

			<span className={'titleModal'}>{t('translation.language')}</span>
			<span className={'subtitleModal'}>{t('translation.instructionLanguage')}</span>

			<div className={'listItemsLanguage'}>
				{Object.keys(Languages)
					.filter((key) => key !== 'default')
					.map((key) => (
						<div onClick={() => changeLanguage(key)}>
							<ItemLanguage
								label={Languages[key]}
								isSelected={Languages[i18n.language] === Languages[key]}
								key={key}
							/>
						</div>
					))}
			</div>

			<div className={'moreComingSoon'}>{t('translation.andMoreComingSoon')}</div>
		</Modal>
	);
}

function ItemLanguage({ label, isSelected, key, changeLanguage }) {
	return (
		<div className={isSelected ? 'itemLanguageSelected' : 'itemLanguage'}>
			{label} <img src={checkVert} alt="" style={{ opacity: isSelected ? 1 : 0 }} className={'checkVert'} />
		</div>
	);
}
