import React from 'react';
import './_onboardingStep.scss';
import { BlueButton, GoBack, HeaderStep, ItemChoice } from '../components/ComponentsForm';
import personasIcon from '../../../assets/personasIcon.svg';
import companyLogo from '../../../assets/companyLogo.svg';
import { CLIENT_TYPE, ROLES, STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';

export default function ClientCompanyOrIndividual({
	setStep,
	projectName,
	role,
	setRole,
	handleNextStep,
	marginTop,
	lastStep,
}) {
	const { t } = useTranslation();

	return (
		<div className={'onboardingStep projectToJoin'} style={{ marginTop: marginTop }}>
			<GoBack lastStep={lastStep} setStep={setStep} />
			<HeaderStep
				title={t('translation.joinProjectTitleStep6')}
				subtitle={t('translation.joinProjectSubtitleStep6', { projectName: projectName })}
			/>

			<div className={'choiceList'}>
				<ItemChoice
					choiceName={t('common.physicPerson')}
					choiceTag={CLIENT_TYPE.individual}
					setChoice={setRole}
					icon={personasIcon}
					activeElement={role === CLIENT_TYPE.individual}
				/>
				<ItemChoice
					choiceName={t('common.moralPerson')}
					choiceTag={CLIENT_TYPE.company}
					setChoice={setRole}
					icon={companyLogo}
					activeElement={role === CLIENT_TYPE.company}
				/>
			</div>

			<BlueButton handleClick={handleNextStep} text={t('common.next')} />
		</div>
	);
}
