import React, { useContext, useState } from 'react';
import crossIcon from '../../../../assets/crossIcon.png';
import O_Opus from '../../../../assets/O_Opus.png';
import whiteSearchIcon from '../../../../assets/whiteSearchIcon.png';
import AgencyContext from '../../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../firebase/config';

export default function ModalNewPartnerAgency({
	isCreationCompte,
	setIsCreationCompte,
	setAccounts,
	modalFelicitationAddAgence,
	setModalFelicitationAddAgence,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);

	const [loading, setLoading] = useState(false);
	const [existingAccountId, setExistingAccountId] = useState('');
	const [existingAccountData, setExistingAccountData] = useState();

	function addExistingPartnerAgency(e) {
		e.preventDefault();

		setLoading(true);

		firestore
			.doc(`agencies/${agency}/partnerAgencies/${existingAccountId}`)
			.get()
			.then(async (documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					window.alert(t('new_partner_agency.this_account_already_linked'));
				} else {
					const data = {
						uid: existingAccountId,
						notes: '',
						date: new Date().toISOString(),
					};
					await firestore.doc(`agencies/${agency}/partnerAgencies/${existingAccountId}`).set(data);
					setAccounts((prevState) => [data, ...prevState]);
					await firestore.doc(`agencies/${existingAccountId}/partnerAgencies/${agency}`).set({
						uid: agency,
						notes: '',
						date: new Date().toISOString(),
					});
				}
			});

		setLoading(false);
		setModalFelicitationAddAgence(true);
	}

	function searchPartnerAgency() {
		firestore
			.doc(`agencies/${existingAccountId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setExistingAccountData({ ...documentSnapshot.data(), id: documentSnapshot.id });
				} else {
					window.alert(t('new_partner_agency.this_id_link_to_no_account'));
				}
			});
	}

	return (
		<div
			className={'modalCreationCompte'}
			style={{
				width: isCreationCompte ? 430 : 0,
				transform: isCreationCompte ? 'scale(1)' : 'scale(0.8)',
				height: modalFelicitationAddAgence ? 420 : 430,
			}}>
			{modalFelicitationAddAgence ? (
				<div className={'modalFelicitationAddAgence'}>
					<div className={'center'}>
						<p className={'felicitation'}>{t('new_partner_agency.congrats')}</p>

						<div className={'emailContainer'}>
							<p className={'email'}>{existingAccountData?.name}</p>
						</div>

						<p className={'detailFelicitation'}>{t('new_partner_agency.has_been_added')}</p>

						<p className={'detailFelicitation'}>{t('new_partner_agency.no_email_sent')}</p>

						<div style={{ display: 'flex', marginTop: 30 }}>
							<div
								className={'terminer'}
								onClick={() => {
									setModalFelicitationAddAgence(false);
									setIsCreationCompte(false);
									setExistingAccountId('');
									setExistingAccountData('');
								}}>
								<p className={'textTerminer'}>{t('common.finish')}</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={'contentCreationPartnerAgency'} style={{ opacity: isCreationCompte ? 1 : 0 }}>
					<div style={{ display: 'flex' }}>
						<div style={{ width: 400 }}>
							<p className={'titleModal'}>{t('new_partner_agency.invite_a_new_agency')}</p>
						</div>
						<img
							src={crossIcon}
							alt="crossIcon"
							className={'crossIcon'}
							style={{ marginTop: -20 }}
							onClick={() => setIsCreationCompte(false)}
						/>
					</div>

					<p className={'titleModal'} style={{ fontSize: 16 }}>
						{t('new_partner_agency.agency_not_client')}
					</p>
					<p className={'subtitleModal'}>{t('new_partner_agency.invite_a_partenaire')}</p>

					<p className={'titleModal'} style={{ fontSize: 16 }}>
						{t('new_partner_agency.agency_is_user')}
					</p>
					<p className={'subtitleModal'}>{t('new_partner_agency.type_the_id')}</p>

					<div style={{ display: 'flex' }}>
						<input
							type="text"
							className={'input'}
							placeholder={t('new_partner_agency.account_id')}
							value={existingAccountId}
							onChange={(e) => setExistingAccountId(e.target.value)}
						/>
						<div
							className={'searchButton'}
							style={{ opacity: existingAccountId.length !== 28 ? 0.3 : 1 }}
							onClick={searchPartnerAgency}>
							<img src={whiteSearchIcon} alt="whiteSearchIcon" className={'whiteSearchIcon'} />
						</div>
					</div>

					{existingAccountData ? (
						<div style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
							<img
								src={
									existingAccountData.imgUrl && !existingAccountData.imgUrl.includes('/static/media/')
										? existingAccountData.imgUrl
										: O_Opus
								}
								className={'profilePicture'}
							/>
							<div style={{ width: 185 }}>
								<div className={'blankName'} style={{ backgroundColor: '#FFF', fontWeight: 600 }}>
									{existingAccountData.name}
								</div>
								<div className={'blankMailAdress'} style={{ backgroundColor: '#FFF', fontSize: 13 }}>
									{existingAccountData.email}
								</div>
							</div>
							<button
								className={'blankButtonAdd'}
								style={{ border: 'solid 0px', fontWeight: 700 }}
								onClick={addExistingPartnerAgency}>
								{t('common.add')}
							</button>
						</div>
					) : (
						<div style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
							<div className={'profilePicture'} />
							<div style={{ width: 185 }}>
								<div className={'blankName'} />
								<div className={'blankMailAdress'} />
							</div>
							<div className={'blankButtonAdd'} />
						</div>
					)}
				</div>
			)}
		</div>
	);
}
