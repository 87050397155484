import React, { useEffect, useRef, useState, useContext } from 'react';
import './_modalAddVersion.scss';
import crossIcon from '../../../../../../../assets/crossIcon.png';
import Modal from 'react-modal';
import plusSquare from '../../../../../../../assets/plusSquare.svg';
import { chooseIconFile } from '../../../../utils';
import { storage, auth, firestore } from '../../../../../../../firebase/config';
import { generateUniqueFirestoreId, joinPaths } from '../../../../../../../firebase/utils';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../../../../contexts/projectContext';
import { Paths } from '../../../../../../../firebase/paths';
import { createdByConstructor } from '../../../../../utils';
import TypeContext from '../../../../../../../contexts/typeContext';
import AgencyContext from '../../../../../../../contexts/agencyContext';
import { usePartnersContext } from 'src/contexts/partnersContext';
import { useClientsContext } from 'src/contexts/clientsContext';
import { getFirstLetters, normalizeString } from 'src/useful/UsefulFunctions';
import ItemAccessibility from '../../components/ItemAccessibility';
import PropTypes from 'prop-types';
import { NotificationSource } from 'src/pages/Fiche Projet/Notifications/utils';
import { sendNotificationToCollaborators } from 'src/pages/Fiche Projet/Notifications/utils';
import { sendNotificationToClients } from 'src/pages/Fiche Projet/Notifications/utils';
import AuthDataContext from 'src/contexts/authDataContext';

Modal.setAppElement('#root');

ModalAddVersion.propTypes = {
	modalAddVersion: PropTypes.bool,
	setModalAddVersion: PropTypes.func,
	file: PropTypes.object,
	parentFolder: PropTypes.object,
};

export default function ModalAddVersion({ modalAddVersion, setModalAddVersion, file, parentFolder }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [partners] = usePartnersContext();
	const [clients] = useClientsContext();
	const authData = useContext(AuthDataContext);

	const [isLoading, setIsLoading] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [versionName, setVersionName] = useState('');
	const [notes, setNotes] = useState('');
	const [selectedVersionIndex, setSelectedVersionIndex] = useState('');

	const fileInputRef = useRef(null);
	const inputNameRef = useRef(null);

	useEffect(() => {
		if (file) {
			setVersionName(file.name);
			const lastVersionIndex = file.versions?.at(-1)?.versionIndex;
			const nextVersionIndex = options.findIndex((option) => option.value === lastVersionIndex) + 1;
			setSelectedVersionIndex(options[nextVersionIndex].value);
		}
	}, [file]);

	const handleChange = (event) => {
		setSelectedVersionIndex(event.target.value);
	};

	const handleDetailsChange = (event) => {
		setNotes(event.target.value);
	};

	const handleFileChose = (event) => {
		const newfile = event.target.files[0];
		if (!newfile) {
			return;
		}
		setUploadedFile(newfile);
	};

	const handlePublish = async () => {
		setIsLoading(true);

		const firestoreId = generateUniqueFirestoreId();

		const fileRef = storage.ref(joinPaths(Paths.PROJECTS, project.id, Paths.FILES, firestoreId));

		const url = await fileRef.put(uploadedFile).then(async (snapshot) => {
			return await snapshot.ref.getDownloadURL();
		});

		const version = {
			clientPermission: file.clientPermission || false,
			clientPermissionReadOnly: file.clientPermissionReadOnly || false,
			createdAt: new Date().toISOString(),
			createdBy: file.createdBy || createdByConstructor(uid, userType, agencyId),
			deletedAt: null,
			id: firestoreId,
			isFavorite: false,
			isFolder: false,
			isVersion: true,
			modifiedAt: new Date().toISOString(),
			name: versionName,
			normalizedName: normalizeString(versionName),
			notes: notes ?? '',
			parentId: file.id,
			path: file.path || '/',
			permissions: file.permissions || [agencyId],
			permissionsReadOnly: file.permissionsReadOnly || [],
			previousPath: null,
			projectId: project.id,
			seenBy: [{ userId: uid, seenAt: new Date().toISOString() }],
			size: uploadedFile.size,
			type: uploadedFile.type,
			url: url,
			versionIndex: selectedVersionIndex,
		};

		await firestore
			.collection(joinPaths(Paths.PROJECTS, project.id, Paths.FILES))
			.doc(firestoreId)
			.set(version);

		if (String(selectedVersionIndex) >= String(file.lastVersionIndex)) {
			await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.FILES, file.id)).update({
				lastVersionIndex: selectedVersionIndex,
				modifiedAt: version.modifiedAt,
				name: version.name,
				normalizedName: version.normalizedName,
				notes: version.notes,
				seenBy: version.seenBy,
				size: version.size,
				type: version.type,
				url: version.url,
			});
		} else {
			await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.FILES, file.id)).update({
				modifiedAt: version.modifiedAt,
			});
		}

		setIsLoading(false);
		setModalAddVersion(false);
		setUploadedFile(null);
		setVersionName('');
		setSelectedVersionIndex('');
		setNotes('');

		if (file.clientPermission) {
			await sendNotificationToClients(
				createdByConstructor(uid, userType, agencyId),
				project.id,
				NotificationSource.FILES,
				parentFolder,
				[version],
				authData?.surname + ' ' + authData?.name,
				project?.name,
				project?.imgUrl
			);
			for (const client of clients) {
				if (client.type === 'agencies') {
					sendNotificationToCollaborators(
						createdByConstructor(uid, userType, agencyId),
						client.id,
						project.id,
						NotificationSource.FILES,
						parentFolder,
						[version],
						authData?.surname + ' ' + authData?.name,
						project?.name,
						project?.imgUrl
					);
				}
			}
		}

		for (const partner of file.permissions) {
			if (partner !== agencyId) {
				sendNotificationToCollaborators(
					createdByConstructor(uid, userType, agencyId),
					partner,
					project.id,
					NotificationSource.FILES,
					parentFolder,
					[version],
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}
		}
	};

	return (
		<Modal
			isOpen={modalAddVersion}
			className={'modalAddVersion'}
			overlayClassName="overlayModalMoveItem"
			closeTimeoutMS={300}
			onAfterOpen={() => {
				if (fileInputRef.current) {
					fileInputRef.current.click();
				}
			}}
			onRequestClose={() => setModalAddVersion(false)}
			shouldCloseOnOverlayClick={true}>
			<div className={'contentModalAddVersion'}>
				<img src={crossIcon} alt="" className={'crossIcon'} onClick={() => setModalAddVersion(false)} />

				<p className={'title'}>{t('translation.addNewDocumentVersion')}</p>

				<label htmlFor="inputFileRef" style={{ display: 'none' }}>
					<div className={'importFile'}>
						<img src={plusSquare} alt="" className={'plusSquare'} />
						{t('translation.importNewVersion')}
					</div>
				</label>

				<div className={'documentJustImported'}>
					<img src={chooseIconFile(uploadedFile?.type ?? file?.type)} alt="" className={'pdfIcon'} />
					<input
						ref={inputNameRef}
						type="text"
						value={versionName}
						className={'inputFileName'}
						onChange={(e) => {
							setVersionName(e.target.value);
							e.target.style.width = 'auto';
							e.target.style.width = `${e.target.scrollWidth}px`;
						}}
					/>

					<div className={'custom-select-container'}>
						<select value={selectedVersionIndex} onChange={handleChange} className={'custom-select'}>
							<option value="">{t('translation.version')}</option>
							{options
								.filter(
									(option) =>
										!file?.versions?.some(
											(fileVersion) => fileVersion.versionIndex === option.value
										)
								)
								.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
						</select>
					</div>
				</div>

				<input
					id={'inputFileRef'}
					type="file"
					style={{ display: 'none' }}
					onChange={handleFileChose}
					ref={fileInputRef}
				/>

				<p className={'littleTitle'}>{t('translation.noteDescriptive')}</p>

				<textarea
					name=""
					id=""
					cols="30"
					rows="10"
					placeholder={t('translation.noteDescriptive')}
					className={'noteDescriptive'}
					onChange={handleDetailsChange}></textarea>

				<button
					className={'buttonSave'}
					onClick={handlePublish}
					disabled={isLoading || !uploadedFile || !versionName || !selectedVersionIndex}>
					{t('translation.publish')}
				</button>

				{userType !== 'clients' && !clients?.map((it) => it.id).includes(agencyId) && (
					<div className={'modalAddVersion__accessContainer'}>
						<p className={'modalAddVersion__accessTitle'}>{t('translation.accessibility')}</p>

						<ItemAccessibility
							name={t('common.mo')}
							permission={
								file.clientPermissionReadOnly ? 'readonly' : file.clientPermission ? 'all' : 'hidden'
							}
							disabled={true}
							diminutif={t('translation.terms.0.acronym')}
						/>

						{clients.length === 0 && partners.length === 0 && (
							<p className={'modalAddVersion__noPartners'}>{t('translation.addMoAndPartners')}</p>
						)}

						{userType !== 'clients' && clients.length > 0 && partners.length === 0 && (
							<p className={'modalAddVersion__noPartners'}>{t('translation.addPartners')}</p>
						)}

						{partners.map((it) => (
							<ItemAccessibility
								key={it.id}
								name={it.name}
								permission={
									file.permissionsReadOnly?.includes(it.id)
										? 'readonly'
										: file.permissions?.includes(it.id)
											? 'all'
											: 'hidden'
								}
								disabled={true}
								diminutif={it.diminutifAgency ?? getFirstLetters(it.name)}
							/>
						))}
					</div>
				)}
			</div>

			{isLoading && (
				<div className="deletion-overlay">
					<div className="deletion-spinner"></div>
					<p>{t('translation.publicationInProgress')}</p>
				</div>
			)}
		</Modal>
	);
}

const options = [
	{ value: '0', label: '0' },
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' },
	{ value: 'E', label: 'E' },
	{ value: 'F', label: 'F' },
	{ value: 'G', label: 'G' },
	{ value: 'H', label: 'H' },
	{ value: 'I', label: 'I' },
	{ value: 'J', label: 'J' },
	{ value: 'K', label: 'K' },
	{ value: 'L', label: 'L' },
	{ value: 'M', label: 'M' },
	{ value: 'N', label: 'N' },
	{ value: 'O', label: 'O' },
	{ value: 'P', label: 'P' },
	{ value: 'Q', label: 'Q' },
	{ value: 'R', label: 'R' },
	{ value: 'S', label: 'S' },
	{ value: 'T', label: 'T' },
	{ value: 'U', label: 'U' },
	{ value: 'V', label: 'V' },
	{ value: 'W', label: 'W' },
	{ value: 'X', label: 'X' },
	{ value: 'Y', label: 'Y' },
	{ value: 'Z', label: 'Z' },
];
