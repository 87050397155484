import { generateUniqueFirestoreId, getPathStorageFromUrl, joinPaths } from '../firebase/utils';
import { firestore, storage } from '../firebase/config';
import Resizer from 'react-image-file-resizer';
import O_Opus from '../assets/O_Opus.png';
import { FILE_COLLECTION, MEDIA_COLLECTION, Paths, PROJECT_COLLECTION } from '../firebase/paths';
import { arrayRemove } from 'firebase/firestore';
import { normalizeString } from './UsefulFunctions';
import { ProgressionStatus } from '../pages/Fiche Projet/Progression/utils';
import mixpanel from 'mixpanel-browser';

export async function createProject(auth, agency, projectId, imgFile, name, endFunction, t) {
	const helper = async (file) => {
		const date = new Date().toISOString();
		const doc = firestore.doc(`projects/${projectId}`);

		if (file) {
			const storageRef = storage.ref(`projects/${projectId}/pictures`);

			const fileRef = storageRef.child(generateUniqueFirestoreId());
			await fileRef.put(file);
			const imgUrl = await fileRef.getDownloadURL();

			const fileRef1 = storageRef.child(generateUniqueFirestoreId());
			await fileRef1.put(file);
			const imgUrl1 = await fileRef1.getDownloadURL();

			const fileRef2 = storageRef.child(generateUniqueFirestoreId());
			await fileRef2.put(file);
			const imgUrl2 = await fileRef2.getDownloadURL();

			const fileRef3 = storageRef.child(generateUniqueFirestoreId());
			await fileRef3.put(file);
			const imgUrl3 = await fileRef3.getDownloadURL();

			await doc.set({
				name,
				imgUrl: imgUrl,
				imgUrl1: imgUrl1,
				imgUrl2: imgUrl2,
				imgUrl3: imgUrl3,
				start: new Date().toISOString(),
				end: new Date().toISOString(),
				budget: 0,
				date,
				active: true,
				progression: 10,
				payed: 0,
				total: 0,
				creator: agency,
				uid: auth,
				notes: '',
				address: '',
				finishDate: null,
				tag: name,
				ttc: false,
				currency: '€',
				withoutFinance: false,
			});
		} else {
			await doc.set({
				name,
				imgUrl: O_Opus,
				imgUrl1: null,
				imgUrl2: null,
				imgUrl3: null,
				start: new Date().toISOString(),
				end: new Date().toISOString(),
				budget: 0,
				date,
				active: true,
				progression: 0,
				payed: 0,
				total: 0,
				creator: agency,
				uid: auth,
				notes: '',
				address: '',
				finishDate: null,
				tag: name,
				ttc: false,
				currency: '€',
				withoutFinance: false,
			});
		}

		await addDefaultProgressTemplate(t, projectId, agency, auth, () => null);
		await addDefaultAlbumsTemplate(projectId, agency, auth, t);
		await addDefaultFilesTemplates(t, projectId, agency, auth, () => null);

		await firestore
			.doc(`agencies/${agency}/projects/${projectId}`)
			.set({ id: projectId, own: true, name, tag: name });
		await firestore.doc(`projects/${projectId}/accounts/${auth}`).set({
			uid: auth,
			type: 'collaborators',
			agency: agency,
		});
		await firestore.doc(`projects/${projectId}/accounts/${agency}`).set({
			uid: agency,
			type: 'agencies',
		});
		await firestore.doc(`agencies/${agency}/collaborators/${auth}/projects/${projectId}`).set({
			id: projectId,
			lastClick: date,
			seen: true,
			notifMessage: true,
			notifNewEvent: true,
			notifSupprEvent: true,
			notifModifEvent: true,
			notifPhoto: false,
			notifAlbum: false,
			notifFinance: false,
			notifProgression: false,
			active: true,
			name,
			tag: name,
		});

		await firestore
			.doc(`messaging/${projectId}`)
			.set({ id: projectId, parentId: projectId, parentType: 'project', isGeneral: true });

		await firestore.doc(`messaging/${projectId}/participants/${auth}`).set({ id: auth, uid: auth });

		await firestore.doc(`users/${auth}/messaging/${projectId}`).set({
			id: projectId,
			lastClickAt: null,
			lastMessageAt: new Date().toISOString(),
			isDeleted: false,
			isGroup: true,
			isNotificationsOff: false,
			parentId: projectId,
			isUnread: false,
			firstName: '',
			lastName: '',
			name: '',
			email: '',
			phone: '',
			userAgencyId: '',
			userType: '',
			isGeneral: true,
		});
	};

	if (imgFile) {
		Resizer.imageFileResizer(
			imgFile,
			2000,
			2000,
			'jpeg',
			50,
			0,
			async (file) => {
				await helper(file);
				endFunction();
				mixpanel.track('Project Created', {
					'Project ID': projectId,
					'Company ID': agency,
					'With image': true,
				});
			},
			'file'
		);
	} else {
		await helper(null);
		endFunction();
		mixpanel.track('Project Created', {
			'Project ID': projectId,
			'Company ID': agency,
			'With image': false,
		});
	}
}

export async function deleteCollection(
	collectionWithCondition,
	collectionWithoutCondition,
	deleteStorage1 = false,
	attributUrl1,
	deleteStorage2 = false,
	attributUrl2
) {
	try {
		const querySnapshot = (await collectionWithCondition.get()).docs;
		const batch = firestore.batch();
		for (const documentSnapshot of querySnapshot) {
			if (documentSnapshot && documentSnapshot.exists) {
				if (deleteStorage1) {
					if (documentSnapshot.data()[attributUrl1]) {
						await storage.ref(getPathStorageFromUrl(documentSnapshot.data()[attributUrl1])).delete();
					}
				}
				if (deleteStorage2) {
					if (documentSnapshot.data()[attributUrl2]) {
						await storage.ref(getPathStorageFromUrl(documentSnapshot.data()[attributUrl2])).delete();
					}
				}
				batch.delete(documentSnapshot.ref);
			}
		}
	} catch (error) {
		console.error(error);
		alert('An error occurred while deleting the data.'); // TODO: [Low] translate
	}
}

export async function deleteMessaging(pathWithCondition, pathWithoutCondition) {
	pathWithCondition.get().then((querySnapshot) => {
		if (querySnapshot) {
			querySnapshot.forEach(async (documentSnapshot) => {
				await deleteCollection(
					pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
					pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
					true,
					'contents'
				);
				await deleteCollection(
					pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
					pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
					true,
					'contents'
				);
				await deleteCollection(
					pathWithoutCondition.doc(documentSnapshot.id).collection('messages'),
					pathWithoutCondition.doc(documentSnapshot.id).collection('messages')
				);
				await deleteCollection(pathWithCondition, pathWithoutCondition);
			});
		}
	});
}

export async function deleteMessagingProject(uid, type, agency, projectId, localAgency) {
	firestore
		.collection(`projects/${projectId}/accounts`)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach(async (documentSnapshot) => {
				const uidOther = documentSnapshot.data().uid;
				const typeOther = documentSnapshot.data().type;
				const agencyOther = documentSnapshot.data().agency;
				if (typeOther !== 'agencies' && (type === 'clients' || agencyOther !== agency)) {
					const projectIds = (
						await firestore
							.doc(
								type === 'collaborators'
									? `agencies/${agency}/collaborators/${uid}/messaging/${uidOther}`
									: `clients/${uid}/messaging/${uidOther}`
							)
							.get()
					).data().projectIds;
					const path1 = firestore.doc(
						type === 'collaborators'
							? `agencies/${agency}/collaborators/${uid}/messaging/${uidOther}`
							: `clients/${uid}/messaging/${uidOther}`
					);
					const path2 = firestore.doc(
						typeOther === 'collaborators'
							? `agencies/${agencyOther}/collaborators/${uidOther}/messaging/${uid}`
							: `clients/${uidOther}/messaging/${uid}`
					);

					if (projectIds && projectIds.length > 1) {
						await path1.update({ projectIds: arrayRemove(projectId) });
						await path2.update({ projectIds: arrayRemove(projectId) });
					} else {
						if (type === 'collaborators') {
							if (agency === localAgency) {
								await deleteCollection(
									path1.collection('pictures'),
									path1.collection('pictures'),
									true,
									'contents'
								);
								await deleteCollection(
									path1.collection('files'),
									path1.collection('files'),
									true,
									'contents'
								);
								await deleteCollection(path1.collection('messages'), path1.collection('messages'));
								await path1.delete();

								await path2.update({
									projectIds: arrayRemove(projectId),
								});
							} else {
								await path1.update({
									projectIds: arrayRemove(projectId),
								});
								if (agencyOther === localAgency) {
									await deleteCollection(
										path2.collection('pictures'),
										path2.collection('pictures'),
										true,
										'contents'
									);
									await deleteCollection(
										path2.collection('files'),
										path2.collection('files'),
										true,
										'contents'
									);
									await deleteCollection(path2.collection('messages'), path2.collection('messages'));
									await path2.delete();
								} else {
									await path2.update({
										projectIds: arrayRemove(projectId),
									});
								}
							}
						} else if (type === 'clients') {
							await path1.update({ projectIds: arrayRemove(projectId) });

							if (typeOther === 'collaborators' && agencyOther === localAgency) {
								await deleteCollection(
									path2.collection('pictures'),
									path2.collection('pictures'),
									true,
									'contents'
								);
								await deleteCollection(
									path2.collection('files'),
									path2.collection('files'),
									true,
									'contents'
								);
								await deleteCollection(path2.collection('messages'), path2.collection('messages'));
								await path2.delete();
							} else {
								await path2.update({
									projectIds: arrayRemove(projectId),
								});
							}
						}
					}
				}
			});
		});
}

export async function deleteUserInProject(uid, type, agency, projectId, localAgency) {
	const pathProject = firestore.doc(
		type === 'collaborators'
			? `agencies/${agency}/collaborators/${uid}/projects/${projectId}`
			: `clients/${uid}/projects/${projectId}`
	);

	await firestore.doc(`projects/${projectId}/accounts/${uid}`).delete();
	await pathProject.delete();

	firestore
		.collection(`users/${uid}/messaging`)
		.where('parentId', '==', projectId)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((documentSnapshot) => {
				if (documentSnapshot.exists) {
					documentSnapshot.ref.delete();
					firestore.doc(`messaging/${documentSnapshot.id}/participants/${uid}`).delete();
				}
			});
		});
}

// Templates par défaut --------------------------------------------------------

export async function addDefaultProgressTemplate(t, projectId, agency, uid) {
	const phases = [
		{
			data: {
				notes: '',
				name: t('translation.templateProg1'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime()).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg11'),
					date: new Date(0).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg12'),
					date: new Date(new Date().getTime() + 1).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg13'),
					date: new Date(new Date().getTime() + 2).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg14'),
					date: new Date(new Date().getTime() + 3).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg15'),
					date: new Date(new Date().getTime() + 4).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
			],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg2'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 1).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg21'),
					date: new Date(0).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg22'),
					date: new Date(new Date().getTime() + 1).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
			],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg3'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 2).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg31'),
					date: new Date(0).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg32'),
					date: new Date(new Date().getTime() + 1).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg33'),
					date: new Date(new Date().getTime() + 2).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg34'),
					date: new Date(new Date().getTime() + 3).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
			],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg4'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 3).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg5'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 4).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg6'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 5).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg7'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 6).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg71'),
					date: new Date(0).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg72'),
					date: new Date(new Date().getTime() + 1).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg73'),
					date: new Date(new Date().getTime() + 2).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg74'),
					date: new Date(new Date().getTime() + 3).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg75'),
					date: new Date(new Date().getTime() + 4).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
			],
		},
		{
			data: {
				notes: '',
				name: t('translation.templateProg8'),
				startAt: null,
				endAt: null,
				status: ProgressionStatus.NOT_STARTED,
				date: new Date(new Date().getTime() + 7).toISOString(),
				id: generateUniqueFirestoreId(),
				agency,
				uid,
				type: 'phase',
			},
			stepsList: [
				{
					id: generateUniqueFirestoreId(),
					name: t('translation.templateProg81'),
					date: new Date(0).toISOString(),
					agency,
					uid,
					status: ProgressionStatus.NOT_STARTED,
					type: 'step',
				},
			],
		},
	];

	const batch = firestore.batch();
	for (const phase of phases) {
		batch.set(firestore.doc(joinPaths(Paths.PROJECTS, projectId, Paths.PROGRESSION, phase.data.id)), phase.data);
		for (const step of phase.stepsList) {
			batch.set(firestore.doc(`${Paths.PROJECTS}/${projectId}/${Paths.PROGRESSION}/${step.id}`), {
				...step,
				status: ProgressionStatus.NOT_STARTED,
				phaseId: phase.data.id,
			});
		}
	}

	await batch.commit();
}

export async function addDefaultAlbumsTemplate(projectId, agency, uid, t) {
	const albums = [
		{
			albumId: null,
			clientPermission: true,
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: 'collaborator',
				agencyId: agency,
			},
			deletedAt: null,
			fileType: null,
			fileSize: 0,
			id: generateUniqueFirestoreId(),
			isFavorite: false,
			modifiedAt: new Date().toISOString(),
			orderDate: new Date().toISOString(),
			noPermissions: [],
			previousAlbumId: null,
			type: 'album',
			url: null,
			projectId,
			name: t('translation.proposals'),
		},
		{
			albumId: null,
			clientPermission: true,
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: 'collaborator',
				agencyId: agency,
			},
			deletedAt: null,
			fileType: null,
			fileSize: 0,
			id: generateUniqueFirestoreId(),
			isFavorite: false,
			modifiedAt: new Date().toISOString(),
			orderDate: new Date().toISOString(),
			noPermissions: [],
			previousAlbumId: null,
			type: 'album',
			url: null,
			projectId,
			name: t('translation.studies'),
		},
		{
			albumId: null,
			clientPermission: true,
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: 'collaborator',
				agencyId: agency,
			},
			deletedAt: null,
			fileType: null,
			fileSize: 0,
			id: generateUniqueFirestoreId(),
			isFavorite: false,
			modifiedAt: new Date().toISOString(),
			orderDate: new Date().toISOString(),
			noPermissions: [],
			previousAlbumId: null,
			type: 'album',
			url: null,
			projectId,
			name: t('translation.realisations'),
		},
		{
			albumId: null,
			clientPermission: true,
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: 'collaborator',
				agencyId: agency,
			},
			deletedAt: null,
			fileType: null,
			fileSize: 0,
			id: generateUniqueFirestoreId(),
			isFavorite: false,
			modifiedAt: new Date().toISOString(),
			orderDate: new Date().toISOString(),
			noPermissions: [],
			previousAlbumId: null,
			type: 'album',
			url: null,
			projectId,
			name: t('translation.work'),
		},
		{
			albumId: null,
			clientPermission: true,
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: 'collaborator',
				agencyId: agency,
			},
			deletedAt: null,
			fileType: null,
			fileSize: 0,
			id: generateUniqueFirestoreId(),
			isFavorite: false,
			modifiedAt: new Date().toISOString(),
			orderDate: new Date().toISOString(),
			noPermissions: [],
			previousAlbumId: null,
			type: 'album',
			url: null,
			projectId,
			name: t('translation.existing'),
		},
	];

	for (const album of albums) {
		await firestore
			.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${album.id}`)
			.set({ ...album, orderDate: new Date().toISOString() });
	}

	return albums.reverse();
}

export async function addDefaultFilesTemplates(t, projectId, agency, uid) {
	const id1 = generateUniqueFirestoreId();
	const id2 = generateUniqueFirestoreId();
	const id3 = generateUniqueFirestoreId();
	const id4 = generateUniqueFirestoreId();
	const id5 = generateUniqueFirestoreId();
	const id6 = generateUniqueFirestoreId();
	const id7 = generateUniqueFirestoreId();

	const defaultFile = {
		clientPermission: false,
		createdAt: new Date().toISOString(),
		createdBy: {
			userId: uid,
			userType: 'collaborator',
			agencyId: agency,
		},
		deletedAt: null,
		id: '',
		isFavorite: false,
		isFolder: true,
		modifiedAt: new Date().toISOString(),
		name: '',
		normalizedName: '',
		path: '/',
		permissions: [agency],
		previousPath: null,
		projectId,
		seenBy: [{ userId: uid, seenAt: new Date().toISOString() }],
		size: null,
		type: null,
		url: null,
	};

	const files = [
		{
			...defaultFile,
			id: id1,
			name: t('translation.templateFiles1'),
			normalizedName: normalizeString(t('translation.templateFiles1')),
		},
		{
			...defaultFile,
			id: id2,
			name: t('translation.templateFiles2'),
			normalizedName: normalizeString(t('translation.templateFiles2')),
		},
		{
			...defaultFile,
			id: id3,
			name: t('translation.templateFiles3'),
			normalizedName: normalizeString(t('translation.templateFiles3')),
		},
		{
			...defaultFile,
			id: id4,
			name: t('translation.templateFiles4'),
			normalizedName: normalizeString(t('translation.templateFiles4')),
		},
		{
			...defaultFile,
			id: id5,
			name: t('translation.templateFiles5'),
			normalizedName: normalizeString(t('translation.templateFiles5')),
		},
		{
			...defaultFile,
			id: id6,
			name: t('translation.templateFiles6'),
			normalizedName: normalizeString(t('translation.templateFiles6')),
		},
		{
			...defaultFile,
			id: id7,
			name: t('translation.templateFiles7'),
			normalizedName: normalizeString(t('translation.templateFiles7')),
		},
	];

	for (const file of files) {
		await firestore
			.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`)
			.set({ ...file, modifiedAt: new Date().toISOString() });
	}
}
