import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import printer from '../../../assets/printer.svg';
import crossIcon from '../../../assets/crossIcon.png';
import './_soloSnagReport.scss';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../contexts/projectContext';
import SnagPage from '../Multi Snag Report/Snag Page/SnagPage';
import ResolutionPage from '../Multi Snag Report/Resolution Page/ResolutionPage';
import { firestore } from '../../../firebase/config';
import { joinPaths } from '../../../firebase/utils';
import { Paths } from '../../../firebase/paths';
import LogsPage from '../Multi Snag Report/Snag Page/Logs Page/LogsPage';
import { getTicketStatus } from '../../Fiche Projet/Tickets/utils';
import AgencyContext from '../../../contexts/agencyContext';
import { useClientsContext } from '../../../contexts/clientsContext';
import TypeContext from '../../../contexts/typeContext';
import { chunkArray } from 'src/useful/utils';

export default function ModalPrintSnag({ isOpen, onClose, ticket }) {
	const { t, i18n } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const [clients] = useClientsContext();
	const type = useContext(TypeContext);
	const [project] = useProjectContext();

	const printRef = useRef();

	const [resolutions, setResolutions] = useState([]);
	const [logs, setLogs] = useState([]);
	const [status, setStatus] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const handlePrint = useReactToPrint({
		contentRef: printRef,
		documentTitle: `${project.name}-${t('translation.snagListReport')}-${new Date().toLocaleDateString(
			i18n.language,
			{
				month: 'numeric',
				day: 'numeric',
				year: 'numeric',
			}
		)}`,
	});

	const handlePrintClick = () => {
		handlePrint();
	};

	// ================================================ STATUS ================================================

	useEffect(() => {
		const isClient = type === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (ticket?.properties?.type === 'issue' && (isClient || agencyId)) {
			setStatus(getTicketStatus(ticket, isClient ? 'clients' : agencyId));
		}
	}, [agencyId, ticket, clients, type]);

	// ================================================ RESOLUTIONS ================================================

	useEffect(() => {
		if (ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.RESOLUTIONS))
				.orderBy('createdAt', 'asc')
				.onSnapshot((snapshot) => {
					if (snapshot && snapshot.docs.length > 0) {
						setResolutions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
					} else {
						setResolutions([]);
					}
				});
			return () => unsubscribe();
		}
	}, [ticket?.id]);

	// ================================================ LOGS ================================================

	useEffect(() => {
		if (project?.id && ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.LOGS))
				.orderBy('createdAt', 'desc')
				.onSnapshot((snapshot) => {
					if (snapshot.empty) {
						setLogs([]);
					} else {
						setLogs(snapshot.docs.map((doc) => doc.data()));
					}
					setIsLoading(false);
				});
			return () => unsubscribe();
		}
	}, [project?.id, ticket?.id]);

	return (
		<Modal isOpen={isOpen} closeTimeoutMS={300} className={'page'}>
			<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 25 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '210mm', alignItems: 'center' }}>
					<div style={{ width: 25 }} />
					<span style={{ height: 50 }} onClick={handlePrintClick} className={'buttonPrint hover'}>
						<img src={printer} alt="" /> {t('translation.printReport')}
					</span>
					<img src={crossIcon} alt="" className={'crossIcon hover'} onClick={onClose} />
				</div>

				<div className={'largeBar'} />
			</div>
			<div className={'containerPage'} ref={printRef}>
				<div className={'pdfPage'}>
					<SnagPage ticket={ticket} index={1} ticketsLength={1} isLarge={true} />
				</div>
				{resolutions.length > 0 ? (
					resolutions.map((item, index) => (
						<div className={'pdfPage'}>
							<ResolutionPage
								ticket={ticket}
								index={1}
								ticketsLength={1}
								isLarge={true}
								reviewers={ticket?.properties?.reviewers}
								resolution={item}
								reviews={item.reviews}
								status={status}
							/>
						</div>
					))
				) : (
					<div className={'pdfPage'}>
						<ResolutionPage
							ticket={ticket}
							index={1}
							ticketsLength={1}
							isLarge={true}
							blank={true}
							status={status}
						/>
					</div>
				)}

				{logs.length > 0 && (
					<>
						<div className={'pdfPage'}>
							<LogsPage logs={logs.slice(0, 15)} ticket={ticket} justLogs={false} status={status} />
						</div>
						{chunkArray(logs.slice(15), 17).map((chunk, index) => (
							<div key={index} className={'pdfPage'}>
								<LogsPage logs={chunk} ticket={ticket} justLogs={true} status={status} />
							</div>
						))}
					</>
				)}
			</div>
		</Modal>
	);
}
