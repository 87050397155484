import { createContext, useContext } from 'react';

const ProjectUsersContext = createContext();

export function useProjectUsersContext() {
	const context = useContext(ProjectUsersContext);
	if (!context) {
		throw new Error('useProjectUsersContext must be used within a ProjectUsersProvider');
	}
	return context;
}

export default ProjectUsersContext;
