import Resizer from 'react-image-file-resizer';
import heic2any from 'heic2any';

/**
 * Converts HEIC/HEIF image format to JPEG
 * @param {File} file - The image file to convert
 * @returns {Promise<File>} Converted JPEG file or original file if not HEIC/HEIF
 * @throws {Error} When HEIC conversion fails
 */
export const convertHeicToJpeg = async (file) => {
	if (file.type === 'image/heic' || file.type === 'image/heif') {
		try {
			const convertedBlob = await heic2any({
				blob: file,
				toType: 'image/jpeg',
				quality: 1,
			});

			// Handle both single blob and array of blobs
			const singleBlob = Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob;
			return new File([singleBlob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), { type: 'image/jpeg' });
		} catch (error) {
			console.error('Error converting HEIC:', error);
			throw new Error('HEIC format not supported');
		}
	}
	return file;
};

/**
 * Retrieves the dimensions of an image file
 * @param {File} file - The image file to measure
 * @returns {Promise<{width: number, height: number}>} Object containing image dimensions
 * @throws {Error} When image loading fails
 */
export const getImageDimensions = (file) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			URL.revokeObjectURL(img.src); // Clean up object URL
			resolve({ width: img.width, height: img.height });
		};
		img.onerror = (error) => {
			URL.revokeObjectURL(img.src); // Clean up object URL
			reject(new Error('Failed to load image'));
		};
		img.src = URL.createObjectURL(file);
	});
};

/**
 * Determines if an image needs resizing based on file size and dimensions
 * @param {File} file - The image file to check
 * @returns {Promise<boolean>} True if image needs resizing, false otherwise
 * @throws {Error} When dimension check fails
 */
export const shouldResizeImage = async (file) => {
	try {
		// Skip if file is smaller than 1MB
		if (file.size < 1024 * 1024) {
			return false;
		}

		const dimensions = await getImageDimensions(file);
		const maxDimension = Math.max(dimensions.width, dimensions.height);
		return maxDimension > 3000; // Resize if larger than 3000px
	} catch (error) {
		console.error('Error checking image dimensions:', error);
		throw new Error('Failed to determine if image should be resized');
	}
};

/**
 * Resizes an image while maintaining aspect ratio
 * Converts HEIC/HEIF to JPEG if necessary
 * Maximum dimension is set to 3000px
 * @param {File} file - The image file to resize
 * @returns {Promise<File>} Resized image file
 * @throws {Error} When conversion or resizing fails
 */
export const resizeImage = async (file) => {
	try {
		const convertedFile = await convertHeicToJpeg(file);

		if (!(await shouldResizeImage(convertedFile))) {
			return convertedFile;
		}

		// Calculate target dimensions maintaining aspect ratio
		const { width, height } = await getImageDimensions(convertedFile);
		const isLandscape = width > height;

		let targetWidth, targetHeight;
		if (isLandscape) {
			targetWidth = 3000;
			targetHeight = Math.round((height * 3000) / width);
		} else {
			targetHeight = 3000;
			targetWidth = Math.round((width * 3000) / height);
		}

		// Perform image resizing
		return new Promise((resolve, reject) => {
			Resizer.imageFileResizer(
				convertedFile,
				targetWidth,
				targetHeight,
				'JPEG',
				80, // Quality
				0, // Rotation
				(uri) => {
					if (uri instanceof Error) {
						reject(new Error('Failed to resize image'));
					} else {
						resolve(uri);
					}
				},
				'file',
				targetWidth,
				targetHeight
			);
		});
	} catch (error) {
		console.error('Error resizing image:', error);
		throw new Error('Failed to resize image');
	}
};
