import React, { useState, useEffect } from 'react';
import './_ticketDocument.scss';
import importIcon from '../../../../../../assets/importIcon.svg';
import trashIcon from '../../../../../../assets/trashIcon.svg';
import trashIconCircleRed from '../../../../../../assets/trashIconCircleRed.svg';
import Masonry from 'react-responsive-masonry';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { firestore } from '../../../../../../firebase/config';
import { joinPaths } from '../../../../../../firebase/utils';
import { Paths } from '../../../../../../firebase/paths';
import { useUsersContext } from '../../../../../../contexts/usersContext';
import { chooseIconFile } from '../../../../Documents/utils';
import ModalPreviewDocument from '../../../../Documents/components/Modal Preview Document/ModalPreviewDocument';

export default function TicketDocument({ files, images, uploadFile, handleDeleteFile, canModifyTicket }) {
	const { t } = useTranslation();

	const [modalPreview, setModalPreview] = useState(false);
	const [previewFile, setPreviewFile] = useState(null);

	const handlePreviewFile = (file) => {
		setPreviewFile({ ...file, type: file.mimeType });
		setModalPreview(true);
	};

	// ==================================== Handle file input ====================================

	const handleFileInput = (event) => {
		const files = event.target.files;
		for (let i = 0; i < files.length; i++) {
			uploadFile(files[i]);
		}
	};

	// ==================================== Render ====================================

	return (
		<div className={'ticketDocument'}>
			{(canModifyTicket || files?.length > 0) && <p className={'titleDocument'}>{t('translation.docs')}</p>}

			<div className={'listElems'}>
				<Masonry style={{ gap: 30 }} columnsCount={1}>
					{files.map((file, index) => (
						<File
							key={index}
							file={file}
							handleDeleteFile={handleDeleteFile}
							handlePreviewFile={handlePreviewFile}
							canModify={canModifyTicket}
						/>
					))}
				</Masonry>
				<Masonry style={{ gap: 10 }} columnsCount={2}>
					{images.map((image, index) => (
						<Picture
							key={index}
							image={image}
							index={index}
							handleDeleteFile={handleDeleteFile}
							handlePreviewFile={handlePreviewFile}
							canModify={canModifyTicket}
						/>
					))}
				</Masonry>
			</div>

			{canModifyTicket && (
				<label className={'buttonAddItems'} htmlFor="inputFileModalTicket">
					<img src={importIcon} alt="" />
					<div>{t('translation.joinFilesAndPictures')}</div>
				</label>
			)}

			<input
				id="inputFileModalTicket"
				type="file"
				className="input-hidden"
				multiple={true}
				onChange={handleFileInput}
			/>

			<ModalPreviewDocument
				modalPreview={modalPreview}
				setModalPreview={setModalPreview}
				files={[...files, ...images]?.map((file) => ({ ...file, type: file.mimeType }))}
				previewFile={previewFile}
				setPreviewFile={setPreviewFile}
			/>
		</div>
	);
}

export function File({ file, handleDeleteFile, disabled, handlePreviewFile, canModify }) {
	const { t } = useTranslation();

	const [users] = useUsersContext();

	const [creatorName, setCreatorName] = useState('');

	useEffect(() => {
		if (file.createdBy) {
			if (users && users.find((user) => user.id === file.createdBy?.userId)) {
				const user = users.find((user) => user.id === file.createdBy?.userId);
				setCreatorName(`${user.surname} ${user.name}`);
			} else if (file.createdBy?.userId) {
				firestore
					.doc(
						file.createdBy?.userType === 'collaborator'
							? joinPaths(
									Paths.AGENCIES,
									file.createdBy?.agencyId,
									Paths.COLLABORATORS,
									file.createdBy?.userId
								)
							: joinPaths(Paths.CLIENTS, file.createdBy?.userId)
					)
					.get()
					.then((doc) => setCreatorName(`${doc.data().surname} ${doc.data().name}`));
			}
		}
	}, [users, file]);

	return (
		<div
			className={`containerPDF ${disabled ? '' : 'hover'}`}
			onClick={() => handlePreviewFile && handlePreviewFile(file)}
			style={{ marginBottom: 10 }}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={chooseIconFile(file.mimeType)} alt="" />
				<div>
					<p className={'docName'}>{file.name}</p>
					<p className={'authorName'}>
						{t('translation.addedBy')} {creatorName}
					</p>
				</div>
			</div>

			{!disabled && canModify && (
				<img
					src={trashIconCircleRed}
					alt=""
					className={'trashIcon hover'}
					onClick={(e) => {
						e.stopPropagation();
						handleDeleteFile(file);
					}}
				/>
			)}
		</div>
	);
}

export function Picture({ image, index, handleDeleteFile, disabled, handlePreviewFile, canModify }) {
	const [users] = useUsersContext();

	const [creatorName, setCreatorName] = useState('');

	useEffect(() => {
		if (image.createdBy) {
			if (users && users.find((user) => user.id === image.createdBy?.userId)) {
				const user = users.find((user) => user.id === image.createdBy?.userId);
				setCreatorName(`${user.surname} ${user.name}`);
			} else if (image.createdBy?.userId) {
				firestore
					.doc(
						image.createdBy?.userType === 'collaborator'
							? joinPaths(
									Paths.AGENCIES,
									image.createdBy?.agencyId,
									Paths.COLLABORATORS,
									image.createdBy?.userId
								)
							: joinPaths(Paths.CLIENTS, image.createdBy?.userId)
					)
					.get()
					.then((doc) => setCreatorName(`${doc.data().surname} ${doc.data().name}`));
			}
		}
	}, [users, image]);

	return (
		<div className={'containerTicketImage'} onClick={() => handlePreviewFile && handlePreviewFile(image)}>
			<img src={image.url} alt={`Photo ${index + 1}`} className={'ticketImage'} />
			<div className={'imageOverlay'}>
				<div className={'adder'}>
					<div>{`Photo ${index + 1}`}</div>
					<div>{creatorName}</div>
				</div>
				{!disabled && canModify && (
					<img
						src={trashIconCircleRed}
						alt=""
						className={'trashIcon hover'}
						onClick={(e) => {
							e.stopPropagation();
							handleDeleteFile(image);
						}}
					/>
				)}
			</div>
		</div>
	);
}

TicketDocument.propTypes = {
	uploadFile: PropTypes.func.isRequired,
	files: PropTypes.array.isRequired,
	images: PropTypes.array.isRequired,
	handleDeleteFile: PropTypes.func.isRequired,
	canModifyTicket: PropTypes.bool,
};

File.propTypes = {
	file: PropTypes.object.isRequired,
	handleDeleteFile: PropTypes.func,
	disabled: PropTypes.bool,
	handlePreviewFile: PropTypes.func,
	canModify: PropTypes.bool,
};

Picture.propTypes = {
	image: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	handleDeleteFile: PropTypes.func,
	disabled: PropTypes.bool,
	handlePreviewFile: PropTypes.func,
	canModify: PropTypes.bool,
};
