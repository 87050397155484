import React from 'react';

export default function HeaderJoinProject({ projectName }) {
	return (
		<div className={'headerJoinProject'}>
			<p className={'opus'}>OPUS</p>
			<p className={'projectName'}> • {projectName}</p>
		</div>
	);
}
