import React from 'react';
import searchIcon from '../../../assets/searchIcon.svg';
import greyCross from '../../../assets/greyCross.svg';
import { useTranslation } from 'react-i18next';

export default function SearchBarMessaging({ research, setResearch, width }) {
	const { t } = useTranslation();

	return (
		<div className={'searchBarMessaging'}>
			<img src={searchIcon} alt="" className={'searchIconMessaging'} />
			<input
				type="text"
				placeholder={t('common.search_placeholder')}
				style={{ width: '100%' }}
				value={research}
				onChange={(e) => setResearch(e.target.value)}
			/>
			{research.length > 0 && (
				<img
					src={greyCross}
					alt={''}
					style={{ cursor: 'pointer', width: 12, height: 12, marginRight: 10 }}
					onClick={() => setResearch('')}
				/>
			)}
		</div>
	);
}
