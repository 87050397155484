import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TypeContext from '../../contexts/typeContext';

export default function MenuNav({ isFicheProjet, grasComptes, grasProjet, backToOrganisation }) {
	const { t } = useTranslation();
	const type = useContext(TypeContext);

	return (
		<div className="menuNavigation">
			<div className={'inner'}>
				<div className="navigation" style={{ display: 'flex', gap: 5 }}>
					<NavLink exact to="/projets">
						<div
							className={grasProjet ? 'backgroundMenuItemBleu' : 'backgroundMenuItem'}
							style={{ marginLeft: 0 }}>
							{isFicheProjet ? (
								<p>{t('projects_page.back_projects')}</p>
							) : (
								<p>{t('projects_page.projects')}</p>
							)}

							{/*contNotifsProjets > 0 &&
                                <div className={'contNotifsProjets'}>
                                    {contNotifsProjets}
                                </div>
                            */}
						</div>
					</NavLink>

					{/*
						<NavLink exact to="/global-messagerie">
							<div className={grasMessagerie ? 'backgroundMenuItemBleu' : 'backgroundMenuItem'}>
								<p>{t('common.opus_transfer')}</p>

								{contNotifsTransferts > 0 && (
									<div className={'contNotifsProjets'}>{contNotifsTransferts}</div>
								)}
							</div>
						</NavLink>
                    */}

					{type !== 'clients' && (
						<NavLink exact to="/agence">
							<div className={grasComptes ? 'backgroundMenuItemBleu' : 'backgroundMenuItem'}>
								<p>{backToOrganisation ? t('common.back_to_organization') : t('common.agency')}</p>
							</div>
						</NavLink>
					)}
				</div>
			</div>
		</div>
	);
}
