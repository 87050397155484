import React, { useContext, useEffect, useState } from 'react';
import { adjustText, transformString } from '../../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import { deleteUserInProject } from '../../../../../useful/UsefulForProject';
import { firestore } from '../../../../../firebase/config';
import AgencyContext from '../../../../../contexts/agencyContext';

export default function ItemListCollabs({ uid, agency, research, setResearchNumber, projectId, blockDelete }) {
	const [oldResearch, setOldResearch] = useState(false);
	const { t } = useTranslation();
	const localAgency = useContext(AgencyContext);

	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [surname, setSurname] = useState();

	useEffect(() => {
		firestore
			.doc(`agencies/${agency}/collaborators/${uid}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setName(documentSnapshot.data().name);
					setSurname(documentSnapshot.data().surname);
					setEmail(documentSnapshot.data().email);
				}
			});
	}, [uid, agency, projectId]);

	useEffect(() => {
		if (name) {
			if (transformString(name).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(name).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, name]);

	return (
		<>
			{transformString(surname + name + email).includes(transformString(research)) && (
				<div className={'itemListUser'}>
					<div className={'leftPartItemUser'}>
						<div className={'profilePictureBleu'}>
							<p
								style={{
									letterSpacing: 1,
									fontSize: 15,
									margin: 0,
								}}>
								{surname && surname?.charAt(0)?.toUpperCase()}
								{name && name?.charAt(0)?.toUpperCase()}
							</p>
						</div>

						<div className={'nameEmail'}>
							{surname && name && <p className={'name'}>{adjustText(`${surname} ${name}`, 26)}</p>}
							{email && <p className={'email'}>{adjustText(email, 32)}</p>}
						</div>
					</div>

					<div className={'rightPartItemUser'} style={{ justifyContent: 'flex-end' }}>
						<div
							className={'retirer hover'}
							style={{
								opacity: blockDelete ? 0.5 : 1,
								cursor: blockDelete ? 'not-allowed' : 'pointer',
							}}
							onClick={async () => {
								if (!blockDelete && window.confirm(`${t('collab_list_item.sure_to_remove_collab', { name: name })}`)) {
									await deleteUserInProject(uid, 'collaborators', agency, projectId, localAgency);
								}
							}}>
							{t('translation.extirp')}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
