import React from 'react';
import ItemOptionCategoryFunctionality from './ItemOptionCategoryFunctionality';
import { useTranslation } from 'react-i18next';

export default function CategoryFunctionality({ category }) {
	const { t } = useTranslation();

	return (
		<div className={'categoryFunctionality'}>
			<div className={'headerCategoryFunctionality'}>
				<div className={'firstPart'}>
					<h3 className={'title'}>{category.title}</h3>
				</div>
				<div className={'secondaryPart'} style={{ color: '#6C6C6C' }}></div>

				<div className={'secondaryPart'} style={{ color: '#949ACC', opacity: 0 }}>
					OPUS
				</div>

				<div className={'secondaryPart'} style={{ color: '#5667F6' }}>
					OPUS Services
				</div>
			</div>

			<div className={'listFunctionalities'}>
				{category.options.map((item, index) => (
					<ItemOptionCategoryFunctionality key={index} option={item} />
				))}
			</div>
		</div>
	);
}
