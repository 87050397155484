import React from 'react';
import infoAccount from "../../../../assets/infoAccount.svg";
import editAccountBleu from "../../../../assets/editAccountBleu.svg";

export default function PlaceHolderAccountItem({isCreationCompte, collaborateurs, nom, img, statut, date, activity, projets}) {

    return(
        <div className={'itemCollaborateur'} style={{width: isCreationCompte ? 800 : 1280}}>
            <div style={{
                marginLeft: 20,
                width: 260,
                display: 'flex',
                alignItems: 'center',
            }}>
                <img src={img} alt="" className={'photoProfil'}/>
                <p className={'nomCompte'}>{nom}</p>
            </div>

            <div
                className={'transitionItemAccount'}
                style={{
                    marginLeft: 30,
                    width:isCreationCompte ? 0 : 240,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <p className={'dateCompte'}>{date}</p>
            </div>

            <div style={{
                marginLeft: 30,
                width: 240,
                display: 'flex',
                justifyContent: 'center',
            }}>
                {collaborateurs ?
                    <div className={'statutContainer'}>
                        {statut}
                    </div>
                    :
                    <div className={'statutContainer'}>
                        {projets}
                    </div>
                }

            </div>

            <div
                className={'transitionItemAccount'}
                style={{
                    marginLeft: 30,
                    width: isCreationCompte ? 0 : 240,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',

                }}>
                <div className={'connexionContainer'}>
                    {activity}
                </div>
            </div>

            <div style={{
                marginLeft: 30,
                width: 140,
                height: 30,
                display: 'flex',
                justifyContent: 'flex-end',

            }}>
                <img src={infoAccount} alt="" className={'editAccountBleu'}/>
                <img src={editAccountBleu} alt="" className={'deuxPointsGris'}/>

            </div>
        </div>
    )
}
