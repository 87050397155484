import React from 'react';
import rightIcon from '../../../../../assets/rightIcon.svg';
import '../_choosePlanPage.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function HeaderChoosePlanPage() {
	const { t } = useTranslation();

	return (
		<>
			<p className={'planTarifs'}>{t('translation.planPricing')}</p>
			<p className={'mainTitle'}>{t('translation.toLinePricing')}</p>
		</>
	);
}
