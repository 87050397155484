import React, { useEffect, useState } from 'react';
import '../messaging.scss';
import peopleTwo from '../../../assets/peopleTwo.png';
import { auth, firestore } from '../../../firebase/config';
import { joinPaths } from '../../../firebase/utils';
import { stringTime } from '../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import { isToday } from '../../../useful/utils';
import i18n from 'i18next';
import LoadingDiv from '../../../components/LoadingDiv/LoadingDiv';
import { formatDate } from '../utils';
import { MESSAGING_COLLECTION, NEW_USER_COLLECTION } from '../../../firebase/paths';

export default function ItemGroupMessaging({ data, setSelectedConvId, isActive }) {
	const { t } = useTranslation();
	const language = i18n.language;

	const uid = auth.currentUser?.uid;
	const [lastMessage, setLastMessage] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (data) {
			const subscriber = firestore
				.collection(joinPaths('messaging', data.id, 'messages'))
				.where('type', 'in', ['text', 'picture', 'video', 'file'])
				.orderBy('date', 'desc')
				.limit(1)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								setLastMessage({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
					}
					setIsLoading(false);
				});
			return () => subscriber();
		}
	}, [data]);

	return (
		data && (
			<div
				className={isActive ? 'itemConvActive' : 'itemConv'}
				onClick={async () => {
					setSelectedConvId(data.id);
					await firestore
						.doc(joinPaths(NEW_USER_COLLECTION, uid, MESSAGING_COLLECTION, data.id))
						.update({ isUnread: false, lastClickAt: new Date().toISOString() });
				}}>
				<div className={'profilePictureBleu'} style={{ marginLeft: 0 }}>
					<img src={peopleTwo} alt="" style={{ width: 26 }} />
				</div>
				<div className={'rightPartItemConv'} style={{ width: 275 }}>
					<div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
						<p className={'convName'}>{data.name}</p>
						<p className={'dateConv'}>
							{lastMessage
								? isToday(lastMessage.date)
									? stringTime(language, lastMessage.date)
									: formatDate(t, lastMessage.date)
								: isToday(data.lastMessageAt)
									? stringTime(language, data.lastMessageAt)
									: formatDate(t, data.lastMessageAt)}
						</p>
					</div>
					{isLoading ? (
						<LoadingDiv width={'200px'} height={'15px'} borderRadius={'2px'} />
					) : (
						<p className={'lastMessage'} style={{ width: 278 }}>
							{lastMessage
								? lastMessage.type === 'picture'
									? t('common.image')
									: lastMessage.type === 'video'
										? t('common.video')
										: lastMessage.type === 'file'
											? t('common.file')
											: lastMessage.contents
								: t('messaging.new_conv')}
						</p>
					)}
				</div>
			</div>
		)
	);
}
