import React, { useContext, useEffect, useState } from 'react';
import '../../../messaging.scss';
import { firestore } from '../../../../../firebase/config';
import AgencyContext from '../../../../../contexts/agencyContext';
import { joinPaths } from '../../../../../firebase/utils';
import { useProjectsContext } from '../../../../../contexts/projectsContext';
import ModalChooseProject from './ModalChooseProject';
import AgencyDataContext from '../../../../../contexts/agencyDataContext';
import { useTranslation } from 'react-i18next';

export default function ContainerCommonProjects({ conversation }) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const [projects] = useProjectsContext();

	const [commonProjects, setCommonProjects] = useState([]);
	const [modalChooseProject, setModalChooseProject] = useState(false);
	const [changeLoading, setChangeLoading] = useState(false);

	useEffect(() => {
		if (conversation) {
			if (conversation.isGroup) {
				setCommonProjects([
					conversation.parentId === agencyId
						? { name: agencyData.name, imgUrl: agencyData.logoUrl }
						: projects.find((p) => p.id === conversation.parentId),
				]);
			} else if (
				conversation.userType &&
				(conversation.userAgencyId || conversation.userType === 'clients') &&
				projects &&
				projects.length > 0
			) {
				firestore
					.collection(
						conversation.userType === 'collaborators'
							? joinPaths(
									'agencies',
									conversation.userAgencyId,
									'collaborators',
									conversation.id,
									'projects'
							  )
							: joinPaths('clients', conversation.id, 'projects')
					)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot) {
							const commonProjects = [];
							querySnapshot.forEach((documentSnapshot) => {
								if (documentSnapshot && documentSnapshot.exists) {
									const project = projects.find((p) => p.id === documentSnapshot.id);
									if (project) {
										commonProjects.push(project);
									}
								}
							});
							setCommonProjects(commonProjects);
						}
					});
			}
		}
	}, [conversation, projects, agencyId, agencyData]);

	const handleChangeProject = async (projectId) => {
		if (projectId && projectId !== conversation.parentId) {
			setChangeLoading(true);

			const batch = firestore.batch();

			batch.update(firestore.doc(joinPaths('messaging', conversation.id)), { parentId: projectId });

			const participants = (
				await firestore.collection(joinPaths('messaging', conversation.id, 'participants')).get()
			).docs.map((doc) => doc.id);

			for (const participant of participants) {
				batch.update(firestore.doc(joinPaths('users', participant, 'messaging', conversation.id)), {
					parentId: projectId,
					isUnread: true,
					lastMessageAt: new Date().toISOString(),
				});
			}

			await batch.commit();

			setChangeLoading(false);

			setModalChooseProject(false);
		}
	};

	return (
		<>
			<div className={'containerCommonProjects'}>
				<div className={'containerHeader'}>
					<p>{conversation.isGroup ? t('translation.linkedProject') : t('translation.commonProjects')}</p>
				</div>
				<div>
					<div className={'listProjects'}>
						{commonProjects.map((project, index) => (
							<div className={'projectItem'}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}>
									<div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
										<img src={project?.imgUrl} alt="" />
										{project.name}
									</div>

									{conversation.isGroup && (
										<button onClick={() => setModalChooseProject(true)} className={'modify'}>
											{t('translation.modify')}
										</button>
									)}
								</div>
								{index < commonProjects.length - 1 && <div className={'bar'} />}
							</div>
						))}
					</div>
				</div>
			</div>

			<ModalChooseProject
				isOpen={modalChooseProject}
				setIsOpen={setModalChooseProject}
				handleChoose={handleChangeProject}
				isLoading={changeLoading}
				parentId={conversation.parentId}
			/>
		</>
	);
}
