import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '../../components/Main/Menu';
import ParametresProfil from './ParametresProfil';
import Securite from './Securite';
import ParametresAgence from './ParametresAgence';
import AgencyContext from '../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import TypeContext from '../../contexts/typeContext';
import { auth, firestore } from '../../firebase/config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { appVersion } from '../../useful/constants';
import mixpanel from 'mixpanel-browser';

export default function Parametres(props) {
	const { t } = useTranslation();
	const location = useLocation();

	const type = useContext(TypeContext);
	const agency = useContext(AgencyContext);
	const navigate = useNavigate();

	const [uid, setUid] = useState();
	const [userSession, setUserSession] = useState();
	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUid(user.uid);
			} else navigate('/');
		});
		return () => {
			listener();
		};
	}, [userSession]);

	const [lastProject, setLastProject] = useState();
	useEffect(() => {
		const subscriber = firestore
			.collection(
				type === 'collaborators'
					? `agencies/${agency}/collaborators/${uid}/projects`
					: `${type}/${uid}/projects`
			)
			.orderBy('lastClick', 'desc')
			.limit(1)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							setLastProject(documentSnapshot.data().id);
						}
					});
				}
			});
		return () => subscriber();
	}, [agency, uid, type]);

	const [profil, setProfil] = useState(true);
	const [securite, setSecurite] = useState(false);
	const [agence, setAgence] = useState(false);
	const [billing, setBilling] = useState(false);
	const [mentions, setMentions] = useState(false);

	useEffect(() => {
		if (location.state && location.state.agency) {
			setAgence(true);
			setProfil(false);
			setSecurite(false);
			setMentions(false);
		}
	}, [location]);

	const [marginTop, setmarginTop] = useState(0);
	const [opacity, setOpacity] = useState(1);

	function GoProfil() {
		setmarginTop(30);
		setOpacity(0);
		setTimeout(() => {
			setProfil(true);
			setAgence(false);
			setSecurite(false);
			setMentions(false);
			setBilling(false);
			setmarginTop(-30);
			setTimeout(() => {
				setmarginTop(0);
				setOpacity(1);
			}, 300);
		}, 300);
	}

	function GoSecurite() {
		setmarginTop(profil ? -30 : 30);
		setOpacity(0);
		setTimeout(() => {
			setProfil(false);
			setAgence(false);
			setSecurite(true);
			setMentions(false);
			setBilling(false);
			setmarginTop(profil ? 30 : -30);
			setTimeout(() => {
				setmarginTop(0);
				setOpacity(1);
			}, 300);
		}, 300);
	}

	function GoAgence() {
		setmarginTop(profil || securite ? -30 : 30);
		setOpacity(0);
		setTimeout(() => {
			setProfil(false);
			setAgence(true);
			setSecurite(false);
			setMentions(false);
			setBilling(false);
			setmarginTop(profil || securite ? 30 : -30);
			setTimeout(() => {
				setmarginTop(0);
				setOpacity(1);
			}, 300);
		}, 300);
	}

	function GoMentions() {
		setmarginTop(-30);
		setOpacity(0);
		setTimeout(() => {
			setProfil(false);
			setAgence(false);
			setSecurite(false);
			setMentions(true);
			setBilling(false);
			setmarginTop(30);
			setTimeout(() => {
				setmarginTop(0);
				setOpacity(1);
			}, 300);
		}, 300);
	}

	return (
		<div className="parametresProfil">
			<div className="leftPart">
				<Menu grasParametres={true} lastProject={lastProject} />
			</div>

			<div className="rightPart" style={{ display: 'flex' }}>
				<div className={'menuFicheProjet'}>
					<p className={'menuName'}>{t('settings.settings')}</p>

					<div className="containerMenu">
						<div className="inner">
							<div className="backBarMenu" style={{ height: type !== 'clients' ? 128 : 98 }}>
								<div
									className={'transitionWidthMarginLeft'}
									style={{
										marginTop: profil
											? 0
											: securite
												? 35
												: mentions
													? type === 'clients'
														? 71
														: 106
													: agence
														? 71
														: billing
															? 110
															: null,
										width: 2.5,
										height: 20,
										borderRadius: 20,
										backgroundColor: '#4867EC',
									}}
								/>
							</div>

							<div>
								<div
									className={profil ? 'active' : 'unactive'}
									onClick={() => {
										GoProfil();
									}}>
									<p style={{ letterSpacing: 0, margin: 0 }}>{t('settings.profile')}</p>
								</div>

								<div
									className={securite ? 'active' : 'unactive'}
									onClick={() => {
										GoSecurite();
									}}>
									<p style={{ letterSpacing: 0, margin: 0 }}>{t('settings.safety')}</p>
								</div>

								{type !== 'clients' && (
									<div
										className={agence ? 'active' : 'unactive'}
										onClick={() => {
											GoAgence();
										}}>
										<p style={{ letterSpacing: 0, margin: 0 }}>{t('common.agency')}</p>
									</div>
								)}

								<a
									href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f"
									target={'_blank'}>
									<div className={mentions ? 'active' : 'unactive'} onClick={() => {}}>
										<p style={{ letterSpacing: 0, margin: 0 }}>{t('settings.legal')}</p>
									</div>
								</a>

								<div style={{ height: 'calc(100vh - 362px)' }} />

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										paddingBottom: 30,
										marginLeft: -10,
									}}>
									<div>
										<p
											style={{
												fontSize: 13,
												color: 'rgba(139,139,139,0.51)',
												fontHeight: 400,
											}}>
											v {appVersion}
										</p>
									</div>

									<div
										className={'hover'}
										style={{
											fontSize: 13,
											backgroundColor: '#D2E7F8',
											color: '#4867EC',
											fontWeight: '700',
											paddingLeft: 10,
											paddingRight: 10,
											width: 80,
											borderRadius: 5,
											height: 25,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginTop: -7,
										}}
										onClick={() =>
											signOut(auth).then(() => {
												navigate('/');
												mixpanel.reset();
											})
										}>
										{t('settings.log_out')}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{profil ? (
					<div style={{ opacity: opacity, marginTop: marginTop }} className={'transitionMenuProjet'}>
						<ParametresProfil />
					</div>
				) : securite ? (
					<div style={{ opacity: opacity, marginTop: marginTop }} className={'transitionMenuProjet'}>
						<Securite />
					</div>
				) : agence ? (
					<div style={{ opacity: opacity, marginTop: marginTop }} className={'transitionMenuProjet'}>
						<ParametresAgence />
					</div>
				) : null}
			</div>
		</div>
	);
}
