import React, { useEffect, useState } from 'react';
import { adjustText, transformString } from '../../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../../firebase/config';
import { arrayRemove } from 'firebase/firestore';

export default function ItemListClient({ uid, type, agency, research, setResearchNumber, projectId, isMandataire }) {
	const [data, setData] = useState();
	const [oldResearch, setOldResearch] = useState(false);
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (type === 'collaborators') {
			firestore
				.doc(`agencies/${agency}/collaborators/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		} else {
			firestore
				.doc(`clients/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		}
	}, [uid, type, agency]);

	useEffect(() => {
		if (data) {
			if (transformString(data.name + data.surname).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(data.name + data.surname).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, data]);

	async function deleteMessaging(pathWithCondition, pathWithoutCondition) {
		pathWithCondition.get().then((querySnapshot) => {
			if (querySnapshot) {
				querySnapshot.forEach(async (documentSnapshot) => {
					const projectIds = documentSnapshot.data().projectIds;
					if (projectIds && projectIds.length > 1 && projectIds.includes(projectId)) {
						await firestore
							.doc(`clients/${uid}/messaging/${documentSnapshot.id}`)
							.update({ projectIds: arrayRemove(projectId) });
						const typeOther = (await firestore.doc(`accounts/${documentSnapshot.id}`).get()).data().type;
						if (typeOther === 'collaborators') {
							const agencyOther = (await firestore.doc(`accounts/${documentSnapshot.id}`).get()).data()
								.agency;
							await firestore
								.doc(`agencies/${agencyOther}/collaborators/${documentSnapshot.id}/messaging/${uid}`)
								.update({ projectIds: arrayRemove(projectId) });
						} else {
							await firestore
								.doc(`${typeOther}/${documentSnapshot.id}/messaging/${uid}`)
								.update({ projectIds: arrayRemove(projectId) });
						}
					} else {
						await deleteCollection(
							pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
							pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
							true,
							'contents'
						);
						await deleteCollection(
							pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
							pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
							true,
							'contents'
						);
						await deleteCollection(
							pathWithoutCondition.doc(documentSnapshot.id).collection('messages'),
							pathWithoutCondition.doc(documentSnapshot.id).collection('messages')
						);
						await pathWithoutCondition.doc(documentSnapshot.id).delete();
					}
				});
			}
		});
	}

	async function deleteClient(uid) {
		setResearchNumber((prevValue) => prevValue - 1);
		await firestore.doc(`clients/${uid}/projects/${projectId}`).delete();

		const path = firestore.doc(`clients/${uid}`);

		await firestore.doc(`projects/${projectId}/accounts/${uid}`).delete();
		await deleteMessaging(path.collection('messaging').where('group', '==', false), path.collection('messaging'));
		path.collection('messaging')
			.where('group', '==', true)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							firestore
								.doc(`projects/${projectId}/messaging/${documentSnapshot.id}/participants/${uid}`)
								.delete()
								.then(() => {
									path.collection('messaging')
										.doc(documentSnapshot.id)
										.delete()
										.then(() => {
											firestore
												.collection(
													`projects/${projectId}/messaging/${documentSnapshot.id}/participants`
												)
												.get()
												.then(async (querySnapshot) => {
													if (querySnapshot && querySnapshot.empty) {
														await deleteMessaging(
															firestore
																.collection(`projects/${projectId}/messaging`)
																.where('id', '==', documentSnapshot.id),
															firestore.collection(`projects/${projectId}/messaging`)
														);
													} else if (!querySnapshot) {
														await deleteMessaging(
															firestore
																.collection(`projects/${projectId}/messaging`)
																.where('id', '==', documentSnapshot.id),
															firestore.collection(`projects/${projectId}/messaging`)
														);
													}
												});
										});
								});
						}
					});
				}
			});
		path.collection('calendar')
			.where('projectId', '==', projectId)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						firestore
							.doc(`projects/${projectId}/calendar/${documentSnapshot.id}/participants/${uid}`)
							.delete()
							.then(() => {
								firestore
									.collection(`projects/${projectId}/calendar/${documentSnapshot.id}/participants`)
									.get()
									.then(async (querySnapshot) => {
										if (querySnapshot && querySnapshot.empty) {
											await deleteCollection(
												firestore
													.collection(`projects/${projectId}/calendar`)
													.where('id', '==', documentSnapshot.id),
												firestore.collection(`projects/${projectId}/calendar`),
												true,
												'fileUrl',
												true,
												'fileUrlFinish'
											);
										} else if (!querySnapshot) {
											await deleteCollection(
												firestore
													.collection(`projects/${projectId}/calendar`)
													.where('id', '==', documentSnapshot.id),
												firestore.collection(`projects/${projectId}/calendar`),
												true,
												'fileUrl',
												true,
												'fileUrlFinish'
											);
										}
									});
							});
					});
				}
			});
	}

	async function deleteAccount(e) {
		e.preventDefault();

		if (window.confirm(`${t('contributors.sure_to_delete')} ${name} ${t('from_this_project.contributors')}`)) {
			setIsLoading(true);
			await deleteClient(uid, agency);
			setIsLoading(false);
		}
	}

	return (
		<>
			{transformString(data?.surname + data?.name + data?.email).includes(transformString(research)) && (
				<div className={'itemListUser'}>
					<div className={'leftPartItemUser'}>
						<div className={'profilePictureBleu'}>
							<p
								style={{
									letterSpacing: 1,
									fontSize: 15,
									margin: 0,
								}}>
								{data?.surname?.charAt(0)?.toUpperCase()}
								{data?.name?.charAt(0)?.toUpperCase()}
							</p>
						</div>

						<div className={'nameEmail'}>
							{data && <p className={'name'}>{adjustText(`${data?.surname} ${data?.name}`, 26)}</p>}
							{data && <p className={'email'}>{adjustText(data?.email, 32)}</p>}
						</div>
					</div>

					<div className={'rightPartItemUser'}>
						<div className={'phoneNumber'}>
							{data?.phone ? data?.phone : t('translation.noPhoneNumber')}
						</div>
						{isMandataire ? (
							<div className={'retirer hover'} onClick={deleteAccount}>
								{t('translation.extirp')}
							</div>
						) : (
							<div className={'status'}>{t('translation.individual')}</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
