import React from 'react';
import Modal from 'react-modal';
import crossIcon from '../assets/crossIcon.png';
import imageFonct from '../assets/imageFonct.png';
import { useTranslation } from 'react-i18next';

export default function ModalPageEnCoursConstruction({
	modalPageEnCoursConstruction,
	setModalPageEnCoursConstruction,
}) {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={modalPageEnCoursConstruction}
			className={'modalFonctModeDemo'}
			overlayClassName="OverlayModal"
			closeTimeoutMS={300}
			onRequestClose={() => setModalPageEnCoursConstruction(false)}
			shouldCloseOnOverlayClick={true}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => setModalPageEnCoursConstruction(false)}
			/>

			<p className={'title'}>{t('constructing_page.constructing_page')}</p>

			<p className={'subtitle'}>{t('constructing_page.info')}</p>

			<img src={imageFonct} alt="" className={'imageFonct'} />

			<div className={'button'} onClick={() => setModalPageEnCoursConstruction(false)}>
				{t('common.i_understand')}
			</div>
		</Modal>
	);
}
