import i18n from 'i18next';

export const link = (projectId) => `https://opusoft.app/join-project?id=${encodeURIComponent(projectId)}`;

const subject = (projectName) => {
	const isFrench = i18n.language === 'fr';
	return encodeURIComponent(
		isFrench ? `Votre projet ${projectName} sur OPUS` : `Your project ${projectName} on OPUS`
	);
};
const body = (projectId) => {
	const isFrench = i18n.language === 'fr';
	return encodeURIComponent(
		isFrench
			? `Bonjour !
			 
Nous utilisons l'application OPUS pour partager les informations de nos projets. 
			 
Vous pouvez rejoindre le projet tout de suite en cliquant sur ce lien : ${link(projectId)}. 
			  
Cordialement,`
			: `Hello! 
			
We are using the OPUS application to share information about our projects. 
			
You can join the project right away by clicking on this link: ${link(projectId)}. 
			  
Best regards,`
	);
};

export const mailtoHref = (t, email, projectName, projectId) =>
	`mailto:${encodeURIComponent(email)}?subject=${subject(projectName)}&body=${body(projectId)}`;
