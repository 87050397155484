import React from 'react';
import '../../../messaging.scss';
import playSymbolVideo from '../../../../../assets/playSymbolVideo.svg';

export default function ImageMessageResponse({ message }) {
	return message?.type === 'video' ? (
		<div>
			<video src={message?.contents} className={'imageMessage'} />
			<img
				src={playSymbolVideo}
				alt=""
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '30px',
				}}
			/>
		</div>
	) : (
		<img src={message?.contents} alt="" className={'imageMessage'} />
	);
}
