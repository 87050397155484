import React, { useEffect, useState } from 'react';
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

const NotFound = () => {
	const navigate = useNavigate();
	const [userSession, setUserSession] = useState(null);
	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			user ? setUserSession(user) : navigate('/');
		});
		return () => {
			listener();
		};
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
				height: '100vh',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<h1 style={{ margin: 0 }}>
				<span style={{ fontSize: 14, marginTop: -10 }}>(Page not found)</span>
			</h1>
		</div>
	);
};

export default NotFound;
