import React from 'react';
import { REVIEW_RESULT, TICKET_STATUS } from '../../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../contexts/projectContext';
import '../Snag Page/_snagPage.scss';
import hourglass from '../../../../assets/hourglass.svg';
import ResolutionProposal from '../Snag Page/Resolution Proposal/ResolutionProposal';

export default function ResolutionPage({
	isLarge,
	ticket,
	index,
	ticketsLength,
	blank,
	resolution,
	reviewers,
	reviews,
	status,
}) {
	const { t, i18n } = useTranslation();
	const [project] = useProjectContext();

	if (blank) {
		return (
			<div className={'snagPage'}>
				<div
					className={'statusBar'}
					style={
						status === TICKET_STATUS.approved
							? {
									color: '#3CA504',
									backgroundColor: 'rgba(60,165,4,0.1)',
								}
							: { backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }
					}>
					{isLarge ? (
						<span className={'ticketNameWebkit'} style={{ width: 300 }}>
							{ticket.name}
						</span>
					) : (
						<span style={{ width: 200 }}>
							{t('translation.snag')} {index} {t('translation.of')} {ticketsLength}
						</span>
					)}
					<span>
						{new Date().toLocaleDateString(i18n.language, {
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
					<span style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>{project.name}</span>
				</div>

				<div className={'snagDescription'} style={{ height: 1100, overflow: 'hidden' }}>
					<div className={'containerSnagNameCreator'}>
						<span className={'snagName'}>{t('translation.snagResolution')}</span>
					</div>

					<div className={'containerSolutionProposal'}>
						<div className={'waitingForSolving'}>
							<img src={hourglass} alt="" className={'hourglass'} />{' '}
							{t('translation.waitingForSolvingProposal')}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={'snagPage'}>
				<div
					className={'statusBar'}
					style={
						status === TICKET_STATUS.approved
							? {
									color: '#3CA504',
									backgroundColor: 'rgba(60,165,4,0.1)',
								}
							: { backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }
					}>
					{isLarge ? (
						<span className={'ticketNameWebkit'} style={{ width: 300 }}>
							{ticket.name}
						</span>
					) : (
						<span style={{ width: 200 }}>
							{t('translation.snag')} {index} {t('translation.of')} {ticketsLength}
						</span>
					)}
					<span>
						{new Date().toLocaleDateString(i18n.language, {
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
					<span style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>{project.name}</span>
				</div>

				<div className={'snagDescription'} style={{ height: 1100, overflow: 'hidden' }}>
					<div className={'containerSnagNameCreator'}>
						<span className={'snagName'}>{t('translation.snagResolution')}</span>
					</div>

					<ResolutionProposal resolution={resolution} />

					{reviews?.map((review, index) => (
						<ResolutionProposal
							resolution={review}
							isReview={true}
							isRefused={review.result === REVIEW_RESULT.refused}
							isApproved={review.result === REVIEW_RESULT.approved}
						/>
					))}
				</div>
			</div>
		);
	}
}
