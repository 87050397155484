import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.scss';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import App from './App';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

// The mere presence of this import will activate App Check
const loadAppCheck = () => {
	if (process.env.NODE_ENV !== 'development') {
		import('./firebase/config');
	}
};
loadAppCheck();

mixpanel.init('0cb4024db1b20eedc4b490698c6d6708', {
	debug: true,
	persistence: 'localStorage',
	api_host: process.env.NODE_ENV === 'development' ? '' : 'https://mixpanel-proxy-54o2nvoogq-ew.a.run.app',
});

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		fallbackLng: 'en',
		detection: {
			// order and from where user language should be detected
			order: [
				'navigator',
				'querystring',
				'cookie',
				'localStorage',
				'sessionStorage',
				'htmlTag',
				'path',
				'subdomain',
			],

			// keys or params to lookup language from
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			lookupSessionStorage: 'i18nextLng',
			lookupFromPathIndex: 0,
			lookupFromSubdomainIndex: 0,

			// cache user language on
			caches: ['localStorage', 'cookie'],
			excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

			// optional expire and domain for set cookie
			cookieMinutes: 10,
			cookieDomain: 'opusoft.app',

			// optional htmlTag with lang attribute, the default is:
			htmlTag: document.documentElement,

			// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
			cookieOptions: { path: '/', sameSite: 'strict' },
		},
		backend: {
			loadPath: '/locales/{{lng}}/translation.json',
		},
		react: { useSuspense: false },
	})
	.then((r) => {
		console.log('i18next initialized', r);
	})
	.catch((e) => {
		console.error('i18next failed to initialize', e);
	});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<IntercomProvider appId={'p8hzwawn'} autoBoot>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</IntercomProvider>
);
