import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase/config';
import 'firebase/firestore';
import 'firebase/storage';
import AlbumsList from './AlbumsList';
import chargement from '../../../assets/chargement.png';
import AgencyContext from '../../../contexts/agencyContext';
import './_photos.scss';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../firebase/paths';
import AlbumContent from './Album Content/AlbumContent';
import { useLocation } from 'react-router-dom';
import { useClientsContext } from '../../../contexts/clientsContext';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';

export default function PhotosPage({
	type,
	projectId,
	projectImgUrl,
	isFreeTrial,
	partnerAgencies,
	matterportActive,
	isModeLecture,
}) {
	const { t } = useTranslation();

	const location = useLocation();
	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const [clients] = useClientsContext();

	const [dataLoading, setDataLoading] = useState(true);

	const [albums, setAlbums] = useState();
	const [visits, setVisits] = useState();

	const [albumSelected, setAlbumSelected] = useState({});
	const [nameAlbumSelected, setNameAlbumSelected] = useState('');

	const [research, setResearch] = useState('');

	//-------------------------------------------------------------------------------------

	useEffect(() => {
		if (location && location.state) {
			if (location.state.selectedAlbumId) {
				firestore
					.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
					.doc(location.state.selectedAlbumId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							const album = { ...doc.data(), id: doc.id };
							handleSelectAlbum(album, location.state.visit ? location.state.visit : false);
						}
					});
			}
		}
	}, [location]);

	useEffect(() => {
		setAlbums([]);
		if (projectId && uid && ((type === 'collaborators' && agencyId) || type === 'clients') && clients) {
			let ref = firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('type', '==', 'album');
			if (type !== 'collaborators' || clients?.map((item) => item.id).includes(agencyId)) {
				ref = ref.where('clientPermission', '==', true);
			}
			ref.orderBy('orderDate', 'desc')
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const albums = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								if (
									!documentSnapshot.data().noPermissions ||
									(!documentSnapshot.data().noPermissions.includes(uid) &&
										!documentSnapshot.data().noPermissions.includes(agencyId))
								) {
									albums.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								}
							}
						});
						setAlbums((prevState) => [...prevState, ...albums]);
						setDataLoading(false);
					}
				});
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('type', '==', 'visit')
				.orderBy('orderDate', 'desc')
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const visits = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								visits.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
						setVisits(visits);
						setDataLoading(false);
					}
				});
			let ref1 = firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('type', '==', 'albumMatterport');
			if (type !== 'collaborators' || clients?.map((item) => item.id).includes(agencyId)) {
				ref1 = ref1.where('clientPermission', '==', true);
			}
			ref1.orderBy('orderDate', 'desc')
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const albums = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								if (
									!documentSnapshot.data().noPermissions ||
									(!documentSnapshot.data().noPermissions.includes(uid) &&
										!documentSnapshot.data().noPermissions.includes(agencyId))
								) {
									albums.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								}
							}
						});
						setAlbums((prevState) => [...albums, ...prevState]);
						setDataLoading(false);
					}
				});
		}
	}, [projectId, type, uid, agencyId, clients]);

	//-------------------------------------------------------------------------------------

	const handleSelectAlbum = (album, isVisit) => {
		setAlbumSelected(album);
		if (isVisit) {
			setNameAlbumSelected(`${t('common.visit')} ${visits.length - visits.map((it) => it.id).indexOf(album.id)}`);
			mixpanel.track('Visit Opened', {
				'Project ID': projectId,
				Page: 'albums',
			});
		} else {
			setNameAlbumSelected(album.name);
			mixpanel.track('Album Opened', {
				'Project ID': projectId,
				Page: 'albums',
			});
		}
		setDisplayAlbum(false);
		setCountPhoto(true);
	};

	const handleBackToAlbums = () => {
		setDisplayAlbum(true);
		setAlbumSelected({});
		setNameAlbumSelected('');
		setCountPhoto(false);
	};

	//-------------------------------------------------------------------------------------

	const [displayAlbums, setDisplayAlbum] = useState(true);
	const [countPhoto, setCountPhoto] = useState(false);

	const [alreadyClicked, setAlreadyClicked] = useState(false);
	const handleClickPage = () => {
		if (!alreadyClicked) {
			setAlreadyClicked(true);
			mixpanel.track_pageview({
				Page: 'albums',
				'Project ID': projectId,
			});
		}
	};

	return (
		<div onClick={handleClickPage}>
			{!dataLoading ? (
				displayAlbums ? (
					<AlbumsList
						projectId={projectId}
						projectImgUrl={projectImgUrl}
						albums={albums}
						setAlbums={setAlbums}
						visits={visits}
						setVisits={setVisits}
						research={research}
						setResearch={setResearch}
						isFreeTrial={isFreeTrial}
						matterportActive={matterportActive}
						handleSelectAlbum={handleSelectAlbum}
						isModeLecture={isModeLecture}
					/>
				) : (
					albumSelected &&
					(albumSelected.type === 'visit' ? (
						<AlbumContent
							type={type}
							projectId={projectId}
							projectImgUrl={projectImgUrl}
							setDisplayAlbum={setDisplayAlbum}
							albumSelected={albumSelected}
							setAlbumSelected={setAlbumSelected}
							nameAlbumSelected={nameAlbumSelected}
							setNameAlbumSelected={setNameAlbumSelected}
							countPhoto={countPhoto}
							setCountPhoto={setCountPhoto}
							setAlbums={setVisits}
							setOtherTypeAlbums={setAlbums}
							albumSelectedIndex={visits?.map((it) => it.id).indexOf(albumSelected.id)}
							partnerAgencies={partnerAgencies}
							handleBackToAlbums={handleBackToAlbums}
							isModeLecture={isModeLecture}
						/>
					) : (
						<AlbumContent
							type={type}
							projectId={projectId}
							projectImgUrl={projectImgUrl}
							setDisplayAlbum={setDisplayAlbum}
							albumSelected={albumSelected}
							setAlbumSelected={setAlbumSelected}
							nameAlbumSelected={nameAlbumSelected}
							setNameAlbumSelected={setNameAlbumSelected}
							countPhoto={countPhoto}
							setCountPhoto={setCountPhoto}
							setAlbums={setAlbums}
							setOtherTypeAlbums={setVisits}
							albumSelectedIndex={albums?.map((it) => it.id).indexOf(albumSelected.id)}
							partnerAgencies={partnerAgencies}
							handleBackToAlbums={handleBackToAlbums}
							isModeLecture={isModeLecture}
						/>
					))
				)
			) : (
				<div className={'chargementContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			)}
		</div>
	);
}
