import React from 'react';
import blueCrossIcon from '../../../assets/blueCrossIcon.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function WidgetExpirationCarte({ setBaniereExpirationCarte }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<div className={'bigWidgetSlectionForfait'}>
			<p className={'titleWidget'}>{t('widget_exp_carte.info_widget')}</p>
			<div>
				<div className={'buttonSelectForfait'} onClick={() => navigate('/services', { goBilling: true })}>
					{t('widget_exp_carte.button')}
				</div>
			</div>
			<img
				src={blueCrossIcon}
				alt=""
				className={'blueCrossIcon'}
				onClick={() => setBaniereExpirationCarte(false)}
			/>
		</div>
	);
}
