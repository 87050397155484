import React from 'react';
import simpleCheck from '../../../../../assets/simpleCheck.svg';

export default function ItemOptionCategoryFunctionality({ option }) {
	return (
		<div className={'ItemOptionCategoryFunctionality'}>
			<div className={'firstPart'}>
				<h3 className={'title'}>{option.title}</h3>
			</div>
			<div className={'secondaryPart'}>
				{option.freePlan === 'true' ? (
					<img src={simpleCheck} alt="simpleCheck" className={'simpleCheck'} />
				) : option.freePlan === 'false' ? (
					''
				) : (
					option.freePlan
				)}
			</div>

			<div className={'secondaryPart'}>
				{option.opusShare === 'true' ? (
					<img src={simpleCheck} alt="simpleCheck" className={'simpleCheck'} />
				) : option.opusShare === 'false' ? (
					''
				) : (
					option.opusShare
				)}
			</div>

			<div className={'secondaryPart'}>
				{option.opusSharePlus === 'true' ? (
					<img src={simpleCheck} alt="simpleCheck" className={'simpleCheck'} />
				) : option.opusSharePlus === 'false' ? (
					''
				) : (
					option.opusSharePlus
				)}
			</div>
		</div>
	);
}
