import React, { useEffect, useState } from 'react';
import O_Opus from '../../assets/O_Opus.png';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../firebase/config';
import { convertHeicToJpeg } from '../../useful/medias';

export default function LastProjectMenu({ projectId, isAdministrator }) {
	const { t } = useTranslation();

	const [imgUrl, setImgUrl] = useState();
	const [name, setName] = useState();
	const [noLastProject, setNoLastProject] = useState(false);

	const [count, setCount] = useState(false);
	const [opacity1, setOpacity1] = useState(0);

	const [projectImg, setProjectImg] = useState('');

	useEffect(() => {
		if (projectId) {
			firestore
				.doc(`projects/${projectId}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setImgUrl(documentSnapshot.data().imgUrl);
						setName(documentSnapshot.data().name);
						setNoLastProject(false);
					} else {
						setTimeout(() => setNoLastProject(true), 1000);
					}
				})
				.catch(() => {
					setTimeout(() => setNoLastProject(true), 1000);
				});
		} else {
			setTimeout(() => setNoLastProject(true), 1000);
		}
	}, [projectId]);

	useEffect(() => {
		let isMounted = true;

		const loadImage = async () => {
			if (imgUrl) {
				try {
					const convertedUrl = await convertHeicToJpeg(imgUrl);
					if (isMounted) {
						setProjectImg(convertedUrl);
					}
				} catch (error) {
					console.error('Error loading image:', error);
					if (isMounted) {
						setProjectImg('');
					}
				}
			}
		};

		loadImage();

		return () => {
			isMounted = false;
		};
	}, [imgUrl]);

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 400);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setOpacity1(1);
			}
		}, 0);
	}, [count]);

	return (
		<>
			{!projectImg && noLastProject ? (
				<div style={{ display: 'flex' }}>
					<img src={O_Opus} className="etatDesLieux" style={{ opacity: opacity1 }} />
					<p
						className={'titleProject'}
						style={{
							marginTop: 18,
							width: 200,
							display: 'flex',
							position: 'absolute',
							alignItems: 'center',
							marginBottom: 0,
							marginLeft: 50,
							fontWeight: 700,
						}}>
						OPUS
					</p>
				</div>
			) : (
				<div style={{ display: 'flex' }}>
					{projectImg ? (
						<img src={projectImg} className="etatDesLieux" style={{ opacity: opacity1 }} />
					) : (
						<div className="etatDesLieux" />
					)}
					<p
						className={'titleProject'}
						style={{
							marginTop: 18,
							width: 200,
							display: 'flex',
							position: 'absolute',
							alignItems: 'center',
							marginBottom: 0,
							marginLeft: 50,
							fontWeight: 700,
						}}>
						{name}
					</p>
				</div>
			)}
		</>
	);
}
