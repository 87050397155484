import React from 'react';
import checkBlanc from '../../../../assets/checkBlanc.png';
import './Step.scss';
import { useProjectContext } from '../../../../contexts/projectContext';
import { ProgressionStatus } from '../utils';
import Colors from '../../../../useful/Colors';
import chevronDown from '../../../../assets/chevronDown.svg';
import chevronUp from '../../../../assets/chevronUp.svg';

export default function StepReorganize({ setPhases, stepData }) {
	const [project] = useProjectContext();
	const projectId = project?.id;

	const reorganizeStep = async (direction) => {
		setPhases((prevState) => {
			const nextState = [...prevState];
			const phase = nextState.find((phase) => phase.id === stepData.phaseId);
			const step = phase.stepList?.find((step) => step.id === stepData.id);
			const index = phase.stepList?.findIndex((item) => item.id === stepData.id);

			if (direction === 'up' && index > 0) {
				const temp = phase.stepList[index - 1];
				phase.stepList[index - 1] = step;
				phase.stepList[index] = temp;
			} else if (direction === 'down' && index < phase.stepList.length - 1) {
				const temp = phase.stepList[index + 1];
				phase.stepList[index + 1] = step;
				phase.stepList[index] = temp;
			} else {
				return nextState;
			}

			return nextState;
		});
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={'etapePhase'}>
				<div className={'stepReorganizeContainer'}>
					<img
						src={chevronUp}
						alt=""
						className={'stepReorganizeArrow'}
						onClick={() => reorganizeStep('up')}
					/>
					<img
						src={chevronDown}
						alt=""
						className={'stepReorganizeArrow'}
						onClick={() => reorganizeStep('down')}
					/>
				</div>
				<div
					className={'stepCircle'}
					style={{
						backgroundColor:
							stepData.status === ProgressionStatus.COMPLETED
								? Colors.PROGRESSION_GREEN
								: stepData.status === ProgressionStatus.IN_PROGRESS
									? Colors.PROGRESSION_BLUE
									: Colors.PROGRESSION_VERY_LIGHT_BLUE,
					}}>
					{stepData.status === ProgressionStatus.COMPLETED && <img src={checkBlanc} alt="" />}
				</div>
				<p className={'titleEtapePhase'}>{stepData.name}</p>
			</div>
		</div>
	);
}
