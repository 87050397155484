import React, { useContext, useEffect, useState } from 'react';
import AgencyContext from '../../contexts/agencyContext';
import TypeContext from '../../contexts/typeContext';
import Resizer from 'react-image-file-resizer';
import checkBlanc from '../../assets/checkBlanc.png';
import O_Opus from '../../assets/O_Opus.png';
import { generateUniqueFirestoreId } from '../../firebase/utils';
//import 'react-phone-number-input/style.css'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, storage } from '../../firebase/config';
//import PhoneInput from "react-phone-number-input";
import { onAuthStateChanged } from 'firebase/auth';

export default function ParametresProfil(props) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);

	const [uid, setUid] = useState();
	const [user, setUser] = useState(null);

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
				setUid(user.uid);
			} else navigate('/');
		});
		return () => {
			listener();
		};
	}, [user]);

	useEffect(() => {
		firestore
			.doc(type === 'collaborators' ? `agencies/${agency}/collaborators/${uid}` : `${type}/${uid}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setNewUserData({ ...documentSnapshot.data(), id: documentSnapshot.id });
				}
			});
	}, [agency, uid, type]);

	const data = {
		name: ' ',
		surname: ' ',
		phone: ' ',
		email: '',
	};
	const [newUserData, setNewUserData] = useState(data);
	const { name, surname, phone, email } = newUserData;

	const handleChange = (e) => {
		setNewUserData({ ...newUserData, [e.target.id]: e.target.value });
	};

	async function save(e) {
		e.preventDefault();
		setWidthEnregistre(200);
		setHeightEnregistre(170);
		setOpacityEnregistre(1);
		setTimeout(() => {
			setWidthEnregistre(0);
			setHeightEnregistre(0);
			setOpacityEnregistre(0);
		}, 1000);
		await firestore
			.doc(type === 'collaborators' ? `agencies/${agency}/collaborators/${uid}` : `${type}/${uid}`)
			.update({ name, surname, phone: phone ?? '', email: email.trim().toLowerCase() });
	}

	//-------------------------------------------------------------------------------------

	const [isCopied, setIsCopied] = useState(false);
	const [widthEnregistre, setWidthEnregistre] = useState(0);
	const [heightEnregistre, setHeightEnregistre] = useState(0);
	const [opacityEnregistre, setOpacityEnregistre] = useState(0);

	return (
		<>
			{newUserData && (
				<div className="contenu">
					<div className={'inner'} style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
						<div
							className={'enregistre'}
							style={{ width: widthEnregistre, height: heightEnregistre, opacity: opacityEnregistre }}>
							<img src={checkBlanc} alt="" className={'checkBlanc'} />
							<p className={'textEnregistre'}>{t('common.saved')}</p>
						</div>
						<p className="title" style={{ fontWeight: '700' }}>
							{t('settings_profile.settings_profile')}
						</p>
						<div>
							<div
								className={'banniere'}
								style={{
									height:
										newUserData.surname?.length === 0 ||
										newUserData.name?.length === 0 ||
										newUserData.phone?.length === 0
											? 60
											: 0,
									paddingTop:
										newUserData.surname?.length === 0 ||
										newUserData.name?.length === 0 ||
										newUserData.phone?.length === 0
											? 10
											: 0,
									marginTop:
										newUserData.surname?.length === 0 ||
										newUserData.name?.length === 0 ||
										newUserData.phone?.length === 0
											? 20
											: 0,
									marginBottom:
										newUserData.surname?.length === 0 ||
										newUserData.name?.length === 0 ||
										newUserData.phone?.length === 0
											? 5
											: 0,
								}}>
								<p className={'title'}>{t('settings_profile.infos_profile_1')}</p>
								<p className={'subtitle'}>{t('settings_profile.infos_profile_2')}</p>
							</div>

							<p className={'designant'} style={{ marginTop: '20px' }}>
								{t('settings_profile.personal_informations')}
							</p>

							<p className={'information'} style={{ marginBottom: 30 }}>
								{t('settings_profile.info_profile_3')}
							</p>

							<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<div style={{ width: 260 }}>
									<p className={'designantInput'}>{t('common.last_name')}</p>
								</div>

								<input type="text" id={'name'} value={name} onChange={handleChange} />

								{name != ' ' && name.length < 1 ? (
									<div
										className={'backgroundColor1'}
										style={{
											marginTop: 34.5,
											marginLeft: 20,
											width: 8,
											height: 8,
											borderRadius: 20,
										}}
									/>
								) : null}
							</div>

							<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<div style={{ width: 260 }}>
									<p className={'designantInput'}>{t('common.first_name')}</p>
								</div>

								<input type="text" id={'surname'} value={surname} onChange={handleChange} />

								{surname != ' ' && surname.length < 1 ? (
									<div
										className={'backgroundColor1'}
										style={{
											marginTop: 34.5,
											marginLeft: 20,
											width: 8,
											height: 8,
											borderRadius: 20,
										}}
									/>
								) : null}
							</div>

							<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<div style={{ width: 260 }}>
									<p className={'designantInput'}>{t('common.phone_number')}</p>
								</div>
								{/*<PhoneInput
                                    placeholder={t('common.phone_number')}
                                    withCountryCallingCode={true}
                                    international={true}
                                    value={phone}
                                    onChange={phone => setNewUserData({...newUserData, phone: phone ?? ''})}
                                    style={{
                                        marginLeft: -41,
                                    }}
                                />*/}
								<input
									type={'text'}
									id={'phone'}
									placeholder={t('common.phone_number')}
									value={phone}
									onChange={handleChange}
								/>

								{phone != ' ' && phone?.length < 1 ? (
									<div
										className={'backgroundColor1'}
										style={{
											marginTop: 34.5,
											marginLeft: 20,
											width: 8,
											height: 8,
											borderRadius: 20,
										}}
									/>
								) : null}
							</div>

							<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<div style={{ width: 260 }}>
									<p className={'designantInput'}>{t('common.email_address')}</p>
								</div>

								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<input
										type="text"
										placeholder={t('common.email_example')}
										id={'email'}
										value={email}
										onChange={handleChange}
									/>

									{email != ' ' && email.length < 1 ? (
										<div
											className={'backgroundColor1'}
											style={{
												marginTop: 34.5,
												marginLeft: 20,
												width: 8,
												height: 8,
												borderRadius: 20,
											}}
										/>
									) : null}
								</div>
							</div>

							<div style={{ display: 'flex' }}>
								<div className={'longBar'} style={{ marginTop: 15 }} />
								<div
									style={{
										width: 200,
										marginLeft: -310,
										display: 'flex',
										justifyContent: 'flex-end',
									}}>
									<button
										className={'enregistrerButton'}
										disabled={!name || !surname || !email}
										onClick={save}>
										<p>{t('common.save')}</p>
									</button>
								</div>
							</div>
						</div>

						<div style={{ display: 'flex' }}>
							<p className={'information'} style={{ marginBottom: '50px', marginTop: -30 }}>
								{t('common.id')} : {uid}
							</p>

							<div style={{ marginTop: -36 }}>
								<button
									className={'copier'}
									onClick={() => {
										setIsCopied(true);
										navigator.clipboard.writeText(uid);
									}}>
									{t('common.copy')}
								</button>
							</div>
							{isCopied ? (
								<p className={'information'} style={{ marginTop: -30, marginLeft: 10 }}>
									{t('common.copied')}
								</p>
							) : null}
						</div>

						<div className="bar" />
					</div>
				</div>
			)}
		</>
	);
}
