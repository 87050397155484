import React, { useContext, useEffect, useState } from 'react';
import './_invitationLinkManager.scss';
import emailIcon from '../../../../assets/emailIcon.svg';
import inviteSent from '../../../../assets/inviteSent.svg';
import { useProjectContext } from '../../../../contexts/projectContext';
import { link, mailtoHref } from '../utils';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../../../contexts/agencyContext';

export default function WidgetSendLink({ projectName, progression, projectImage, isMandataire }) {
	const { t } = useTranslation();
	const agencyId = useContext(AgencyContext);

	const [project] = useProjectContext();

	const [isCopied, setIsCopied] = useState(false);
	const [email, setEmail] = useState('');

	const [linkSent, setLinkSent] = useState(false);
	const [emailSent, setEmailSent] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		window.location.href = mailtoHref(t, email, projectName, project.id);
		setEmail('');
	};

	const [realBar1, setRealBar1] = useState(0);
	const [realBar2, setRealBar2] = useState(0);
	const [realBar3, setRealBar3] = useState(0);
	const [realBar4, setRealBar4] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (isNaN(progression)) {
				null;
			} else {
				setRealBar1(progression < 100 ? (progression - 5 > 0 ? progression - 5 : progression) / 100 : 100);
				setRealBar2(
					progression < 100 ? (progression > 0 ? parseInt(progression) + 5 : progression) / 100 : 100
				);
				setRealBar4(progression < 100 ? (progression - 2 > 0 ? progression - 2 : progression) / 100 : 100);
				setRealBar3(
					progression < 100 ? (progression > 0 ? parseInt(progression) + 2 : progression) / 100 : 100
				);
			}
		}, 400);
	}, [progression]);

	return (
		<div className={'widgetSendLink'}>
			<p className={'titleWidget'} style={{ width: linkSent && 468, marginBottom: 15 }}>
				{isMandataire ? t('translation.sendInvitationLink') : t('translation.inviteYourCollabs')}
			</p>

			<form onSubmit={handleSubmit}>
				<div className={'containerInputEmail'}>
					<img src={emailIcon} alt="" className={'icon'} />
					<input
						required
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						type={'email'}
						placeholder={t('translation.enterEmail')}
						className={'inputEmail'}
					/>
				</div>

				<div
					className={'containerLink'}
					style={{ borderColor: isCopied ? '#5FBA2F' : '#FFF' }}
					onClick={() => {
						navigator.clipboard.writeText(link(project.id)).then((r) => setIsCopied(true));
					}}>
					<p className={'link hover'}>{link(project?.id)}</p>
					<div className={'buttonCopy hover'}>
						{isCopied ? t('translation.copied') : t('translation.copy')}
					</div>
				</div>

				<button style={{ textDecoration: 'none', borderWidth: 0, backgroundColor: 'rgba(255,255,255,0)' }}>
					<div className={'sendLink hover'}>{t('translation.buttonSendInvitationLink')}</div>
				</button>
			</form>

			{!linkSent && (
				<div className={'projectCard'}>
					<img src={projectImage} alt="" className={'projectPicture'} />

					<div className={'containerInfosProject'}>
						<div className={'flexSpace'}>
							<p className={'projectName'}>{projectName}</p>
							<p className={'percentageProject'}>{progression}%</p>
						</div>

						<div className={'barProject'} />

						<div className="progressBars">
							<div className="firstBar">
								<div
									className="secondBar"
									style={{
										width: `calc(100% * ${realBar1}`,
										maxWidth: '100%',
										backgroundColor: '#4867EC',
									}}
								/>
							</div>

							<div className="firstBar">
								<div
									className="secondBar"
									style={{
										width: `calc(100% * ${realBar2}`,
										maxWidth: '100%',
										backgroundColor: '#4867EC',
									}}
								/>
							</div>

							<div className="firstBar">
								<div
									className="secondBar"
									style={{
										width: `calc(100% * ${realBar3}`,
										maxWidth: '100%',
										backgroundColor: '#64a64a',
									}}
								/>
							</div>

							<div className="firstBar">
								<div
									className="secondBar"
									style={{
										width: `calc(100% * ${realBar4}`,
										maxWidth: '100%',
										backgroundColor: '#64a64a',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
