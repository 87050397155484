import React, { useContext, useEffect, useState } from 'react';
import avatar from '../../../assets/avatar.png';
import { DecorationNotification, ProfileNotification, TextContentNotification } from './NotificationComponents';
import { useUsersContext } from '../../../contexts/usersContext';
import { auth, firestore } from '../../../firebase/config';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	NEW_USER_COLLECTION,
	NOTIFICATION_COLLECTION,
} from '../../../firebase/paths';
import { dateString, getFirstLetters } from '../../../useful/UsefulFunctions';
import AgencyContext from '../../../contexts/agencyContext';
import { getNotificationObject, NotificationObject, NotificationSource, timeSince, updateBadge } from './utils';
import { useTranslation } from 'react-i18next';
import getRoleById, { roles, rolesWithMOA } from '../utils';

export default function NotificationItem({ notification }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const [users, setUsers] = useUsersContext();
	const agencyId = useContext(AgencyContext);

	const [creator, setCreator] = useState({});

	useEffect(() => {
		if (!notification.createdBy?.userId || !notification.createdBy.agencyId) {
			console.error('Notification has no createdBy field', notification);
		}
		if (
			notification.createdBy &&
			notification.createdBy.userId &&
			!users?.some((user) => user.id === notification.createdBy.userId)
		) {
			if (notification.createdBy.userType === 'client') {
				firestore
					.collection(CLIENT_COLLECTION)
					.doc(notification.createdBy.userId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							setUsers((prev) => [
								...prev,
								{
									id: doc.id,
									...doc.data(),
								},
							]);
							setCreator({
								id: doc.id,
								...doc.data(),
							});
						}
					});
			} else {
				if (notification.createdBy.agencyId) {
					firestore
						.collection(AGENCY_COLLECTION)
						.doc(notification.createdBy.agencyId)
						.collection(COLLABORATOR_COLLECTION)
						.doc(notification.createdBy.userId)
						.get()
						.then((doc) => {
							if (doc.exists) {
								setUsers((prev) => [
									...prev,
									{
										id: doc.id,
										...doc.data(),
									},
								]);
								setCreator({
									id: doc.id,
									...doc.data(),
								});
							}
						});
				}
			}
		} else {
			const user = users.find((user) => user.id === notification.createdBy.userId);
			if (user) {
				setCreator(user);
			}
		}
	}, [notification.createdBy]);

	const handleClick = async () => {
		if (notification.isUnread) {
			await firestore
				.collection(NEW_USER_COLLECTION)
				.doc(uid)
				.collection(NOTIFICATION_COLLECTION)
				.doc(notification.id)
				.update({
					isUnread: false,
					readAt: new Date().toISOString(),
				});
			updateBadge(uid);
		}
	};

	return (
		<div className={notification.isUnread ? 'notificationItemBlue' : 'notificationItem'} onClick={handleClick}>
			<div className={'notificationItemContent'}>
				<ProfileNotification
					initiales={getFirstLetters(
						getNotificationObject(notification) === NotificationObject.NEW_AGENCY_IN_PROJECT ||
							(getNotificationObject(notification) === NotificationObject.ACCEPT_TO_JOIN_PROJECT &&
								notification.parent?.uid !== agencyId)
							? notification.parent?.name ?? notification.data[0]?.name
							: creator?.surname + ' ' + creator?.name
					)}
					type={notification.createdBy.userType}
					collabEntreprise={notification.createdBy.agencyId !== agencyId}
					entreprise={
						getNotificationObject(notification) === NotificationObject.NEW_AGENCY_IN_PROJECT ||
						(getNotificationObject(notification) === NotificationObject.ACCEPT_TO_JOIN_PROJECT &&
							notification.parent?.uid !== agencyId &&
							notification.createdBy.userType !== 'client')
					}
				/>

				<div className={'rightPartNotification'}>
					<TextContentNotification
						object={getNotificationObject(notification)}
						initializer={
							getNotificationObject(notification) === NotificationObject.NEW_AGENCY_IN_PROJECT
								? notification.data[0]?.name
								: getNotificationObject(notification) === NotificationObject.ACCEPT_TO_JOIN_PROJECT &&
								    notification.parent?.uid !== agencyId
								  ? notification.parent?.name ?? creator?.surname + ' ' + creator?.name
								  : creator?.surname + ' ' + creator?.name
						}
						multiple={notification.data?.length > 1}
						sameAgency={
							(notification.createdBy.agencyId === agencyId ||
								(notification.source === NotificationSource.INTERVENANTS &&
									notification.parent?.type !== 'agencies')) &&
							getNotificationObject(notification) !== NotificationObject.NEW_AGENCY_IN_PROJECT
						}
						folderName={
							notification.source === NotificationSource.FILES
								? notification.parent?.name ?? t('common.files')
								: null
						}
						visitDate={dateString(t, notification.parent?.orderDate, true)}
						numberVisit={notification.parent?.name?.split(' ')[1]}
						phaseName={
							notification.source === NotificationSource.PROGRESSION ? notification.parent?.name : null
						}
						stepName={notification.data[0]?.name}
						percentage={notification.parent?.progression}
						type={notification.source}
						albumName={
							notification.source === NotificationSource.PHOTOS && notification.parent?.type === 'album'
								? notification.parent?.name
								: null
						}
						agencyName={
							notification.source === NotificationSource.INTERVENANTS ? notification.parent?.name : null
						}
						role={
							getRoleById(
								t,
								notification.createdBy.userType === 'client'
									? '0'
									: notification.parent?.roleIds
									  ? notification.parent.roleIds[0]
									  : '-1'
							)?.name
						}
					/>
					<p className={'notifDate'}>{timeSince(t, notification.createdAt)}</p>
					<DecorationNotification
						notification={notification}
						senderName={creator?.surname + ' ' + creator?.name}
					/>
				</div>
				<div className={'pastille'} style={{ display: notification.isUnread ? 'flex' : 'none' }} />
			</div>
		</div>
	);
}
