import React, { useEffect, useState } from 'react';
import { firestore } from '../../../../firebase/config';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../../firebase/paths';
import { dateString } from '../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';

export default function VisitDetail({ projectId, projectImgUrl, visit, setVisits }) {
	const { t } = useTranslation();

	const [editRemarque, setEditRemarque] = useState(false);
	const [remarque, setRemarque] = useState(visit?.description);

	useEffect(() => {
		if (!remarque) {
			setRemarque(visit?.description);
		}
	}, [visit]);

	return (
		<div className={'visitInfos'}>
			<img src={projectImgUrl} alt="" className={'projectImage'} />

			<div style={{ display: 'flex', alignItems: 'center' }}>
				<p className={'visitNumber'}>{dateString(t, visit.orderDate, true)}</p>
			</div>

			<div className={'whiteBar'} />

			{visit.creator && (
				<div style={{ display: 'flex', alignItems: 'center', gap: 10, marginLeft: 25, marginTop: 10 }}>
					<p className={'createdBy'}>{t('common.created_by')}</p>
					<div className={'profilePicture'}>
						{visit.creator?.surname?.charAt(0)?.toUpperCase() +
							visit.creator?.name?.charAt(0)?.toUpperCase()}
					</div>
					<p className={'createdBy'}>
						{visit.creator?.surname} {visit.creator?.name}
					</p>
				</div>
			)}

			<div className={'containerRemarques'}>
				<p className={'titleRemarques'}>{t('translation.globalNotes')}</p>

				{editRemarque ? (
					<textarea
						name=""
						id=""
						cols="30"
						rows="10"
						value={remarque}
						onChange={(e) => setRemarque(e.target.value)}
						className={'editRemarque'}></textarea>
				) : (
					<p className={'remarque'}>{remarque}</p>
				)}

				<div
					className={'buttonEdit'}
					style={{ backgroundColor: editRemarque ? '#3ea621' : '#1C57DD' }}
					onClick={async () => {
						if (editRemarque) {
							setEditRemarque(false);
							setVisits((prev) => {
								const newVisits = [...prev];
								const index = newVisits.findIndex((v) => v.id === visit.id);
								newVisits[index].description = remarque ?? '';
								return newVisits;
							});
							await firestore
								.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${visit.id}`)
								.update({ description: remarque ?? '' });
						} else {
							setEditRemarque(true);
						}
					}}>
					{editRemarque
						? t('translation.saveTheNotes')
						: remarque?.length === 0
							? t('translation.createNotes')
							: t('translation.editNotes')}
				</div>
			</div>
		</div>
	);
}
