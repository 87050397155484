import React, { useCallback, useState, useEffect, useRef } from 'react';
import threeDots from '../../../../../../assets/threeDots.svg';
import enterIcon from '../../../../../../assets/enterIcon.svg';
import checkVert from '../../../../../../assets/checkVert.svg';
import DropDownPropertyItem from './Drop Down Property Item/DropDownPropertyItem';
import PropTypes from 'prop-types';
import plusCircle from '../../../../../../assets/plusCircle.svg';

export default function ItemOptionPropertyItem({
	option,
	isSelected,
	onChange,
	isNew,
	newName,
	notModifiable,
	handleCreateOption,
	handleRenameOption,
	handleDeleteOption,
}) {
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [isRename, setIsRename] = useState(false);
	const [name, setName] = useState(option?.label);

	const inputRef = useRef(null);

	useEffect(() => {
		setName(option?.label);
	}, [option?.label]);

	useEffect(() => {
		if (inputRef) {
			inputRef.current?.focus();
		}
	}, [inputRef, isRename]);

	const handleClickOutside = useCallback(
		(event) => {
			if (isDropDownOpen && !event.target.closest('.containerDropDownPropertyItem')) {
				setIsDropDownOpen(false);
				setIsRename(false);
			}
		},
		[isDropDownOpen]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	if (isNew) {
		return (
			<div style={{ display: 'flex' }} onClick={handleCreateOption}>
				<div style={{ display: 'flex' }} className={'containerItemDropDown'}>
					<div className={'type'}>{newName}</div>
					<img src={plusCircle} alt="" className={'plusCircle'} />
				</div>
			</div>
		);
	} else {
		return (
			<div style={{ display: 'flex' }}>
				<div
					style={{ display: 'flex' }}
					className={
						isRename
							? 'containerItemDropDownRename'
							: `containerItemDropDown ${isSelected ? 'selected' : ''}`
					}
					onClick={() => !isRename && onChange(option?.key)}>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleRenameOption(name.trim());
							setIsRename(false);
						}}
						className={'formRenameOption'}
						style={{ display: !isRename && 'none' }}>
						<input
							ref={inputRef}
							type="text"
							className={'inputRenameOption'}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<button className={'containerEnterIcon'}>
							<img src={enterIcon} alt="" className={'enterIcon'} />
						</button>
					</form>
					<div className={'type'} style={{ display: isRename && 'none' }}>
						{name}
					</div>
					{!isRename && (
						<img
							src={checkVert}
							alt=""
							className={'checkVert'}
							style={{ display: !isSelected && 'none' }}
						/>
					)}
				</div>

				{!notModifiable && (
					<div className={'hover'} style={{ marginTop: 4 }} onClick={() => setIsDropDownOpen(true)}>
						<img src={threeDots} alt="" />
					</div>
				)}

				<div className={'containerDropDownPropertyItem'}>
					{isDropDownOpen && (
						<DropDownPropertyItem
							setIsRename={setIsRename}
							setIsDropDownOpen={setIsDropDownOpen}
							handleDeleteOption={handleDeleteOption}
						/>
					)}
				</div>
			</div>
		);
	}
}

ItemOptionPropertyItem.propTypes = {
	option: PropTypes.object.isRequired,
	isSelected: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	isNew: PropTypes.bool,
	newName: PropTypes.string,
	notModifiable: PropTypes.bool,
};
