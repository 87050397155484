import { transformString } from '../../useful/UsefulFunctions';

/**
 * Returns "today" if the date is today, "yesterday" if the date is yesterday,
 * the day of the week if the date is within the current week,
 * and the date in dd/mm/yyyy format for all other dates.
 * @param t - Translation function
 * @param dateISOString - Date to format
 * @returns {string} - Formatted date
 */
export function formatDate(t, dateISOString) {
	const inputDate = new Date(dateISOString);
	const today = new Date();

	// Utilisation de setHours pour éviter les problèmes de fuseau horaire
	today.setHours(0, 0, 0, 0);

	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	const days = [
		t('translation.sunday'),
		t('translation.monday'),
		t('translation.tuesday'),
		t('translation.wednesday'),
		t('translation.thursday'),
		t('translation.friday'),
		t('translation.saturday'),
	];

	if (inputDate.toDateString() === today.toDateString()) {
		return t('translation.today');
	} else if (inputDate.toDateString() === yesterday.toDateString()) {
		return t('translation.yesterday');
	} else if (inputDate > yesterday) {
		// Cela couvre les cas comme aujourd'hui, hier et avant-hier
		return days[inputDate.getDay()];
	} else {
		const day = inputDate.getDate().toString().padStart(2, '0');
		const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
		const year = inputDate.getFullYear();
		return `${day}/${month}/${year}`;
	}
}

/**
 * Filter conversations
 * @param {Array} conversations - List of conversations
 * @param {String} research - Research string
 * @param {String} sortSelected - Selected sort
 * @param {String} agencyId - Agency id of the current user
 * @returns {Array} - Filtered conversations
 */
export function filterConversations(conversations, research, sortSelected, agencyId) {
	let filteredConversations = conversations;

	if (sortSelected === 'unread') {
		filteredConversations = conversations.filter((conversation) => conversation.isUnread);
	}
	if (sortSelected === 'clients') {
		filteredConversations = conversations.filter((conversation) => conversation.userType === 'clients');
	}
	if (sortSelected === 'team') {
		filteredConversations = conversations.filter((conversation) => conversation.userAgencyId === agencyId);
	}
	if (sortSelected === 'groups') {
		filteredConversations = conversations.filter((conversation) => conversation.isOrganisation);
	}

	return filteredConversations.filter((conversation) =>
		transformString(conversation.name).includes(transformString(research))
	);
}
