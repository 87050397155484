import React, { useEffect, useState } from 'react';
import plusGreen from '../../../../assets/plusGreen.svg';
import warning from '../../../../assets/warning.svg';
import rightIcon from '../../../../assets/rightIcon.svg';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../firebase/config';
import ProfilePictureContributors from './ProfilePictureContributors';

export default function ContainerGroupContributors({
	agencyId,
	client,
	vous,
	accounts,
	projectId,
	research,
	setResearchNumber,
	setModalAjout,
	me,
	button,
	agence,
	setAgencyUidModal,
	setModalClientsAccounts,
	setModalCollabsAccounts,
	setModalPartnerCompany,
	exteriorCompany,
	index,
	seatType,
	isMandataire,
}) {
	const [agencyData, setAgencyData] = useState();
	const [accessMessaging, setAccessMessaging] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (!client) {
			firestore
				.doc(`agencies/${agencyId}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setAgencyData({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
		}
	}, [agencyId, client]);

	useEffect(() => {
		if (vous) {
			accounts.forEach((account) => {
				firestore
					.doc(`agencies/${agencyId}/collaborators/${account.uid}/projects/${projectId}`)
					.get()
					.then((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							if (documentSnapshot.data().accessMessaging) {
								setAccessMessaging(true);
							}
						}
					});
			});
		}
	}, [agencyId, accounts, projectId, vous]);

	const handleClick = () => {
		if (vous) {
			setModalCollabsAccounts(true);
			setAgencyUidModal(agencyId);
		} else if (agence) {
			setModalPartnerCompany(true);
			setAgencyUidModal(agencyId);
		} else {
			setModalClientsAccounts(true);
			setAgencyUidModal(agencyId);
		}
	};

	return (
		<div className={'containerGroupContributors'} onClick={handleClick}>
			<div className={'designatePart'}>
				<p className={'name'}>
					{client ? t('contributors.contracting_owner') : agencyData?.name}
					{!vous && !client && <span className={'diminutif'}>• {agencyData?.diminutifAgency}</span>}
					{vous && <span className={'vous'}>{t('translation.you')}</span>}
				</p>
				{button && (
					<div className={'buttonGroupContributors hover'} onClick={() => setModalAjout(true)}>
						<img src={plusGreen} alt="" className={'icon'} />
						{button}
					</div>
				)}
			</div>

			<div className={'accessPart'}>
				{client || (vous ? !client && accounts?.length > 1 : !client) ? (
					<div className={'containerProfilePicture'}>
						{accounts &&
							accounts
								.slice(0, 6)
								.map((item, index) => (
									<ProfilePictureContributors
										key={index}
										uid={item.uid}
										type={client ? 'clients' : 'collaborators'}
										agency={agencyId}
										research={research}
										setResearchNumber={setResearchNumber}
									/>
								))}
					</div>
				) : (
					<div style={{ display: 'flex' }}>
						<div className={'warningNoOne'}>
							<img src={warning} className={'warningIcon'} />
							<p className={'messageWarning'}>{t('translation.noCollabAdded')}</p>
						</div>
					</div>
				)}

				<img src={rightIcon} alt="" className={'rightIconAccess'} />
			</div>
		</div>
	);
}
