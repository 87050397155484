import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import chargement from '../assets/chargement.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({ file, width, height, zoom = 1, isPreview = false }) {
	const [numPages, setNumPages] = useState(isPreview ? 1 : null);
	const [heightFactorPreview, setHeightFactorPreview] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		if (isPreview) {
			setNumPages(1);
		} else {
			setNumPages(numPages);
		}
	}

	return (
		<div
			style={{
				overflowY: isPreview ? 'hidden' : 'scroll',
				padding: isPreview ? 0 : 10,
				backgroundColor: '#f8f9fa',
				border: '1px solid #ddd',
				margin: '0 auto',
				height: isPreview ? width * heightFactorPreview : height,
			}}>
			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				options={{ workerSrc: '/pdf.worker.js' }}
				loading={<></>}>
				{numPages
					? Array.from(new Array(numPages), (el, index) => (
							<Page
								style={{
									marginBottom: isPreview ? 0 : 15,
									boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
									backgroundColor: 'rgba(255,255,255,0)',
									padding: 10,
								}}
								key={`page_${index + 1}`}
								pageNumber={index + 1}
								renderTextLayer={false}
								customTextRenderer={false}
								width={width}
								onLoadSuccess={(pdf) => {
									setHeightFactorPreview(pdf.height / pdf.width);
								}}
								loading={
									<div
										style={{
											height: '100vh',
											width: 'calc(0.707 * 100vh)',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<div
											className={'chargementContainer'}
											style={{
												width: 20,
												height: 20,
												marginLeft: 0,
												fontSize: 13,
												fontWeight: 600,
											}}>
											Chargement...
											<img src={chargement} alt="" className={'chargement'} />
										</div>
									</div>
								}
							/>
					  ))
					: null}
			</Document>

			{/*
						<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				options={{ workerSrc: '/pdf.worker.js' }}
				loading={<></>}>
				{numPages
					? Array.from(new Array(numPages), (el, index) => (
							<Page
								style={{
									marginBottom: isPreview ? 0 : 15,
									boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
									backgroundColor: 'rgba(255,255,255,0)',
									padding: 10,
								}}
								key={`page_${index + 1}`}
								pageNumber={index + 1}
								renderTextLayer={false}
								customTextRenderer={false}
								width={width}
								onLoadSuccess={(pdf) => {
									setHeightFactorPreview(pdf.height / pdf.width);
								}}
								loading={
									<div
										style={{
											height: '100vh',
											width: 'calc(0.707 * 100vh)',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<div
											className={'chargementContainer'}
											style={{
												width: 20,
												height: 20,
												marginLeft: 0,
												fontSize: 13,
												fontWeight: 600,
											}}>
											Chargement...
											<img src={chargement} alt="" className={'chargement'} />
										</div>
									</div>
								}
							/>
					  ))
					: null}
			</Document>
			*/}
		</div>
	);
}
