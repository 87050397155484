import React from 'react';
import './_onboardingStep.scss';
import { BlueButton, GoBack, HeaderStep, SingleInput } from '../components/ComponentsForm';
import { STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';
export default function InfosEntrepriseStep({
	lastStep,
	diminutif,
	setDiminutif,
	companyName,
	setCompanyName,
	setStep,
	handleNextStep,
	projectName,
	marginTop,
	setRoleIds,
	roleIds,
}) {
	const { t } = useTranslation();

	return (
		<form
			className={'onboardingStep projectToJoin'}
			style={{ marginTop: marginTop }}
			onSubmit={(e) => {
				e.preventDefault();
				handleNextStep(STEPS.accessProjectLastStep);
			}}>
			<GoBack lastStep={lastStep} setStep={setStep} />
			<HeaderStep
				title={t('translation.joinProjectTitleStep7')}
				subtitle={t('translation.joinProjectSubtitleStep7', { projectName: projectName })}
			/>

			<SingleInput
				value={companyName}
				setValue={setCompanyName}
				title={t('translation.entrepriseName')}
				placeholder={'OPUS Architecture & Design'}
			/>

			<SingleInput
				value={diminutif}
				setValue={setDiminutif}
				title={t('translation.diminutifEntreprise')}
				placeholder={'OA&D'}
			/>

			{lastStep === STEPS.chooseEntreprise && (
				<SingleInput
					select={true}
					setRoleIds={setRoleIds}
					roleIds={roleIds}
					value={diminutif}
					setValue={setDiminutif}
					title={t('translation.socialObject')}
				/>
			)}

			<BlueButton text={t('common.next')} />
		</form>
	);
}
