import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import searchIcon from '../../../../assets/searchIcon.png';
import avatar from '../../../../assets/avatar.png';
import avatar1 from '../../../../assets/avatar1.jpg';
import avatar2 from '../../../../assets/avatar2.jpg';
import plusSquare from '../../../../assets/plusSquare.svg';
import { auth, firestore } from '../../../../firebase/config';
import AccountItem from '../Composants/AccountItem';
import PlaceHolderAccountItem from '../Composants/PlaceHolderAccountItem';
import ModalNewCollaborateur from './ModalNewCollaborateur';
import ModalNewClient from './ModalNewClient';
import ModalNewPartnerAgency from './ModalNewPartnerAgency';
import AgencyContext from '../../../../contexts/agencyContext';
import AccountInfo from '../Composants/AccountInfo';
import AccountModif from '../Composants/AccountModif';
import { useTranslation } from 'react-i18next';
import { useProjectsContext } from '../../../../contexts/projectsContext';

export default function ModalComptesLies({
	modalComptesLies,
	setModalComptesLies,
	collaborateurs,
	clients,
	partenaires,
	agencyProjects,
	accounts,
	setAccounts,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;
	const [projects] = useProjectsContext();

	// Modaux et infos

	const [isInfosContactOpen, setIsInfoContactOpen] = useState(false);
	const [isModifContactOpen, setIsModifContactOpen] = useState(false);
	const [isCreationCompte, setIsCreationCompte] = useState(false);
	const [isModalProjectsOpen, setIsModalProjectsOpen] = useState(false);
	const [modalFelicitationCreationCollab, setModalFelicitationCreationCollab] = useState(false);
	const [modalFelicitationAddClient, setModalFelicitationAddClient] = useState(false);
	const [modalFelicitationAddAgence, setModalFelicitationAddAgence] = useState(false);

	// Recherche

	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState(0);

	// Comptes

	const [selectedAccount, setSelectedAccount] = useState(null);

	// Autorisations

	const [canCreateCollaborateur, setCanCreateCollaborateur] = useState(true);
	const [canDeleteCollaborateur, setCanDeleteCollaborateur] = useState(false);
	const [canCreateClient, setCanCreateClient] = useState(true);
	const [canDeleteClient, setCanDeleteClient] = useState(false);
	const [canCreateAgencePartenaire, setCreateAgencePartenaire] = useState(false);
	const [canDeleteAgencePartenaire, setCanDeleteAgencePartenaire] = useState(false);

	useEffect(() => {
		if (modalComptesLies) {
			firestore
				.doc(`agencies/${agency}/collaborators/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						if (documentSnapshot.data().subtype === 'administrator') {
							setCanCreateCollaborateur(true);
							setCanDeleteCollaborateur(true);
							setCanCreateClient(true);
							setCanDeleteClient(true);
							setCreateAgencePartenaire(true);
							setCanDeleteAgencePartenaire(true);
						} else if (documentSnapshot.data().subtype === 'collaborator') {
							setCanCreateCollaborateur(false);
							setCanDeleteCollaborateur(false);
							setCanCreateClient(true);
							setCanDeleteClient(true);
							setCreateAgencePartenaire(false);
							setCanDeleteAgencePartenaire(false);
						} else {
							setCanCreateCollaborateur(documentSnapshot.data().canCreateCollaborator);
							setCanDeleteCollaborateur(documentSnapshot.data().canDeleteCollaborator);
							setCanCreateClient(documentSnapshot.data().canCreateClient);
							setCanDeleteClient(documentSnapshot.data().canDeleteClient);
							setCreateAgencePartenaire(documentSnapshot.data().canCreatePartnerAgency);
							setCanDeleteAgencePartenaire(documentSnapshot.data().canDeletePartnerAgency);
						}
					}
				});
		}
	}, [agency, collaborateurs, clients, partenaires, modalComptesLies]);

	// Click outside

	const refAll = useRef();
	const refList = useRef();
	const refInfo = useRef();
	const refModif = useRef();

	function handleClickOutside(event) {
		if (
			!isModalProjectsOpen &&
			refAll.current &&
			refList.current &&
			refInfo.current &&
			refModif.current &&
			refAll.current.contains(event.target) &&
			!refList.current.contains(event.target) &&
			!refInfo.current.contains(event.target) &&
			!refModif.current.contains(event.target)
		) {
			setIsInfoContactOpen(false);
			setSelectedAccount(null);
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refAll, refList, refInfo, refModif, isModalProjectsOpen]);

	return (
		<Modal
			isOpen={modalComptesLies}
			className={'modalComptesLies'}
			overlayClassName="OverlayModalComptesLies"
			onRequestClose={() => {
				setModalComptesLies(false);
				setIsCreationCompte(false);
				setIsInfoContactOpen(false);
				setIsModifContactOpen(false);
				setSelectedAccount(null);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<div className={'modalListe'} style={{ width: isCreationCompte ? 830 : 1300 }} ref={refAll}>
				<div style={{ display: 'flex' }}>
					<div className={'transition2'} style={{ width: isCreationCompte ? 580 : 1000 }}>
						{collaborateurs ? (
							<p className={'title'}>{t('modal_accounts.collab_account')}</p>
						) : clients ? (
							<p className={'title'}>{t('modal_accounts.clients_accounts')}</p>
						) : partenaires ? (
							<p className={'title'}>{t('modal_accounts.agencies_accounts')}</p>
						) : null}

						{collaborateurs ? (
							<p className={'subtitle'}>{t('modal_accounts.manage_your_collab')}</p>
						) : clients ? (
							<p className={'subtitle'}>{t('modal_accounts.manage_your_clients')}</p>
						) : partenaires ? (
							<p className={'subtitle'}>{t('modal_accounts.manage_partner_agencies')}</p>
						) : null}
					</div>

					<div style={{ display: 'flex', justifyContent: 'flex-end', width: 285 }}>
						{(collaborateurs && canCreateCollaborateur) ||
						(clients && canCreateClient) ||
						(partenaires && canCreateAgencePartenaire) ? (
							<div
								onClick={() => {
									if (isCreationCompte) {
										setIsCreationCompte(false);
									} else {
										setIsInfoContactOpen(false);
										setIsModifContactOpen(false);
										setIsCreationCompte(true);
									}
								}}
								style={{
									display: 'flex',
									marginRight: 10,
									marginTop: 60,
									backgroundColor: '#eeeeee',
									paddingLeft: 10,
									paddingRight: 10,
									borderRadius: 5,
									marginBottom: -47,
									height: 30,
									opacity: isCreationCompte ? 0 : 1,
								}}
								className={'hoverTransition'}>
								<img
									style={{
										marginTop: 8,
										height: 14,
										marginRight: 5,
										objectFit: 'contain',
									}}
									src={plusSquare}
									alt="iconPlus"
								/>
								<p
									style={{
										marginLeft: 3,
										fontSize: 12,
										marginTop: 7,
										fontWeight: 600,
										color: '#000',
									}}>
									{collaborateurs
										? t('modal_accounts.create_collab_account')
										: clients
											? t('modal_accounts.add_client_account')
											: partenaires
												? t('modal_accounts.add_partner_agencies')
												: null}
								</p>
							</div>
						) : null}
					</div>

					<img
						src={crossIcon}
						alt="crossIcon"
						className={'crossIcon'}
						onClick={() => {
							setModalComptesLies(false);
							setIsCreationCompte(false);
							setIsInfoContactOpen(false);
							setIsModifContactOpen(false);
							setSelectedAccount(null);
						}}
					/>
				</div>

				<div style={{ display: 'flex', overflow: 'scroll', width: 1300 }}>
					<div
						className={'contacts'}
						style={{ width: isInfosContactOpen || isCreationCompte || isModifContactOpen ? 830 : 1300 }}>
						<div className={'headerListCollabs'}>
							<div
								style={{
									marginLeft: 30,
									width: 260,
									display: 'flex',
								}}>
								<img
									src={searchIcon}
									alt=""
									style={{ width: 20, objectFit: 'contain', marginTop: 4, marginRight: 5 }}
								/>
								<input
									type="text"
									value={research}
									onChange={(e) => setResearch(e.target.value)}
									className={'inputResearchAccounts'}
									placeholder={t('modal_accounts.search')}
								/>
							</div>
							<div
								style={{
									marginLeft: 30,
									width: isInfosContactOpen || isCreationCompte || isModifContactOpen ? 0 : 240,
									overflow: 'hidden',
									display: 'flex',
									justifyContent: 'center',
								}}>
								<p className={'itemHeadListCollab'}>{t('activity.a_creation')}</p>
							</div>
							<div
								style={{
									marginLeft: 30,
									width: 240,
									display: 'flex',
									justifyContent: 'center',
								}}>
								<p className={'itemHeadListCollab'}>
									{collaborateurs ? t('billing.status') : t('projects_page.projects')}
								</p>
							</div>
							<div
								style={{
									marginLeft: 30,
									width: isInfosContactOpen || isCreationCompte || isModifContactOpen ? 0 : 240,
									overflow: 'hidden',
									display: 'flex',
									justifyContent: 'center',
								}}>
								<p className={'itemHeadListCollab'}>{t('modal_accounts.activity')}</p>
							</div>
							<div style={{ marginLeft: 115 }}>
								<p className={'itemHeadListCollab'}>{t('modal_accounts.actions')}</p>
							</div>
						</div>

						<div
							className={'bar'}
							style={{
								width: isCreationCompte ? 780 : isInfosContactOpen || isModifContactOpen ? 1270 : 1240,
							}}
						/>

						{accounts.length > 0 ? (
							<div className={'listAccounts'}>
								<div ref={refList}>
									{accounts.map((item, index) => (
										<AccountItem
											key={index}
											setIsInfoContactOpen={setIsInfoContactOpen}
											isInfosContactOpen={isInfosContactOpen}
											setIsModifContactOpen={setIsModifContactOpen}
											isModifContactOpen={isModifContactOpen}
											uid={item?.uid}
											type={collaborateurs ? 'collaborators' : clients ? 'clients' : 'agencies'}
											isCreationCompte={isCreationCompte}
											setIsCreationCompte={setIsCreationCompte}
											collaboratorData={item}
											selectedAccount={selectedAccount}
											research={research}
											setResearchNumber={setResearchNumber}
											setSelectedAccount={setSelectedAccount}
											agencyProjects={agencyProjects}
											canCreate={
												collaborateurs
													? canCreateCollaborateur
													: clients
														? canCreateClient
														: canCreateAgencePartenaire
											}
											canDelete={
												collaborateurs
													? canDeleteCollaborateur
													: clients
														? canDeleteClient
														: canDeleteAgencePartenaire
											}
										/>
									))}
								</div>
								<div style={{ height: 10 }} />
							</div>
						) : (
							<>
								<div className={'listAccounts'} style={{ zIndex: 2 }}>
									<div
										className={'transition2'}
										style={{
											width: isCreationCompte ? 800 : 1300,
											display: 'flex',
											justifyContent: 'center',
										}}>
										<div
											className={'modalBienvenue'}
											style={{
												position: 'relative',
												height: 270,
												margin: 0,
												boxShadow: '0px 0px 20px 0px rgba(51, 51, 51, 0)',
												marginTop: 30,
												marginBottom: 40,
											}}>
											<div>
												<div className={'textPart'}>
													<p className={'title'}>
														{collaborateurs
															? t('modal_accounts.let_add_a_first_collab')
															: clients
																? t('modal_accounts.let_add_a_first_client')
																: partenaires
																	? t('modal_accounts.let_add_first_agency')
																	: null}
													</p>
													<p className={'subtitle'}>
														{collaborateurs
															? t('modal_accounts.info_add_collab')
															: clients
																? t('modal_accounts.info_add_clients')
																: partenaires
																	? t('modal_accounts.info_add_agencies')
																	: null}
													</p>
												</div>
											</div>

											<div className={'buttonPart'}>
												<div onClick={() => setIsCreationCompte(true)} className={'button'}>
													{collaborateurs
														? t('modal_accounts.add_my_first_collab')
														: clients
															? t('modal_accounts.add_my_first_client')
															: partenaires
																? t('modal_accounts.add_my_first_agency')
																: null}
												</div>
											</div>
										</div>
									</div>

									<PlaceHolderAccountItem
										isCreationCompte={isCreationCompte}
										collaborateurs={collaborateurs}
										nom={'Louis Richard'}
										img={avatar1}
										date={'01/12/2022'}
										statut={t('common.admin')}
										activity={t('common.today')}
										projets={'1 Projet'}
									/>
									<PlaceHolderAccountItem
										isCreationCompte={isCreationCompte}
										collaborateurs={collaborateurs}
										nom={'Emma Weber'}
										img={avatar}
										date={'12/05/2022'}
										statut={t('collab.collab')}
										activity={t('collab.yesterday')}
										projets={'3 Projets'}
									/>
									<PlaceHolderAccountItem
										isCreationCompte={isCreationCompte}
										collaborateurs={collaborateurs}
										nom={'Jules Andrieux'}
										img={avatar2}
										date={'25/04/2022'}
										statut={t('collab.collab')}
										activity={t('common.today')}
										projets={'0 Projet'}
									/>
								</div>
								<div
									className={'voileBlanc'}
									style={{
										zIndex: 1,
										width: isCreationCompte ? 800 : 1300,
										height: 250,
										marginTop: -250,
									}}
								/>
							</>
						)}
					</div>

					<div ref={refInfo}>
						<AccountInfo
							accountData={selectedAccount}
							isInfosContactOpen={isInfosContactOpen}
							isClient={clients}
							isPartner={partenaires}
							isCollaborator={collaborateurs}
							authProjects={projects}
							isModalProjectsOpen={isModalProjectsOpen}
							setIsModalProjectsOpen={setIsModalProjectsOpen}
							isModifContactOpen={isModifContactOpen}
						/>
					</div>

					<div ref={refModif}>
						<AccountModif
							accountData={selectedAccount}
							isModifContactOpen={isModifContactOpen}
							isInfosContactOpen={isInfosContactOpen}
							isCollaborator={collaborateurs}
							isPartner={partenaires}
							isClient={clients}
							setAccounts={setAccounts}
							agencyProjects={agencyProjects}
							setIsModifContactOpen={setIsModifContactOpen}
							canCreate={
								collaborateurs
									? canCreateCollaborateur
									: clients
										? canCreateClient
										: canCreateAgencePartenaire
							}
							canDelete={
								collaborateurs
									? canDeleteCollaborateur
									: clients
										? canDeleteClient
										: canDeleteAgencePartenaire
							}
						/>
					</div>
				</div>
			</div>

			{collaborateurs && (
				<ModalNewCollaborateur
					modalFelicitationCreationCollab={modalFelicitationCreationCollab}
					setModalFelicitationCreationCollab={setModalFelicitationCreationCollab}
					isCreationCompte={isCreationCompte}
					setIsCreationCompte={setIsCreationCompte}
					authProjects={projects}
					numberCollab={accounts.length + 1}
					setAccounts={setAccounts}
				/>
			)}

			{clients && (
				<ModalNewClient
					modalFelicitationAddClient={modalFelicitationAddClient}
					setModalFelicitationAddClient={setModalFelicitationAddClient}
					isCreationCompte={isCreationCompte}
					setIsCreationCompte={setIsCreationCompte}
					setAccounts={setAccounts}
					authProjects={projects}
				/>
			)}

			{partenaires && (
				<ModalNewPartnerAgency
					isCreationCompte={isCreationCompte}
					setIsCreationCompte={setIsCreationCompte}
					setAccounts={setAccounts}
					modalFelicitationAddAgence={modalFelicitationAddAgence}
					setModalFelicitationAddAgence={setModalFelicitationAddAgence}
				/>
			)}

			{(modalFelicitationCreationCollab || modalFelicitationAddClient || modalFelicitationAddAgence) && (
				<div
					onClick={() => window.alert(t('modal_accounts.need_to_click_on_finish'))}
					style={{
						position: 'absolute',
						width: 830,
						height: 750,
						backgroundColor: 'rgba(255,255,255,0.62)',
						borderRadius: 10,
					}}
				/>
			)}
		</Modal>
	);
}
