import React, { useCallback, useEffect, useState } from 'react';
import './_ticketFilterItem.scss';
import DownGrey from '../../../../../../assets/DownGrey.svg';
import downBlue from '../../../../../../assets/downBlue.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function TicketFilterItem({ name, handleClick, isTrue, pastille, dropDown, options, array, setArray }) {
	const { t } = useTranslation();

	const [dropDownOption, setDropDownOption] = useState(false);

	const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

	function addElementToArray(option) {
		setArray((prevArray) => {
			if (prevArray.length < 1) return options.filter((item) => item.key !== option).map((item) => item.key);
			if (prevArray.includes(option)) return prevArray.filter((item) => item !== option);
			return [...prevArray, option];
		});
	}

	const handleClickOutside = useCallback(
		(event) => {
			if (dropDownOption && !event.target.closest('.containerDropDowOptions')) {
				setDropDownOption(null);
			}
		},
		[dropDownOption]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	const handleContextMenu = (event) => {
		event.preventDefault();
		setMenuPosition({ x: event.pageX - 170, y: event.pageY + 20 });
		setTimeout(() => {
			setDropDownOption(true);
		}, 100);
	};

	return (
		<>
			<div
				className={isTrue ? 'ticketFilterItemSelected' : 'ticketFilterItem'}
				style={pastille > 0 ? { paddingTop: 3, paddingBottom: 3, paddingRight: 3 } : {}}
				onClick={!dropDown ? handleClick : handleContextMenu}>
				{name}
				{pastille > 0 && (
					<div className={'pastille'}>
						<p>{pastille}</p>
					</div>
				)}
				{dropDown && <img src={isTrue ? downBlue : DownGrey} alt="" className={'downIcon'} />}
			</div>
			{dropDownOption && (
				<div
					className={'containerDropDowOptions'}
					style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}>
					<div className={'dropDownOptions'}>
						{options.map((option, index) => (
							<li
								key={index}
								onClick={() => addElementToArray(option?.key)}
								className={'context-menu-item'}
								style={{ color: option?.color ?? 'black' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img src={option?.icon} alt="" className={'itemIcon'} />
									{option?.label}
								</div>

								<input
									type="checkbox"
									id="myCheckbox"
									name="myCheckbox"
									checked={array.length < 1 || array.includes(option?.key)}
									style={{ marginLeft: 15 }}
								/>
							</li>
						))}
						<div className={'buttonSelectUnselect hover'} onClick={() => setArray([])}>
							{t('translation.selectAll')}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

TicketFilterItem.propTypes = {
	name: PropTypes.string.isRequired,
	handleClick: PropTypes.func.isRequired,
	isTrue: PropTypes.bool.isRequired,
	pastille: PropTypes.number,
	dropDown: PropTypes.bool,
	options: PropTypes.array,
	array: PropTypes.array,
	setArray: PropTypes.func,
};
