import React from 'react';
import { useTranslation } from 'react-i18next';
import './_greyButton.scss';

export default function GreyButton({ functionOnClick, image, title, conditionNoImage, backgroundColor, marginRight }) {
	return (
		<div className={'greyButton'} onClick={functionOnClick} style={{ backgroundColor: backgroundColor }}>
			{!conditionNoImage && <img className={'icon'} src={image} alt="iconPlus" />}
			<p className={'titleButton'} style={{ marginRight: marginRight }}>
				{title}
			</p>
		</div>
	);
}
