import React from 'react';
import './_onboardingStep.scss';
import { BlueButton, DoubleInput, GoBack, HeaderStep, SingleInput } from '../components/ComponentsForm';
import { STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';
export default function ProfileStep({
	setStep,
	name,
	setName,
	surname,
	setSurname,
	phoneNumber,
	setPhoneNumber,
	emailAddress,
	setEmailAddress,
	handleNextStep,
	marginTop,
	projectName,
	lastStep,
}) {
	const { t } = useTranslation();

	return (
		<form
			className={'onboardingStep projectToJoin'}
			style={{ marginTop: marginTop }}
			onSubmit={(e) => {
				e.preventDefault();
				handleNextStep(STEPS.securityProfile);
			}}>
			<GoBack lastStep={lastStep} setStep={setStep} />
			<HeaderStep
				title={t('translation.joinProjectTitleStep2')}
				subtitle={t('translation.joinProjectSubtitleStep2', { projectName: projectName })}
			/>

			<DoubleInput
				firstInput={name}
				secondInput={surname}
				placehodlerFirstInput={'Quentin'}
				placehodlerSecondInput={'Andrieux'}
				setFirstInput={setName}
				setSecondInput={setSurname}
				titleFirstInput={t('common.first_name')}
				titleSecondInput={t('common.last_name')}
			/>

			<SingleInput
				phone={true}
				value={phoneNumber}
				setValue={setPhoneNumber}
				title={t('common.phone_number')}
				placeholder={'+33 7 69 09 48 54'}
			/>

			<SingleInput
				value={emailAddress}
				setValue={setEmailAddress}
				title={t('common.email_address')}
				placeholder={'quentin.andrieux@opusoft.fr'}
			/>

			<BlueButton text={t('common.next')} />
		</form>
	);
}
