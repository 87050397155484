import React, { useRef, useState, useEffect, useContext } from 'react';
import './ModalNameVersion.css';
import Modal from 'react-modal';
import crossIcon from 'src/assets/crossIcon.png';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

ModalNameVersion.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	defaultName: PropTypes.string.isRequired,
	defaultVersion: PropTypes.string.isRequired,
};

export default function ModalNameVersion({ isOpen, onClose, onSubmit, defaultName, defaultVersion }) {
	const { t } = useTranslation();

	const ref = useRef(null);

	const [text, setText] = useState(defaultName);
	const [selectedVersion, setSelectedVersion] = useState(defaultVersion);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setText(defaultName);
		setSelectedVersion(defaultVersion);
	}, [defaultName, defaultVersion]);

	const handleClose = () => {
		setIsLoading(false);
		onClose();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		await onSubmit(text, selectedVersion);

		handleClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			className="modalNameVersion"
			overlayClassName="overlayModalNameVersion"
			onRequestClose={handleClose}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<div className="modalNameVersion__crossButton" onClick={handleClose}>
				<img src={crossIcon} alt="" />
			</div>
			<p className="modalNameVersion__title">{t('translation.nameOfTheVersion')}</p>

			<form onSubmit={handleSubmit}>
				<div style={{ display: 'flex' }}>
					<input
						style={{ width: 314 }}
						type={'text'}
						ref={ref}
						placeholder={t('common.last_name')}
						value={text}
						onChange={(e) => setText(e.target.value)}
					/>
					<div className="modalNameVersion__selectContainer">
						<select value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)}>
							<option value="">{t('translation.version')}</option>
							{options.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
				</div>
				<button
					className="modalNameVersion__button"
					style={{ borderWidth: 0 }}
					disabled={text.length === 0 || selectedVersion.length === 0 || isLoading}>
					{isLoading ? t('common.loading') : t('common.save')}
				</button>
			</form>
		</Modal>
	);
}

const options = [
	{ value: '0', label: '0' },
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' },
	{ value: 'E', label: 'E' },
	{ value: 'F', label: 'F' },
	{ value: 'G', label: 'G' },
	{ value: 'H', label: 'H' },
	{ value: 'I', label: 'I' },
	{ value: 'J', label: 'J' },
	{ value: 'K', label: 'K' },
	{ value: 'L', label: 'L' },
	{ value: 'M', label: 'M' },
	{ value: 'N', label: 'N' },
	{ value: 'O', label: 'O' },
	{ value: 'P', label: 'P' },
	{ value: 'Q', label: 'Q' },
	{ value: 'R', label: 'R' },
	{ value: 'S', label: 'S' },
	{ value: 'T', label: 'T' },
	{ value: 'U', label: 'U' },
	{ value: 'V', label: 'V' },
	{ value: 'W', label: 'W' },
	{ value: 'X', label: 'X' },
	{ value: 'Y', label: 'Y' },
	{ value: 'Z', label: 'Z' },
];
