import React from 'react';
import './_onboardingStep.scss';
import {
	BlueButton,
	ButtonCreateCompany,
	EntrepriseItemChoice,
	GoBack,
	HeaderStep,
} from '../components/ComponentsForm';
import { STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';
import leftIcon from '../../../assets/leftIcon.svg';

export default function ChooseACompanyStep({
	selectedCid,
	setStep,
	setSelectedCid,
	handleNextStep,
	createCompany,
	setCreateCompany,
	marginTop,
	companyIds,
	projectName,
	lastStep,
	agencyProject,
	setCompanyName,
}) {
	const { t } = useTranslation();

	return (
		<div className={'onboardingStep projectToJoin'} style={{ marginTop: marginTop }}>
			<GoBack lastStep={lastStep} setStep={setStep} />
			<HeaderStep
				title={t('translation.joinProjectTitleStep8')}
				subtitle={t('translation.joinProjectSubtitleStep8', { projectName: projectName })}
			/>
			<div style={{ display: 'flex' }}>
				<p className={'canScroll'}>{t('translation.canScroll')}</p>
				<img src={leftIcon} alt="" className={'leftIconScroll'} />
			</div>

			<div
				style={{
					display: 'flex',
					maxHeight: 'calc(100vh - 550px)',
					overflowY: 'scroll',
				}}>
				<div className={'listEnterprises'}>
					<div style={{ display: 'flex' }}>
						<ButtonCreateCompany
							setValue={setCreateCompany}
							value={createCompany}
							setSelectedCid={setSelectedCid}
							setCompanyName={setCompanyName}
						/>
					</div>

					{companyIds.map((id) => (
						<div style={{ display: 'flex' }}>
							<EntrepriseItemChoice
								key={id}
								cid={id}
								selectedCid={selectedCid}
								setSelectedCid={setSelectedCid}
								setCreateCompany={setCreateCompany}
								agencyProject={agencyProject}
								setCompanyName={setCompanyName}
							/>
						</div>
					))}
				</div>
			</div>

			<BlueButton handleClick={handleNextStep} text={t('common.next')} />
		</div>
	);
}
