import React from 'react';
import './_onboardingStep.scss';
import {
	BlueButton,
	CardAgencyProject,
	GoBack,
	HeaderStep,
	ProjectCardOnboarding,
	SingleInput,
} from '../components/ComponentsForm';
import bigCheckBleu from '../../../assets/bigCheckBleu.png';
import { useTranslation } from 'react-i18next';
import FalseNotificationItem from '../../Fiche Projet/Notifications/FalseNotificationItem';
import { desescapeSpecialChars, getFirstLetters } from '../../../useful/UsefulFunctions';
import NotificationItem from '../../Fiche Projet/Notifications/NotificationItem';
import { ROLES } from '../JoinProject';
import getRoleById, { createdByConstructor } from '../../Fiche Projet/utils';
import { getNotificationObject, timeSince } from '../../Fiche Projet/Notifications/utils';
import { ProfileNotification, TextContentNotification } from '../../Fiche Projet/Notifications/NotificationComponents';

export default function AccessProjectFinalStep({
	projectName,
	agencyProject,
	diminutifAgencyProject,
	projectImg,
	projectPercentage,
	marginTop,
	handleFinish,
	isLoading,
	isLoading2,
	isLoading1,
	isLoading3,
	mockNotification,
	agencyToNotifyName,
}) {
	const { t } = useTranslation();

	const [acceptedTerms, setAcceptedTerms] = React.useState(false);
	const [acceptedPrivacy, setAcceptedPrivacy] = React.useState(false);

	return (
		<div className={'onboardingStep projectToJoin'} style={{ marginTop: marginTop }}>
			<HeaderStep
				title={t('translation.joinProjectTitleStep9')}
				subtitle={t('translation.joinProjectSubtitleStep9')}
			/>

			<p className={'petitTitre'}>
				{t('translation.hereTheNotificationForCollab')} {agencyToNotifyName} :
			</p>

			<div className={'notificationItemBlue'}>
				<div className={'notificationItemContent'}>
					<ProfileNotification
						initiales={getFirstLetters(mockNotification.senderName)}
						entreprise={mockNotification.isCompany}
					/>
					<div className={'rightPartNotification'}>
						<TextContentNotification
							object={getNotificationObject(mockNotification)}
							initializer={mockNotification.senderName}
							role={getRoleById(t, mockNotification.roleIds[0])?.name}
							sameAgency={!mockNotification.isCompany}
						/>
						<p className={'notifDate'}>{timeSince(t, mockNotification.createdAt)}</p>
						<div className={'containerButtons'}>
							<button className={'acceptButton'} disabled={true}>
								{t('translation.accept')}
							</button>
							<button className={'refuseButton'} disabled={true}>
								{t('translation.refuse')}{' '}
							</button>
						</div>
					</div>
					<div className={'pastille'} style={{ display: 'flex' }} />
				</div>
			</div>

			<BlueButton
				text={t('translation.askPermissionToEnter')}
				handleClick={handleFinish}
				isLoading={isLoading}
				disabled={!acceptedTerms || !acceptedPrivacy}
				subtitle={desescapeSpecialChars(
					t('translation.weWillAskPermission', { agencyProject: agencyToNotifyName })
				)}
			/>

			{!isLoading && (
				<div className="checkbox-container">
					<div>
						<input
							type="checkbox"
							id="acceptTerms"
							checked={acceptedTerms}
							onChange={(e) => setAcceptedTerms(e.target.checked)}
						/>
						<label className={'hover'} htmlFor="acceptTerms">
							{t('translation.acceptTerms')}
						</label>{' '}
						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
							target={'_blank'}>
							<p className={'consult hover'}>{t('translation.consult')}</p>
						</a>
					</div>
					<div>
						<input
							type="checkbox"
							id="acceptPrivacy"
							checked={acceptedPrivacy}
							onChange={(e) => setAcceptedPrivacy(e.target.checked)}
						/>
						<label className={'hover'} htmlFor="acceptPrivacy">
							{t('translation.acceptPrivacy')}
						</label>{' '}
						<a
							href="https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c"
							target={'_blank'}>
							<p className={'consult hover'}>{t('translation.consult')}</p>
						</a>
					</div>
				</div>
			)}

			<div className={'multiStepLoading'}>
				<div className={'itemMultiStepLoading'} style={{ opacity: !isLoading ? 0 : !isLoading1 ? 0.4 : 1 }}>
					<img src={bigCheckBleu} alt="" className={'icon'} />
					<p className={'titleItem'}>{t('translation.creationOfYourAccount')}</p>
				</div>
				<div className={'itemMultiStepLoading'} style={{ opacity: !isLoading ? 0 : !isLoading2 ? 0.4 : 1 }}>
					<img src={bigCheckBleu} alt="" className={'icon'} />
					<p className={'titleItem'}>
						{t('translation.linkOfYourAccount')} {projectName}{' '}
					</p>
				</div>
				<div className={'itemMultiStepLoading'} style={{ opacity: !isLoading ? 0 : !isLoading3 ? 0.4 : 1 }}>
					<img src={bigCheckBleu} alt="" className={'icon'} />
					<p className={'titleItem'}>{t('translation.notificationOfIntern')}</p>
				</div>
			</div>
		</div>
	);
}
