import React, { useEffect, useState } from 'react';
import peopleTwo from '../../../../../assets/peopleTwo.png';
import { firestore } from '../../../../../firebase/config';
import { joinPaths } from '../../../../../firebase/utils';
import { useTranslation } from 'react-i18next';

export default function ContainerIdentity({ isLarge, setIsLarge, data, agencyId, isGroup }) {
	const [agencyName, setAgencyName] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		if (data.userAgencyId) {
			firestore
				.doc(joinPaths('agencies', data.userAgencyId))
				.get()
				.then((doc) => {
					if (doc.exists) {
						setAgencyName(doc.data().name);
					}
				});
		}
	}, [data.userAgencyId]);

	return (
		<div
			className={isLarge ? 'containerIdentityLarge' : 'containerIdentity'}
			style={{
				flexDirection: isLarge ? 'column' : 'row',
				transition: 'all ease-in-out 0.2s',
			}}
			onClick={() => setIsLarge(!isLarge)}>
			<div
				className={
					isLarge
						? data.userType === 'clients'
							? 'profilePictureVertLarge'
							: data.userAgencyId === agencyId || data.isGroup
							  ? 'profilePictureBleuLarge'
							  : 'profilePictureBleuClairLarge'
						: data.userType === 'clients'
						  ? 'profilePictureVert'
						  : data.userAgencyId === agencyId || data.isGroup
						    ? 'profilePictureBleu'
						    : 'profilePictureBleuClair'
				}
				style={{ transform: 'scale(0.8)' }}>
				{data.isGroup ? (
					<img src={peopleTwo} alt="" style={{ width: isLarge ? 50 : 30 }} />
				) : (
					data.firstName?.at(0)?.toUpperCase() + data.lastName?.at(0)?.toUpperCase()
				)}
			</div>

			<div style={{ marginLeft: 8 }} className={'containerName'}>
				<p className={'contactName'}>{data.name}</p>
				<p className={'contactType'}>
					{data.isGroup
						? t('translation.groupChat')
						: data.userType === 'clients'
						  ? t('translation.client')
						  : agencyName}
				</p>
			</div>
		</div>
	);
}
