import { createContext, useContext } from 'react';

const ProjectLocationsContext = createContext();

export function useProjectLocations() {
	const context = useContext(ProjectLocationsContext);
	if (!context) {
		throw new Error('useProjectLocations must be used within a ProjectLocationsProvider');
	}
	return context;
}

export default ProjectLocationsContext;
