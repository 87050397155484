import React, { useEffect } from 'react';
import Modal from 'react-modal';
import whiteLeft from '../../../../../assets/whiteLeft.svg';
import { ProgressBar, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import leftIcon from '../../../../../assets/leftIcon.png';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { useProjectContext } from '../../../../../contexts/projectContext';

export default function ModalPreviewDocument({
	modalPreview,
	setModalPreview,
	files,
	previewFile,
	setPreviewFile,
	toggleFileSelection,
}) {
	const { t } = useTranslation();

	const { project } = useProjectContext();

	const filesToDisplay = files?.filter(
		(file) =>
			!file.isFolder &&
			file.path === previewFile?.path &&
			(file.type?.includes('image') ||
				file.type?.includes('heic') ||
				file.type?.includes('heif') ||
				file.type?.includes('pdf'))
	);
	const startIndex = filesToDisplay?.map((it) => it.id)?.findIndex((it) => it === previewFile?.id);

	const newplugin = defaultLayoutPlugin();

	useEffect(() => {
		if (previewFile && project) {
			mixpanel.track('File View', {
				'Project ID': project?.id,
				Page: 'File',
				'File Type': previewFile?.type,
			});
		}
	}, [previewFile, project]);

	const nextFile = () => {
		const newIndex = startIndex + 1;
		if (newIndex < filesToDisplay?.length) {
			setPreviewFile(filesToDisplay?.[newIndex]);
			if (toggleFileSelection) {
				toggleFileSelection(filesToDisplay?.[newIndex]);
			}
		}
	};

	const previousFile = () => {
		const newIndex = startIndex - 1;
		if (newIndex >= 0) {
			setPreviewFile(filesToDisplay?.[newIndex]);
			if (toggleFileSelection) {
				toggleFileSelection(filesToDisplay?.[newIndex]);
			}
		}
	};

	useEffect(() => {
		if (modalPreview && startIndex !== -1) {
			const handleKeyPress = (event) => {
				switch (event.key) {
					case 'ArrowRight':
						nextFile();
						break;
					case 'ArrowLeft':
						previousFile();
						break;
					default:
						break;
				}
			};
			document.addEventListener('keydown', handleKeyPress);
			return () => {
				document.removeEventListener('keydown', handleKeyPress);
			};
		}
	}, [modalPreview, startIndex]);

	return (
		<Modal isOpen={modalPreview} className={'modalPhoto'} overlayClassName="overlayModalPhoto">
			<div className={'containerBackMenu'}>
				<div
					style={{ width: 200, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
					className={'goBackButton'}
					onClick={(e) => {
						setModalPreview(false);
					}}>
					<img src={whiteLeft} alt="" className={'icon'} style={{ marginRight: 8, width: 9, marginTop: 1 }} />
					<p style={{ color: '#FFF', fontSize: 15, margin: 0 }}>{t('common.back')}</p>
				</div>

				<div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
					{startIndex !== -1 && (
						<div className={'previousNext'} onClick={previousFile}>
							<img src={leftIcon} alt="" className={'leftIcon'} />
						</div>
					)}
					<p style={{ color: '#FFF', margin: 0, fontSize: 14, whiteSpace: 'nowrap' }}>{previewFile?.name}</p>
					{startIndex !== -1 && (
						<div className={'previousNext'} onClick={nextFile}>
							<img src={leftIcon} alt="" className={'rightIcon'} />
						</div>
					)}
				</div>

				<div className={'threeDots hover'} style={{ opacity: 0, width: 200 }}>
					<div className={'dot'} />
					<div className={'dot'} />
					<div className={'dot'} />
				</div>
			</div>

			{previewFile?.type.includes('image') ||
			previewFile?.type?.includes('heic') ||
			previewFile?.type?.includes('heif') ? (
				<div
					style={{
						display: 'flex',
						width: '100vw',
						height: 'calc(100vh - 60px)',
						marginTop: 60,
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<div style={{ cursor: 'pointer', display: 'flex' }}>
						<img src={previewFile?.url} className={'photoOuvert'} />
					</div>
				</div>
			) : previewFile?.type.includes('pdf') ? (
				previewFile?.url && (
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
						<div className={'pdfViewer'}>
							<Viewer
								theme={'dark'}
								defaultScale={1}
								fileUrl={previewFile?.url}
								plugins={[newplugin]}
								renderLoader={(percentages) => (
									<div style={{ width: '240px' }}>
										<ProgressBar progress={Math.round(percentages)} />
									</div>
								)}
							/>
						</div>
					</Worker>
				)
			) : (
				<div
					style={{
						display: 'flex',
						width: '100vw',
						height: 'calc(100vh - 60px)',
						marginTop: 60,
						alignItems: 'center',
						justifyContent: 'center',
					}}></div>
			)}
		</Modal>
	);
}
