import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import '../messaging.scss';
import Message from './Message/Message';
import MessageDate from './Message/MessageDate';
import { auth, firestore } from '../../../firebase/config';
import { joinPaths } from '../../../firebase/utils';
import { isDifferenceOneHourOrMore, mergeAndOrder } from '../../../useful/utils';
import AgencyContext from '../../../contexts/agencyContext';
import TypeContext from '../../../contexts/typeContext';
import integrerProjetVisuel from '../../../assets/integrerProjetVisuel.png';
import { useTranslation } from 'react-i18next';
import chargement from '../../../assets/chargement.png';
import leftConv from '../../../assets/leftConv.png';
import LockIcon from '../../../assets/LockIcon.png';
import rigthConv from '../../../assets/rigthConv.png';
import ModalPicture from '../../../components/ModalPicture';
import ModalDocument from '../../../components/ModalDocument';

export default function ContainerMessages({
	conversations,
	conversationId,
	messages,
	setMessages,
	setResponseMessage,
}) {
	const uid = auth.currentUser?.uid;
	const { t } = useTranslation();

	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const listRef = useRef(null);

	const [isLoading, setIsLoading] = useState(true);
	const [lastDocumentSnapshot, setLastDocumentSnapshot] = useState(null);
	const [isMoreLoading, setIsMoreLoading] = useState(false);

	const conversation = conversations?.find((conv) => conv.id === conversationId);

	const [modalPreviewPicture, setModalPreviewPicture] = useState(false);
	const [modalPreviewFile, setModalPreviewFile] = useState(false);
	const [selectedPicture, setSelectedPicture] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const pictures = messages.filter((message) => message.type === 'picture' || message.type === 'video');
	const files = messages.filter((message) => message.type === 'file');

	const [receiverLastClickAt, setReceiverLastClickAt] = useState('');

	useEffect(() => {
		setIsLoading(true);
		if (uid && conversation) {
			const subscriber = firestore
				.collection(
					conversation.isGroup
						? joinPaths('messaging', conversationId, 'messages')
						: type === 'collaborators'
							? joinPaths(
									'agencies',
									agencyId,
									'collaborators',
									uid,
									'messaging',
									conversationId,
									'messages'
								)
							: joinPaths('clients', uid, 'messaging', conversationId, 'messages')
				)
				.where('type', 'in', ['text', 'picture', 'video', 'file'])
				.orderBy('date', 'desc')
				.limit(30)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const messages = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								messages.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								setLastDocumentSnapshot(documentSnapshot);
							}
						});
						setMessages((prevState) => mergeAndOrder((a, b) => a.date - b.date, 'id', messages, prevState));
						setIsLoading(false);
					}
				});

			return () => subscriber();
		}
	}, [uid, conversation]);

	useEffect(() => {
		setMessages([]);
		setResponseMessage(null);
	}, [conversation?.id]);

	useEffect(() => {
		if (conversation?.id && uid && !conversation.isGroup) {
			const subscriber = firestore
				.doc(joinPaths('users', conversation.id, 'messaging', uid))
				.onSnapshot((doc) => {
					if (doc.exists) {
						setReceiverLastClickAt(doc.data().lastClickAt);
					}
				});
			return () => subscriber();
		}
	}, [conversation, uid]);

	function handleScroll(e) {
		const top = e.target.clientHeight - e.target.scrollHeight >= e.target.scrollTop - 10;
		if (top && conversationId && conversation && lastDocumentSnapshot && !isMoreLoading && !isLoading) {
			setIsMoreLoading(true);
			firestore
				.collection(
					conversation.isGroup
						? joinPaths('messaging', conversationId, 'messages')
						: type === 'collaborators'
							? joinPaths(
									'agencies',
									agencyId,
									'collaborators',
									uid,
									'messaging',
									conversationId,
									'messages'
								)
							: joinPaths('clients', uid, 'messaging', conversationId, 'messages')
				)
				.where('type', 'in', ['text', 'picture', 'video', 'file'])
				.orderBy('date', 'desc')
				.startAfter(lastDocumentSnapshot)
				.limit(30)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const messages = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								messages.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								setLastDocumentSnapshot(documentSnapshot);
							}
						});
						setMessages((prevState) => mergeAndOrder((a, b) => a.date - b.date, 'id', prevState, messages));
						setIsMoreLoading(false);
					}
				});
		}
	}

	useEffect(() => {
		if (listRef && listRef.current && !isLoading) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	}, [listRef?.current, isLoading]);

	return (
		<>
			{conversationId ? (
				isLoading ? (
					<div className={'loadingContainer'}>
						<img src={chargement} alt="" className={'chargement'} />
					</div>
				) : messages?.length > 0 ? (
					<div className={'containerMessages'} ref={listRef} onScroll={handleScroll}>
						<p className={'comble'}>Louis</p>
						{messages.map((message, index) => {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginTop:
											messages[index + 1] &&
											!isDifferenceOneHourOrMore(message.date, messages[index + 1].date) &&
											messages[index + 1].sender !== message.sender
												? 15
												: 2,
									}}>
									{(!messages[index + 1] ||
										isDifferenceOneHourOrMore(message.date, messages[index + 1].date)) && (
										<MessageDate date={message.date} />
									)}
									<Message
										key={message.id}
										conversation={conversation}
										message={message}
										isMyMessage={message.sender === uid}
										isLastMessage={index === 0}
										selectPicture={() => {
											setSelectedPicture(message);
											setModalPreviewPicture(true);
										}}
										selectFile={() => {
											setSelectedFile(message);
											setModalPreviewFile(true);
										}}
										setResponseMessage={setResponseMessage}
										receiverLastClickAt={receiverLastClickAt}
										contacts={conversations}
									/>
								</div>
							);
						})}
						<p className={'comble'} style={{ marginBottom: 50 }}>
							Louis
						</p>
					</div>
				) : (
					<div className={'placeholderConversation'}>
						<img src={leftConv} alt="" style={{ width: 200, objectFit: 'contain' }} />
						<div
							style={{
								width: 'calc(100vw - 1000px)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
								fontSize: 13,
								fontWeight: 600,
								color: '#606060',
							}}>
							{t('messaging.new_safe_conv')}
							<img src={LockIcon} alt="" style={{ width: 11, opacity: 0.3, marginTop: 15 }} />
						</div>
						<img src={rigthConv} alt="" style={{ width: 200, objectFit: 'contain' }} />
					</div>
				)
			) : (
				<div className={'placeholderNoConvSelected'}>
					<img
						src={integrerProjetVisuel}
						alt=""
						style={{ width: 400, objectFit: 'contain', marginBottom: 40 }}
					/>
					<p
						style={{
							marginLeft: 50,
							marginRight: 50,
							fontSize: 13,
							fontWeight: 600,
							color: '#989898',
							textAlign: 'center',
						}}>
						{t('messaging.select_a_conv')}
					</p>
				</div>
			)}

			{selectedPicture && (
				<ModalPicture
					isOpen={modalPreviewPicture}
					setIsOpen={setModalPreviewPicture}
					uri={selectedPicture.contents}
					type={selectedPicture.type}
					handleNext={() => {
						const index = pictures.findIndex((picture) => picture.id === selectedPicture.id);
						if (index > 0) {
							setSelectedPicture(pictures[index - 1]);
						}
					}}
					handlePrevious={() => {
						const index = pictures.findIndex((picture) => picture.id === selectedPicture.id);
						if (index < pictures.length - 1) {
							setSelectedPicture(pictures[index + 1]);
						}
					}}
					noPrevious={
						pictures.findIndex((picture) => picture.id === selectedPicture.id) === pictures.length - 1
					}
					noNext={pictures.findIndex((picture) => picture.id === selectedPicture.id) === 0}
				/>
			)}

			{selectedFile && (
				<ModalDocument
					isOpen={modalPreviewFile}
					setIsOpen={setModalPreviewFile}
					uri={selectedFile.contents}
					name={selectedFile.fileName}
					type={selectedFile.fileType}
					handleNext={() => {
						const index = files.findIndex((file) => file.id === selectedFile.id);
						if (index > 0) {
							setSelectedFile(files[index - 1]);
						}
					}}
					handlePrevious={() => {
						const index = files.findIndex((file) => file.id === selectedFile.id);
						if (index < files.length - 1) {
							setSelectedFile(files[index + 1]);
						}
					}}
					noPrevious={files.findIndex((file) => file.id === selectedFile.id) === files.length - 1}
					noNext={files.findIndex((file) => file.id === selectedFile.id) === 0}
				/>
			)}
		</>
	);
}
