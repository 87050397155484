import React from 'react';
import plusGreen from '../../../assets/plusGreen.svg';
import questionCircle from '../../../assets/questionCircle.svg';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

export default function AddEntreprisesComponent({ companiesLeft, setModalNewPartnerCompany }) {
	const { t } = useTranslation();
	const { show } = useIntercom();

	return (
		<div className={'addEntreprisesComponent'}>
			<p className={'firstLine'}>
				{t('translation.invite')}
				<span style={{ color: '#1C57DD' }}>
					{' ' + t('translation.companies').toLowerCase()}
				</span>{' '}
				{t('translation.intoTheProject')}{' '}
				<span style={{ color: '#1C57DD' }}>{t('translation.freeAccess')}</span>
			</p>

			<p className={'secondLine'}>{t('translation.addYourFirstStakeholder')}</p>

			<div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
				<div className={'avatarOpus'} style={{ backgroundColor: '#E7ECFE', color: '#5b84e8' }}>
					O
				</div>
				<div className={'avatarOpus'} style={{ backgroundColor: '#E7ECFE', color: '#5b84e8' }}>
					P
				</div>
				<div className={'avatarOpus'} style={{ backgroundColor: '#E7ECFE', color: '#5b84e8' }}>
					U
				</div>
				<div className={'avatarOpus'} style={{ backgroundColor: '#E7ECFE', color: '#5b84e8' }}>
					S
				</div>
			</div>

			<div className={'containerButtonAddCompany hover'} onClick={() => setModalNewPartnerCompany(true)}>
				<img src={plusGreen} alt="" className={'icon'} />
				<p>{t('translation.addACompany')}</p>
			</div>
		</div>
	);
}
