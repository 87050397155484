import React, { useContext, useEffect, useState } from 'react';
import AgencyContext from '../../../../contexts/agencyContext';
import { firestore } from '../../../../firebase/config';
import profilPicture from '../../../../useful/UsefulFunctions';
import checkBleu from '../../../../assets/checkBleu.png';
import ModalDeleteCollaborator from '../Modaux/ModalDeleteCollaborator';
import ModalDeleteClient from '../Modaux/ModalDeleteClient';
import trashIcon from '../../../../assets/trashIcon.svg';
import squareCheck from '../../../../assets/squareCheck.svg';
import ModalDeletePartner from '../Modaux/ModalDeletePartner';
import { useTranslation } from 'react-i18next';

export default function AccountModif({
	accountData,
	isModifContactOpen,
	setIsModifContactOpen,
	isInfosContactOpen,
	isCollaborator,
	isClient,
	isPartner,
	setAccounts,
	agencyProjects,
	canCreate,
	canDelete,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);

	const [modalDelete, setModalDelete] = useState(false);
	const [deletionLoading, setDeletionLoading] = useState(false);

	// Choix type autorisation de compte

	const [administrateurSelected, setAdministrateurSelected] = useState(accountData?.subtype === 'administrator');
	const [collaborateurSelected, setCollaborateurSelected] = useState(accountData?.subtype === 'collaborator');
	const [personnaliseSelected, setPersonnaliseSelected] = useState(accountData?.subtype === 'custom');

	// Choix autorisation du compte personnalisé

	const [creationProjet, setCreationProjet] = useState(accountData?.canCreateProject);
	const [suppressionProjet, setSuppressionProjet] = useState(accountData?.canDeleteProject);
	const [creationCollaborateur, setCreationCollaborateur] = useState(accountData?.canCreateCollaborator);
	const [suppressionCollaborateur, setSuppressionCollaborateur] = useState(accountData?.canDeleteCollaborator);
	const [creationClient, setcreationClient] = useState(accountData?.canCreateClient);
	const [suppressionClient, setSuppressionClient] = useState(accountData?.canDeleteClient);
	const [ajoutAgencePartenaire, setAjoutAgencePartenaire] = useState(accountData?.canCreatePartnerAgency);
	const [suppressionAgencePartenaire, setSuppressionAgencePartenaire] = useState(accountData?.canDeletePartnerAgency);
	const [accesFacturation, setAccesFacturation] = useState(accountData?.canAccesBilling);

	useEffect(() => {
		setAdministrateurSelected(accountData?.subtype === 'administrator');
		setCollaborateurSelected(accountData?.subtype === 'collaborator');
		setPersonnaliseSelected(accountData?.subtype === 'custom');
		setCreationProjet(accountData?.canCreateProject);
		setSuppressionProjet(accountData?.canDeleteProject);
		setCreationCollaborateur(accountData?.canCreateCollaborator);
		setSuppressionCollaborateur(accountData?.canDeleteCollaborator);
		setcreationClient(accountData?.canCreateClient);
		setSuppressionClient(accountData?.canDeleteClient);
		setAjoutAgencePartenaire(accountData?.canCreatePartnerAgency);
		setSuppressionAgencePartenaire(accountData?.canDeletePartnerAgency);
		setAccesFacturation(accountData?.canAccesBilling);
	}, [accountData]);

	return (
		<>
			{accountData &&
				!isInfosContactOpen &&
				(isCollaborator ? (
					<div>
						<p
							style={{
								marginLeft: 195,
								marginBottom: -38,
								fontSize: 14,
								fontWeight: '600',
								color: '#7A818C',
								marginTop: 22,
								opacity: isModifContactOpen ? 1 : 0,
							}}>
							{t('activity.an_edit')}
						</p>
						<div className={'modifContact'} style={{ opacity: isModifContactOpen ? 1 : 0 }}>
							{accountData.deletion && (
								<div
									style={{
										position: 'absolute',
										width: 450,
										height: 300,
										backgroundColor: 'rgba(255,255,255,0.62)',
										marginTop: 200,
									}}
								/>
							)}
							<img
								alt=""
								className={'photoProfilInfoContact'}
								src={isPartner ? profilPicture(accountData.logoUrl) : profilPicture(accountData.imgUrl)}
							/>
							<p className={'nomContact'}>
								{accountData.surname} {accountData.name}
							</p>

							<div style={{ display: 'flex', opacity: canCreate ? 1 : 0.4 }}>
								<div
									style={{
										height: 50,
										marginLeft: 27,
									}}
									className={'cardAutor'}
									onClick={() => {
										setAdministrateurSelected(true);
										setCollaborateurSelected(false);
										setPersonnaliseSelected(false);
									}}>
									<div className={'noChoiceAutor'}>
										<img
											src={checkBleu}
											alt="choice"
											className={'choiceAutor'}
											style={{ opacity: administrateurSelected ? 1 : 0 }}
										/>
									</div>
									<p className={'titleAutor'}>{t('common.admin')}</p>
								</div>

								<div
									style={{ marginRight: 30, marginLeft: 20 }}
									className={'cardAutor'}
									onClick={() => {
										setAdministrateurSelected(false);
										setCollaborateurSelected(true);
										setPersonnaliseSelected(false);
									}}>
									<div className={'noChoiceAutor'}>
										<img
											src={checkBleu}
											alt="choice"
											className={'choiceAutor'}
											style={{ opacity: collaborateurSelected ? 1 : 0 }}
										/>
									</div>
									<p className={'titleAutor'}>{t('common.collab')}</p>
								</div>
							</div>

							<div
								className={'cardAutor'}
								style={{ flexDirection: 'column', marginTop: 20, width: 370 }}
								onClick={() => {
									setAdministrateurSelected(false);
									setCollaborateurSelected(false);
									setPersonnaliseSelected(true);
								}}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: 65,
										marginLeft: -20,
										width: 350,
									}}>
									<div className={'noChoiceAutor'}>
										<img
											src={checkBleu}
											alt="choiceAutor"
											className={'choiceAutor'}
											style={{ opacity: personnaliseSelected ? 1 : 0 }}
										/>
									</div>
									<p className={'titleAutor'}>{t('billing.personalised')}</p>
								</div>

								<div className={'choixAutorPersonalise'}>
									<div
										className={creationProjet ? 'autorActive' : 'autor'}
										onClick={() => {
											setCreationProjet(!creationProjet);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.creating_project')}</p>
									</div>

									<div
										className={suppressionProjet ? 'autorActive' : 'autor'}
										onClick={() => {
											setSuppressionProjet(!suppressionProjet);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.deletion_project')}</p>
									</div>

									<div
										className={creationCollaborateur ? 'autorActive' : 'autor'}
										onClick={() => {
											if (creationCollaborateur) {
												setSuppressionCollaborateur(false);
											} else {
												setAccesFacturation(true);
											}
											setCreationCollaborateur(!creationCollaborateur);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.creation_collab')}</p>
									</div>

									<div
										className={suppressionCollaborateur ? 'autorActive' : 'autor'}
										onClick={() => {
											if (!suppressionCollaborateur) {
												setAccesFacturation(true);
												setCreationCollaborateur(true);
											}
											setSuppressionCollaborateur(!suppressionCollaborateur);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.deletion_collab')}</p>
									</div>

									<div
										className={creationClient ? 'autorActive' : 'autor'}
										onClick={() => {
											if (creationClient) {
												setSuppressionClient(false);
											}
											setcreationClient(!creationClient);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.client_creation')}</p>
									</div>

									<div
										className={suppressionClient ? 'autorActive' : 'autor'}
										onClick={() => {
											if (!suppressionClient) {
												setcreationClient(true);
											}
											setSuppressionClient(!suppressionClient);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.client_deletion')}</p>
									</div>

									<div
										className={accesFacturation ? 'autorActive' : 'autor'}
										onClick={() => {
											if (accesFacturation) {
												setCreationCollaborateur(false);
												setSuppressionCollaborateur(false);
											}
											setAccesFacturation(!accesFacturation);
										}}>
										<p className={'typeAutor'}>{t('new_collab_account.billing_access')}</p>
									</div>
								</div>
							</div>
							{accountData.deletion ? (
								<div>
									<button
										style={{
											width: 370,
											marginTop: 30,
											marginLeft: 30,
											height: 35,
											backgroundColor: '#D2E7F8',
											color: '#4B5FB0',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: 7,
											fontSize: 13,
											fontWeight: 600,
											border: 0,
										}}
										onClick={async () => {
											setAccounts((prevState) => {
												const newState = [...prevState];
												const index = newState.map((item) => item.uid).indexOf(accountData.uid);
												newState[index].deletion = false;
												return newState;
											});
											await firestore
												.doc(`accounts/${accountData.uid}`)
												.set({ deletion: false }, { merge: true });
											await firestore
												.doc(`agencies/${agency}/collaborators/${accountData.uid}`)
												.set({ deletion: false }, { merge: true });
										}}>
										{t('modal_accounts.restaure_the_account')}
									</button>
								</div>
							) : (
								<div style={{ display: 'flex' }}>
									{canDelete && (
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												width: 170,
												marginTop: 28,
												marginLeft: 22,
												backgroundColor: 'rgba(248,210,210,0.69)',
												borderRadius: 5,
												height: 30,
												opacity: 1,
											}}
											onClick={() => setModalDelete(true)}
											className={'hover'}>
											<img
												style={{
													marginLeft: 0,
													marginTop: 8,
													height: 14,
													marginRight: 5,
													objectFit: 'contain',
												}}
												src={trashIcon}
												alt="iconPlus"
											/>
											<p
												style={{
													marginLeft: 3,
													fontSize: 12,
													marginTop: 7,
													fontWeight: 600,
													color: '#000',
												}}>
												{deletionLoading ? t('common.loading') : t('common.delete')}
											</p>
										</div>
									)}

									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											marginRight: 20,
											width: 170,
											marginTop: 28,
											marginLeft: 15,
											backgroundColor: '#b7e8a6',
											borderRadius: 5,
											height: 30,
											opacity: 1,
										}}
										onClick={async () => {
											const data = personnaliseSelected
												? {
														subtype: 'custom',
														canCreateProject: creationProjet,
														canDeleteProject: suppressionProjet,
														canCreateCollaborator: creationCollaborateur,
														canDeleteCollaborator: suppressionCollaborateur,
														canCreateClient: creationClient,
														canDeleteClient: suppressionClient,
														canCreatePartnerAgency: ajoutAgencePartenaire,
														canDeletePartnerAgency: suppressionAgencePartenaire,
														canAccesBilling: accesFacturation,
												  }
												: {
														subtype: administrateurSelected
															? 'administrator'
															: collaborateurSelected
															  ? 'collaborator'
															  : null,
														canCreateProject: false,
														canDeleteProject: false,
														canCreateCollaborator: false,
														canDeleteCollaborator: false,
														canCreateClient: false,
														canDeleteClient: false,
														canCreatePartnerAgency: false,
														canDeletePartnerAgency: false,
														canAccesBilling: false,
												  };
											setAccounts((prevState) => {
												const newState = [...prevState];
												const index = newState.map((item) => item.uid).indexOf(accountData.uid);
												newState[index] = { ...newState[index], ...data };
												return newState;
											});
											await firestore
												.doc(`agencies/${agency}/collaborators/${accountData.uid}`)
												.update(data);
											setIsModifContactOpen(false);
										}}
										className={'hover'}>
										<img
											style={{
												marginLeft: 0,
												marginTop: 8,
												height: 13,
												marginRight: 5,
												objectFit: 'contain',
											}}
											src={squareCheck}
											alt="iconPlus"
										/>
										<p
											style={{
												marginLeft: 3,
												fontSize: 12,
												marginTop: 7,
												fontWeight: 600,
												color: '#000',
											}}>
											{t('common.save')}
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
				) : (
					<div>
						<p
							style={{
								marginLeft: 195,
								marginBottom: -38,
								fontSize: 14,
								fontWeight: '600',
								color: '#7A818C',
								marginTop: 22,
								opacity: isModifContactOpen ? 1 : 0,
							}}>
							{t('modal_accounts.edits')}
						</p>
						<div className={'modifContact'} style={{ opacity: isModifContactOpen ? 1 : 0 }}>
							<img
								alt=""
								className={'photoProfilInfoContact'}
								src={isPartner ? profilPicture(accountData.logoUrl) : profilPicture(accountData.imgUrl)}
							/>
							<p className={'nomContact'}>
								{accountData.surname} {accountData.name}
							</p>

							<div
								style={{
									width: 400,
									height: 1,
									backgroundColor: '#eeeeee',
								}}
							/>

							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: 170,
									marginTop: 28,
									backgroundColor: 'rgba(248,210,210,0.69)',
									borderRadius: 5,
									height: 30,
									opacity: 1,
								}}
								onClick={() => setModalDelete(true)}
								className={'hover'}>
								<img
									style={{
										marginLeft: 0,
										marginTop: 8,
										height: 14,
										marginRight: 5,
										objectFit: 'contain',
									}}
									src={trashIcon}
									alt="iconPlus"
								/>
								<p
									style={{
										marginLeft: 3,
										fontSize: 12,
										marginTop: 7,
										fontWeight: 600,
										color: '#000',
									}}>
									{t('common.delete')}
								</p>
							</div>

							{isPartner ? (
								<p
									style={{
										marginTop: 35,
										width: 400,
										textAlign: 'justify',
										fontSize: 13,
										opacity: 0.7,
									}}>
									{t('modal_accounts.info_delete_partner_agency')}
									<br /> <br />
									{t('modal_accounts.info_legal_deletion_agency')}
								</p>
							) : (
								<p
									style={{
										marginTop: 35,
										width: 400,
										textAlign: 'justify',
										fontSize: 13,
										opacity: 0.7,
									}}>
									{t('modal_accounts.info_deletion_client')}
									<br /> <br />
									{t('modal_accounts.info_legal_deletion_client')}
								</p>
							)}
						</div>
					</div>
				))}

			{accountData &&
				(isCollaborator ? (
					<ModalDeleteCollaborator
						accountData={accountData}
						modal={modalDelete}
						setModal={setModalDelete}
						setAccounts={setAccounts}
						setIsModifContactOpen={setIsModifContactOpen}
						setDeletionLoading={setDeletionLoading}
					/>
				) : isClient ? (
					<ModalDeleteClient
						accountData={accountData}
						modal={modalDelete}
						setModal={setModalDelete}
						agencyProjects={agencyProjects}
						setAccounts={setAccounts}
						setIsModifContactOpen={setIsModifContactOpen}
					/>
				) : (
					<ModalDeletePartner
						setIsModifContactOpen={setIsModifContactOpen}
						modal={modalDelete}
						setModal={setModalDelete}
						accountData={accountData}
						setAccounts={setAccounts}
						agencyProjects={agencyProjects}
					/>
				))}
		</>
	);
}
