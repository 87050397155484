import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import './_ticketsColumn.scss';
import TicketCard from '../Ticket Card/TicketCard';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import plusSquare from '../../../../../assets/plusSquare.svg';
import plusCircle from '../../../../../assets/plusCircle.svg';
import minus from '../../../../../assets/minus.svg';
import upDownGrey from '../../../../../assets/upDownGrey.svg';
import upDownBlue from '../../../../../assets/upDownBlue.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TICKET_STATUS } from '../Modal Ticket/Follow Up/FollowUp';
import AgencyContext from '../../../../../contexts/agencyContext';

export default function TicketsColumn({
	label,
	ticketsCount,
	numberOfColumns,
	tickets,
	setModalTicket,
	isStatusShown,
	isLotShown,
	isLocationShown,
	isTicketOwnerShown,
	isCompanyInChargeShown,
	isDueDateShown,
	isWorkDurationShown,
	isLinkedFileShown,
	isDescriptionShown,
	type,
	handleCreateTicket,
	handleSelectTicket,
	setSortChoice,
	sortChoice,
	key,
	setNumberOfColumns,
	setSortUp,
	sortUp,
	isSnagging,
}) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);

	const divRef = useRef(null);
	const [width, setWidth] = useState(0);

	const numberColumns = Math.max(Math.floor(width / 315), 1);

	useEffect(() => {
		const updateWidth = () => {
			if (divRef.current) {
				// getBoundingClientRect() donne les dimensions complètes
				const width = divRef.current.getBoundingClientRect().width;
				setWidth(width);
			}
		};

		const resizeObserver = new ResizeObserver(() => {
			updateWidth();
		});

		if (divRef.current) {
			resizeObserver.observe(divRef.current);
		}

		updateWidth();

		return () => {
			if (divRef.current) {
				resizeObserver.unobserve(divRef.current);
			}
		};
	}, []);

	const getStatusPriority = (ticket) => {
		let status = ticket.issueStatus ?? TICKET_STATUS.inProgress;

		if (!ticket?.companiesViewed?.includes(agencyId)) {
			status = TICKET_STATUS.new;
		} else if (!ticket?.properties?.companies?.some((company) => ticket?.companiesViewed?.includes(company))) {
			status = TICKET_STATUS.pendingProcess;
		} else if (ticket?.issueStatus === TICKET_STATUS.refused) {
			status = ticket?.properties?.companies?.includes(agencyId)
				? TICKET_STATUS.refused
				: TICKET_STATUS.inProgress;
		} else if (ticket?.issueStatus === TICKET_STATUS.approved) {
			status = TICKET_STATUS.approved;
		}

		switch (status) {
			case TICKET_STATUS.new:
				return 0;
			case TICKET_STATUS.waitingForProcessing:
				return 1;
			case TICKET_STATUS.inProgress:
				return 2;
			case TICKET_STATUS.pendingApproval:
				return 3;
			case TICKET_STATUS.refused:
				return 4;
			case TICKET_STATUS.approved:
				return 5;
			default:
				return -1;
		}
	};

	const sortedTickets = useMemo(() => {
		return tickets?.sort((a, b) => {
			if (sortChoice === 'issueDate')
				return sortUp
					? a.properties.issueAt?.localeCompare(b.properties.issueAt)
					: b.properties.issueAt?.localeCompare(a.properties.issueAt);
			if (sortChoice === 'dueDate')
				return sortUp
					? a.properties.dueAt?.localeCompare(b.properties.dueAt)
					: b.properties.dueAt?.localeCompare(a.properties.dueAt);
			if (sortChoice === 'progress')
				return type === 'issue'
					? sortUp
						? getStatusPriority(a) - getStatusPriority(b)
						: getStatusPriority(b) - getStatusPriority(a)
					: sortUp
						? a.todos?.reduce(
								(acc, todo) =>
									acc +
									(todo.completed
										? 1
										: Math.min(todo.subTodos.filter((subTodo) => subTodo.completed).length) /
											(todo.subTodos.length + 1)),
								0
							) /
								a.todos?.length -
							b.todos?.reduce(
								(acc, todo) =>
									acc +
									(todo.completed
										? 1
										: Math.min(todo.subTodos.filter((subTodo) => subTodo.completed).length) /
											(todo.subTodos.length + 1)),
								0
							) /
								b.todos?.length
						: b.todos?.reduce(
								(acc, todo) =>
									acc +
									(todo.completed
										? 1
										: Math.min(todo.subTodos.filter((subTodo) => subTodo.completed).length) /
											(todo.subTodos.length + 1)),
								0
							) /
								b.todos?.length -
							a.todos?.reduce(
								(acc, todo) =>
									acc +
									(todo.completed
										? 1
										: Math.min(todo.subTodos.filter((subTodo) => subTodo.completed).length) /
											(todo.subTodos.length + 1)),
								0
							) /
								a.todos?.length;
		});
	}, [tickets, sortChoice, sortUp]);

	return (
		<div className={'ticketsColumn'}>
			<div ref={divRef}>
				<div style={{ width: numberColumns * 315, display: 'grid' }}>
					<Masonry columnsCount={numberColumns}>
						{sortedTickets.map((ticket) => (
							<div
								style={{ display: 'flex', width: 300 }}
								key={ticket.id}
								onClick={() => handleSelectTicket(ticket.id)}>
								<TicketCard
									isIssueTicket={ticket.properties.type === 'issue'}
									ticket={ticket}
									setModalTicket={setModalTicket}
									isStatusShown={isStatusShown}
									isLotShown={isLotShown}
									isLocationShown={isLocationShown}
									isTicketOwnerShown={isTicketOwnerShown}
									isCompanyInChargeShown={isCompanyInChargeShown}
									isDueDateShown={isDueDateShown}
									isWorkDurationShown={isWorkDurationShown}
									isLinkedFileShown={isLinkedFileShown}
									isDescriptionShown={isDescriptionShown}
									isSnagging={isSnagging}
								/>
							</div>
						))}
					</Masonry>
				</div>
			</div>
			{tickets?.length === 0 && (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 'calc(100vh - 400px)',
					}}>
					<div className={'buttonNewTicket'} onClick={() => handleCreateTicket(type)}>
						<img src={plusSquare} alt="" />
						<div>{isSnagging ? t('translation.createANewTicket') : t('translation.createNewMission')}</div>
					</div>
				</div>
			)}
		</div>
	);
}

function SortOption({ name, label, isSelected, setSortChoice, sortUp, setSortUp }) {
	return (
		<div
			className={isSelected ? 'sortOption sortOptionSelected' : 'sortOption hover'}
			onClick={() => {
				setSortChoice(label);
				setSortUp((prev) => !prev);
			}}>
			{name}
			<div style={{ width: 18, display: 'flex' }}>
				<img
					src={isSelected ? upDownBlue : upDownGrey}
					alt=""
					style={{ transform: isSelected && sortUp && 'rotate(180deg)' }}
				/>
			</div>
		</div>
	);
}

TicketsColumn.propTypes = {
	label: PropTypes.string.isRequired,
	ticketsCount: PropTypes.number.isRequired,
	numberOfColumns: PropTypes.number.isRequired,
	setNumberOfColumns: PropTypes.func.isRequired,
	tickets: PropTypes.array.isRequired,
	setModalTicket: PropTypes.func.isRequired,
	isStatusShown: PropTypes.bool.isRequired,
	isLotShown: PropTypes.bool.isRequired,
	isLocationShown: PropTypes.bool.isRequired,
	isTicketOwnerShown: PropTypes.bool.isRequired,
	isCompanyInChargeShown: PropTypes.bool.isRequired,
	isDueDateShown: PropTypes.bool.isRequired,
	isWorkDurationShown: PropTypes.bool.isRequired,
	isLinkedFileShown: PropTypes.bool.isRequired,
	isDescriptionShown: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	handleCreateTicket: PropTypes.func.isRequired,
	handleSelectTicket: PropTypes.func.isRequired,
};

SortOption.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	setSortChoice: PropTypes.func.isRequired,
	sortUp: PropTypes.bool.isRequired,
	setSortUp: PropTypes.func.isRequired,
};
