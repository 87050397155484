import React, { useContext, useMemo } from 'react';
import hourglass from '../../../../../assets/hourglass.svg';
import { desescapeSpecialChars } from '../../../../../useful/UsefulFunctions';
import AuthDataContext from '../../../../../contexts/authDataContext';
import { useProjectUsersContext } from '../../../../../contexts/projectUsersContext';
import { useTranslation } from 'react-i18next';
import { formatDateHourly, formatDateNumericallyWithPastDayKey } from '../../../../../useful/date';
import PropTypes from 'prop-types';
import { REVIEW_RESULT } from '../../../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';

ResolutionProposal.propTypes = {
	resolution: PropTypes.object,
};

export default function ResolutionProposal({ resolution, isReview, reviewers, isApproved, isRefused }) {
	const { t } = useTranslation();
	return (
		<div className={'containerSolutionProposal'}>
			{resolution && !resolution.reviews?.some((review) => review.result === REVIEW_RESULT.refused) ? (
				<div className={'containerSolution'}>
					<ItemResolution
						resolution={resolution}
						isReview={isReview}
						isApproved={isApproved}
						isRefused={isRefused}
					/>
					<div style={{ display: 'flex' }}>
						{resolution?.files
							?.filter((file) => file.type === 'image')
							?.map((image) => (
								<img src={image.url} alt="" className={'img'} key={image.id} />
							))}
					</div>
				</div>
			) : (
				<div className={'waitingForSolving'}>
					<img src={hourglass} alt="" className={'hourglass'} /> {t('translation.waitingForSolvingProposal')}
				</div>
			)}
		</div>
	);
}

ItemResolution.propTypes = {
	resolution: PropTypes.object,
};

function ItemResolution({ resolution, isReview, isApproved, isRefused }) {
	const { t, i18n } = useTranslation();

	const authData = useContext(AuthDataContext);
	const [users] = useProjectUsersContext();

	// ==================================== Reaction date ====================================
	const resolutionDate = useMemo(() => {
		const date = formatDateNumericallyWithPastDayKey(resolution?.createdAt, i18n.language);
		const time = formatDateHourly(resolution?.createdAt, i18n.language);

		if (date === 'today') {
			return t('translation.todayAt', { time });
		} else if (date === 'yesterday') {
			return t('translation.yesterdayAt', { time });
		} else if (date === 'monday') {
			return t('translation.mondayAt', { time });
		} else if (date === 'tuesday') {
			return t('translation.tuesdayAt', { time });
		} else if (date === 'wednesday') {
			return t('translation.wednesdayAt', { time });
		} else if (date === 'thursday') {
			return t('translation.thursdayAt', { time });
		} else if (date === 'friday') {
			return t('translation.fridayAt', { time });
		} else if (date === 'saturday') {
			return t('translation.saturdayAt', { time });
		} else if (date === 'sunday') {
			return t('translation.sundayAt', { time });
		} else {
			return t('translation.theAt', { date, time });
		}
	}, [resolution?.createdAt, i18n.language]);

	return (
		<div className={'resolutionSubmitted'}>
			<span className={'whoPropos'}>
				{isReview && (
					<span
						className={'boldBlue'}
						style={{
							color: isRefused ? '#ff0000' : '#3CA504',
						}}>
						{isReview && isRefused ? t('translation.refusedBy') : t('translation.approvedBy')}
					</span>
				)}{' '}
				<span className={'boldBlue'}>
					{[authData, ...users]?.find((user) => user?.id === resolution?.createdBy.userId)?.surname +
						' ' +
						[authData, ...users]?.find((user) => user?.id === resolution?.createdBy.userId)?.name}
				</span>{' '}
				{!isReview && t('translation.hasProposed')} :
			</span>
			<p className={'date'}>{desescapeSpecialChars(resolutionDate)}</p>

			<p className={'titleResolution'}>{resolution?.title}</p>
			<p className={'descriptionResolution'}>{resolution?.description}</p>

			<div style={{ display: 'flex', flexDirection: 'column' }}></div>
		</div>
	);
}
