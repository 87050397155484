import React, { useEffect, useState } from 'react';
import loginBlue from '../../assets/loginBlue.png';
import wraning from '../../assets/wraning.svg';
import eye from '../../assets/eye.svg';
import barreEye from '../../assets/barreEye.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../firebase/config';
import {
	fetchSignInMethodsForEmail,
	onAuthStateChanged,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	RecaptchaVerifier,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
} from 'firebase/auth';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';

export default function Login() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [loginEtape1, setLoginEtape1] = useState(true);
	const [champVideEmail, setChampVideEmail] = useState(false);
	const [champVidePassword, setChampVidePassword] = useState(false);
	const [numeros, setNumeros] = useState();

	const [showPassword, setShowPassword] = useState(false);

	const [userSession, setUserSession] = useState(null);
	useEffect(() => {
		let listener = onAuthStateChanged(auth, async (user) => {
			if (user && password.length === 0) {
				setUserSession(user);
				firestore
					.doc(`accounts/${user.uid}`)
					.get()
					.then((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							if (documentSnapshot.data().type === 'collaborators') {
								firestore
									.doc(`agencies/${documentSnapshot.data().agency}/collaborators/${user.uid}`)
									.get()
									.then((documentSnapshot) => {
										if (documentSnapshot && documentSnapshot.exists) {
											if (
												documentSnapshot.data().active ||
												!documentSnapshot.data().firstConnexion
											) {
												navigate('/loading');
											}
										} else {
											signOut(auth);
										}
									})
									.catch((error) => {
										signOut(auth);
									});
							} else {
								navigate('/loading');
							}
						}
					});
			}
		});
		return () => listener();
	}, [userSession]);

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const [twoStepsIdentification, setTwoStepsIdentification] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (isMotDePasseOublie) {
			sendPasswordResetEmail(auth, email)
				.then(() => setEmailSent(true))
				.catch((error) => {
					if (
						error?.toString() ===
						'Error: There is no user record corresponding to this identifier. The user may have been deleted.'
					) {
						window.alert(t('login_page.errors.unknown_id'));
					} else {
						window.alert(t('login_page.errors.error'));
					}
				});
		} else if (loginEtape1) {
			if (email.length > 0) {
				fetchSignInMethodsForEmail(auth, email)
					.then((task) => {
						if (task && task.length > 0) {
							setLoginEtape1(false);
						} else {
							window.alert(t('login_page.errors.unknown_id'));
						}
					})
					.catch(() => {
						if (
							error?.toString() ===
							'Error: There is no user record corresponding to this identifier. The user may have been deleted.'
						) {
							window.alert(t('login_page.errors.unknown_id'));
						} else {
							window.alert(t('login_page.errors.error'));
						}
					});
			} else {
				setChampVideEmail(true);
			}
		} else {
			if (password.length === 0) {
				setChampVidePassword(true);
			}
			if (!isMotDePasseOublie) {
				setLoading(true);
				if (email && password && email.length > 0 && password.length > 0) {
					signInWithEmailAndPassword(auth, email, password)
						.then((credential) => {
							firestore
								.doc(`accounts/${credential.user.uid}`)
								.get()
								.then(async (documentSnapshot) => {
									if (documentSnapshot && documentSnapshot.exists) {
										if (documentSnapshot.data().type === 'clients') {
											/*auth.signOut().then(() => {
                                                window.alert(t('login_page.errors.only_pros'));
                                            });
                                            setLoading(false);*/
											navigate('/loading');
										} else if (documentSnapshot.data().type === 'collaborators') {
											firestore
												.doc(
													`agencies/${documentSnapshot.data().agency}/collaborators/${
														credential.user.uid
													}`
												)
												.get()
												.then(async (documentSnapshot) => {
													if (documentSnapshot && documentSnapshot.exists) {
														navigate('/loading');
													}
												});
											setLoading(false);
										} else {
											if (window.confirm(t('login_page.errors.only_admins'))) {
												navigate('/loading');
											} else {
												await signOut(auth);
											}
											setLoading(false);
										}
									}
								});
						})
						.catch((error) => {
							if (error?.code === 'auth/multi-factor-auth-required') {
								const recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
									size: 'invisible',
								});
								const resolver = error?.resolver;
								// Ask user which second factor to use.
								if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
									const phoneInfoOptions = {
										multiFactorHint: resolver.hints[0],
										session: resolver.session,
									};
									const phoneAuthProvider = new PhoneAuthProvider();
									// Send SMS verification code
									return phoneAuthProvider
										.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
										.then(function (verificationId) {
											// Ask user for the SMS verification code.
											const verificationCode = window.prompt(
												'Please enter the verification ' +
													'code that was sent to your mobile device.'
											);
											const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
											const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
											// Complete sign-in.
											return resolver.resolveSignIn(multiFactorAssertion);
										})
										.then(function (credential) {
											// User successfully signed in with the second factor phone number.
											firestore
												.doc(`accounts/${credential.user.uid}`)
												.get()
												.then(async (documentSnapshot) => {
													if (documentSnapshot && documentSnapshot.exists) {
														if (documentSnapshot.data().type === 'clients') {
															/*auth.signOut().then(() => {
                                                                window.alert(t('login_page.errors.only_pros'));
                                                            });
                                                            setLoading(false);*/
															navigate('/loading');
														} else if (documentSnapshot.data().type === 'collaborators') {
															firestore
																.doc(
																	`agencies/${
																		documentSnapshot.data().agency
																	}/collaborators/${credential.user.uid}`
																)
																.get()
																.then(async (documentSnapshot) => {
																	if (documentSnapshot && documentSnapshot.exists) {
																		navigate('/loading');
																	}
																});
															setLoading(false);
														} else {
															if (window.confirm(t('login_page.errors.only_admins'))) {
																navigate('/loading');
															} else {
																await signOut(auth);
															}
															setLoading(false);
														}
													}
												});
										});
								} else {
									// Unsupported second factor.
								}
							} else {
								setNombreTentatives(nombreTentatives + 1);
								setError(error);
							}
							setLoading(false);
						});
				}
			}
		}
	};

	const data = {
		password: '',
		email: '',
	};
	const [eventData, setEventData] = useState(data);
	const { password, email } = eventData;
	const [nombreTentatives, setNombreTentatives] = useState(0);
	const [isMotDePasseOublie, setIsMotDePasseOublie] = useState(false);
	const [emailSent, setEmailSent] = useState(false);

	function handleChange(e) {
		setEventData({ ...eventData, [e.target.id]: e.target.value });
		setError('');
	}

	const [count, setCount] = useState(false);
	const [opacity, setOpacity] = useState(0);
	const [widthLeft, setWidthLeft] = useState(100 + 'vw');
	const [widthSecondPart, setWidthSecondPart] = useState(100 + 'vw');
	const [widthRight, setWidthRight] = useState(0 + 'vw');

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 500);
	}, []);

	useEffect(() => {
		if (count) {
			setOpacity(1);
		}
	}, [count]);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setWidthLeft(50 + 'vw');
				setWidthSecondPart(50 + 'vw');
				setWidthRight(50 + 'vw');
			}
		}, 500);
	}, [count]);

	const [marginLeftForm, setMarginLeftForm] = useState(0);
	const [opacityForm, setOpacityForm] = useState(1);

	function DemarrerEssai() {
		setWidthSecondPart(40 + 'vw');
		setMarginLeftForm(10 + 'vw');
		setOpacityForm(0);
		setTimeout(() => {
			navigate('/OnBoarding');
		}, 500);
	}

	useEffect(() => {
		if (email.length > 1) {
			setChampVideEmail(false);
		}
	}, [email, setChampVideEmail, password, setChampVidePassword]);

	const [verifNumber, setVerifNumber] = useState();

	useEffect(() => {
		if (verifNumber?.length === 6) {
		}
	}, [verifNumber, setVerifNumber, setVerifNumber, setVerifNumber]);

	const inputRef = React.createRef();
	const [isFocus, setIsFocus] = useState(false);

	const onFocus = () => setIsFocus(true);
	const onBlur = () => setIsFocus(false);

	return (
		<div className="login" style={{ opacity: opacity }}>
			<div className={'leftPart'} style={{ width: widthLeft }}>
				<div className={'firstPart'} style={{ width: widthLeft }}>
					<a href="https://opusoft.fr/contact" target={'_blank'}>
						<p className={'opus'}>OPUS</p>
					</a>

					<div style={{ marginLeft: 10 }} className={'dot'} />
					<p className={'onBoarding'}>
						{isMotDePasseOublie ? t('login_page.titles.reset_password') : t('login_page.titles.connexion')}{' '}
					</p>
				</div>

				{twoStepsIdentification ? (
					<div className={'secondPart'} style={{ width: widthSecondPart, marginRight: marginLeftForm }}>
						<div className={'loginComponent'}>
							<h2
								className={'title'}
								style={{
									marginTop: 0,
									marginBottom: 5,
								}}>
								{t('login_page.two_steps_id.title')}
							</h2>

							<p
								style={{
									width: 360,
									opacity: 0.6,
									lineHeight: 1.5,
									textAlign: 'center',
								}}>
								{t('login_page.two_steps_id.request')}
							</p>

							<div
								onClick={() => inputRef.current.focus()}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: 60,
								}}>
								<div
									style={{
										backgroundColor: 'rgba(72,103,236,0.18)',
										height: 60,
										width: 48,
										borderTopLeftRadius: 10,
										borderBottomLeftRadius: 10,
										marginRight: 1,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'text',
									}}>
									{!numeros && isFocus && (
										<div
											className={'image-clignote-rapidement'}
											style={{ height: 20, width: 2, backgroundColor: '#4867EC' }}
										/>
									)}
									<div
										style={{
											color: '#4867EC',
											fontSize: 20,
											fontWeight: 600,
										}}>
										{numeros && numeros.length > 0 && numeros.charAt(0)}
									</div>
								</div>

								<div
									style={{
										backgroundColor: 'rgba(72,103,236,0.18)',
										height: 60,
										width: 48,
										marginRight: 1,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'text',
									}}>
									{numeros && numeros.length === 1 && (
										<div
											className={'image-clignote-rapidement'}
											style={{ height: 20, width: 2, backgroundColor: '#4867EC' }}
										/>
									)}
									<div
										style={{
											color: '#4867EC',
											fontSize: 20,
											fontWeight: 600,
										}}>
										{numeros && numeros.length > 1 && numeros.charAt(1)}
									</div>
								</div>

								<div
									style={{
										backgroundColor: 'rgba(72,103,236,0.18)',
										height: 60,
										width: 48,
										borderTopRightRadius: 10,
										borderBottomRightRadius: 10,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'text',
									}}>
									{numeros && numeros.length === 2 && (
										<div
											className={'image-clignote-rapidement'}
											style={{ height: 20, width: 2, backgroundColor: '#4867EC' }}
										/>
									)}
									<div
										style={{
											color: '#4867EC',
											fontSize: 20,
											fontWeight: 600,
										}}>
										{numeros && numeros.length > 2 && numeros.charAt(2)}
									</div>
								</div>

								<div
									style={{
										width: 18,
										height: 4,
										marginLeft: 15,
										marginRight: 15,
										backgroundColor: '#4867EC',
										borderRadius: 30,
									}}
								/>

								<div
									style={{
										backgroundColor: 'rgba(72,103,236,0.18)',
										height: 60,
										width: 48,
										borderTopLeftRadius: 10,
										borderBottomLeftRadius: 10,
										marginRight: 1,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'text',
									}}>
									{numeros && numeros.length === 3 && (
										<div
											className={'image-clignote-rapidement'}
											style={{ height: 20, width: 2, backgroundColor: '#4867EC' }}
										/>
									)}
									<div
										style={{
											color: '#4867EC',
											fontSize: 20,
											fontWeight: 600,
										}}>
										{numeros && numeros.length > 3 && numeros.charAt(3)}
									</div>
								</div>

								<div
									style={{
										backgroundColor: 'rgba(72,103,236,0.18)',
										height: 60,
										width: 48,
										marginRight: 1,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'text',
									}}>
									{numeros && numeros.length === 4 && (
										<div
											className={'image-clignote-rapidement'}
											style={{ height: 20, width: 2, backgroundColor: '#4867EC' }}
										/>
									)}
									<div
										style={{
											color: '#4867EC',
											fontSize: 20,
											fontWeight: 600,
										}}>
										{numeros && numeros.length > 4 && numeros.charAt(4)}
									</div>
								</div>

								<div
									style={{
										backgroundColor: 'rgba(72,103,236,0.18)',
										height: 60,
										width: 48,
										borderTopRightRadius: 10,
										borderBottomRightRadius: 10,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'text',
									}}>
									{numeros && numeros.length === 5 && (
										<div
											className={'image-clignote-rapidement'}
											style={{ height: 20, width: 2, backgroundColor: '#4867EC' }}
										/>
									)}
									<div
										style={{
											color: '#4867EC',
											fontSize: 20,
											fontWeight: 600,
										}}>
										{numeros && numeros.length > 5 && numeros.charAt(5)}
									</div>
								</div>
							</div>

							<input
								ref={inputRef}
								type="text"
								value={numeros}
								maxLength={6}
								onFocus={onFocus}
								onBlur={onBlur}
								onChange={(e) => setNumeros(e.target.value)}
								style={{
									height: 0,
									border: 0,
									outline: 'none',
								}}
							/>

							<div
								className={'hover'}
								style={{
									marginTop: 30,
									backgroundColor: '#4867EC',
									color: '#FFF',
									fontSize: 14,
									fontWeight: 600,
									width: 325,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: 30,
									height: 34,
								}}>
								{t('login_page.two_steps_id.resend_code')}
							</div>

							<p
								onClick={() => setTwoStepsIdentification(false)}
								type={'text'}
								className={'hover'}
								style={{
									fontSize: 13,
									fontWeight: 600,
									color: '#4867EC',
								}}>
								{t('login_page.back_to_login')}
							</p>
						</div>
					</div>
				) : (
					<div className={'secondPart'} style={{ width: widthSecondPart, marginRight: marginLeftForm }}>
						<div className="loginComponent" style={{ opacity: opacityForm }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}>
								<h2
									className={'title'}
									style={{
										marginTop: 0,
										marginBottom: 30,
									}}>
									{isMotDePasseOublie
										? t('login_page.titles.reset_password')
										: loginEtape1
											? t('login_page.titles.connexion')
											: t('login_page.titles.hello')}
								</h2>

								{!loginEtape1 && (
									<div className={'emailInfo'}>
										<div className={'firstLetter'}>
											<p style={{ margin: 0 }}>{email.charAt(0).toUpperCase()}</p>
										</div>
										<p style={{ margin: 0, opacity: 0.7 }}>{email}</p>
									</div>
								)}

								{error !== '' ? (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											width: 367,
											justifyContent: 'center',
											marginTop: loginEtape1 ? -22 : -15,
										}}>
										<img src={wraning} alt="" style={{ width: 16, marginRight: 10 }} />
										<div style={{ width: 270, marginTop: 15, marginBottom: 13 }}>
											<span
												className={'errorMessage'}
												style={{ fontSize: 13, color: '#ec404b', fontWeight: 500 }}>
												{error?.message ===
													'There is no user record corresponding to this identifier. The user may have been deleted.' ||
												error?.message ===
													'The password is invalid or the user does not have a password.'
													? t('login_page.errors.incorrect_infos')
													: error?.message ===
														  'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
														? t('login_page.errors.attempts')
														: error?.message ===
															  'A network error (such as timeout, interrupted connection or unreachable host) has occurred.'
															? t('login_page.errors.network')
															: error?.message}
											</span>
										</div>
									</div>
								) : isMotDePasseOublie ? (
									<p
										className={'subtitle'}
										style={{
											textAlign: 'center',
											width: 380,
											marginTop: -7,
											marginBottom: -8,
										}}>
										{t('login_page.reinitialize.request')}
									</p>
								) : (
									<p className={'subtitle'}></p>
								)}

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}>
									{loginEtape1 ? (
										<div style={{ display: 'flex', marginBottom: 5 }}>
											<p className={'pasCompte'}>
												{!isMotDePasseOublie && t('login_page.no_account.question')}
											</p>
											{!isMotDePasseOublie && (
												<p className={'inscription'} onClick={DemarrerEssai}>
													{t('login_page.no_account.button')}
												</p>
											)}
										</div>
									) : (
										<p
											style={{ marginBottom: 25, marginLeft: 0 }}
											className={'inscription'}
											onClick={() => {
												setLoginEtape1(true);
												setEventData(data);
												setLoading(false);
												setError('');
											}}>
											{t('login_page.use_another_account')}
										</p>
									)}

									<div
										style={{
											width: 20,
											height: 5,
										}}
									/>

									<form onSubmit={handleSubmit}>
										{loginEtape1 && (
											<div className={'inputGroup'}>
												<input
													type="email"
													placeholder={t('login_page.placeholders_input.email')}
													className={champVideEmail ? 'inputRouge' : 'input'}
													value={email}
													id={'email'}
													onChange={handleChange}
													autoFocus={true}
												/>
											</div>
										)}

										{!isMotDePasseOublie && !loginEtape1 && (
											<div className={'inputGroup'}>
												<input
													type={showPassword ? 'text' : 'password'}
													placeholder={t('login_page.placeholders_input.password')}
													className={champVidePassword ? 'inputRouge' : 'input'}
													value={password}
													id={'password'}
													onChange={handleChange}
													autoFocus={true}
												/>
												<img
													src={showPassword ? barreEye : eye}
													alt=""
													className={'eye'}
													onClick={() => setShowPassword(!showPassword)}
												/>
											</div>
										)}

										<div
											className={'transition'}
											style={{
												display: 'flex',
												height: loginEtape1
													? champVideEmail
														? 35
														: 0
													: champVidePassword
														? 35
														: 0,
												overflow: 'hidden',
												opacity: loginEtape1
													? champVideEmail
														? 1
														: 0
													: champVidePassword
														? 1
														: 0,
												alignItems: 'center',
											}}>
											<div
												style={{
													display: 'flex',
													marginTop: 4,
													alignItems: 'center',
													marginLeft: 5,
												}}>
												<img src={wraning} alt="" style={{ width: 16, marginRight: 10 }} />
												<p
													style={{
														margin: 0,
														marginTop: 5,
														fontSize: 13,
														color: '#ec404b',
													}}>
													{t('login_page.errors.empty_input')}
												</p>
											</div>
										</div>

										<div
											style={{
												width: 20,
												height: 10,
											}}
										/>

										{isMotDePasseOublie && emailSent && (
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												<p
													style={{
														color: '#1C57DD',
														fontSize: 13,
														marginBottom: 20,
														marginTop: 5,
														fontWeight: 600,
													}}>
													{t('login_page.reinitialize.email_sent')}
												</p>
											</div>
										)}

										{loginEtape1 ? (
											<button
												style={{
													border: 0,
													height: 45,
													borderRadius: 5,
													opacity: loading ? 0.3 : 1,
												}}
												className="buttonSuivant">
												<p className={'titleButtonSuivant'}>
													{isMotDePasseOublie ? t('common.send') : t('common.continue')}
												</p>
											</button>
										) : (
											<button
												id={'sign-in-button'}
												style={{
													border: 0,
													height: 45,
													borderRadius: 5,
													opacity: loading ? 0.3 : 1,
												}}
												className="buttonSuivant"
												disabled={loading}>
												<p className={'titleButtonSuivant'}>
													{isMotDePasseOublie
														? t('common.send')
														: t('login_page.titles.connexion') + (loading ? '...' : '')}
												</p>
											</button>
										)}

										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
											}}>
											{!isMotDePasseOublie ? (
												<p
													onClick={() => {
														setIsMotDePasseOublie(true);
													}}
													className={'motDePasseOublie'}>
													{t('login_page.forgot_password')}
												</p>
											) : (
												<p
													style={{ marginTop: 23, marginLeft: 0, marginBottom: 22.5 }}
													className={'inscription'}
													onClick={() => {
														setIsMotDePasseOublie(false);
														setEmailSent(false);
													}}>
													{t('login_page.back_to_login')}
												</p>
											)}
										</div>

										{/*
                                <div>

                                    <div className='buttonLogin1' onClick={DemarrerOnBoardingCollaborateur}>
                                        <p className={'textButton'}>Integration Collaborateur</p>
                                    </div>

                                    <div className='buttonLogin1' onClick={DemarrerOnBoardingAgencePartenaire}>
                                        <p className={'textButton'}>Integration Agence Partenaire</p>
                                    </div>
                                </div>
                            */}
									</form>
								</div>
							</div>
						</div>
					</div>
				)}

				<div className={'thirdPart'} style={{ width: widthLeft }}>
					<div style={{ display: 'flex', width: 'calc(50vw - 135px)' }}>
						<a href="https://opusoft.fr" target={'_blank'}>
							<p className={'textThirdPart'}>©OPUS</p>
						</a>

						<a href="https://opusoft.fr/contact" target={'_blank'}>
							<p className={'textThirdPart'}>{t('common.contact')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Politique-de-Protection-des-Donn-es-Personnelles-cd8add65b0eb4e96801cc9751a10cc2c"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.confidentiality')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-d-Utilisation-ad4d7b82deb3415b934cd3a97aa15b0b"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgu')}</p>
						</a>

						<a
							href="https://opusoft.notion.site/Conditions-G-n-rales-de-Vente-7de0a4c9162a453db6b00bf461a4d46a"
							target={'_blank'}>
							<p className={'textThirdPart'}>{t('legals.cgv')}</p>
						</a>
					</div>

					<LanguageSelector />
				</div>
			</div>

			<div className={'rightPart'} style={{ width: widthRight }}>
				<img src={loginBlue} alt="logo" className="imgConnexion" />
			</div>
		</div>
	);
}
