import React, { useMemo } from 'react';
import './_itemEvetnLog.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDateHourly } from 'src/useful/date';
import { formatDateNumerically } from 'src/useful/date';
import { TICKET_LOG_TYPE } from 'src/pages/Fiche Projet/Tickets/data';
import { desescapeSpecialChars } from 'src/useful/UsefulFunctions';

ItemEventLog.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	unread: PropTypes.bool,
};

export default function ItemEventLog({ name, type, date, unread = true, large }) {
	const { t, i18n } = useTranslation();

	const description = useMemo(() => {
		switch (type) {
			case TICKET_LOG_TYPE.created:
				return {
					before: t('translation.ticketLogCreated.before'),
					after: t('translation.ticketLogCreated.after'),
				};
			case TICKET_LOG_TYPE.opened:
				return {
					before: t('translation.ticketLogOpened.before'),
					after: t('translation.ticketLogOpened.after'),
				};
			case TICKET_LOG_TYPE.solved:
				return {
					before: t('translation.ticketLogSolved.before'),
					after: t('translation.ticketLogSolved.after'),
				};
			case TICKET_LOG_TYPE.approved:
				return {
					before: t('translation.ticketLogApproved.before'),
					after: t('translation.ticketLogApproved.after'),
				};
			case TICKET_LOG_TYPE.refused:
				return {
					before: t('translation.ticketLogRefused.before'),
					after: t('translation.ticketLogRefused.after'),
				};
			default:
				return { before: '', after: '' };
		}
	}, [type]);

	return (
		<div className={'itemEventLog'}>
			<div className={'obliqueBar'} style={{ backgroundColor: unread && '#6190ff', marginLeft: large && 0 }} />
			<div>
				<p className={'nameEventLog'}>
					<span style={{ opacity: 0.7, fontWeight: 500 }}>{description.before}</span>
					{name}
					<span style={{ opacity: 0.7, fontWeight: 500 }}>{description.after}</span>
				</p>
				<p className={'dateEventLog'}>
					{desescapeSpecialChars(
						t('translation.theAt', {
							date: formatDateNumerically(date, i18n.language),
							time: formatDateHourly(date, i18n.language),
						})
					)}
				</p>
			</div>
		</div>
	);
}
