import { createContext, useContext, useState } from 'react';

const PagePermissionsContext = createContext();

export function usePagePermissions() {
    const context = useContext(PagePermissionsContext);
    if (!context) {
        throw new Error('usePagePermissions must be used within a PagePermissionsProvider');
    }
    return context;
}

export default PagePermissionsContext;
