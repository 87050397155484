import React, { useState, useEffect } from 'react';
import ModalViewMatterport from './ModalViewMatterport';
import { dateString } from '../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';

export default function MatterportList({ photos, setPhotos, albumData, projectId }) {
	const { t } = useTranslation();

	const [modalViewMatterport, setModalViewMatterport] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	useEffect(() => {
		window.addEventListener('resize', Update);
		Update();
		return () => window.removeEventListener('resize', Update);
	}, []);

	const [width, setWidth] = useState(0);
	function Update() {
		setWidth(window.innerWidth);
	}

	return (
		<div>
			<div className={'containerMatterportList'}>
				{photos?.map((item, index) => (
					<div
						className={'itemVisit'}
						style={{ width: width > 1100 ? 'calc(33% - 17px)' : 'calc(100% - 20px)' }}
						onClick={() => {
							setModalViewMatterport(true);
							setSelectedIndex(index);
						}}>
						<div>
							<img src={item.thumbnailUrl} alt="" className={'imgVisit'} />
							<p className={'title'}>{item.name}</p>
							<p className={'date'}>{dateString(t, item.orderDate, false, false, false)}</p>
						</div>
					</div>
				))}
			</div>

			<ModalViewMatterport
				modalViewMatterport={modalViewMatterport}
				setModalViewMatterport={setModalViewMatterport}
				selectedView={photos[selectedIndex]}
				setPhotos={setPhotos}
				albumData={albumData}
				projectId={projectId}
			/>
		</div>
	);
}
