import React, { useCallback, useEffect } from 'react';
import './_dropDownPropertyItem.scss';
import { useTranslation } from 'react-i18next';

export default function DropDownPropertyItem({ setIsRename, setIsDropDownOpen, handleDeleteOption }) {
	const { t } = useTranslation();

	return (
		<div className={'dropDownPropertyItem'}>
			<div className={'containerOption'}>
				<div className={'option'} style={{ color: '#ff0000' }} onClick={handleDeleteOption}>
					{t('translation.delete')}
				</div>
			</div>
			<div
				className={'containerOption'}
				onClick={() => {
					setIsRename(true);
					setIsDropDownOpen(false);
				}}>
				<div className={'option'}>{t('translation.rename')}</div>
			</div>
		</div>
	);
}
