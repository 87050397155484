import React from 'react';
import './_onboardingStep.scss';
import personasIcon from '../../../assets/personasIcon.svg';
import companyLogo from '../../../assets/companyLogo.svg';
import { BlueButton, GoBack, HeaderStep, ItemChoice } from '../components/ComponentsForm';
import { ROLES, STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';

export default function SelectRoleStep({
	setRole,
	role,
	setStep,
	handleNextStep,
	handleNextStepEmployeeStatus,
	marginTop,
	projectName,
	agencyProject,
	lastStep,
}) {
	const { t } = useTranslation();

	return (
		<div className={'onboardingStep choiceStep'} style={{ marginTop: marginTop }}>
			<GoBack lastStep={lastStep} setStep={setStep} />
			<HeaderStep
				title={t('translation.joinProjectTitleStep4')}
				subtitle={t('translation.joinProjectSubtitleStep4', { projectName: projectName })}
			/>

			<div className={'choiceList'}>
				<ItemChoice
					choiceName={t('common.moProject')}
					choiceTag={ROLES.moa}
					setChoice={setRole}
					icon={personasIcon}
					activeElement={role === ROLES.moa}
				/>
				<ItemChoice
					choiceName={t('common.entreprisesProject')}
					choiceTag={'entreprise'}
					setChoice={setRole}
					icon={companyLogo}
					activeElement={role === 'entreprise'}
				/>

				<div
					className={'buttonSalarie hover'}
					onClick={() => {
						const isConfirmed = window.confirm(
							t('translation.confirmEmployeeStatus', { agencyProject: agencyProject })
						);
						if (isConfirmed) {
							setRole('collaborator');
							handleNextStepEmployeeStatus();
						}
					}}>
					{t('translation.employeeStatus')} {agencyProject} ?
				</div>
			</div>

			<BlueButton handleClick={handleNextStep} text={t('common.next')} />
		</div>
	);
}
