import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../firebase/config';
import checkBlanc from '../../assets/checkBlanc.png';
import { withMajuscule, withMinuscule, withNumber, withSymbol } from '../../useful/UsefulFunctions';
import wraning from '../../assets/wraning.svg';
import checkVert from '../../assets/checkVert.svg';
import ModalCodeDoubleAuth from './ModalCodeDoubleAuth';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../contexts/agencyContext';
//import {formatPhoneNumberIntl} from "react-phone-number-input";
import TypeContext from '../../contexts/typeContext';
import {
	EmailAuthProvider,
	onAuthStateChanged,
	reauthenticateWithCredential,
	updateEmail,
	updatePassword,
} from 'firebase/auth';

export default function Securite(props) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);

	const [widthEnregistre, setWidthEnregistre] = useState(0);
	const [heightEnregistre, setHeightEnregistre] = useState(0);
	const [opacityEnregistre, setOpacityEnregistre] = useState(0);
	const [heightChangementMdp, setHeightChangementMdp] = useState(0);
	const [heightChangementMail, setHeightChangementMail] = useState(0);

	const [modalCodeDoubleAuth, setModalCodeDoubleAuth] = useState(false);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [authPassword, setAuthPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const [newMail, setNewMail] = useState('');
	const [password, setPassword] = useState('');

	const [mfaPhone, setMfaPhone] = useState('');
	const [emailIsVerified, setEmailIsVerified] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const [twoStepsIsActivated, setTwoStepsIsActivated] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
				setEmailIsVerified(user.emailVerified);
				setTwoStepsIsActivated(user.multiFactor.enrolledFactors.length > 0);
			}
		});
		return () => {
			listener();
		};
	}, [user]);

	useEffect(() => {
		firestore
			.doc(type === 'collaborators' ? `agencies/${agency}/collaborators/${uid}` : `${type}/${uid}`)
			.get()
			.then((doc) => {
				if (doc && doc.exists) {
					setMfaPhone(doc.data().mfaPhone);
				}
			});
	}, [agency, uid, type]);

	function changePassword() {
		const user = auth.currentUser;
		const cred = EmailAuthProvider.credential(user.email, authPassword);
		setLoading(true);
		reauthenticateWithCredential(user, cred)
			.then(() => {
				updatePassword(auth.currentUser, newPassword).then(() => {
					setLoading(false);
				});
				setAuthPassword('');
				setNewPassword('');
				setConfirmNewPassword('');
				setError(null);
				setHeightChangementMdp(0);
				setWidthEnregistre(200);
				setHeightEnregistre(170);
				setOpacityEnregistre(1);
				setTimeout(() => {
					setWidthEnregistre(0);
					setHeightEnregistre(0);
					setOpacityEnregistre(0);
				}, 1000);
			})
			.catch((error) => {
				setLoading(false);
				if (
					error.toString() ===
					'Error: [auth/network-request-failed] A network error has occurred, please try again.'
				) {
					window.alert(t('settings_safety.error_network'));
				} else {
					setError(true);
					setAuthPassword('');
				}
			});
	}

	function changeMail() {
		const user = auth.currentUser;
		const cred = EmailAuthProvider.credential(user.email, password);
		setLoading(true);
		reauthenticateWithCredential(user, cred)
			.then(() => {
				updateEmail(auth.currentUser, newMail).then(() => {
					setLoading(false);
				});
				setPassword('');
				setNewMail('');
				setError(null);
				setHeightChangementMail(0);
				setWidthEnregistre(200);
				setHeightEnregistre(170);
				setOpacityEnregistre(1);
				setTimeout(() => {
					setWidthEnregistre(0);
					setHeightEnregistre(0);
					setOpacityEnregistre(0);
				}, 1000);
			})
			.catch((error) => {
				setLoading(false);
				if (
					error.toString() ===
					'Error: [auth/network-request-failed] A network error has occurred, please try again.'
				) {
					window.alert(t('settings_safety.error_network'));
				} else {
					setError(true);
					setPassword('');
				}
			});
	}

	return (
		<div className={'contenu'}>
			<div className={'inner'} style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
				<div
					className={'enregistre'}
					style={{ width: widthEnregistre, height: heightEnregistre, opacity: opacityEnregistre }}>
					<img src={checkBlanc} alt="" className={'checkBlanc'} />
					<p className={'textEnregistre'}>{t('common.saved')}</p>
				</div>

				{heightChangementMail === 0 && (
					<>
						<p className="title" style={{ fontWeight: 700 }}>
							{t('settings_safety.changing_password')}
						</p>

						<p className={'information'} style={{ fontSize: 14, fontWeight: 500 }}>
							{t('settings_safety.infos_changing_password')}
						</p>

						<div
							className={'hover'}
							onClick={() => {
								if (heightChangementMdp === 0) {
									setHeightChangementMdp(450);
									setHeightChangementMail(0);
								} else {
									setHeightChangementMdp(0);
								}
							}}
							style={{
								fontWeight: 600,
								color: '#4867EC',
								fontSize: 15,
								marginBottom: 20,
							}}>
							{heightChangementMdp === 0
								? t('settings_safety.change_password')
								: t('settings_safety.cancel_change_password')}
						</div>

						<div className={'transition'} style={{ height: heightChangementMdp, overflow: 'hidden' }}>
							<div className={'longBar'} style={{ marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<p className={'designantInput'}>{t('settings_safety.old_password')}</p>
								<input
									style={{ marginLeft: 130 }}
									type={'password'}
									value={authPassword}
									placeholder={t('common.password')}
									onChange={(e) => setAuthPassword(e.target.value)}
								/>
							</div>

							<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<p className={'designantInput'}>{t('settings_safety.new_password')}</p>
								<div>
									<input
										style={{ marginLeft: 120 }}
										type={'password'}
										value={newPassword}
										placeholder={t('common.password')}
										onChange={(e) => setNewPassword(e.target.value)}
									/>
									<div className={'requires'}>
										<div className={newPassword.length > 7 ? 'requireValid' : 'require'}>
											<p className={'textRequire'}>{t('common.huit_characters')}</p>
										</div>
										<div className={withMajuscule(newPassword) ? 'requireValid' : 'require'}>
											<p className={'textRequire'}>{t('common.uppercase')}</p>
										</div>
										<div className={withMinuscule(newPassword) ? 'requireValid' : 'require'}>
											<p className={'textRequire'}>{t('common.lowercase')}</p>
										</div>
										<div className={withNumber(newPassword) ? 'requireValid' : 'require'}>
											<p className={'textRequire'}>{t('common.number')}</p>
										</div>
										<div className={withSymbol(newPassword) ? 'requireValid' : 'require'}>
											<p className={'textRequire'}>{t('common.special_character')}</p>
										</div>
									</div>
								</div>
							</div>

							<div className={'longBar'} style={{ marginTop: 25, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<p className={'designantInput'}>{t('settings_safety.confirm_password')}</p>
								<div>
									<input
										style={{ marginLeft: 100 }}
										type={'password'}
										value={confirmNewPassword}
										placeholder={t('common.password')}
										onChange={(e) => setConfirmNewPassword(e.target.value)}
									/>
									{
										<div
											className={'transition'}
											style={{
												display: 'flex',
												alignItems: 'center',
												marginLeft: 90,
												height:
													confirmNewPassword && newPassword !== confirmNewPassword ? 40 : 0,
												overflow: 'hidden',
												opacity:
													confirmNewPassword && newPassword !== confirmNewPassword ? 1 : 0,
											}}>
											<img
												src={wraning}
												alt=""
												style={{
													width: 15,
													objectFit: 'contain',
													marginLeft: 15,
												}}
											/>
											<p
												style={{
													fontSize: 13,
													fontWeight: '600',
													marginLeft: 10,
													color: '#FF0000',
												}}>
												{t('settings_safety.passwords_must_be_the_same')}
											</p>
										</div>
									}
								</div>
							</div>
							<div className={'longBar'} style={{ marginTop: 15 }} />
							<div style={{ display: 'flex' }}>
								{error && <p>{t('common.incorrect_password')}</p>}

								<button
									className={'hover'}
									style={{
										height: 40,
										paddingLeft: 35,
										paddingRight: 35,
										border: 0,
										backgroundColor: '#4867EC',
										color: '#FFF',
										fontSize: 13,
										fontWeight: 600,
										marginTop: 20,
										borderRadius: 8,
									}}
									disabled={
										loading ||
										!newPassword ||
										!confirmNewPassword ||
										!withMinuscule(newPassword) ||
										!withMajuscule(newPassword) ||
										!withNumber(newPassword) ||
										!withSymbol(newPassword) ||
										newPassword === authPassword ||
										newPassword !== confirmNewPassword ||
										newPassword.length < 8
									}
									onClick={(e) => {
										e.preventDefault();
										changePassword();
									}}>
									{t('settings_safety.install_a_new_password')}
								</button>
							</div>
						</div>
					</>
				)}

				<div
					className={'enregistre'}
					style={{ width: widthEnregistre, height: heightEnregistre, opacity: opacityEnregistre }}>
					<img src={checkBlanc} alt="" className={'checkBlanc'} />
					<p className={'textEnregistre'}>{t('common.saved')}</p>
				</div>

				{heightChangementMdp === 0 && (
					<>
						<p className="title" style={{ fontWeight: 700 }}>
							{t('settings_safety.change_mail_address')}
						</p>

						<p className={'information'} style={{ fontSize: 14, fontWeight: 500 }}>
							{t('settings_safety.change_mail_address_subtitle')}
						</p>
						<div
							className={'hover'}
							onClick={() => {
								if (heightChangementMail === 0) {
									setHeightChangementMail(420);
									setHeightChangementMdp(0);
								} else {
									setHeightChangementMail(0);
								}
							}}
							style={{
								fontWeight: 600,
								color: '#4867EC',
								fontSize: 15,
								marginBottom: 20,
							}}>
							{heightChangementMail === 0
								? t('settings_safety.change_email')
								: t('settings_safety.cancel_email')}
						</div>

						<div className={'transition'} style={{ height: heightChangementMail, overflow: 'hidden' }}>
							<div className={'longBar'} style={{ marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<p className={'designantInput'}>{t('settings_safety.old_email_address')}</p>
								<input
									style={{ marginLeft: 119 }}
									type={'text'}
									value={user?.email}
									placeholder={'email@email.com'}
									disabled={true}
								/>
							</div>

							<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<p className={'designantInput'}>{t('settings_safety.new_email_address')}</p>
								<div>
									<input
										style={{ marginLeft: 120 }}
										type={'text'}
										value={newMail}
										placeholder={'email@email.com'}
										onChange={(e) => setNewMail(e.target.value)}
									/>
								</div>
							</div>

							<div className={'longBar'} style={{ marginTop: 25, marginBottom: 15 }} />

							<div style={{ display: 'flex' }}>
								<p className={'designantInput'}>{t('common.password')}</p>
								<div>
									<input
										style={{ marginLeft: 173 }}
										type={'password'}
										value={password}
										placeholder={t('common.password')}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
							</div>
							<div className={'longBar'} style={{ marginTop: 15 }} />
							<div style={{ display: 'flex' }}>
								{error && <p>{t('common.incorrect_password')}</p>}

								<button
									className={'hover'}
									style={{
										height: 40,
										paddingLeft: 35,
										paddingRight: 35,
										border: 0,
										backgroundColor: '#4867EC',
										color: '#FFF',
										fontSize: 13,
										fontWeight: 600,
										marginTop: 20,
										borderRadius: 8,
									}}
									disabled={loading || !password || !newMail}
									onClick={(e) => {
										e.preventDefault();
										changeMail();
									}}>
									{t('settings_safety.install_a_new_password')}
								</button>
							</div>
						</div>
					</>
				)}

				{heightChangementMdp !== 0 ? null : heightChangementMail !== 0 ? null : (
					<div>
						<p className="title" style={{ fontWeight: 700, fontSize: 24, marginTop: 45 }}>
							{t('settings_safety.two_steps_auth')}
						</p>

						<p className={'information'} style={{ fontSize: 14, fontWeight: 500 }}>
							{t('settings_safety.two_steps_auth_info')}
						</p>

						{!twoStepsIsActivated && (
							<div
								className={'hover'}
								onClick={() => {
									/*if (!emailIsVerified) {
                                 window.alert(t('settings_safety.address_mail_to_verify'));
                             } else {
                                 setModalCodeDoubleAuth(true)
                             }*/
								}}
								style={{
									fontWeight: 600,
									color: 'rgb(72,103,236)',
									fontSize: 15,
									marginBottom: 20,
								}}>
								{t('common.soon_available')}
							</div>
						)}

						{twoStepsIsActivated && (
							<div style={{ marginTop: 30 }}>
								<div
									className={'longBar'}
									style={{ marginBottom: 15, backgroundColor: 'rgba(0,0,0,0.06)' }}
								/>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
									<div
										style={{
											width: 'calc(100vw - 320px)',
											marginLeft: 20,
										}}>
										<div style={{ display: 'flex', marginBottom: -15 }}>
											<p style={{ marginTop: 0, fontWeight: '600', fontSize: 15 }}>
												{t('settings_safety.sms')}
											</p>
											<img
												src={checkVert}
												alt=""
												style={{ width: 15, marginTop: -14, marginLeft: 7 }}
											/>
										</div>
										<p style={{ marginBottom: 0, fontWeight: '500', fontSize: 15 }}>
											{mfaPhone /*&& formatPhoneNumberIntl(mfaPhone)*/}
										</p>
									</div>
									{/*<p style={{margin: 0, color: '#4867EC', fontWeight: '600'}}>{t('common.delete')}</p>*/}
								</div>
								<div
									className={'longBar'}
									style={{ marginBottom: 15, backgroundColor: 'rgba(0,0,0,0.06)' }}
								/>
							</div>
						)}

						{!emailIsVerified && (
							<div>
								<div className={'longBar'} style={{ marginBottom: 15 }} />

								<div
									className={'hover'}
									style={{
										fontWeight: 600,
										color: '#f30000',
										fontSize: 15,
										marginBottom: 0,
										marginTop: 5,
									}}
									onClick={() => {
										user.sendEmailVerification().then(() => {
											setEmailSent(true);
										});
									}}>
									{t('settings_safety.verify_my_email_address')}
								</div>

								{emailSent && (
									<p style={{ fontSize: 14, opacity: 0.5 }}>
										{t('settings_safety.mail_cent', { email: user?.email })}
									</p>
								)}
							</div>
						)}
					</div>
				)}
			</div>

			<ModalCodeDoubleAuth
				modalCodeDoubleAuth={modalCodeDoubleAuth}
				setModalCodeDoubleAuth={setModalCodeDoubleAuth}
				isConfigMFA={true}
			/>
		</div>
	);
}
