import React, { useState } from 'react';
import './_ticketsHeader.scss';
import plusSquare from '../../../../../assets/plusSquare.svg';
import whiteGradient from '../../../../../assets/whiteGradient.png';
import printer from '../../../../../assets/printer.svg';
import TicketFilterItem from './Ticket Filter Item/TicketFilterItem';
import TicketsDisplayFilter from './Ticket Filter Item/Tickets Display Filter/TicketsDisplayFilter';
import { useTranslation } from 'react-i18next';
import { useTicketProperties } from '../../data';
import upDownBlue from '../../../../../assets/upDownBlue.svg';
import upDownGrey from '../../../../../assets/upDownGrey.svg';
import { TICKET_STATUS } from '../Modal Ticket/Follow Up/FollowUp';
import MultiSnagReport from '../../../../Reports/Multi Snag Report/MultiSnagReport';

export default function TicketsHeader({
	isSnagging,
	isReadOnly,
	isStatusShown,
	setIsStatusShown,
	isLotShown,
	setIsLotShown,
	isLocationShown,
	setIsLocationShown,
	isTicketOwnerShown,
	setIsTicketOwnerShown,
	isCompanyInChargeShown,
	setIsCompanyInChargeShown,
	isDueDateShown,
	setIsDueDateShown,
	isWorkDurationShown,
	setIsWorkDurationShown,
	isLinkedFileShown,
	setIsLinkedFileShown,
	isDescriptionShown,
	setIsDescriptionShown,

	companyInvolvedSelected,
	setCompanyInvolvedSelected,
	lotsConcernedSelected,
	setLotsConcernedSelected,
	locationOnSiteSelected,
	setLocationOnSiteSelected,
	rolesSelected,
	setRolesSelected,
	statusSelected,
	setStatusSelected,

	handleCreateTicket,
	setSortChoice,
	setSortUp,
	sortChoice,
	sortUp,
	tickets,
}) {
	const { t } = useTranslation();

	const { properties } = useTicketProperties();

	const [modalPrintTickets, setModalPrintTickets] = useState(false);

	return (
		<>
			<div className={'ticketsHeader'}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p className={'ticketsTitle'}>
						{isSnagging ? t('translation.snagging') : t('translation.missions')}
					</p>
					<div className={'privateBeta'}>{t('translation.privateBeta')}</div>
				</div>

				<div className={'ticketsButtonContainer'}>
					{isSnagging && tickets?.length > 0 && (
						<div className={'ticketsButton hover'} onClick={() => setModalPrintTickets(true)}>
							<img src={printer} alt="iconPlus" />
							<p>{t('translation.printReport')}</p>
						</div>
					)}
					{!isReadOnly && (
						<div
							className={'ticketsButton hover'}
							onClick={() => handleCreateTicket(isSnagging ? 'issue' : 'mission')}>
							<img src={plusSquare} alt="iconPlus" />
							<p>{isSnagging ? t('translation.createNewSnag') : t('translation.createNewMission')}</p>
						</div>
					)}
				</div>
			</div>
			<div className={'ticketsHeaderLine'} />
			<div className={'ticketsTagsList'}>
				<div className={'containerLeftTags'}>
					<div className={'sortOptions'}>
						<SortOption
							name={t('translation.issueDate')}
							label={'issueDate'}
							isSelected={sortChoice === 'issueDate'}
							setSortChoice={setSortChoice}
							sortUp={sortUp}
							setSortUp={setSortUp}
						/>
						<SortOption
							name={t('translation.dueDate')}
							label={'dueDate'}
							isSelected={sortChoice === 'dueDate'}
							setSortChoice={setSortChoice}
							sortUp={sortUp}
							setSortUp={setSortUp}
						/>
					</div>

					{/*

					<div className={'verticalBar'} />

					<TicketFilterItem
						name={t('translation.unread')}
						handleClick={() => setDisplayUnread(!displayUnread)}
						isTrue={displayUnread}
						pastille={numberUnread}
					/>

					*/}

					{isSnagging && (
						<>
							<div className={'verticalBar'} />

							<TicketFilterItem
								name={`${t('translation.companiesMaj')} ${companyInvolvedSelected.length < 1 ? '' : `(${companyInvolvedSelected.length})`}`}
								isTrue={companyInvolvedSelected.length > 0}
								array={companyInvolvedSelected}
								setArray={setCompanyInvolvedSelected}
								dropDown={true}
								options={properties.reviewers.options}
							/>
							<TicketFilterItem
								name={`${t('translation.lots')} ${lotsConcernedSelected.length < 1 ? '' : `(${lotsConcernedSelected.length})`}`}
								isTrue={lotsConcernedSelected.length > 0}
								array={lotsConcernedSelected}
								setArray={setLotsConcernedSelected}
								dropDown={true}
								options={properties.lots.options}
							/>
							<TicketFilterItem
								name={`${t('translation.locations')} ${locationOnSiteSelected.length < 1 ? '' : `(${locationOnSiteSelected.length})`}`}
								isTrue={locationOnSiteSelected.length > 0}
								array={locationOnSiteSelected}
								setArray={setLocationOnSiteSelected}
								dropDown={true}
								options={properties.locations.options}
							/>

							<TicketFilterItem
								name={`${t('translation.role')} ${rolesSelected?.length < 1 ? '' : `(${rolesSelected?.length})`}`}
								isTrue={rolesSelected?.length > 0}
								array={rolesSelected}
								setArray={setRolesSelected}
								dropDown={true}
								options={[
									{ key: 'creator', label: t('translation.creator') },
									{ key: 'company', label: t('translation.inCharge') },
									{ key: 'reviewer', label: t('translation.reviewer') },
									{ key: 'user', label: t('translation.viewer') },
								]}
							/>

							<TicketFilterItem
								name={`${t('translation.status')} ${statusSelected?.length < 1 ? '' : `(${statusSelected?.length})`}`}
								isTrue={statusSelected?.length > 0}
								array={statusSelected}
								setArray={setStatusSelected}
								dropDown={true}
								options={[
									{ key: TICKET_STATUS.new, label: t('translation.newSnag') },
									{ key: TICKET_STATUS.pendingProcess, label: t('translation.waitingForProcessing') },
									{ key: TICKET_STATUS.inProgress, label: t('translation.waitingForResolution') },
									{ key: TICKET_STATUS.pendingApproval, label: t('translation.waitingForApproval') },
									{ key: TICKET_STATUS.refused, label: t('translation.proposalRefused') },
									{ key: TICKET_STATUS.approved, label: t('translation.snagResolved') },
								]}
							/>
						</>
					)}

					{(companyInvolvedSelected?.length > 0 ||
						lotsConcernedSelected?.length > 0 ||
						locationOnSiteSelected?.length > 0 ||
						rolesSelected?.length > 0 ||
						statusSelected?.length > 0) && (
						<p
							className={'resetFilterButton hover'}
							onClick={() => {
								setCompanyInvolvedSelected([]);
								setLotsConcernedSelected([]);
								setLocationOnSiteSelected([]);
								setRolesSelected([]);
								setStatusSelected([]);
							}}>
							{t('translation.reset')}
						</p>
					)}
				</div>

				<img src={whiteGradient} alt="" className={'whiteGradient'} style={{ zIndex: 100 }} />

				<TicketsDisplayFilter
					isSnagging={isSnagging}
					isStatusShown={isStatusShown}
					setIsStatusShown={setIsStatusShown}
					isLotShown={isLotShown}
					setIsLotShown={setIsLotShown}
					isLocationShown={isLocationShown}
					setIsLocationShown={setIsLocationShown}
					isTicketOwnerShown={isTicketOwnerShown}
					setIsTicketOwnerShown={setIsTicketOwnerShown}
					isCompanyInChargeShown={isCompanyInChargeShown}
					setIsCompanyInChargeShown={setIsCompanyInChargeShown}
					isDueDateShown={isDueDateShown}
					setIsDueDateShown={setIsDueDateShown}
					isWorkDurationShown={isWorkDurationShown}
					setIsWorkDurationShown={setIsWorkDurationShown}
					isLinkedFileShown={isLinkedFileShown}
					setIsLinkedFileShown={setIsLinkedFileShown}
					isDescriptionShown={isDescriptionShown}
					setIsDescriptionShown={setIsDescriptionShown}
				/>
			</div>
			<div className={'ticketsHeaderLine'} />

			<MultiSnagReport
				isOpen={modalPrintTickets}
				onClose={() => setModalPrintTickets(false)}
				dataTickets={tickets}
			/>
		</>
	);
}

function SortOption({ name, label, isSelected, setSortChoice, sortUp, setSortUp }) {
	return (
		<div
			className={isSelected ? 'sortOption sortOptionSelected' : 'sortOption hover'}
			onClick={() => {
				setSortChoice(label);
				setSortUp((prev) => !prev);
			}}>
			{name}
			<div style={{ width: 18, display: 'flex' }}>
				<img
					src={isSelected ? upDownBlue : upDownGrey}
					alt=""
					style={{ transform: isSelected && sortUp && 'rotate(180deg)' }}
				/>
			</div>
		</div>
	);
}
