import React from "react";
import {useTranslation} from "react-i18next";

export default function WidgetEchecPaiement() {
    const { t } = useTranslation();

    return (
        <div className={'bigWidgetSlectionForfait'} style={{backgroundColor: '#f8d2d2'}}>
            <p className={'titleWidget'} style={{color: '#d52222'}}>{t('widget_payment_fail.fail_1')} <span style={{fontWeight: 700}}>{t('widget_payment_fail.fail_2')}</span> {t('widget_payment_fail.fail_3')}</p>
            <div>
                <div className={'buttonSelectForfait'} style={{backgroundColor: '#d52222'}}>
                    {t('widget_payment_fail.fail_4')}
                </div>
            </div>
        </div>
    );
}
