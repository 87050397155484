import React, { useEffect, useRef } from 'react';
import './_ticketTodolist.scss';
import filledCheck from '../../../../../../assets/filledCheck.svg';
import emptyCheck from '../../../../../../assets/emptyCheck.svg';
import trashIcon from '../../../../../../assets/trashIcon.svg';

export default function SubTodoItem({
	subTodo,
	onDeleteSubTodo,
	todo,
	editTodoText,
	onToggleSubTodo,
	setIdSubTodoFocused,
	idSubTodoFocused,
	index,
	updateTodos,
}) {
	const inputRef = useRef(null);
	const isClickFocus = useRef(false);

	useEffect(() => {
		if (idSubTodoFocused === index && inputRef.current && !isClickFocus.current) {
			inputRef.current.focus();
			// Place le curseur à la fin du texte
			inputRef.current.selectionStart = inputRef.current.value.length;
			inputRef.current.selectionEnd = inputRef.current.value.length;
		}
		isClickFocus.current = false; // Reset pour le prochain focus
	}, [idSubTodoFocused, index]);

	const handleKeyDown = (event) => {
		if (event.key === 'Backspace' && subTodo.text.length === 1) {
			event.preventDefault();
			setIdSubTodoFocused(index - 1);
			onDeleteSubTodo(todo.id, subTodo.id);
		}
		if ((event.key === 'Enter' || event.key === 'NumpadEnter') && todo.subTodos.length - 1 === index) {
			event.preventDefault();
			setIdSubTodoFocused(todo.subTodos.length);
		}
		if ((event.key === 'Enter' || event.key === 'NumpadEnter') && subTodo.text === '') {
			event.preventDefault();
			onDeleteSubTodo(todo.id, subTodo.id);
			setIdSubTodoFocused(index);
		}
	};

	// ==================================== Resize subtodo title ====================================

	useEffect(() => {
		const adjustHeight = () => {
			const textarea = inputRef.current;
			if (textarea) {
				textarea.style.height = '17px';
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		};

		adjustHeight();
		window.addEventListener('resize', adjustHeight);

		return () => window.removeEventListener('resize', adjustHeight);
	}, [subTodo.text]);

	return (
		<div key={subTodo.id} style={{ marginLeft: 45, marginTop: 6.5, display: 'flex', alignItems: 'flex-start' }}>
			<img
				src={subTodo.completed ? filledCheck : emptyCheck}
				alt=""
				className={'checkBox'}
				onClick={() => onToggleSubTodo(todo.id, subTodo.id)}
			/>{' '}
			<textarea
				style={{ marginTop: -2.5 }}
				ref={inputRef}
				onClick={() => {
					isClickFocus.current = true; // Indique que le focus vient d'un clic
					setIdSubTodoFocused(index);
				}}
				className={'subTodoText'}
				value={subTodo.text}
				onKeyDown={handleKeyDown}
				onChange={(e) => {
					const newText = e.target.value;
					editTodoText(subTodo.id, newText, true, todo.id);
				}}
				onBlur={() => {
					if (subTodo.text !== '') {
						updateTodos();
					}
				}}
			/>
		</div>
	);
}
