import React from 'react';

export default function DragComponent({fichiers, dashboard}) {
    return (
        <div className={'dragComponent'} style={{marginLeft: fichiers ? 9 : null, marginRight: fichiers ? 5 : null, marginTop: fichiers ? 8 : 0, opacity: dashboard ? 1 : null}}>
            <div style={{display: 'flex', marginBottom: 4}}>
                <div className={'dragDot'} style={{marginRight: 4, opacity: dashboard ? 1 : null}}/>
                <div className={'dragDot'} style={{opacity: dashboard ? 1 : null}}/>
            </div>

            <div style={{display: 'flex', marginBottom: 4}}>
                <div className={'dragDot'} style={{marginRight: 4, opacity: dashboard ? 1 : null}}/>
                <div className={'dragDot'} style={{opacity: dashboard ? 1 : null}}/>
            </div>

            <div style={{display: 'flex', marginBottom: 4}}>
                <div className={'dragDot'} style={{marginRight: 4, opacity: dashboard ? 1 : null}}/>
                <div className={'dragDot'} style={{opacity: dashboard ? 1 : null}}/>
            </div>
        </div>
    )
}
