import React, { useContext, useEffect, useState } from 'react';
import ItemPlanChoice from './ItemPlanChoice';
import { getPlansListUAE } from './OptionsList';
import { useTranslation } from 'react-i18next';
import AgencyDataContext from '../../../../../contexts/agencyDataContext';
import { useStripeContext } from '../../../../../contexts/stripeContext';

export default function PlanChoices({ isCustom }) {
	const { t } = useTranslation();

	const plansListUAE = getPlansListUAE(t);

	const agencyData = useContext(AgencyDataContext);
	const stripe = useStripeContext();

	const [planType, setPlanType] = useState('annually');
	const [currency, setCurrency] = useState('usd');

	useEffect(() => {
		fetch('https://ipapi.co/json/')
			.then((response) => response.json())
			.then((data) => {
				const cur = data.currency.toLowerCase();
				setCurrency(agencyData?.currency ?? (cur === 'eur' ? 'eur' : cur === 'aed' ? 'aed' : 'usd'));
			});
	}, [agencyData?.currency]);

	useEffect(() => {
		if (agencyData) {
			setPlanType(agencyData.billingPeriod || 'annually');
			setCurrency((prevState) => agencyData.currency ?? prevState ?? 'usd');
		}
	}, [agencyData]);

	return (
		<div className={'planChoices'}>
			{stripe?.isFreeTrial && (
				<div className={'currencies'}>
					<div
						className={planType === 'monthly' ? 'widgetCurrencySelected' : 'widgetCurrency'}
						onClick={() => setPlanType('monthly')}>
						Monthly
					</div>
					<div
						className={planType === 'annually' ? 'widgetCurrencySelected' : 'widgetCurrency'}
						onClick={() => setPlanType('annually')}>
						Yearly
						<div className={'save'}>Save 20% !</div>
					</div>
					<div
						className={planType === 'fiveYearly' ? 'widgetCurrencySelected' : 'widgetCurrency'}
						onClick={() => setPlanType('fiveYearly')}>
						5 years
						<div className={'save'}>Save 40% !</div>
					</div>
				</div>
			)}
			{plansListUAE.map((plan, index) => {
				return (
					<ItemPlanChoice
						key={index}
						plan={plan}
						index={index}
						currency={currency}
						planType={planType}
						isCustom={isCustom}
					/>
				);
			})}
			{stripe?.isFreeTrial && (
				<div className={'currencies'}>
					<div
						className={currency === 'usd' ? 'widgetCurrencySelected' : 'widgetCurrency'}
						onClick={() => setCurrency('usd')}>
						$
						<br />
						<br />
						US Dollar
					</div>
					<div
						className={currency === 'aed' ? 'widgetCurrencySelected' : 'widgetCurrency'}
						onClick={() => setCurrency('aed')}>
						AED <br /> <br /> United Arab Emirates Dirham
					</div>
					<div
						className={currency === 'eur' ? 'widgetCurrencySelected' : 'widgetCurrency'}
						onClick={() => setCurrency('eur')}>
						€ <br /> <br />
						Euro
					</div>
				</div>
			)}
		</div>
	);
}
