import React, { useRef, useState } from 'react';
import InputConv from './InputConv';
import ContainerMessages from './ContainerMessages';
import WidgetInfoConv from './InfoConv/WidgetInfoConv';

export default function ConvContainer({ conversations, selectedConvId }) {
	const conversation = conversations.find((conv) => conv.id === selectedConvId);
	const [messages, setMessages] = useState([]);
	const [responseMessage, setResponseMessage] = useState(null);

	const inputRef = useRef();

	return (
		<div className={'convContainer'}>
			<div className={'actualConv'}>
				<ContainerMessages
					conversations={conversations}
					conversationId={selectedConvId}
					setResponseMessage={setResponseMessage}
					messages={messages}
					setMessages={setMessages}
				/>
				{conversation && (
					<InputConv
						inputRef={inputRef}
						conversation={conversation}
						responseMessage={responseMessage}
						setResponseMessage={setResponseMessage}
						setMessages={setMessages}
						contacts={conversations}
					/>
				)}
				{conversation && (
					<WidgetInfoConv data={conversation} inputRef={inputRef} conversations={conversations} />
				)}
			</div>
		</div>
	);
}
