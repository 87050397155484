import { PRICE_ID_FIVE_YEARLY, PRICE_ID_MONTHLY, PRICE_ID_YEARLY } from '../../../../../stripe/utils';

export const getPlansListUAE = (t) => [
	{
		title: 'OPUS Services',
		subtitle: t('translation.subTitlePrincing3'),
		checks: [
			t('translation.allOPUS'),
			t('translation.consultantDedicated'),
			t('translation.participationOPUSResearch'),
		],
		price: '46€',
		priceYearly: '441€',
		priceFiveYearly: '1680€',
		priceUSD: '$48',
		priceUSDYearly: '$460',
		priceUSDFiveYearly: '$1728',
		priceAED: 'AED 175',
		priceAEDYearly: 'AED 1680',
		priceAEDFiveYearly: 'AED 6300',
		subtitlePrice: t('translation.subTitlePrince'),
		priceIdMonthly: PRICE_ID_MONTHLY,
		priceIdYearly: PRICE_ID_YEARLY,
		priceIdFiveYearly: PRICE_ID_FIVE_YEARLY,
	},
];

export const getOptionsListUAE = (t) => [
	{
		title: t('translation.titlePart1List'),
		options: [
			{
				title: t('translation.titlePart1Point1'),
				opusShare: false,
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart1Point2'),
				opusShare: false,
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart1Point3'),
				opusShare: false,
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart1Point4'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.titlePart1Point5'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
		],
	},

	{
		title: t('translation.titlePart2List'),
		options: [
			{
				title: t('translation.titlePart2Point1'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.titlePart2Point2'),
				opusShare: false,
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart2Point3'),
				opusShare: false,
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart2Point4'),
				opusShare: false,
				opusSharePlus: 'true',
			},
		],
	},

	{
		title: t('translation.OPUSAssist'),
		options: [
			{
				title: t('translation.classiqueAssist'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.premiumAssist'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.priority'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.intercomAssist'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.phoneAssist'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.visioAssist'),
				opusShare: false,
				opusSharePlus: 'true',
			},
		],
	},

	{
		title: t('translation.trainingAndIntegration'),
		options: [
			{
				title: t('translation.onboardingInApp'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.oneHourTraining'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.trainingProcess'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.onSiteIntegration'),
				opusShare: false,
				opusSharePlus: t('translation.onDevis'),
			},
			{
				title: t('translation.contributorsTraining'),
				opusShare: false,
				opusSharePlus: 'true',
			},
		],
	},

	{
		title: t('translation.helpAndInnovation'),
		options: [
			{
				title: t('translation.dedicatedConsultant'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.monthlyMeeting'),
				opusShare: false,
				opusSharePlus: 'true',
			},
			{
				title: t('translation.collaborativeProposalProject'),
				opusShare: false,
				opusSharePlus: t('translation.thirtyFive'),
			},
			{
				title: t('translation.opusResearchRd'),
				opusShare: false,
				opusSharePlus: t('translation.onProposal'),
			},
			{
				title: t('translation.betaFeatures'),
				opusShare: false,
				opusSharePlus: t('translation.onProposal'),
			},
		],
	},
];
