import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoadingPage() {
	const navigate = useNavigate();
	const wrapperRef = React.createRef();
	const [count, setCount] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			wrapperRef?.current?.classList.toggle('big-width');
			setCount(true);
		}, 500);
		setTimeout(() => {
			navigate('/projets');
		}, 2000);
	}, []);

	useEffect(() => {
		if (count) {
			wrapperRef?.current?.classList.toggle('big-width');
		}
	}, [count]);

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<p ref={wrapperRef} className={'loadingPageO'}>
				O
			</p>
		</div>
	);
}
