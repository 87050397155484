import React, { useContext, useEffect, useRef, useState } from 'react';
import '../messaging.scss';
import righticon from '../../../assets/righticon.png';
import plusIcon from '../../../assets/plusIcon.svg';
import ItemGroupMessaging from './ItemGroupMessaging';
import { useTranslation } from 'react-i18next';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import { ProjectsContext } from '../../../contexts/projectsContext';
import ModalNewGroup from './New Group/ModalNewGroup';
import LoadingDiv from '../../../components/LoadingDiv/LoadingDiv';

export default function ItemOrganisationMessaging({
	data,
	isActive,
	setSelectedOrganisationId,
	selectedConvId,
	setSelectedConvId,
	reduceOpacity,
	contacts,
}) {
	const { t } = useTranslation();

	const agencyData = useContext(AgencyDataContext);
	const [projects] = useContext(ProjectsContext);

	const [modalNewGroup, setModalNewGroup] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const contentRef = useRef(null);

	useEffect(() => {
		if (agencyData?.id === data?.id || projects?.find((item) => item.id === data?.id)) {
			setIsLoading(false);
		}
	}, [projects, agencyData]);

	useEffect(() => {
		if (isActive) {
			const element = contentRef.current;
			element.style.height = `${element.scrollHeight}px`;

			const transitionEnd = () => {
				element.style.height = 'auto';
				element.removeEventListener('transitionend', transitionEnd);
			};
			element.addEventListener('transitionend', transitionEnd);
		} else {
			const element = contentRef.current;
			requestAnimationFrame(() => {
				element.style.height = `${element.scrollHeight}px`;
				requestAnimationFrame(() => {
					element.style.height = '0px';
				});
			});
		}
	}, [isActive, contentRef]);

	return (
		data && (
			<div>
				<div
					className={isActive ? 'itemConvActive' : 'itemConv'}
					style={{ opacity: reduceOpacity && !isActive ? 0.4 : 1 }}
					onClick={() => {
						if (isActive) {
							setSelectedOrganisationId('');
						} else {
							setSelectedOrganisationId(data.id);
						}
						setSelectedConvId('');
					}}>
					{isLoading ? (
						<LoadingDiv width={'48px'} height={'48px'} borderRadius={'5px'} />
					) : data.id === agencyData?.id ? (
						<div className={'agencyPicture'}>{agencyData?.diminutifAgency}</div>
					) : projects?.find((item) => item.id === data.id)?.imgUrl &&
					  !projects?.find((item) => item.id === data.id)?.imgUrl.includes('/static/') ? (
						<img
							src={projects?.find((item) => item.id === data.id)?.imgUrl}
							alt=""
							className={'projectPicture'}
							style={{ background: '#eeeeee' }}
						/>
					) : (
						<div className={'agencyPicture'} style={{ fontSize: 30, fontWeight: '200', margin: 0 }}>
							O
						</div>
					)}

					<div className={'rightPartItemConv'}>
						{isLoading ? (
							<>
								<LoadingDiv width={'100px'} height={'20px'} borderRadius={'2px'} />
								<LoadingDiv width={'150px'} height={'15px'} borderRadius={'2px'} />
							</>
						) : (
							<>
								<p className={'convName'}>{data.name}</p>
								<p className={'lastMessage'}>
									{data.conversations.length}{' '}
									{data.conversations?.length > 1
										? t('translation.chatRooms')
										: t('translation.chatRoom')}
								</p>
							</>
						)}
					</div>
					<img src={righticon} alt="" className={'rightIconMessaging'} />
				</div>

				<div className={`groupList ${isActive ? 'expand' : ''}`} ref={contentRef}>
					{data.conversations.map((group) => (
						<ItemGroupMessaging
							key={group.id}
							data={group}
							setSelectedConvId={setSelectedConvId}
							isActive={selectedConvId === group.id}
						/>
					))}
					<ButtonNewGroup setModalNewGroup={setModalNewGroup} />
				</div>

				<div className={'barItemMessaging'} />

				<ModalNewGroup
					modalNewGroup={modalNewGroup}
					setModalNewGroup={setModalNewGroup}
					parentId={data.id}
					contacts={contacts}
				/>
			</div>
		)
	);
}

function ButtonNewGroup({ setModalNewGroup }) {
	const { t } = useTranslation();

	return (
		<div className={'newGroup hover'} onClick={() => setModalNewGroup(true)}>
			<div className={'containerPlusIconNewGroup'}>
				<img src={plusIcon} alt="" className={'plusIcon'} />
			</div>
			<p className={'titleButtonNewGroup'}>{t('translation.createANewGroup')}</p>
		</div>
	);
}
