import gerySearchIcon from '../../assets/gerySearchIcon.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

const NoResult = () => {
	const { t } = useTranslation();

	return (
		<div className={'noResult'}>
			<img src={gerySearchIcon} alt="" />
			<p className={'noResultFound'}>{t('common.no_result_found')}</p>
			<p className={'tryAgain'}>{t('research_project.no_result_2')}</p>
		</div>
	);
};

export default NoResult;
