import React from "react";
import selectAllIcon from "../../../../assets/selectAllIcon.svg";
import logoExport from "../../../../assets/exportIcon.png";
import logoImport from "../../../../assets/importIcon.svg";
import { useTranslation } from "react-i18next";

export default function ButtonsPhotos({
                                        setIsSelectOption,
                                        isSelectOption,
                                        setSelectedPhotos,
                                        selectedPhotos,
                                        deletePhoto,
                                        downloadPhotos,
                                        noPictures,
                                        exportAlbum
                                      }) {
  const { t } = useTranslation();

  return (
    <div className={"containerButtonPhotos"}>
      {!noPictures && (
        <div
          onClick={() => {
            setIsSelectOption((oldValue) => !oldValue);
            setSelectedPhotos([]);
          }}
          style={{ backgroundColor: isSelectOption ? "#daf8d2" : "#eeeeee" }}
          className={"buttonPhoto hover"}>
          <img className={"iconButtonPhoto"} src={selectAllIcon} alt="iconPlus" />
          <p className={"textButtonPhoto"} style={{ color: isSelectOption ? "#328536" : "#000" }}>
            {isSelectOption ? t("common.finish") : t("common.select")}
          </p>
        </div>
      )}

      {isSelectOption && (
        <div
          className={"buttonPhoto hover"}
          style={{ backgroundColor: "rgba(185,23,23,0.15)" }}
          onClick={async () => {
            if (selectedPhotos.length > 0 && window.confirm(t("photos.confirm_delete_selection"))) {
              for (const photo of selectedPhotos) {
                await deletePhoto(photo);
              }
              setSelectedPhotos([]);
            }
          }}>
          <p className={"textButtonPhoto"} style={{ color: "#b91717" }}>
            {t("photos.delete_selection")}
          </p>
        </div>
      )}

      {isSelectOption && (
        <div
          className={"buttonPhoto hover"}
          style={{ backgroundColor: "rgba(73,103,236,0.15)" }}
          onClick={() => downloadPhotos(selectedPhotos)}>
          <p className={"textButtonPhoto"} style={{ color: "#4967EC" }}>
            {t("photos.download_selection")}
          </p>
        </div>
      )}

      <div className={"buttonPhoto hover"} onClick={exportAlbum}>
        <img className={"iconButtonPhoto"} src={logoExport} alt="" />
        <p className={"textButtonPhoto"}>{t("common.export")}</p>
      </div>

      <div style={{ display: "flex" }}>
        <label htmlFor={"input"}>
          <div className={"buttonPhoto hover"}>
            <img className={"iconButtonPhoto"} src={logoImport} alt="" />
            <p className={"textButtonPhoto"}>{t("common.import")}</p>
          </div>
        </label>
      </div>
    </div>
  );
}
