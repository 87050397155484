import settingsIcon from '../../../../assets/settingsIcon.png';
import React, { useContext, useEffect, useState } from 'react';
import profilPicture, { stringSubtype, transformString } from '../../../../useful/UsefulFunctions';
import { firestore } from '../../../../firebase/config';
import AgencyContext from '../../../../contexts/agencyContext';
import crossIcon from '../../../../assets/crossIcon.png';
import searchIcon from '../../../../assets/searchIcon.png';
import gerySearchIcon from '../../../../assets/gerySearchIcon.png';
import ProjetItem from './ProjectItem';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

export default function AccountInfo({
	accountData,
	isInfosContactOpen,
	isCollaborator,
	isClient,
	isPartner,
	authProjects,
	isModalProjectsOpen,
	setIsModalProjectsOpen,
	isModifContactOpen,
}) {
	const agency = useContext(AgencyContext);
	const { t } = useTranslation();

	const [password, setPassword] = useState('');

	// Modal projects

	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState('');
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		if (accountData && isInfosContactOpen) {
			if (!isPartner) {
				firestore
					.doc(`accounts/${accountData.uid}`)
					.get()
					.then((doc) => {
						if (doc && doc.exists) {
							setPassword(doc.data().firstPassword);
						}
					});
			}
			if (isCollaborator) {
				const subscriber1 = firestore
					.collection(`agencies/${agency}/collaborators/${accountData.uid}/projects`)
					.onSnapshot((querySnapshot) => {
						if (querySnapshot) {
							const projects = [];
							querySnapshot.forEach((documentSnapshot) => {
								if (documentSnapshot && documentSnapshot.exists) {
									projects.push(documentSnapshot.id);
								}
							});
							setProjects(projects);
						}
					});
				return () => {
					subscriber1();
				};
			}
		}
	}, [accountData, isCollaborator, isPartner, isInfosContactOpen]);

	return (
		<>
			{accountData && !isModifContactOpen && (
				<div>
					<p
						style={{
							marginLeft: 210,
							marginBottom: -38,
							fontSize: 14,
							fontWeight: '600',
							color: '#7A818C',
							marginTop: 22,
							opacity: isInfosContactOpen ? 1 : 0,
						}}>
						{t('common.infos')}
					</p>

					<div className={'infosContact'} style={{ opacity: isInfosContactOpen ? 1 : 0 }}>
						<img
							alt=""
							className={'photoProfilInfoContact'}
							src={isPartner ? profilPicture(accountData.logoUrl) : profilPicture(accountData.imgUrl)}
						/>
						<p className={'nomContact'}>
							{accountData.surname} {accountData.name}
						</p>
						{isCollaborator ? (
							<div
								className={'statutContainer'}
								style={
									accountData.deletion
										? {
												color: '#b04b4b',
												backgroundColor: '#faf4f4',
												borderColor: '#f8d2d2',
											}
										: null
								}>
								{accountData.deletion
									? t('project_card.in_process_of_deletion')
									: stringSubtype(accountData.subtype, t)}
							</div>
						) : (
							<div className={'statutContainer'}>
								{accountData.nbProject} {t('common.projet') + (accountData.nbProject > 1 ? 's' : '')}
							</div>
						)}

						<div
							style={{
								marginTop: 20,
								borderTopLeftRadius: 15,
								borderTopRightRadius: 15,
								paddingLeft: 10,
								width: 300,
								display: 'flex',
								flexDirection: 'row',
								backgroundColor: '#F8F8F8',
							}}>
							<p
								style={{
									fontSize: 13,
									fontWeight: 500,
									marginLeft: 5,
									marginRight: 5,
								}}>
								{t('common.email')} :
							</p>
							<p
								className={'enableSelection'}
								style={{
									fontSize: 13,
									fontWeight: 500,
									marginLeft: 5,
									marginRight: 5,
									color: '#707070',
								}}>
								{accountData.email}
							</p>
						</div>

						<div
							style={{
								marginTop: 1,
								paddingLeft: 10,
								width: 300,
								display: 'flex',
								flexDirection: 'row',
								backgroundColor: '#F8F8F8',
								borderBottomLeftRadius: isPartner ? 15 : 0,
								borderBottomRightRadius: isPartner ? 15 : 0,
							}}>
							<p
								style={{
									fontSize: 13,
									fontWeight: 500,
									marginLeft: 5,
									marginRight: 5,
								}}>
								{t('common.phone')} :
							</p>

							<p
								style={{
									fontSize: 13,
									fontWeight: 500,
									marginLeft: 5,
									marginRight: 5,
									color: '#707070',
								}}>
								{accountData.phone}
							</p>
						</div>

						{!isPartner && (
							<div
								style={{
									marginTop: 1,
									borderBottomLeftRadius: 15,
									borderBottomRightRadius: 15,
									paddingLeft: 10,
									width: 300,
									display: 'flex',
									flexDirection: 'row',
									backgroundColor: '#F8F8F8',
									marginBottom: 20,
								}}>
								<p
									style={{
										fontSize: 13,
										fontWeight: 500,
										marginLeft: 5,
										marginRight: 5,
									}}>
									{t('modal_accounts.temporary_password')} :
								</p>

								<p
									className={'enableSelection'}
									style={{
										fontSize: 13,
										fontWeight: 500,
										marginLeft: 5,
										marginRight: 5,
										color: '#707070',
									}}>
									{password}
								</p>
							</div>
						)}

						{isCollaborator && (
							<>
								<div style={{ display: 'flex', flexDirection: 'row', width: 330 }}>
									<p
										style={{
											fontSize: 14,
											marginTop: 30,
											fontWeight: 600,
											marginBottom: 0,
										}}>
										{t('modal_accounts.associated_projects')}
									</p>
									<p
										style={{
											fontSize: 14,
											marginTop: 30,
											marginLeft: 7,
											fontWeight: 500,
											color: '#797979',
											marginBottom: 10,
										}}>
										{projects.length}
									</p>
								</div>
								<div
									style={{
										marginBottom: 20,
										width: 340,
										height: 1,
										backgroundColor: '#eeeeee',
									}}
								/>
								<div
									className={'hover'}
									style={{
										width: 340,
										backgroundColor: '#F8F8F8',
										fontSize: 13,
										fontWeight: 600,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: 46,
										marginBottom: 20,
										borderRadius: 10,
										color: 'rgb(0,0,0)',
									}}
									onClick={() => setIsModalProjectsOpen(!accountData.deletion)}>
									<img
										src={settingsIcon}
										alt=""
										style={{
											width: 13,
											marginRight: 5,
										}}
									/>
									{t('modal_accounts.manage_projects')}
								</div>
							</>
						)}
					</div>

					<Modal
						isOpen={isModalProjectsOpen}
						className={'modalAddProjetCollaborateur'}
						overlayClassName="OverlayModal"
						onRequestClose={() => {
							setIsModalProjectsOpen(false);
						}}
						shouldCloseOnOverlayClick={true}
						closeTimeoutMS={300}>
						<img
							src={crossIcon}
							alt="crossIcon"
							className={'crossIcon'}
							onClick={() => {
								setIsModalProjectsOpen(false);
							}}
						/>
						<div>
							<p className={'titleModal'}>
								{t('modal_accounts.add')}{' '}
								<span style={{ color: '#4B5FB0' }}>
									{accountData.surname} {accountData.name}
								</span>{' '}
								{t('modal_accounts.to_your_projects')}
							</p>
							<p className={'subtitle'}>{t('modal_accounts.choose_projects_for_collab')}</p>

							<div style={{ display: 'flex', marginLeft: -5, marginTop: 30, marginBottom: 0 }}>
								<input
									style={{ width: 510 }}
									type={'text'}
									placeholder={t('projects_page.research_a_project')}
									className={'rechercheParticipants'}
									value={research}
									onChange={(e) => setResearch(e.target.value)}
								/>
								<img src={searchIcon} alt="" className={'searchIcon'} style={{ marginLeft: -538 }} />
							</div>

							<div className={'listProjetAjoute'}>
								<div className={'innerListProjetAjoute'}>
									{isModalProjectsOpen &&
										authProjects &&
										authProjects
											.filter((item) =>
												transformString(item.name + item.tag + item.clientName).includes(
													transformString(research)
												)
											)
											.map((item, index) => (
												<ProjetItem
													key={index}
													id={item.id}
													projectData={item}
													projects={projects}
													uid={accountData.uid}
													collaboratorName={accountData.name}
													collaboratorData={accountData}
												/>
											))}
									{research &&
										authProjects.filter((item) =>
											transformString(item.name + item.tag + item.clientName).includes(
												transformString(research)
											)
										).length === 0 && (
											<div
												style={{
													width: 590,
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													justifyContent: 'center',
													marginTop: 40,
												}}>
												<img
													src={gerySearchIcon}
													alt=""
													style={{
														width: 30,
													}}
												/>
												<p
													style={{
														fontSize: 13,
														color: '#8c8c8c',
														fontWeight: 500,
														letterSpacing: 0.6,
														marginBottom: 8,
													}}>
													{t('common.no_result_found')}
												</p>
												<p
													style={{
														margin: 0,
														fontSize: 12,
														color: '#8c8c8c',
														fontWeight: 400,
														letterSpacing: 0.6,
													}}>
													{t('research_project.no_result_2')}
												</p>
											</div>
										)}
									<div style={{ height: 20 }} />
								</div>
							</div>
						</div>
					</Modal>
				</div>
			)}
		</>
	);
}
