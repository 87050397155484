import React, { useContext } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import opusWithPhone from '../../../../assets/opusWithPhone.png';
import { useTranslation } from 'react-i18next';
import '../_photos.scss';
import { useProjectContext } from '../../../../contexts/projectContext';
import AgencyDataContext from '../../../../contexts/agencyDataContext';
import TypeContext from '../../../../contexts/typeContext';

export default function ModalNotYourAlbum({
	modalNotYourAlbum,
	setModalNotYourAlbum,
	albumSelected,
	nameAlbumSelected,
	partnerAgencies,
}) {
	const { t } = useTranslation();
	const [project] = useProjectContext();
	const type = useContext(TypeContext);
	const agencyData = useContext(AgencyDataContext);

	return (
		<Modal
			isOpen={modalNotYourAlbum}
			className={'modalPasAccesFonct'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setModalNotYourAlbum(false);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalNotYourAlbum(false);
				}}
			/>

			<p className={'nomAgence'} style={{ textAlign: 'center', color: '#4F66E3' }}>
				{t('modalNotYourAlbum.title')}
			</p>

			<div>
				<p
					style={{
						fontWeight: 600,
					}}>
					{t('modalNotYourAlbum.subtitle_1')}{' '}
					<span style={{ color: '#4F66E3', fontWeight: 700 }}>{nameAlbumSelected}</span>{' '}
					{t('modalNotYourAlbum.subtitle_2')}
				</p>
			</div>

			{
				<>
					{type === 'clients' ? (
						<div style={{ display: 'flex' }}>
							<div
								style={{
									borderRadius: 13,
									marginTop: 10,
									height: 18,
									marginRight: 10,
									backgroundColor:
										project?.creator === albumSelected.createdBy?.agencyId ? '#3f5cf6' : '#D77534',
									marginLeft: 8,
									color: '#FFF',
									fontSize: 14,
									padding: '3px 8px',
									letterSpacing: 1,
								}}>
								{partnerAgencies.get(albumSelected.createdBy?.agencyId)?.diminutifAgency}
							</div>
						</div>
					) : albumSelected?.createdBy?.userType === 'client' ? (
						<div style={{ display: 'flex' }}>
							<div
								style={{
									borderRadius: 13,
									marginTop: 10,
									height: 18,
									marginRight: 10,
									backgroundColor: '#328536',
									marginLeft: 0,
									color: '#FFF',
									fontSize: 14,
									padding: '3px 8px',
									letterSpacing: 1,
								}}>
								Client
							</div>
						</div>
					) : albumSelected?.createdBy?.agencyId === agencyData?.uid ? null : (
						<div style={{ display: 'flex' }}>
							<div
								style={{
									borderRadius: 13,
									marginTop: 10,
									height: 18,
									marginRight: 10,
									backgroundColor: '#3f5cf6',
									marginLeft: 0,
									color: '#FFF',
									fontSize: 14,
									padding: '3px 8px',
									letterSpacing: 1,
								}}>
								{partnerAgencies.get(albumSelected.createdBy?.agencyId)?.diminutifAgency}
							</div>
						</div>
					)}
				</>
			}

			<div>
				<p
					style={{
						fontWeight: 500,
						marginTop: 30,
						marginBottom: 0,
						fontSize: 14,
					}}>
					{t('modalNotYourAlbum.explanation')}
				</p>
			</div>

			<img
				src={opusWithPhone}
				alt=""
				style={{
					height: 300,
					width: 500,
					objectFit: 'cover',
					borderRadius: 20,
					marginBottom: 40,
					marginTop: 30,
				}}
			/>
		</Modal>
	);
}
