import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { firestore } from '../../../../../firebase/config';
import { joinPaths } from '../../../../../firebase/utils';
import { Paths } from '../../../../../firebase/paths';
import TicketDescription from '../../../../Fiche Projet/Tickets/components/Modal Ticket/Ticket Description/TicketDescription';

export default function SnagDescription({ ticket, isLarge }) {
	const { t, i18n } = useTranslation();
	const [description, setDescription] = useState('');
	const [ticketContent, setTicketContent] = useState([]);
	const [project] = useProjectContext();

	// ==================================== Get content ====================================

	useEffect(() => {
		if (ticket?.id) {
			setTicketContent([]);
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.CONTENT))
				.where('type', '==', 'text')
				.orderBy('createdAt', 'asc')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const content = [];
						setDescription(querySnapshot.docs[0].data().text);

						querySnapshot.forEach((doc) => {
							if (doc.data().title?.length > 0 || doc.data().text?.length > 0) {
								content.push({ ...doc.data(), id: doc.id });
							}
						});
						setTicketContent(content);
					}
				});

			return () => unsubscribe();
		}
	}, [ticket?.id]);

	if (isLarge) {
		return (
			<div style={{ marginTop: 10 }}>
				{ticketContent?.map((item, index) => (
					<TicketDescription
						key={index}
						title={item.title}
						text={item.text}
						canModifyTicket={false}
						isLarge={isLarge}
					/>
				))}
			</div>
		);
	} else {
		return (
			<div className={'description'}>
				<span className={'titleDescription'}>{t('translation.description')}</span>
				<span className={'corpsDescription'}>
					{description.length > 0 ? description : t('translation.noDescription')}
				</span>
			</div>
		);
	}
}
