import React, { useContext, useEffect, useState } from 'react';
import '../messaging.scss';
import FilterOptions from './FilterOptions';
import SearchBarMessaging from './SearchBarMessaging';
import ItemConvMessaging from './ItemConvMessaging';
import ItemOrganisationMessaging from './ItemOrganisationMessaging';
import AgencyContext from '../../../contexts/agencyContext';
import { ProjectsContext } from '../../../contexts/projectsContext';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import chargement from '../../../assets/chargement.png';
import initConv from '../../../assets/initConv.svg';
import leftBigArrow from '../../../assets/leftBigArrow.svg';
import { useTranslation } from 'react-i18next';
import NoResult from '../../../components/NoResult/NoResult';
import { filterConversations } from '../utils';

export default function ConvsList({ conversations, contacts, isLoading, selectedConvId, setSelectedConvId }) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const [projects] = useContext(ProjectsContext);

	const [isContactList, setIsContactList] = useState(false);

	const [filterBy, setFilterBy] = useState('all');
	const [research, setResearch] = useState('');

	const [groupedConvs, setGroupedConvs] = useState([]);

	const [selectedOrganisationId, setSelectedOrganisationId] = useState('');

	useEffect(() => {
		if (conversations) {
			setGroupedConvs(
				conversations.reduce((acc, conversation) => {
					if (conversation) {
						const id = conversation.isGroup ? conversation.parentId : conversation.id;
						if (acc.map((item) => item.id).includes(id)) {
							acc.find((item) => item.id === id).conversations?.push(conversation);
						} else {
							if (conversation.id === id && !conversation.isGeneral) {
								acc.push({ isOrganisation: false, ...conversation });
							} else {
								acc.push({
									id,
									isOrganisation: true,
									conversations: [conversation],
									name:
										id === agencyId
											? agencyData?.name
											: projects.find((p) => p.id === id)?.name ?? '',
								});
							}
						}
					}
					return acc;
				}, [])
			);
		}
	}, [conversations, projects, agencyData]);

	return (
		<div className={'convsList'}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex' }}>
					<div className={'containerLeftArrow'} style={{ width: isContactList ? 35 : 0 }}>
						<img
							src={leftBigArrow}
							alt=""
							className={'leftBigArrow hover'}
							onClick={() => setIsContactList(false)}
						/>
					</div>
					<p className={'titleDiscussions'}>
						{isContactList ? t('translation.contactsOnOpus') : t('translation.discussions')}
					</p>
				</div>

				<img
					src={initConv}
					alt=""
					className={'initConv hover'}
					style={{ opacity: isContactList ? 0 : 1, cursor: isContactList ? 'default' : 'pointer' }}
					onClick={() => setIsContactList(true)}
				/>
			</div>
			<SearchBarMessaging research={research} setResearch={setResearch} />
			{!isContactList ? (
				<FilterOptions setFilterBy={setFilterBy} filterBy={filterBy} />
			) : (
				<div style={{ height: 10 }} />
			)}
			{isLoading ? (
				<div className={'loadingContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			) : (
				filterConversations(isContactList ? contacts : groupedConvs, research, filterBy, agencyId).map(
					(conv) => {
						if (conv.isOrganisation) {
							return (
								<ItemOrganisationMessaging
									key={conv.id}
									data={conv}
									isActive={selectedOrganisationId === conv.id}
									selectedConvId={selectedConvId}
									setSelectedConvId={setSelectedConvId}
									setSelectedOrganisationId={setSelectedOrganisationId}
									reduceOpacity={selectedOrganisationId && selectedOrganisationId !== conv.id}
									contacts={contacts}
								/>
							);
						} else {
							return (
								<ItemConvMessaging
									key={conv.id}
									data={conv}
									isActive={selectedConvId === conv.id}
									setSelectedConvId={setSelectedConvId}
									setSelectedOrganisationId={setSelectedOrganisationId}
									reduceOpacity={!!selectedOrganisationId}
									isContactList={isContactList}
								/>
							);
						}
					}
				)
			)}

			{(research.length > 0 || filterBy !== 'all') &&
				filterConversations(isContactList ? contacts : groupedConvs, research, filterBy, agencyId).length ===
					0 && <NoResult />}
		</div>
	);
}
