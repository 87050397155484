import React, { useEffect, useState, useMemo } from 'react';
import './_widgetUpload.scss';
import uploadIcon from '../../assets/uploadIcon.svg';
import whiteUp from '../../assets/whiteUp.svg';
import blackCrossIcon from '../../assets/blackCrossIcon.svg';
import bottomArrow from '../../assets/bottomArrow.svg';
import doneWhite from '../../assets/doneWhite.svg';
import whiteCross from '../../assets/whiteCross.svg';
import ItemWidgetUpload from './ItemWidgetUpload';
import { useTranslation } from 'react-i18next';

const WidgetUpload = ({ progressFiles, setProgressFiles }) => {
	const [isHidden, setIsHidden] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();

	// vitesse de téléchargement

	const [startTimes, setStartTimes] = useState({});

	useEffect(() => {
		const now = Date.now();
		const newStartTimes = { ...startTimes };

		Object.keys(progressFiles).forEach((id) => {
			if (!newStartTimes[id]) {
				newStartTimes[id] = now;
			}
		});

		setStartTimes(newStartTimes);
	}, [progressFiles]);

	const downloadSpeed = useMemo(() => {
		const now = Date.now();
		const speeds = Object.keys(progressFiles).map((id) => {
			const { bytesTransferred } = progressFiles[id];
			const startTime = startTimes[id] || now;
			const elapsedTime = (now - startTime) / 1000;
			return elapsedTime > 0 ? (bytesTransferred * 8) / elapsedTime : 0;
		});

		const totalSpeed = speeds.reduce((acc, speed) => acc + speed, 0);
		return totalSpeed / speeds.length;
	}, [progressFiles, startTimes]);

	const formatSpeed = (speed) => `${(speed / 2 ** 20).toFixed(2)} Mb/s`;

	const progressFilesIds = useMemo(() => Object.keys(progressFiles).reverse(), [progressFiles]);
	const progressFilesLength = useMemo(() => progressFilesIds.length, [progressFilesIds]);
	const progressFilesLengthDone = useMemo(
		() =>
			progressFilesIds.filter((id) => progressFiles[id].bytesTransferred === progressFiles[id].totalBytes).length,
		[progressFilesIds, progressFiles]
	);

	const inProgress = useMemo(
		() => progressFilesLengthDone < progressFilesLength,
		[progressFilesLengthDone, progressFilesLength]
	);
	const progressBar = useMemo(() => {
		const acc = progressFilesIds.reduce(
			(acc, id) => {
				const { bytesTransferred, totalBytes } = progressFiles[id];
				return {
					bytesTransferred: acc.bytesTransferred + bytesTransferred,
					totalBytes: acc.totalBytes + totalBytes,
				};
			},
			{ bytesTransferred: 0, totalBytes: 0 }
		);
		return (acc.bytesTransferred / acc.totalBytes) * 100;
	}, [progressFiles]);

	useEffect(() => {
		if (progressFiles && Object.keys(progressFiles).length > 0) {
			setIsHidden(false);
		} else {
			setIsHidden(true);
		}
	}, [progressFiles]);

	const closeWidget = () => {
		setIsHidden(true);
		setProgressFiles({});
	};

	return (
		<>
			{!isHidden && (
				<div className={isOpen ? 'widgetUploadOpen' : 'widgetUpload'}>
					{isOpen && (
						<div className={'headerUpload'}>
							<p className={'imports'}>{t('translation.imports')}</p>
							<div className={'containerButtons'}>
								<div className={'buttonCross'} onClick={() => setIsOpen(false)}>
									<img src={bottomArrow} alt="" className={'bottomArrow'} />
								</div>
								<div className={'buttonCross'} onClick={closeWidget}>
									<img src={blackCrossIcon} alt="" className={'blackCrossIcon'} />
								</div>
							</div>
						</div>
					)}

					{isOpen && (
						<div className={'listDocument'}>
							{progressFiles &&
								progressFilesIds.map((id) => <ItemWidgetUpload key={id} data={progressFiles[id]} />)}
						</div>
					)}

					{inProgress ? (
						<div className={'footerUploadInProgress'}>
							<div className={'iconPart'}>
								<img src={uploadIcon} alt="" className={'uploadIcon'} />
							</div>

							<div className={'progressPart'}>
								<div className={'textButton'} style={{ zIndex: 4 }}>
									<p className={'text'}>
										{t('translation.importOf')} {progressFilesLengthDone}{' '}
										{t('translation.elementLittle')}
										{progressFilesLengthDone > 1 && 's'} {t('translation.on')} {progressFilesLength}
										<div className={'speedDisplay'}>
											{t('translation.importSpeed')} {formatSpeed(downloadSpeed)}
										</div>
									</p>
									{!isOpen && (
										<div className={'buttonUp'} onClick={() => setIsOpen(true)}>
											<img src={whiteUp} alt="" className={'whiteUp'} />
										</div>
									)}
								</div>
								<div
									className={'progressBar'}
									style={{ zIndex: 0, width: (330 * progressBar) / 100 }}
								/>
							</div>
						</div>
					) : (
						<div className={'footerUploadInProgress'} style={{ backgroundColor: '#3B8441' }}>
							<div className={'iconPart'}>
								<img src={doneWhite} alt="" className={'uploadIcon'} style={{ width: 20 }} />
							</div>
							<div className={'progressPart'}>
								<div className={'textButton'} style={{ zIndex: 4 }}>
									<div>
										<p className={'text'} style={{ fontSize: 14 }}>
											{t('translation.successTransfer')}
										</p>
										<p className={'text'} style={{ fontSize: 10, marginTop: 2 }}>
											{t('translation.importSuccess')}
										</p>
									</div>

									{!isOpen && (
										<div style={{ display: 'flex' }}>
											<div className={'buttonUp'} onClick={() => setIsOpen(true)}>
												<img src={whiteUp} alt="" className={'whiteUp'} />
											</div>
											<div className={'buttonUp'} onClick={closeWidget}>
												<img src={whiteCross} alt="" className={'whiteCross'} />
											</div>
										</div>
									)}
								</div>
								<div
									className={'progressBar'}
									style={{ zIndex: 0, backgroundColor: '#5AA260', width: 330 }}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default WidgetUpload;
