import React from 'react';
import blueMail from '../../../../../assets/blueMail.svg';
import bluePhone from '../../../../../assets/bluePhone.svg';
import blueMessage from '../../../../../assets/blueMessage.svg';
import { useTranslation } from 'react-i18next';

export default function ContainerButtonsInfoConv({ data, handleSendMessage }) {
	const { t } = useTranslation();

	return (
		<div className={'containerButtonsInfoConv'}>
			<ButtonInfoConv img={blueMessage} titleButton={t('translation.message')} onClick={handleSendMessage} />
			<ButtonInfoConv
				img={bluePhone}
				titleButton={t('translation.phoneCall')}
				isDisabled={!data?.phone}
				onClick={() => alert(data.phone)}
			/>
			<ButtonInfoConv
				img={blueMail}
				titleButton={t('translation.email')}
				isDisabled={!data?.email}
				onClick={() => (window.location.href = 'mailto:' + data.email)}
			/>
		</div>
	);
}

function ButtonInfoConv({ titleButton, img, isDisabled, onClick }) {
	return (
		<div
			className={'buttonInfoConv hover'}
			style={{ opacity: isDisabled ? 0.5 : 1 }}
			onClick={() => {
				if (!isDisabled) onClick();
			}}>
			<img src={img} alt="" />
			{titleButton}
		</div>
	);
}
