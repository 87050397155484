import React, {useState} from 'react';
import Modal from "react-modal";
import imageFonctBienvenue from "../../assets/imageFonctBienvenue.png";
import step2Bienvenue from "../../assets/step2Bienvenue.png";
import step3bienvenue from "../../assets/step3bienvenue.png";
import {useTranslation} from "react-i18next";

export default function ModalBienvenue({modalBienvenue, setModalBienvenue}) {
    const { t } = useTranslation();

    const [firstStep, setFirstStep] = useState(true);
    const [secondStep, setSecondStep] = useState(false);
    const [thirdStep, setThirdStep] = useState(false);
    const [fourthStep, setFourthStep] = useState(false);

    return(
        <div className={'transition'}>
            <Modal isOpen={modalBienvenue} className={'modalBienvenue'} overlayClassName="OverlayModal" closeTimeoutMS={300}>

                <div>
                    <div className={'imagePart'}>
                        <img src={firstStep ?
                            imageFonctBienvenue
                            : secondStep ?
                                step2Bienvenue
                                : thirdStep ?
                                    step3bienvenue
                                    : fourthStep ?
                                        imageFonctBienvenue
                                        : null} alt=""
                         className={firstStep ?
                            'step2Bienvenue'
                            : secondStep ?
                                'step2Bienvenue'
                                : thirdStep ?
                                    'step3Bienvenue'
                                    : fourthStep ?
                                        'step2Bienvenue'
                                        : null

                        }/>
                    </div>

                    <div className={'textPart'}>
                        <p className={'title'}>
                            {firstStep ?
                                t('modal_bienvenue.title_1')
                                : secondStep ?
                                    t('modal_bienvenue.title_2')
                                    : thirdStep ?
                                        t('modal_bienvenue.title_3')
                                        : fourthStep ?
                                            t('modal_bienvenue.title_4')
                                            : null

                            }

                        </p>
                        <p className={'subtitle'}>
                            {firstStep ?
                                t('modal_bienvenue.subtitle_1')
                                : secondStep ?
                                    t('modal_bienvenue.subtitle_2')
                                    : thirdStep ?
                                        t('modal_bienvenue.subtitle_3')
                                        : fourthStep ?
                                            t('modal_bienvenue.subtitle_4')
                                            : null

                            }
                        </p>
                    </div>
                </div>

                <div className={'buttonPart'}>

                    <div className={'points'}>
                        <div
                            onClick={() => {
                                setFirstStep(true);
                                setSecondStep(false);
                                setThirdStep(false);
                                setFourthStep(false);
                            }}
                            className={'point'}
                            style={{backgroundColor: firstStep ? '#4867EC' : null}}
                        />
                        <div style={{width: 9}}/>
                        <div
                            onClick={() => {
                                setFirstStep(false);
                                setSecondStep(true);
                                setThirdStep(false);
                                setFourthStep(false);
                            }}
                            className={'point'}
                            style={{backgroundColor: secondStep ? '#4867EC' : null}}
                        />
                        <div style={{width: 9}}/>
                        <div
                            onClick={() => {
                                setFirstStep(false);
                                setSecondStep(false);
                                setThirdStep(true);
                                setFourthStep(false);
                            }}
                            className={'point'}
                            style={{backgroundColor: thirdStep ? '#4867EC' : null}}
                        />
                        <div style={{width: 9}}/>
                        <div
                            onClick={() => {
                                setFirstStep(false);
                                setSecondStep(false);
                                setThirdStep(false);
                                setFourthStep(true);
                            }}
                            className={'point'}
                            style={{backgroundColor: fourthStep ? '#4867EC' : null}}
                        />
                    </div>

                    <div
                        onClick={() => {
                            if (firstStep) {
                                setFirstStep(false);
                                setSecondStep(true);
                                setThirdStep(false);
                                setFourthStep(false);
                            } else if (secondStep) {
                                setFirstStep(false);
                                setSecondStep(false);
                                setThirdStep(true);
                                setFourthStep(false);
                            } else if (thirdStep) {
                                setFirstStep(false);
                                setSecondStep(false);
                                setThirdStep(false);
                                setFourthStep(true);
                            } else {
                                setModalBienvenue(false);
                            }
                        }}
                        className={'button'}>
                        {firstStep ? t('discover_widget.discover') : secondStep ? t('common.next') : thirdStep ? t('common.next') : fourthStep ? t('modal_bienvenue.enter_in_opus') : null}
                    </div>
                </div>


            </Modal>
        </div>

    )
}
