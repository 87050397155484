import React from 'react';
import './_onboardingStep.scss';
import { BlueButton, GoBack, HeaderStep, ItemChoice } from '../components/ComponentsForm';
import personasIcon from '../../../assets/personasIcon.svg';
import companyLogo from '../../../assets/companyLogo.svg';
import { STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';
export default function ClientTypeChoiceStep({ projectName, setRole, role, handleNextStep, marginTop }) {
	const { t } = useTranslation();

	return (
		<div className={'onboardingStep projectToJoin'} style={{ marginTop: marginTop }}>
			<HeaderStep
				title={t('translation.joinProjectTitleStep5')}
				subtitle={t('translation.joinProjectSubtitleStep5', { projectName: projectName })}
			/>

			<div className={'choiceList'}>
				<ItemChoice
					choiceName={t('common.moProject')}
					choiceTag={'mo'}
					setChoice={setRole}
					icon={personasIcon}
					activeElement={role === 'mo'}
				/>
				<ItemChoice
					choiceName={t('common.assistMoProject')}
					choiceTag={'assistMo'}
					setChoice={setRole}
					icon={companyLogo}
					activeElement={role === 'assistMo'}
				/>
				<ItemChoice
					choiceName={t('common.rpzMoProject')}
					choiceTag={'rpzMo'}
					setChoice={setRole}
					icon={personasIcon}
					activeElement={role === 'rpzMo'}
				/>
			</div>

			<BlueButton handleClick={() => handleNextStep('clientCompanyOrIndividual')} text={t('common.next')} />
		</div>
	);
}
