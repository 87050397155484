import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import Modal from 'react-modal';
import imageFonct from '../../../assets/imageFonct.png';
import crossIcon from '../../../assets/crossIcon.png';

export default function ModalAddProjectClient({ modalAddProjectClient, setModalAddProjectClient }) {
	const { t } = useTranslation();

	const agencyData = useContext(AgencyDataContext);

	return (
		<Modal
			isOpen={modalAddProjectClient}
			className={'modalFinEssaiGratuit'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setModalAddProjectClient(false);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalAddProjectClient(false);
				}}
			/>

			<p className={'title'}>{t('modalAddProjectClient.u_cant_create_project')}</p>

			<p className={'subtitle'}>
				{t('modalAddProjectClient.add_project_client_1')} <br /> <br />{' '}
				{t('modalAddProjectClient.add_project_client_2')}
			</p>

			<img src={imageFonct} alt="" className={'imageFonct'} style={{ marginBottom: 20 }} />
		</Modal>
	);
}
