import { adjustText, transformString } from '../../../../../useful/UsefulFunctions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore, functions } from '../../../../../firebase/config';
import AgencyContext from '../../../../../contexts/agencyContext';
import { httpsCallable } from 'firebase/functions';
import { arrayRemove } from 'firebase/firestore';
import { deleteCollection } from '../../../../../useful/UsefulForProject';
import { createdByConstructor } from '../../../utils';

export default function ItemListAddClient({
	clientId,
	projectId,
	projectName,
	projectTag,
	actors,
	research,
	isActive,
	setResearchNumber,
	mandataireUid,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState();
	const [surname, setSurname] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [imgUrl, setImgUrl] = useState();
	const [subtype, setSubtype] = useState();
	const [oldResearch, setOldResearch] = useState(false);
	const [clientData, setClientData] = useState();
	const [password, setPassword] = useState();
	const [conversations, setConversations] = useState([]);

	useEffect(() => {
		firestore
			.doc(`accounts/${clientId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setPassword(documentSnapshot.data().firstPassword);
				}
			});
		firestore
			.doc(`clients/${clientId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setName(documentSnapshot.data().name);
					setSurname(documentSnapshot.data().surname);
					setEmail(documentSnapshot.data().email);
					setPhone(documentSnapshot.data().phoneNumber);
					setImgUrl(documentSnapshot.data().imgUrl);
					setSubtype(documentSnapshot.data().subtype);
					setClientData(documentSnapshot.data());
				}
			});
		firestore
			.collection(`clients/${clientId}/messaging`)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const conv = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							conv.push(documentSnapshot.id);
						}
					});
					setConversations(conv);
				}
			});
	}, [clientId, projectId]);

	async function ajouter(e) {
		e.preventDefault();

		setIsLoading(true);

		const call = httpsCallable(functions, 'addUserToProject');
		call({
			createdBy: createdByConstructor(clientId, 'client', null),
			projectId: projectId,
			parent: {},
			data: clientData,
			senderName: surname + ' ' + name,
			projectName: projectName,
			projectImgUrl: '',
		}).then((r) => {});

		setIsLoading(false);
	}

	async function deleteMessaging(pathWithCondition, pathWithoutCondition) {
		pathWithCondition.get().then((querySnapshot) => {
			if (querySnapshot) {
				querySnapshot.forEach(async (documentSnapshot) => {
					const projectIds = documentSnapshot.data().projectIds;
					if (projectIds && projectIds.length > 1 && projectIds.includes(projectId)) {
						await firestore
							.doc(`clients/${clientId}/messaging/${documentSnapshot.id}`)
							.update({ projectIds: arrayRemove(projectId) });
						const typeOther = (await firestore.doc(`accounts/${documentSnapshot.id}`).get()).data().type;
						if (typeOther === 'collaborators') {
							const agencyOther = (await firestore.doc(`accounts/${documentSnapshot.id}`).get()).data()
								.agency;
							await firestore
								.doc(
									`agencies/${agencyOther}/collaborators/${documentSnapshot.id}/messaging/${clientId}`
								)
								.update({ projectIds: arrayRemove(projectId) });
						} else {
							await firestore
								.doc(`${typeOther}/${documentSnapshot.id}/messaging/${clientId}`)
								.update({ projectIds: arrayRemove(projectId) });
						}
					} else {
						await deleteCollection(
							pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
							pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
							true,
							'contents'
						);
						await deleteCollection(
							pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
							pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
							true,
							'contents'
						);
						await deleteCollection(
							pathWithoutCondition.doc(documentSnapshot.id).collection('messages'),
							pathWithoutCondition.doc(documentSnapshot.id).collection('messages')
						);
						await pathWithoutCondition.doc(documentSnapshot.id).delete();
					}
				});
			}
		});
	}

	async function deleteClient(clientId) {
		await firestore.doc(`clients/${clientId}/projects/${projectId}`).delete();

		const path = firestore.doc(`clients/${clientId}`);

		await firestore.doc(`projects/${projectId}/accounts/${clientId}`).delete();
		await deleteMessaging(path.collection('messaging').where('group', '==', false), path.collection('messaging'));
		path.collection('messaging')
			.where('group', '==', true)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							firestore
								.doc(`projects/${projectId}/messaging/${documentSnapshot.id}/participants/${clientId}`)
								.delete()
								.then(() => {
									path.collection('messaging')
										.doc(documentSnapshot.id)
										.delete()
										.then(() => {
											firestore
												.collection(
													`projects/${projectId}/messaging/${documentSnapshot.id}/participants`
												)
												.get()
												.then(async (querySnapshot) => {
													if (querySnapshot && querySnapshot.empty) {
														await deleteMessaging(
															firestore
																.collection(`projects/${projectId}/messaging`)
																.where('id', '==', documentSnapshot.id),
															firestore.collection(`projects/${projectId}/messaging`)
														);
													} else if (!querySnapshot) {
														await deleteMessaging(
															firestore
																.collection(`projects/${projectId}/messaging`)
																.where('id', '==', documentSnapshot.id),
															firestore.collection(`projects/${projectId}/messaging`)
														);
													}
												});
										});
								});
						}
					});
				}
			});
		path.collection('calendar')
			.where('projectId', '==', projectId)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						firestore
							.doc(`projects/${projectId}/calendar/${documentSnapshot.id}/participants/${clientId}`)
							.delete()
							.then(() => {
								firestore
									.collection(`projects/${projectId}/calendar/${documentSnapshot.id}/participants`)
									.get()
									.then(async (querySnapshot) => {
										if (querySnapshot && querySnapshot.empty) {
											await deleteCollection(
												firestore
													.collection(`projects/${projectId}/calendar`)
													.where('id', '==', documentSnapshot.id),
												firestore.collection(`projects/${projectId}/calendar`),
												true,
												'fileUrl',
												true,
												'fileUrlFinish'
											);
										} else if (!querySnapshot) {
											await deleteCollection(
												firestore
													.collection(`projects/${projectId}/calendar`)
													.where('id', '==', documentSnapshot.id),
												firestore.collection(`projects/${projectId}/calendar`),
												true,
												'fileUrl',
												true,
												'fileUrlFinish'
											);
										}
									});
							});
					});
				}
			});
	}

	async function deleteAccount(e) {
		e.preventDefault();

		if (window.confirm(`${t('contributors.sure_to_delete')} ${name} ${t('from_this_project.contributors')}`)) {
			setIsLoading(true);
			await deleteClient(clientId, agency);
			setIsLoading(false);
		}
	}

	return (
		<>
			{transformString(surname + name + email).includes(transformString(research)) && (
				<div className={'itemListUser'}>
					<div className={'leftPartItemUser'}>
						<div className={'profilePictureBleu'}>
							<p
								style={{
									letterSpacing: 1,
									fontSize: 15,
									margin: 0,
								}}>
								{surname && surname?.charAt(0)?.toUpperCase()}
								{name && name?.charAt(0)?.toUpperCase()}
							</p>
						</div>

						<div className={'nameEmail'}>
							<p className={'name'}>{name && adjustText(surname + ' ' + name, 26)}</p>
							<p className={'email'}>{email && adjustText(email, 32)}</p>
						</div>
					</div>

					<div className={'rightPartItemUser'}>
						<div className={'phoneNumber'}>{phone && phone}</div>

						{actors && actors.includes(clientId) ? (
							<div onClick={deleteAccount} className={'retirer hover'}>
								{t('translation.extirp')}
							</div>
						) : (
							<div onClick={ajouter} className={'ajouter hover'}>
								{t('translation.add')}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
