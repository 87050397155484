import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import searchIcon from '../../../../../assets/searchIcon.png';
import gerySearchIcon from '../../../../../assets/gerySearchIcon.png';
import { transformString } from '../../../../../useful/UsefulFunctions';
import ItemProjectToChoose from './ItemProjectToChoose';
import AgencyDataContext from '../../../../../contexts/agencyDataContext';
import { useProjectsContext } from '../../../../../contexts/projectsContext';

export default function ModalChooseProject({ isOpen, setIsOpen, parentId, handleChoose, isLoading }) {
	const { t } = useTranslation();

	const agencyData = useContext(AgencyDataContext);
	const [projects] = useProjectsContext();

	const [research, setResearch] = useState();
	const [chosenId, setChosenId] = useState(parentId);

	const filteredProjects = projects?.filter(
		(project) =>
			transformString(project.tag).includes(transformString(research)) ||
			transformString(project.name).includes(transformString(research))
	);

	useEffect(() => {
		if (!isOpen) {
			setResearch('');
		}
	}, [isOpen]);

	useEffect(() => {
		if (parentId) {
			setChosenId(parentId);
		}
	}, [parentId]);

	return (
		<Modal
			isOpen={isOpen}
			className={'modalAddProjetCollaborateur'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setIsOpen(false);
				setResearch('');
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setIsOpen(false);
					setResearch('');
				}}
			/>
			<div>
				<p className={'titleModal'}>{t('translation.likThisGroup')}</p>
				<p className={'subtitle'}>{t('translation.subtitleProjectChoice')}</p>

				<div style={{ display: 'flex', marginLeft: -5, marginTop: 30, marginBottom: 0 }}>
					<input
						style={{ width: 525 }}
						type={'text'}
						placeholder={t('agency.search_for_a_project')}
						className={'rechercheParticipants'}
						value={research}
						onChange={(e) => setResearch(e.target.value)}
					/>
					<img src={searchIcon} alt="" className={'searchIcon'} style={{ marginLeft: -551 }} />
				</div>

				<div className={'listProjectChoice'}>
					{agencyData && (
						<>
							<p className={'indicator'}>{t('translation.myCompany')}</p>
							<ItemProjectToChoose
								name={agencyData.name}
								isAgency={true}
								diminutif={agencyData.diminutifAgency}
								isChoosen={chosenId === agencyData.id}
								onClick={() => setChosenId(agencyData.id)}
							/>
						</>
					)}

					<p className={'indicator'}>{t('translation.myProjects')}</p>
					{projects.map((project) => (
						<ItemProjectToChoose
							key={project.id}
							name={project.name}
							imgUrl={project.imgUrl}
							isChoosen={chosenId === project.id}
							onClick={() => setChosenId(project.id)}
						/>
					))}

					{filteredProjects?.length === 0 && (
						<div
							style={{
								width: 590,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 40,
							}}>
							<img
								src={gerySearchIcon}
								alt=""
								style={{
									width: 30,
								}}
							/>
							<p
								style={{
									fontSize: 13,
									color: '#8c8c8c',
									fontWeight: 500,
									letterSpacing: 0.6,
									marginBottom: 8,
								}}>
								{t('common.no_result_found')}
							</p>
							<p
								style={{
									margin: 0,
									fontSize: 12,
									color: '#8c8c8c',
									fontWeight: 400,
									letterSpacing: 0.6,
								}}>
								{t('research_project.no_result_2')}
							</p>
						</div>
					)}
					<div style={{ height: 20 }} />
				</div>
			</div>

			<button onClick={() => handleChoose(chosenId)} disabled={isLoading}>
				Valider
			</button>
		</Modal>
	);
}
