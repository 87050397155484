import React, { useContext, useEffect } from 'react';
import CompanyOrPersonItem from '../../../Little Components/Company Or Person Item/CompanyOrPersonItem';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { removeDuplicates } from '../../../../../../../useful/UsefulFunctions';
import TypeContext from 'src/contexts/typeContext';
import { useClientsContext } from 'src/contexts/clientsContext';

export default function TicketOpening({ ticket }) {
	const { t } = useTranslation();

	const [clients] = useClientsContext();

	return (
		<div className={'followUpStep'}>
			<div className={'bar'} style={{ backgroundColor: '#59c425' }} />
			<div>
				<p className={'titleStep'}>{t('translation.ticketOpening')}</p>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 5, marginTop: 15 }}>
					<CompanyOrPersonItem
						company={true}
						agencyId={
							ticket?.properties?.owner?.userType === 'client' ||
							clients?.map((item) => item.id)?.includes(ticket?.properties?.owner?.agencyId)
								? 'clients'
								: ticket?.properties?.owner?.agencyId
						}
						hasViewed={true}
						emoji={true}
					/>

					{removeDuplicates(
						[
							...(ticket?.properties?.companies || []).map((company) => ({ id: company })),
							...(ticket?.properties?.reviewers || []).map((company) => ({ id: company })),
						],
						'id'
					).map(
						(company) =>
							company.id !== ticket?.properties?.owner?.agencyId &&
							((!clients?.map((client) => client.id)?.includes(company.id) && company.id !== 'clients') ||
								(!clients?.map((client) => client.id)?.includes(ticket?.properties?.owner?.agencyId) &&
									ticket?.properties?.owner?.userType !== 'client')) && (
								<CompanyOrPersonItem
									key={company.id}
									company={true}
									agencyId={company.id}
									hasViewed={
										ticket?.companiesViewed?.includes(company.id) ||
										(ticket?.companiesViewed?.includes('clients') &&
											(company.id === 'clients' ||
												clients?.map((client) => client.id)?.includes(company.id)))
									}
									emoji={true}
								/>
							)
					)}
				</div>
			</div>
		</div>
	);
}

TicketOpening.propTypes = {
	ticket: PropTypes.object.isRequired,
	status: PropTypes.string.isRequired,
};
