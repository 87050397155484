import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../../../firebase/config';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { joinPaths } from '../../../../../firebase/utils';
import { mergeAndOrder } from '../../../../../useful/utils';
import ModalPicture from '../../../../../components/ModalPicture';
import { useTranslation } from 'react-i18next';
import pictureIcon from '../../../../../assets/pictureIcon.png';

export default function ContainerPicturesSent({ conversationId, isGroup }) {
	const { t } = useTranslation();
	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [pictures, setPictures] = useState([]);
	const [lastDocumentSnapshot, setLastDocumentSnapshot] = useState(null);

	const [selectedPicture, setSelectedPicture] = useState(null);
	const [modalPreview, setModalPreview] = useState(false);

	const [seeMoreCount, setSeeMoreCount] = useState(0);

	useEffect(() => {
		if (uid && type && conversationId && (agencyId || type === 'clients')) {
			setSeeMoreCount(0);
			setPictures([]);
			const subscriber = firestore
				.collection(
					isGroup
						? joinPaths('messaging', conversationId, 'messages')
						: type === 'collaborators'
						  ? joinPaths(
									'agencies',
									agencyId,
									'collaborators',
									uid,
									'messaging',
									conversationId,
									'messages'
						    )
						  : joinPaths('clients', uid, 'messaging', conversationId, 'messages')
				)
				.where('type', 'in', ['picture', 'video'])
				.orderBy('date', 'desc')
				.limit(7)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const pictures = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								pictures.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
						setLastDocumentSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
						setPictures((prevState) => mergeAndOrder((a, b) => a.date - b.date, 'id', prevState, pictures));
					}
				});
			return () => {
				subscriber();
			};
		}
	}, [uid, type, isGroup, conversationId]);

	function loadMorePictures() {
		if (lastDocumentSnapshot) {
			setSeeMoreCount((prevState) => prevState + 1);
			firestore
				.collection(
					isGroup
						? joinPaths('messaging', conversationId, 'messages')
						: type === 'collaborators'
						  ? joinPaths(
									'agencies',
									agencyId,
									'collaborators',
									uid,
									'messaging',
									conversationId,
									'messages'
						    )
						  : joinPaths('clients', uid, 'messaging', conversationId, 'messages')
				)
				.where('type', 'in', ['picture', 'video'])
				.orderBy('date', 'desc')
				.startAfter(lastDocumentSnapshot)
				.limit(30)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const pictures = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								pictures.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
						setLastDocumentSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
						setPictures((prevState) => mergeAndOrder((a, b) => a.date - b.date, 'id', prevState, pictures));
					}
				});
		}
	}

	return (
		<>
			<div className={'containerPicturesSent'}>
				<div className={'containerHeader'}>
					<p>{t('translation.picturesAndVids')}</p>
					<p className={'all hover'} onClick={loadMorePictures}>
						{t('translation.allWord')}
					</p>
				</div>
				{pictures.length > 0 ? (
					<div>
						<div className={'listPictures'}>
							{pictures.slice(0, 6 + seeMoreCount * 30).map((picture) =>
								picture.type?.includes('video') ? (
									<video
										src={picture.contents}
										className={'hover'}
										key={picture.id}
										style={{ width: '33%', aspectRatio: 1 }}
										onClick={() => {
											setSelectedPicture(picture);
											setModalPreview(true);
										}}
									/>
								) : (
									<img
										className={'hover'}
										key={picture.id}
										src={picture.contents}
										alt={''}
										onClick={() => {
											setSelectedPicture(picture);
											setModalPreview(true);
										}}
									/>
								)
							)}
						</div>
						{pictures.length > 6 + seeMoreCount * 30 && (
							<div onClick={loadMorePictures} className={'seeMore'}>
								<p>{t('common.see_more')}</p>
							</div>
						)}
					</div>
				) : (
					<div className={'containerPlaceholderSent'}>
						<img src={pictureIcon} alt="" className={'pdfIcon'} />

						<p>{t('translation.noPicturesShared')}</p>
					</div>
				)}
			</div>

			{selectedPicture && (
				<ModalPicture
					isOpen={modalPreview}
					setIsOpen={setModalPreview}
					uri={selectedPicture.contents}
					type={selectedPicture.type}
					handleNext={() => {
						const index = pictures.findIndex((picture) => picture.id === selectedPicture.id);
						if (index < pictures.length - 1) {
							setSelectedPicture(pictures[index + 1]);
						}
						if (index >= pictures.length - 2) {
							loadMorePictures();
						}
					}}
					handlePrevious={() => {
						const index = pictures.findIndex((picture) => picture.id === selectedPicture.id);
						if (index > 0) {
							setSelectedPicture(pictures[index - 1]);
						}
					}}
					noNext={pictures.findIndex((picture) => picture.id === selectedPicture.id) === pictures.length - 1}
					noPrevious={pictures.findIndex((picture) => picture.id === selectedPicture.id) === 0}
				/>
			)}
		</>
	);
}
