import React, { useContext, useEffect, useState } from 'react';
import '../messaging.scss';
import sendIcon from '../../../assets/sendIcon.png';
import newLinkIcon from '../../../assets/newLinkIcon.png';
import { updateBadge } from '../../Fiche Projet/Notifications/utils';
import { auth, firestore, functions, storage } from '../../../firebase/config';
import { generateUniqueFirestoreId, joinPaths } from '../../../firebase/utils';
import { httpsCallable } from 'firebase/functions';
import AuthDataContext from '../../../contexts/authDataContext';
import { useTranslation } from 'react-i18next';
import { useProjectsContext } from '../../../contexts/projectsContext';
import TypeContext from '../../../contexts/typeContext';
import crossIcon from '../../../assets/blackCrossIcon.svg';
import Resizer from 'react-image-file-resizer';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import ResponseMessage from './Message/Response Message/ResponseMessage';
import mixpanel from 'mixpanel-browser';

export default function InputConv({
	inputRef,
	conversation,
	responseMessage,
	setResponseMessage,
	setMessages,
	contacts,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const agencyData = useContext(AgencyDataContext);
	const [projects] = useProjectsContext();

	const projectName = projects?.find((p) => p.id === conversation.parentId)?.name;

	const [inputText, setInputText] = useState('');

	useEffect(() => {
		if (inputRef && inputRef.current) {
			inputRef.current.style.height = '0px';
			const scrollHeight = inputRef.current.scrollHeight;
			inputRef.current.style.height = scrollHeight + 'px';
		}
	}, [inputText, inputRef]);

	const sendNotification = (userId, content, contentType) => {
		firestore
			.doc(`accounts/${userId}`)
			.get()
			.then(async (documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					const tokens = documentSnapshot.data().tokens;

					const call = httpsCallable(functions, 'sendNotificationPush');
					call({
						tokens: tokens ?? [],
						title: conversation.isGroup
							? conversation.parentId === agencyData?.id
								? agencyData?.name
								: projectName
							: (authData?.surname ?? '') +
								(authData?.surname && authData?.name ? ' ' : '') +
								(authData?.name ?? ''),
						body: conversation.isGroup
							? conversation?.name +
								'\n' +
								(contentType === 'picture'
									? `${authData?.surname} ${authData?.name} sent a picture.`
									: contentType === 'video'
										? `${authData?.surname} ${authData?.name} sent a video.`
										: contentType === 'file'
											? `${authData?.surname} ${authData?.name} sent a file.`
											: content)
							: contentType === 'picture'
								? 'Sent a picture.'
								: contentType === 'video'
									? 'Sent a video.'
									: contentType === 'file'
										? 'Sent a file.'
										: content,
						data: {
							page: 'messaging',
							conversation: conversation.isGroup ? conversation.id : uid,
						},
					}).then((r) => {
						updateBadge(userId);
					});
				}
			});
	};

	const sendMessage = async (data) => {
		if (conversation.isGroup) {
			await firestore.doc(joinPaths('messaging', conversation.id, 'messages', data.id)).set({
				...data,
				sender: uid,
				conversation: conversation.id,
			});

			const memberIds = (
				await firestore.collection(joinPaths('messaging', conversation.id, 'participants')).get()
			).docs.map((doc) => doc.id);

			const batch = firestore.batch();
			memberIds.forEach((memberId) => {
				if (memberId !== uid) {
					batch.update(firestore.doc(joinPaths('users', memberId, 'messaging', conversation.id)), {
						lastMessageAt: new Date().toISOString(),
						isUnread: true,
					});
				} else {
					batch.update(firestore.doc(joinPaths('users', memberId, 'messaging', conversation.id)), {
						lastMessageAt: new Date().toISOString(),
						lastClickAt: new Date(new Date().getTime() + 10).toISOString(),
						isUnread: false,
					});
				}
			});

			await batch.commit();

			memberIds.forEach((memberId) => {
				if (memberId !== uid) {
					sendNotification(memberId, data.contents, data.type);
				}
			});
		} else {
			let message = {
				...data,
				sender: uid,
				conversation: uid,
			};

			const receiverPath =
				conversation.userType === 'clients'
					? joinPaths('clients')
					: joinPaths('agencies', conversation.userAgencyId, 'collaborators');
			await firestore
				.doc(joinPaths(receiverPath, conversation.id, 'messaging', uid, 'messages', message.id))
				.set(message);

			message = {
				...message,
				conversation: conversation.id,
			};

			const senderPath =
				type === 'clients' ? joinPaths('clients') : joinPaths('agencies', agencyData.id, 'collaborators');
			await firestore
				.doc(joinPaths(senderPath, uid, 'messaging', conversation.id, 'messages', message.id))
				.set(message);

			await firestore.doc(joinPaths('users', conversation.id, 'messaging', uid)).update({
				lastMessageAt: new Date().toISOString(),
				isUnread: true,
			});

			await firestore.doc(joinPaths('users', uid, 'messaging', conversation.id)).update({
				lastMessageAt: new Date().toISOString(),
				lastClickAt: new Date(new Date().getTime() + 10).toISOString(),
				isUnread: false,
			});

			sendNotification(conversation.id, data.contents, data.type);
		}
	};

	const sendMessageText = async () => {
		if (inputText.trim().length < 1) return;

		const message = {
			contents: inputText,
			date: new Date().toISOString(),
			sender: uid,
			conversation: null,
			type: 'text',
			responseMessage: responseMessage ? responseMessage : null,
			id: generateUniqueFirestoreId(),
		};

		setMessages((prevState) => [{ ...message, isLocal: true }, ...prevState]);

		setInputText('');
		setResponseMessage(null);

		await sendMessage(message);

		mixpanel.track('Message Sent', {
			'Conversation ID': conversation.id,
			'Conversation Type': conversation.isGroup
				? conversation.parentId === agencyData?.id
					? 'intern group'
					: 'project group'
				: 'private',
			'Conversation Parent ID': conversation.parentId,
			Type: 'text',
		});
	};

	const sendFiles = (e) => {
		e.preventDefault();

		if (e.target && e.target.files && e.target.files.length > 0) {
			for (const file of e.target.files) {
				const reader = new FileReader();
				reader.onload = async () => {
					if (reader.readyState === 2) {
						const message = {
							contents: reader.result,
							date: new Date().toISOString(),
							sender: uid,
							conversation: null,
							type: file.type.includes('video')
								? 'video'
								: file.type.includes('image')
									? 'picture'
									: 'file',
							id: generateUniqueFirestoreId(),
							fileName: file.name,
							fileType: file.type,
							fileSize: file.size,
						};
						setMessages((prevState) => [{ ...message, isLocal: true }, ...prevState]);

						if (file.type === 'picture') {
							Resizer.imageFileResizer(
								file,
								2000,
								2000,
								'jpeg',
								0,
								async (file) => {
									const storageRef = storage.ref(`messaging/${conversation}/pictures`);
									const fileRef = storageRef.child(message.id);
									await fileRef.put(file);
									const imgUrl = await fileRef.getDownloadURL();
									await sendMessage({ ...message, contents: imgUrl });
								},
								'file'
							);
						} else {
							const storageRef = storage.ref(`messaging/${conversation}/pictures`);
							const fileRef = storageRef.child(message.id);
							await fileRef.put(file);
							const imgUrl = await fileRef.getDownloadURL();
							await sendMessage({ ...message, contents: imgUrl });
						}

						mixpanel.track('Message Sent', {
							'Conversation ID': conversation.id,
							'Conversation Type': conversation.isGroup
								? conversation.parentId === agencyData?.id
									? 'intern group'
									: 'project group'
								: 'private',
							'Conversation Parent ID': conversation.parentId,
							Type: file.type.includes('video')
								? 'video'
								: file.type.includes('image')
									? 'image'
									: 'file',
							'File Type': file.type,
						});
					}
				};
				reader.readAsDataURL(file);
			}
		}
	};

	/*const handlePaste = async (e) => {
		e.preventDefault();
		const items = e.clipboardData.items;
		console.log(items);
		for (let i = 0; i < items.length; i++) {
			if (items[i].kind === 'file') {
				const file = items[i].getAsFile();
				await handleImageUpload(file);
				break;
			}
		}
	};

	const handleImageUpload = async (file) => {
		const reader = new FileReader();
		reader.onload = async () => {
			if (reader.readyState === 2) {
				const message = {
					contents: reader.result,
					date: new Date().toISOString(),
					sender: uid,
					conversation: null,
					type: 'picture',
					id: generateUniqueFirestoreId(),
					fileName: file.name,
					fileType: file.type,
					fileSize: file.size,
				};
				setMessages((prevState) => [{ ...message, isLocal: true }, ...prevState]);

				Resizer.imageFileResizer(
					file,
					2000,
					2000,
					'jpeg',
					0,
					async (resizedFile) => {
						const storageRef = storage.ref(`messaging/${conversation.id}/pictures`);
						const fileRef = storageRef.child(message.id);
						await fileRef.put(resizedFile);
						const imgUrl = await fileRef.getDownloadURL();
						await sendMessage({ ...message, contents: imgUrl });
					},
					'file',
				);

				mixpanel.track('Message Sent', {
					'Conversation ID': conversation.id,
					'Conversation Type': conversation.isGroup ? conversation.parentId === agencyData?.id ? 'intern group' : 'project group' : 'private',
					'Conversation Parent ID': conversation.parentId,
					'Type': 'image',
					'File Type': file.type,
				});
			}
		};
		reader.readAsDataURL(file);
	};*/

	return (
		<div className={'containerInputConv'} style={{ opacity: inputText.length > 0 || responseMessage ? 1 : null }}>
			<label htmlFor={`inputFiles`}>
				<img src={newLinkIcon} alt="" className={'linkIcon hover'} />
			</label>
			<input type={'file'} id={`inputFiles`} className={'input-hidden'} multiple={true} onChange={sendFiles} />

			<div>
				{responseMessage && (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ResponseMessage message={responseMessage} contacts={contacts} />
						<img src={crossIcon} alt="" className={'cross'} onClick={() => setResponseMessage(null)} />
					</div>
				)}
				<textarea
					ref={inputRef}
					className={'inputConv'}
					rows={1}
					placeholder={t('translation.message')}
					autoCapitalize={'true'}
					value={inputText}
					onChange={(e) => setInputText(e.target.value)}
					//onPaste={handlePaste}
				/>
			</div>

			<img
				src={sendIcon}
				alt=""
				className={'sendIcon hover'}
				style={{
					opacity: inputText.length < 1 ? 0.5 : null,
					transform: inputText.length < 1 ? 'scale(0.8)' : null,
				}}
				onClick={sendMessageText}
			/>
		</div>
	);
}
