import leftBleu from '../../assets/leftBleu.png';
import temlateLogo from '../../assets/temlateLogo.png';
import portfolioAgenceLogo from '../../assets/portfolioAgenceLogo.png';
import React, { useContext, useState } from 'react';
import WidgetAccount from './Comptes Liés/WidgetAccount';
import integrerProjetVisuel from '../../assets/integrerProjetVisuel.png';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AgencyDataContext from '../../contexts/agencyDataContext';
import { useStripeContext } from '../../contexts/stripeContext';
import AgencyContext from '../../contexts/agencyContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';

export default function Widget({
	facturation,
	setModalFacturation,
	essaiGratuit,
	title,
	accounts,
	template,
	portfolio,
	collaborateurWidget,
	agencesPartenairesWidget,
	clientWidget,
	setIsCollaborators,
	setIsClients,
	setIsPartners,
	setModalComptesLies,
	list,
	setModalTemplate,
	setModalPortfolio,
	loading,
	agencyProjects,
	currency,
}) {
	const { t } = useTranslation();
	const agencyData = useContext(AgencyDataContext);
	const agency = useContext(AgencyContext);
	const stripe = useStripeContext();
	const [isLoading, setIsLoading] = useState(false);

	const handleManagePlan = async () => {
		setIsLoading(true);
		const functionRef = httpsCallable(functions, 'createBillingPortalLink');
		await functionRef({
			companyId: agency,
			returnUrl: window.location.origin + '/choose-plan',
			locale: 'auto',
		})
			.then((res) => {
				setIsLoading(false);
				console.log(res);
				window.location.assign(res.data.url);
			})
			.catch((error) => {
				alert(error.message);
				setIsLoading(false);
			});
	};

	return (
		<div
			style={{
				height: accounts ? null : essaiGratuit ? 235 : facturation ? 235 : 230,
			}}
			onClick={() => {
				if (collaborateurWidget) {
					setIsCollaborators(true);
					setIsClients(false);
					setIsPartners(false);
					setModalComptesLies(true);
				} else if (clientWidget) {
					setIsCollaborators(false);
					setIsClients(true);
					setIsPartners(false);
					setModalComptesLies(true);
				} else if (agencesPartenairesWidget) {
					setIsCollaborators(false);
					setIsClients(false);
					setIsPartners(true);
					setModalComptesLies(true);
				} else if (template) {
					setModalTemplate(true);
				} else if (portfolio) {
					setModalPortfolio(true);
				}
			}}
			className={'carteLiens'}>
			<div
				style={{
					display: 'flex',
					marginBottom: template ? -25 : 0,
				}}>
				<div style={{ width: 300 }}>
					<p className={'title'}>{title}</p>
				</div>

				{essaiGratuit && (
					<div
						className={'image-clignote'}
						style={{
							width: 13,
							height: 13,
							backgroundColor: '#3CA504',
							borderRadius: 20,
							marginTop: 20,
							marginLeft: 70,
						}}
					/>
				)}

				{!accounts && !essaiGratuit && !facturation && (
					<div
						className={'hover'}
						style={{
							display: 'flex',
							marginTop: 7,
						}}>
						<p
							style={{
								fontWeight: '700',
								color: '#4B5FB0',
								fontSize: 14,
							}}>
							{t('common.access')}
						</p>
						<img
							src={leftBleu}
							alt=""
							style={{
								width: 13,
								marginTop: -20,
								marginLeft: 4,
								objectFit: 'contain',
							}}
						/>
					</div>
				)}
			</div>

			{accounts &&
				(!loading ? (
					list.length > 0 ? (
						<>
							{list.map((item, index) => (
								<WidgetAccount
									key={index}
									collaboratorData={item}
									uid={item.uid}
									agencyProjects={agencyProjects}
									type={collaborateurWidget ? 'collaborators' : clientWidget ? 'clients' : 'agencies'}
								/>
							))}

							<div className={'buttonVoirPlus'}>{t('common.see_more')}</div>
							<div style={{ height: 15 }} />
						</>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: 35,
							}}>
							<img src={integrerProjetVisuel} alt="" className={'integrerProjetVisuel'} />
							<p
								style={{
									textAlign: 'center',
									marginRight: 35,
									fontWeight: 600,
									opacity: 0.6,
									fontSize: 14,
									lineHeight: 1.3,
									marginBottom: 25,
								}}>
								{collaborateurWidget
									? t('agency_part.agency_dashboard.widgets.placeholder.placeholder_collabs')
									: clientWidget
										? t('agency_part.agency_dashboard.widgets.placeholder.placeholder_clients')
										: agencesPartenairesWidget
											? t(
													'agency_part.agency_dashboard.widgets.placeholder.placeholder_partner_agency'
												)
											: null}
							</p>
							<div className={'buttonVoirPlus'} style={{ marginLeft: -20 }}>
								{collaborateurWidget
									? t('agency_part.agency_dashboard.widgets.placeholder.button_collabs')
									: clientWidget
										? t('agency_part.agency_dashboard.widgets.placeholder.button_clients')
										: agencesPartenairesWidget
											? t(
													'agency_part.agency_dashboard.widgets.placeholder.button_partner_agency'
												)
											: null}
							</div>
							<div style={{ height: 15 }} />
						</div>
					)
				) : (
					<div>
						<WidgetAccount
							placeholder={true}
							type={collaborateurWidget ? 'collaborators' : clientWidget ? 'clients' : 'agencies'}
						/>
						<WidgetAccount
							placeholder={true}
							type={collaborateurWidget ? 'collaborators' : clientWidget ? 'clients' : 'agencies'}
						/>
						<WidgetAccount
							placeholder={true}
							type={collaborateurWidget ? 'collaborators' : clientWidget ? 'clients' : 'agencies'}
						/>
						<WidgetAccount
							placeholder={true}
							type={collaborateurWidget ? 'collaborators' : clientWidget ? 'clients' : 'agencies'}
						/>
						<div className={'buttonVoirPlus'} style={{ backgroundColor: 'rgba(248,248,248,0.68)' }}>
							<div style={{ width: 100, height: 10, backgroundColor: '#eeeeee', borderRadius: 20 }} />
						</div>
						<div style={{ height: 15 }} />
					</div>
				))}

			{template && (
				<img
					src={temlateLogo}
					alt=""
					style={{
						width: 400,
					}}
				/>
			)}

			{portfolio && (
				<img
					src={portfolioAgenceLogo}
					alt=""
					style={{
						width: 360,
						marginLeft: 20,
					}}
				/>
			)}

			{facturation && (
				<div
					style={{
						marginLeft: 20,
					}}>
					<p
						style={{
							marginTop: -20,
							marginBottom: 10,
							fontWeight: 600,
							fontSize: 40,
							color: '#4E66E4',
						}}>
						OPUS Services
					</p>

					<p
						style={{
							fontSize: 15,
							marginRight: 10,
							marginBottom: 10,
						}}>
						{!facturation
							? t('agency_part.agency_dashboard.widgets.facturation.info_choice_plan_2')
							: t('translation.infoManagePan')}
					</p>

					<div style={{ display: 'flex' }}>
						<NavLink exact to="/choose-plan">
							<div style={{ display: 'flex' }}>
								<div className={'buttonManageSubscription'}>
									{facturation
										? t('translation.managePlan')
										: t('agency_part.agency_dashboard.widgets.facturation.button_select')}
								</div>
							</div>
						</NavLink>

						<div className={'buttonClaimBills'} onClick={handleManagePlan}>
							{' '}
							<span style={{ fontSize: 25, marginRight: 10 }}>🧾</span>{' '}
							{isLoading ? 'Loading ...' : t('translation.myBills')}
						</div>
					</div>

					<div style={{ height: 17 }} />
				</div>
			)}

			{essaiGratuit && (
				<div
					style={{
						marginLeft: 20,
					}}>
					<p
						style={{
							marginTop: -20,
							marginBottom: 10,
							fontWeight: 600,
							fontSize: 40,
						}}>
						{currency === 'eur' ? (
							<>
								0<span style={{ fontSize: 20 }}>€</span>{' '}
							</>
						) : currency === 'aed' ? (
							<>
								<span style={{ fontSize: 20 }}>AED</span> 0{' '}
							</>
						) : (
							<>
								<span style={{ fontSize: 20 }}>$</span>0{' '}
							</>
						)}
						<span style={{ fontSize: 14, fontWeight: '600', opacity: 0.8 }}>{t('billing.per_month')}</span>{' '}
					</p>

					<p
						style={{
							fontSize: 15,
							marginRight: 10,
							marginBottom: 10,
						}}>
						{t('agency_part.agency_dashboard.widgets.facturation.info_choice_plan_2')}
					</p>

					<NavLink exact to="/choose-plan">
						<div style={{ display: 'flex' }}>
							<div
								className={'buttonVoirPlus'}
								style={{
									marginLeft: -5,
									backgroundColor: '#3CA504',
									color: '#fff',
								}}>
								{t('agency_part.agency_dashboard.widgets.facturation.button_select')}
							</div>
						</div>
					</NavLink>

					<div style={{ height: 17 }} />
				</div>
			)}
		</div>
	);
}
