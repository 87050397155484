import React, { useContext, useEffect, useState } from 'react';
import TypeContext from '../../contexts/typeContext';
import AgencyContext from '../../contexts/agencyContext';
import Menu from '../../components/Main/Menu';
import ModalResiliation from '../Agence/Facturation/Stripe/ModalResiliation';
import ModalPageEnCoursConstruction from '../../components/ModalPageEnCoursConstruction';
import ModalBienvenue from '../Intégration/ModalBienvenue';
import ProjectList from './ProjectList';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import ModalJoinProject from './Modaux/ModalJoinProject';
import ModalNeedToPay from './Modaux/ModalNeedToPay';
import { useStripeContext } from '../../contexts/stripeContext';
import AgencyDataContext from '../../contexts/agencyDataContext';
import AuthDataContext from '../../contexts/authDataContext';
import ModalWaitForApproval from './Modaux/ModalWaitForApproval';
import ModalAccessDenied from './Modaux/ModalAccessDenied';
import { useProjectsContext } from '../../contexts/projectsContext';
import { useOnlyAgencyProjectsContext } from '../../contexts/onlyAgencyProjectsContext';
import { COMPANIES_COLLECTION } from '../../firebase/paths';

export default function PageProjets(props) {
	const location = useLocation();

	const [modalBienvenue, setModalBienvenue] = useState(false);
	useEffect(() => {
		if (location.state) {
			setModalBienvenue(location.state.firstTime);
		}
	}, []);

	const type = useContext(TypeContext);
	const agency = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);
	const [projects] = useProjectsContext();
	const [allProjects] = useOnlyAgencyProjectsContext();

	const [uid, setUid] = useState();
	const [userSession, setUserSession] = useState();
	const navigate = useNavigate();
	const stripe = useStripeContext();

	useEffect(() => {
		const listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUid(user.uid);
			} else navigate('/');
		});
		return () => {
			listener();
		};
	}, [userSession]);

	// -------------------------------------------------------------------------------------

	const [logoUrl, setLogoUrl] = useState();
	const [canCreateProjet, setCanCreateProjet] = useState(false);
	const [modalAddProject, setModalAddProject] = useState(false);

	// Stripe
	const [baniereExpirationCarte, setBaniereExpirationCarte] = useState(false);
	const [baniereEchecPaiement, setBaniereEchecPaiement] = useState(false);
	const [canceledDate, setCanceledDate] = useState();
	const [deletionDate, setDeletionDate] = useState();

	// Modaux
	const [modalResiliation, setModalResiliation] = useState(false);
	const [modalPageEnCoursConstruction, setModalPageEnCoursConstruction] = useState(false);
	const [modalJoinProject, setModalJoinProject] = useState(false);
	const [modalWaitForApproval, setModalWaitForApproval] = useState(false);
	const [modalAccessDenied, setModalAccessDenied] = useState(false);
	const [modalNeedToPay, setModalNeedToPay] = useState(false);

	useEffect(() => {
		if (type === 'collaborators') {
			const subscriber1 = firestore.doc(`agencies/${agency}`).onSnapshot((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					const agencyData = { ...documentSnapshot.data(), id: documentSnapshot.id };
					setLogoUrl(agencyData.logoUrl);
				}
			});
			const subscriber2 = firestore
				.doc(`agencies/${agency}/collaborators/${uid}`)
				.onSnapshot((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setCanCreateProjet(
							documentSnapshot.data().subtype === 'administrator' ||
							documentSnapshot.data().subtype === 'collaborator' ||
							documentSnapshot.data().canCreateProject
						);
					}
				});
			return () => {
				subscriber1();
				subscriber2();
			};
		}
	}, [agency, type, uid]);

	function compareLastClick(a, b) {
		if (a.lastClick > b.lastClick) {
			return -1;
		} else if (a.lastClick < b.lastClick) {
			return 1;
		}
		return 0;
	}

	// -------------------------------------------------------------------------------------

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const billing = searchParams.get('billing');

		if (billing === 'true' && agency) {
			const random = searchParams.get('random');
			const priceId = searchParams.get('priceId');

			if (random) {
				firestore
					.collection(COMPANIES_COLLECTION)
					.doc(agency)
					.collection('checkout_sessions')
					.where('random', '==', random)
					.get()
					.then(async (querySnapshot) => {
						if (querySnapshot && !querySnapshot.empty) {
							await firestore.collection(COMPANIES_COLLECTION).doc(agency).update({
								isFreeTrial: false,
								priceId: priceId,
							});
						}
					});
			}
		}
	}, [location.search, agency]);

	// -------------------------------------------------------------------------------------

	return (
		<div className="pageProjets">
			<Menu
				grasProjet={true}
				lastProject={projects && projects[0] && [...projects].sort(compareLastClick)[0].id}
			/>

			<div className="corpsProjets">
				<ProjectList
					auth={uid}
					baniereExpirationCarte={baniereExpirationCarte}
					setBaniereExpirationCarte={setBaniereExpirationCarte}
					baniereEchecPaiement={baniereEchecPaiement}
					canCreateProjet={canCreateProjet}
					logoUrl={logoUrl}
					modalAddProject={modalAddProject}
					setModalAddProject={setModalAddProject}
					setModalWaitForApproval={setModalWaitForApproval}
					setModalAccessDenied={setModalAccessDenied}
				/>
			</div>

			<ModalPageEnCoursConstruction
				modalPageEnCoursConstruction={modalPageEnCoursConstruction}
				setModalPageEnCoursConstruction={setModalPageEnCoursConstruction}
			/>

			<ModalResiliation
				modal={modalResiliation}
				setModal={setModalResiliation}
				canceledDate={canceledDate}
				deletionDate={deletionDate}
			/>

			<ModalNeedToPay modalNeedToPay={modalNeedToPay} />

			<ModalJoinProject
				modalJoinProject={modalJoinProject}
				setModalJoinProject={setModalJoinProject}
				setModalWaitForApproval={setModalWaitForApproval}
			/>
			<ModalWaitForApproval
				setModalWaitForApproval={setModalWaitForApproval}
				modalWaitForApproval={modalWaitForApproval}
			/>
			<ModalAccessDenied setModalAccessDenied={setModalAccessDenied} modalAccessDenied={modalAccessDenied} />
			<ModalBienvenue modalBienvenue={modalBienvenue} setModalBienvenue={setModalBienvenue} />
		</div>
	);
}
