import React, { useContext, useState } from 'react';
import crossIcon from '../../../../../../assets/crossIcon.png';
import history from '../../../../../../assets/history.svg';
import plusSquare from '../../../../../../assets/plusSquare.svg';
import '../_documentInfos.scss';
import { chooseIconFile } from '../../../utils';
import ModalNameVersion from '../modaux/Modal Name Version/ModalNameVersion';
import { generateUniqueFirestoreId } from 'src/firebase/utils';
import { Paths } from 'src/firebase/paths';
import { auth, firestore } from 'src/firebase/config';
import { joinPaths } from 'src/firebase/utils';
import { normalizeString } from 'src/useful/UsefulFunctions';
import { createdByConstructor } from 'src/pages/Fiche Projet/utils';
import { useProjectContext } from 'src/contexts/projectContext';
import TypeContext from 'src/contexts/typeContext';
import AgencyContext from 'src/contexts/agencyContext';
import { arrayUnion } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

export default function SeeVersions({
	isVersionsOpen,
	setIsVersionOpen,
	setModalAddVersion,
	file,
	selectedVersionId,
	setSelectedVersionId,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();

	const [modalRename, setModalRename] = useState(false);

	const createFirstVersion = async (text, selectedVersion) => {
		if (project && file) {
			await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.FILES, file.id)).update({
				name: text,
				lastVersionIndex: selectedVersion,
				normalizedName: normalizeString(text),
			});

			const newVersion = {
				clientPermission: file.clientPermission || false,
				clientPermissionReadOnly: file.clientPermissionReadOnly || false,
				createdAt: file.createdAt || new Date().toISOString(),
				createdBy: file.createdBy || createdByConstructor(uid, userType, agencyId),
				deletedAt: null,
				id: generateUniqueFirestoreId(),
				isFavorite: file.isFavorite || false,
				isFolder: false,
				isVersion: true, // Added field specific to a version
				modifiedAt: file.modifiedAt || new Date().toISOString(),
				name: text,
				normalizedName: normalizeString(text),
				path: file.path || '/',
				parentId: file.id, // Added field specific to a version
				permissions: file.permissions || [agencyId],
				permissionsReadOnly: file.permissionsReadOnly || [],
				previousPath: null,
				projectId: project.id,
				seenBy: file.seenBy || [],
				size: file.size || 0,
				type: file.type || '',
				url: file.url || '',
				versionIndex: selectedVersion, // Added field specific to a version
			};

			await firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.FILES, newVersion.id)).set(newVersion);
		}
	};

	return (
		<div
			className={'seeVersions'}
			style={{
				width: isVersionsOpen ? 322 : 322,
				height: isVersionsOpen
					? (file?.createdBy?.agencyId === agencyId || file?.createdBy?.userId === uid ? 116 : 60) +
						file?.versions?.length * 45
					: 40,
				zIndex: 1000,
				cursor: 'pointer',
				boxShadow: isVersionsOpen
					? '0px 0px 20px 0px rgba(51, 51, 51, 0.11)'
					: '0px 0px 20px 0px rgba(51, 51, 51, 0)',
			}}>
			<div
				style={{ display: 'flex', width: '100%' }}
				onClick={() => {
					if (file?.versions?.length > 0) {
						setIsVersionOpen(!isVersionsOpen);
					} else {
						setModalRename(true);
					}
				}}>
				<div className={'headerVersionViewer'}>
					<div style={{ color: file?.versions?.length > 0 ? '#3CA504' : '#8f8f8f' }}>
						{isVersionsOpen
							? t('translation.allVersionsOfTheDocument')
							: file?.versions?.length > 0
								? `${t('translation.version')} ${file?.versions?.find((it) => it.id === selectedVersionId)?.versionIndex}`
								: t('translation.specifyTheIndexOfThisDocumentByClickingHere')}
					</div>

					{file?.versions?.length > 0 && (
						<div style={{ display: 'flex', alignItems: 'center', opacity: 0.6, color: '#000' }}>
							{isVersionsOpen ? t('translation.close') : t('translation.history')}
							<img src={isVersionsOpen ? crossIcon : history} alt="" className={'historyGreen'} />
						</div>
					)}
				</div>
			</div>

			<div className={'barListVersions'} />

			<div className={'docList'}>
				{file?.versions?.map((version, index) => (
					<ItemVersionDoc
						key={index}
						name={version?.name}
						type={version?.type}
						versionIndex={version?.versionIndex}
						onClick={async () => {
							setSelectedVersionId(version.id);
							setIsVersionOpen(false);

							if (!version.seenBy?.map((it) => it.userId).includes(uid)) {
								await firestore
									.doc(joinPaths(Paths.PROJECTS, project.id, Paths.FILES, version.id))
									.update({
										seenBy: arrayUnion({
											userId: uid,
											seenAt: new Date().toISOString(),
										}),
									});
							}
						}}
					/>
				))}
			</div>

			{(file?.createdBy?.agencyId === agencyId || file?.createdBy?.userId === uid) && (
				<div className={'buttonPlusSquare'} onClick={() => setModalAddVersion(true)}>
					<img src={plusSquare} alt="" className={'plusSquare'} />
					{t('translation.addNewVersion')}
				</div>
			)}

			<ModalNameVersion
				isOpen={modalRename}
				onClose={() => setModalRename(false)}
				onSubmit={createFirstVersion}
				defaultName={file?.name}
				defaultVersion={'0'}
			/>
		</div>
	);
}

function ItemVersionDoc({ name, type, versionIndex, onClick }) {
	return (
		<div className={'itemVersionDoc'} onClick={onClick}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={chooseIconFile(type)} alt="" className={'pdfIcon'} />
				<p className={'docName'}>{name}</p>
			</div>

			<div className={'version'}>{versionIndex}</div>
		</div>
	);
}
