import React, { useEffect, useState } from 'react';
import '../_contributorAccountsContainer.scss';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import circlePlus from '../../../../../assets/circlePlus.svg';
import searchIcon from '../../../../../assets/searchIcon.png';
import ItemListClient from '../List Item/ItemListClient';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../../firebase/config';
import gerySearchIcon from '../../../../../assets/gerySearchIcon.png';

export default function ModalClientsAccount({
	modalClientsAccounts,
	setModalClientsAccounts,
	setModalNewClient,
	accounts,
	uid,
	vous,
	client,
	projectId,
	isMandataire,
}) {
	const { t } = useTranslation();

	const [modalPasAccesFonct, setModalPasAccesFonct] = useState(false);

	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState(0);

	return (
		<Modal
			isOpen={modalClientsAccounts}
			className={'modalProjectAccounts modalClientsAccounts'}
			overlayClassName="overlayModalProjectAccounts"
			onRequestClose={() => {
				setModalClientsAccounts(false);
				setResearchNumber(0);
				setResearch('');
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt=""
				className={'crossIcon'}
				onClick={() => {
					setModalClientsAccounts(false);
					setResearchNumber(0);
					setResearch('');
				}}
			/>

			<p className={'titleModal'}>{t('translation.clientsAccounts')}</p>

			<div className={'containerSearchButton'}>
				<div className={'searchContainer'}>
					<img src={searchIcon} alt="" className={'searchIconAccounts'} />
					<input
						onChange={(e) => setResearch(e.target.value)}
						value={research}
						type="email"
						className={'searchInput'}
						placeholder={t('translation.researchNameMail')}
					/>
					<img
						src={crossIcon}
						alt=""
						className={'crossIconSearch hover'}
						style={{ opacity: research.length > 1 ? 1 : 0 }}
						onClick={() => setResearch('')}
					/>
				</div>

				{isMandataire && (
					<div className={'buttonNew hover'} onClick={() => setModalNewClient(true)}>
						<img src={circlePlus} alt="" className={'circlePlus'} />
						{t('translation.addAClient')}
					</div>
				)}
			</div>

			<div className={'barModalAccounts'} />

			<div className={'scrollableList'} style={{ height: 522, overflowX: 'hidden' }}>
				<div style={{ display: 'flex', width: '100%', paddingLeft: 60 }}>
					<p className={'addWhat'} style={{ marginBottom: 0 }}>
						{t('translation.clientsAddedToTheProject')}
					</p>
				</div>

				{accounts &&
					accounts.map((item) => (
						<ItemListClient
							uid={item.uid}
							type={client ? 'clients' : 'collaborators'}
							agency={uid}
							research={research}
							setResearchNumber={setResearchNumber}
							projectId={projectId}
							isMandataire={isMandataire}
						/>
					))}

				{accounts.length === 0 && (
					<div className={'placeholderMo'}>
						<p>
							{t('translation.noMo')} <br /> <br /> {t('translation.giveTheBestExperience')}
						</p>
					</div>
				)}

				{researchNumber === 0 && research.length > 1 && (
					<div className={'noResult'}>
						<img
							src={gerySearchIcon}
							alt=""
							style={{
								width: 30,
							}}
						/>
						<p className={'noResultFound'}>{t('common.no_result_found')}</p>
						<p className={'noResultFound'}>{t('research_project.no_result_2')}</p>
					</div>
				)}

				<div style={{ display: 'flex' }}>
					<div className={'comble'} />
				</div>
			</div>
		</Modal>
	);
}
