import React, { useContext, useEffect, useState } from 'react';
import AgencyContext from '../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import { auth, firestore } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';
import { joinPaths } from '../../../firebase/utils';
import { getFirstLetters, stringTime } from '../../../useful/UsefulFunctions';
import { isToday } from '../../../useful/utils';
import i18n from 'i18next';
import LoadingDiv from '../../../components/LoadingDiv/LoadingDiv';
import { formatDate } from '../utils';
import { MESSAGING_COLLECTION, NEW_USER_COLLECTION } from '../../../firebase/paths';

export default function ItemConvMessaging({
	data,
	isActive,
	setSelectedConvId,
	setSelectedOrganisationId,
	reduceOpacity,
	isContactList,
}) {
	const { t } = useTranslation();
	const language = i18n.language;

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const isClient = data?.userType === 'clients';
	const isPartner = data?.userType === 'collaborators' && data?.userAgencyId !== agencyId;

	const [lastMessage, setLastMessage] = useState();
	const [agencyName, setAgencyName] = useState('');
	const [diminutif, setDiminutif] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (isContactList && isClient) {
			setIsLoading(false);
		} else if (data && data.userAgencyId) {
			firestore
				.doc(joinPaths('agencies', data.userAgencyId))
				.get()
				.then((doc) => {
					if (doc.exists) {
						setAgencyName(doc.data().name);
						setDiminutif(doc.data().diminutifAgency ?? getFirstLetters(doc.data().name));
						if (isContactList) {
							setIsLoading(false);
						}
					}
				});
		}
		if (!isContactList && data && data.id && uid && type && (agencyId || type === 'clients')) {
			const subscriber = firestore
				.collection(
					type === 'collaborators'
						? joinPaths('agencies', agencyId, 'collaborators', uid, 'messaging', data.id, 'messages')
						: joinPaths('clients', uid, 'messaging', data.id, 'messages')
				)
				.orderBy('date', 'desc')
				.limit(1)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								setLastMessage({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
						setIsLoading(false);
					}
				});
			return () => subscriber();
		}
	}, [uid, type, agencyId, data, isContactList]);

	return (
		data && (
			<div>
				<div
					className={isActive ? 'itemConvActive' : 'itemConv'}
					style={{ opacity: reduceOpacity ? 0.4 : 1 }}
					onClick={async () => {
						setSelectedConvId(data.id);
						setSelectedOrganisationId('');
						await firestore
							.doc(joinPaths(NEW_USER_COLLECTION, uid, MESSAGING_COLLECTION, data.id))
							.update({ isUnread: false, lastClickAt: new Date().toISOString() });
					}}>
					<div
						className={
							isClient
								? 'profilePictureVert'
								: isPartner
									? 'profilePictureBleuClair'
									: 'profilePictureBleu'
						}>
						{data.firstName?.at(0)?.toUpperCase() + data.lastName?.at(0)?.toUpperCase()}
					</div>
					<div className={'rightPartItemConv'}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', overflow: 'hidden', marginRight: 10 }}>
								<p className={'convName'}>
									{data.firstName + (data.firstName && data.lastName ? ' ' : '') + data.lastName}
								</p>
								{diminutif && <p className={'diminutif'}>• {diminutif}</p>}
							</div>
							<p className={'dateConv'}>
								{lastMessage
									? isToday(lastMessage.date)
										? stringTime(language, lastMessage.date)
										: formatDate(t, lastMessage.date)
									: isToday(data.lastMessageAt)
										? stringTime(language, data.lastMessageAt)
										: formatDate(t, data.lastMessageAt)}
							</p>
						</div>
						{isLoading ? (
							<LoadingDiv width={'200px'} height={'15px'} borderRadius={'2px'} />
						) : (
							<p
								className={'lastMessage'}
								style={data.lastMessageAt > data.lastClickAt ? { color: 'black' } : {}}>
								{isContactList
									? isClient
										? t('translation.client')
										: agencyName
									: lastMessage
										? lastMessage.type === 'picture'
											? t('common.image')
											: lastMessage.type === 'video'
												? t('common.video')
												: lastMessage.type === 'file'
													? t('common.file')
													: lastMessage.contents
										: ''}
							</p>
						)}
					</div>
				</div>
				<div className={'barItemMessaging'} />
			</div>
		)
	);
}
