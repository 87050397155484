import React, { useRef } from 'react';
import './_projectSynthesisReport.scss';
import printer from '../../../assets/printer.svg';
import crossIcon from '../../../assets/crossIcon.png';
import Modal from 'react-modal';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import PageProjectSynthesisReport from './Page Project Synthesis Report/PageProjectSynthesisReport';

export default function ProjectSynthesisReport({ isOpen, onClose, projects }) {
	const { t, i18n } = useTranslation();

	const printRef = useRef();

	const handlePrint = useReactToPrint({
		contentRef: printRef,
		documentTitle: `${projects?.length > 1 ? t('common.projects') : projects[0]?.name}-${t('translation.ficheProjet')}-${new Date().toLocaleDateString(
			i18n.language,
			{
				month: 'numeric',
				day: 'numeric',
				year: 'numeric',
			}
		)}`,
	});

	const handlePrintClick = () => {
		handlePrint();
	};

	return (
		<Modal isOpen={isOpen} closeTimeoutMS={300} className={'page'}>
			<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 25 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '210mm', alignItems: 'center' }}>
					<div style={{ width: 25 }} />
					<span style={{ height: 50 }} onClick={handlePrintClick} className={'buttonPrint hover'}>
						<img src={printer} alt="" /> {t('translation.printReport')}
					</span>
					<img src={crossIcon} alt="" className={'crossIcon hover'} onClick={onClose} />
				</div>

				<div className={'largeBar'} />
			</div>

			<div className={'containerPage'} ref={printRef}>
				{projects?.map((project) => (
					<div className={'pdfPage'} key={project.id}>
						<PageProjectSynthesisReport
							project={project}
							missions={project.missions}
							collaborators={project.collaborators}
						/>
					</div>
				))}
			</div>
		</Modal>
	);
}
