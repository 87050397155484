import React, { createRef, useEffect, useRef, useState } from 'react';
import './_documentNavigator.scss';
import ContainerFolder from './Container Folder/ContainerFolder';

export default function DocumentNavigator({
	projectId,
	path,
	files,
	setFiles,
	selectedFileIds,
	toggleFileSelection,
	modalPreview,
	setModalPreview,
	setPreviewFile,
	modalMoveItem,
	diminutifs,
	filterPartnerId,
	setUploadedFileIds,
	setModalSetAccessDuringImport,
	isModeLecture,
}) {
	// List ref
	const listRef = useRef(null);

	// Listen to path changes
	useEffect(() => {
		// Scroll to end (right) smoothly
		if (listRef.current) {
			listRef.current.scrollTo({
				left: listRef.current.scrollWidth,
				behavior: 'smooth',
			});
		}
	}, [path]);

	return (
		<div
			className={'documentNavigator'}
			style={{
				width: isModeLecture ? 'calc(100vw - 196px)' : 'calc(100vw - 586px)',
				maxHeight: isModeLecture ? 'calc(100vh - 125px)' : 'calc(100vh - 180px)',
			}}
			ref={listRef}>
			<ContainerFolder
				index={0}
				actualPath={'/'}
				projectId={projectId}
				isFirst={true}
				allPath={path}
				allFiles={files}
				files={files.filter((it) => it.path === '/')}
				setFiles={setFiles}
				selectedFileIds={selectedFileIds}
				toggleFileSelection={toggleFileSelection}
				setModalPreview={setModalPreview}
				modalPreview={modalPreview}
				setPreviewFile={setPreviewFile}
				modalMoveItem={modalMoveItem}
				diminutifs={diminutifs}
				selectedPart={filterPartnerId}
				setUploadedFileIds={setUploadedFileIds}
				setModalSetAccessDuringImport={setModalSetAccessDuringImport}
				isModeLecture={isModeLecture}
			/>
			{path.map((_, index) => (
				<ContainerFolder
					index={index + 1}
					projectId={projectId}
					key={index}
					actualPath={path.reduce(
						(prev, cur, curIndex) => (curIndex <= index ? prev + `${cur}/` : prev),
						'/'
					)}
					allPath={path}
					allFiles={files}
					files={files.filter(
						(it) =>
							it.path ===
							path.reduce((prev, cur, curIndex) => (curIndex <= index ? prev + `${cur}/` : prev), '/')
					)}
					setFiles={setFiles}
					selectedFileIds={selectedFileIds}
					toggleFileSelection={toggleFileSelection}
					setModalPreview={setModalPreview}
					modalPreview={modalPreview}
					setPreviewFile={setPreviewFile}
					modalMoveItem={modalMoveItem}
					diminutifs={diminutifs}
					selectedPart={filterPartnerId}
					setUploadedFileIds={setUploadedFileIds}
					setModalSetAccessDuringImport={setModalSetAccessDuringImport}
					isModeLecture={isModeLecture}
				/>
			))}

			<div style={{ display: 'flex' }}>
				<div
					style={{
						width: 100,
						height: 100,
					}}></div>
			</div>
		</div>
	);
}
