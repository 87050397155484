import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ItemAccessibility({ permission, name, diminutif, onClick, disabled, withLoading }) {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [newPermission, setNewPermission] = useState(permission);

	useEffect(() => {
		if (!isLoading) {
			setNewPermission(permission);
		}
	}, [permission, isLoading]);

	const handleClick = async (updatedPermission) => {
		if (withLoading) {
			setIsLoading(true);
			setNewPermission(updatedPermission);
			await onClick(updatedPermission);
			setIsLoading(false);
		} else {
			onClick(updatedPermission);
		}
	};

	return (
		<div style={{ opacity: disabled ? 0.7 : 1, pointerEvents: disabled ? 'none' : 'auto' }}>
			<div
				className={
					newPermission !== 'hidden'
						? `itemAccessibilityActivated ${newPermission} transition`
						: `itemAccessibility transition`
				}>
				<p className={'titleAccessibility'} style={{ color: getPrimaryColor(newPermission) }}>
					{name}
				</p>

				<p className={'littleTitleAccessibility'} style={{ color: getPrimaryColor(newPermission) }}>
					{diminutif}
				</p>
				<div
					className={'roundedButton'}
					style={{
						borderColor: getSecondaryColor(newPermission),
						borderTopColor: isLoading ? 'rgba(255,255,255,0.08)' : getSecondaryColor(newPermission),
					}}>
					<div
						className={'littleCircle'}
						style={{
							backgroundColor: getSecondaryColor(newPermission),
						}}
					/>
				</div>
				<div className={'containerChoiceRounded'}>
					<div
						style={{ display: 'flex', alignItems: 'center' }}
						className={'hover'}
						onClick={() => handleClick('hidden')}>
						<div className={'roundedButtonChoice'} style={{ borderColor: getSecondaryColor('hidden') }}>
							<div className={'littleCircle'} style={{ backgroundColor: getSecondaryColor('hidden') }} />
						</div>
						<p className={'designantChoice'} style={{ color: getPrimaryColor('hidden') }}>
							{t('permissions.hidden')}
						</p>
					</div>

					<div
						style={{ display: 'flex', alignItems: 'center' }}
						className={'hover'}
						onClick={() => handleClick('readonly')}>
						<div className={'roundedButtonChoice'} style={{ borderColor: getPrimaryColor('readonly') }}>
							<div className={'littleCircle'} style={{ backgroundColor: getPrimaryColor('readonly') }} />
						</div>
						<p className={'designantChoice'} style={{ color: getPrimaryColor('readonly') }}>
							{t('permissions.read')}
						</p>
					</div>

					<div
						style={{ display: 'flex', alignItems: 'center' }}
						className={'hover'}
						onClick={() => handleClick('all')}>
						<div className={'roundedButtonChoice'} style={{ borderColor: getPrimaryColor('all') }}>
							<div className={'littleCircle'} style={{ backgroundColor: getPrimaryColor('all') }} />
						</div>
						<p className={'designantChoice'} style={{ color: getPrimaryColor('all') }}>
							{t('permissions.read_and_add')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function getPrimaryColor(status) {
	if (status === 'all') return '#2D56D5';
	if (status === 'readonly') return '#ee8233';
	return '#4d4d4d';
}

function getSecondaryColor(status) {
	if (status === 'all') return '#2D56D5';
	if (status === 'readonly') return '#ee8233';
	return '#A2A2A2';
}
