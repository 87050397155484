import React, { useContext, useEffect, useState } from 'react';
import '../../messaging.scss';
import AgencyContext from '../../../../contexts/agencyContext';
import ContainerIdentity from './components/ContainerIdentity';
import ContainerPicturesSent from './components/ContainerPicturesSent';
import ContainerFilesSent from './components/ContainerFilesSent';
import ButtonDeleteConv from './components/ButtonDeleteConv';
import ContainerButtonsInfoConv from './components/ContainerButtonsInfoConv';
import crossIcon from '../../../../assets/crossIcon.png';
import ContainerCommonProjects from './Choose Project/ContainerCommonProjects';
import { useTranslation } from 'react-i18next';
import ContainerParticipants from './components/ContainerParticipants';
import ModalNewGroup from '../../List/New Group/ModalNewGroup';
import { firestore } from '../../../../firebase/config';
import { joinPaths } from '../../../../firebase/utils';

export default function WidgetInfoConv({ data, inputRef, conversations }) {
	const agencyId = useContext(AgencyContext);
	const { t } = useTranslation();

	const [isLarge, setIsLarge] = useState(false);

	const [modalNewGroup, setModalNewGroup] = useState(false);
	const [participants, setParticipants] = useState([]);

	useEffect(() => {
		if (data && conversations) {
			const subscriber = firestore
				.collection(joinPaths('messaging', data.id, 'participants'))
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const participants = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								participants.push(
									conversations.find(
										(contact) => !contact.isDeleted && contact.id === documentSnapshot.id
									)
								);
							}
						});
						setParticipants(participants);
					}
				});
			return () => subscriber();
		}
	}, [data, conversations]);

	return (
		data && (
			<div
				className={'widgetInfoConv'}
				style={{
					width: isLarge ? 400 : 200,
					height: isLarge ? 700 : 55,
					borderRadius: isLarge ? 15 : 99,
					marginTop: isLarge ? 82 : 70,
					opacity: isLarge ? 1 : 0.9,
					transform: isLarge ? 'scale(1.03)' : null,
					alignItems: isLarge ? 'center' : 'flex-start',
					boxShadow: isLarge
						? '0px 0px 20px 0px rgba(51, 51, 51, 0.08)'
						: '0px 0px 20px 0px rgba(51, 51, 51, 0)',
				}}>
				{isLarge && <img src={crossIcon} alt="" className={'crossIcon'} onClick={() => setIsLarge(false)} />}

				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<ContainerIdentity
						agencyId={agencyId}
						data={data}
						isLarge={isLarge}
						setIsLarge={setIsLarge}
						isGroup={data.isGroup}
					/>

					{isLarge && (
						<>
							{data.isGroup ? (
								<div
									style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
									onClick={() => setModalNewGroup(true)}>
									<div className={'buttonEditGroup hover'}>{t('translation.editGroup')}</div>
								</div>
							) : (
								<ContainerButtonsInfoConv
									data={data}
									handleSendMessage={() => {
										setIsLarge(false);
										inputRef?.current?.focus();
									}}
								/>
							)}
							{data.isGroup && (
								<ContainerParticipants
									isGeneral={data.isGeneral}
									participants={participants}
									setModalNewGroup={setModalNewGroup}
								/>
							)}
							<ContainerPicturesSent conversationId={data.id} isGroup={data.isGroup} />
							<ContainerFilesSent conversationId={data.id} isGroup={data.isGroup} />
							{!data.isGeneral && <ContainerCommonProjects conversation={data} />}
							<ButtonDeleteConv />
						</>
					)}
				</div>

				<ModalNewGroup
					modalNewGroup={modalNewGroup}
					setModalNewGroup={setModalNewGroup}
					isEdit={true}
					parentId={data.parentId}
					contacts={conversations}
					group={data}
					oldParticipantIds={participants.map((participant) => participant?.id)}
				/>
			</div>
		)
	);
}
