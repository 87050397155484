import React, { useContext, useEffect, useState } from 'react';
import '../../../messaging.scss';
import { auth, firestore } from '../../../../../firebase/config';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { joinPaths } from '../../../../../firebase/utils';
import { mergeAndOrder } from '../../../../../useful/utils';
import { chooseIconFile } from '../../../../Fiche Projet/Documents/utils';
import ModalDocument from '../../../../../components/ModalDocument';
import { useTranslation } from 'react-i18next';
import pdfIcon from '../../../../../assets/pdfIcon.svg';

export default function ContainerFilesSent({ conversationId, isGroup }) {
	const { t } = useTranslation();
	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [files, setFiles] = useState([]);
	const [lastDocumentSnapshot, setLastDocumentSnapshot] = useState(null);

	const [selectedFile, setSelectedFile] = useState(null);
	const [modalPreview, setModalPreview] = useState(false);

	const [seeMoreCount, setSeeMoreCount] = useState(0);

	useEffect(() => {
		if (uid && type && conversationId && (agencyId || type === 'clients')) {
			setSeeMoreCount(0);
			setFiles([]);
			const subscriber = firestore
				.collection(
					isGroup
						? joinPaths('messaging', conversationId, 'messages')
						: type === 'collaborators'
						  ? joinPaths(
									'agencies',
									agencyId,
									'collaborators',
									uid,
									'messaging',
									conversationId,
									'messages'
						    )
						  : joinPaths('clients', uid, 'messaging', conversationId, 'messages')
				)
				.where('type', 'in', ['file'])
				.orderBy('date', 'desc')
				.limit(7)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const files = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								files.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
						setFiles((prevState) => mergeAndOrder((a, b) => a.date - b.date, 'id', prevState, files));
					}
				});
			return () => {
				subscriber();
			};
		}
	}, [uid, type, isGroup, conversationId]);

	function loadMoreFiles() {
		if (lastDocumentSnapshot) {
			setSeeMoreCount((prevState) => prevState + 1);
			firestore
				.collection(
					isGroup
						? joinPaths('messaging', conversationId, 'messages')
						: type === 'collaborators'
						  ? joinPaths(
									'agencies',
									agencyId,
									'collaborators',
									uid,
									'messaging',
									conversationId,
									'messages'
						    )
						  : joinPaths('clients', uid, 'messaging', conversationId, 'messages')
				)
				.where('type', 'in', ['file'])
				.orderBy('date', 'desc')
				.startAfter(lastDocumentSnapshot)
				.limit(15)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const files = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								files.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
							}
						});
						setLastDocumentSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
						setFiles((prevState) => mergeAndOrder((a, b) => a.date - b.date, 'id', prevState, files));
					}
				});
		}
	}

	return (
		<>
			<div className={'containerFilesSent'}>
				<div className={'containerHeader'}>
					<p>{t('common.files')}</p>
					<p className={'all hover'}>{t('translation.allWord')}</p>
				</div>
				{files.length > 0 ? (
					<div>
						<div className={'listFiles'}>
							{files.slice(0, 6 + seeMoreCount * 15).map((file) => (
								<div>
									<div
										className={'fileSent hover'}
										onClick={() => {
											setSelectedFile(file);
											setModalPreview(true);
										}}>
										<img src={chooseIconFile(file.fileType)} alt="" className={'pdfIcon'} />
										{file.fileName}
									</div>
									<div className={'barFile'} />
								</div>
							))}
						</div>
						{files.length > 6 + seeMoreCount * 15 && (
							<div onClick={loadMoreFiles} className={'seeMore hover'}>
								<p>{t('common.see_more')}</p>
							</div>
						)}
					</div>
				) : (
					<div className={'containerPlaceholderSent'}>
						<img src={pdfIcon} alt="" className={'pdfIcon'} />
						<p>{t('translation.noFilesSent')}</p>
					</div>
				)}
			</div>

			{selectedFile && (
				<ModalDocument
					isOpen={modalPreview}
					setIsOpen={setModalPreview}
					uri={selectedFile.contents}
					name={selectedFile.fileName}
					type={selectedFile.fileType}
					handleNext={() => {
						const index = files.findIndex((file) => file.id === selectedFile.id);
						if (index < files.length - 1) {
							setSelectedFile(files[index + 1]);
						}
						if (index >= files.length - 2) {
							loadMoreFiles();
						}
					}}
					handlePrevious={() => {
						const index = files.findIndex((file) => file.id === selectedFile.id);
						if (index > 0) {
							setSelectedFile(files[index - 1]);
						}
					}}
					noNext={files.findIndex((file) => file.id === selectedFile.id) === files.length - 1}
					noPrevious={files.findIndex((file) => file.id === selectedFile.id) === 0}
				/>
			)}
		</>
	);
}
