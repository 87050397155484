export function formatDateNumericallyWithPastDayKey(dateISOString, language) {
	if (!dateISOString) return '';

	const now = new Date();
	const date = new Date(dateISOString);

	const nowDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	const dateDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

	const diffTime = nowDay - dateDay;
	const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays === 0) {
		return 'today';
	} else if (diffDays === 1) {
		return 'yesterday';
	} else if (diffDays > 1 && diffDays <= 6) {
		return new Intl.DateTimeFormat(language, { weekday: 'long' }).format(date);
	} else {
		return new Intl.DateTimeFormat(language, {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}).format(date);
	}
}

export function formatDateNumericallyWithTodayOrYesterdayOrTomorrow(dateISOString, language, t) {
	if (!dateISOString) return '';

	const now = new Date();
	const date = new Date(dateISOString);

	const nowDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	const dateDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

	const diffTime = nowDay - dateDay;
	const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays === 0) {
		return t('common.today');
	} else if (diffDays === 1) {
		return t('common.yesterday');
	} else if (diffDays === -1) {
		return t('common.tomorrow');
	} else {
		return new Intl.DateTimeFormat(language, {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}).format(date);
	}
}

export function formatDateString(dateISOString, language, t) {
	if (!dateISOString) return '';
	const date = new Date(dateISOString);
	return date.toLocaleDateString(language, {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});
}

export function formatDateStringWithTodayOrYesterdayOrTomorrow(dateISOString, language, t) {
	if (!dateISOString) return '';

	const now = new Date();
	const date = new Date(dateISOString);

	const nowDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	const dateDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

	const diffTime = nowDay - dateDay;
	const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays === 0) {
		return t('common.today');
	} else if (diffDays === 1) {
		return t('common.yesterday');
	} else if (diffDays === -1) {
		return t('common.tomorrow');
	} else {
		return new Intl.DateTimeFormat(language, {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
		}).format(date);
	}
}

export function formatDateNumerically(dateISOString, language) {
	if (!dateISOString) return '';

	return new Intl.DateTimeFormat(language, {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).format(new Date(dateISOString));
}

export function formatDateHourly(dateISOString, language) {
	if (!dateISOString) return '';

	return new Intl.DateTimeFormat(language, {
		hour: 'numeric',
		minute: 'numeric',
	}).format(new Date(dateISOString));
}
