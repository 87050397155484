import React, { useContext, useEffect, useRef, useState } from 'react';
import './_inputModalTicket.scss';
import CompanyOrPersonItem from '../../Little Components/Company Or Person Item/CompanyOrPersonItem';
import ItemOptionPropertyItem from '../Ticket Property Item/ItemOptionPropertyItem';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';
import { normalizeString } from '../../../../../../useful/UsefulFunctions';
import { generateUniqueFirestoreId } from '../../../../../../firebase/utils';
import TypeContext from 'src/contexts/typeContext';
import { useClientsContext } from 'src/contexts/clientsContext';

export default function InputModalTicket({ onClose, property, value, onChange, openModalLots, ticket }) {
	const { t, i18n } = useTranslation();

	const [clients] = useClientsContext();

	const inputRef = useRef(null);
	const containerRef = useRef(null);

	const [research, setResearch] = useState('');

	useEffect(() => {
		inputRef.current.focus();

		const handleClickOutside = (event) => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setTimeout(() => onClose(), 100);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [containerRef]);

	const formatDateForDisplay = (isoString) => {
		if (!isoString) return '';
		const date = parseISO(isoString);
		return format(date, 'yyyy-MM-dd');
	};

	return (
		<div className={'containerInputModalTicket'} ref={containerRef}>
			<div className="inputModalTicket">
				{property.type === 'select' || property.type === 'people' || property.type === 'company' ? (
					<div>
						<div className={'containerInputDropDown'} style={{ display: property.fixed && 'none' }}>
							<input
								ref={inputRef}
								className="inputDropDown"
								type={'text'}
								placeholder={t(
									property.modifyModal || property.type !== 'select'
										? 'translation.research'
										: 'translation.researchOrCreate'
								)}
								value={research}
								onChange={(e) => setResearch(e.target.value)}
							/>
						</div>

						{property.fixed ? (
							<div className={'list'}>
								<p className={'subtitleDropDown'}>{t('translation.selectATicketType')}</p>
								{property.options?.map((option, index) => (
									<div
										style={{ display: 'flex' }}
										className={'containerType'}
										key={index}
										onClick={() => {
											onChange(option.key);
											if (!property.multiple) {
												onClose();
											}
										}}>
										<div
											className={'type'}
											style={{ backgroundColor: option.backgroundColor, color: option.color }}>
											{option.label}
										</div>
									</div>
								))}
							</div>
						) : property.type === 'people' ? (
							<div className={'list'}>
								<p className={'subtitleDropDown'}>{t('translation.selectOneOrMorePeople')}</p>
								<div style={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
									{property?.options
										?.filter((user) =>
											normalizeString(user.label).includes(normalizeString(research))
										)
										?.map((user, index) => (
											<CompanyOrPersonItem
												key={index}
												userId={user.key}
												company={false}
												name={user.label}
												isSelected={
													value?.includes(user.key) ||
													(ticket?.properties?.type === 'issue' &&
														(ticket?.properties?.companies?.some(
															(company) => company === user.companyId
														) ||
															ticket?.properties?.owner?.agencyId === user.companyId ||
															((ticket?.properties?.owner?.userType === 'client' ||
																clients
																	?.map((client) => client.id)
																	?.includes(user.companyId) ||
																ticket?.properties?.companies?.includes('clients') ||
																ticket?.properties?.reviewers?.includes('clients')) &&
																user.key === 'clients')))
												}
												onClick={() => {
													onChange(user.key);
													if (!property.multiple) {
														onClose();
													}
												}}
												disabled={
													ticket?.properties?.type === 'issue' &&
													(ticket?.properties?.companies?.some(
														(company) => company === user.companyId
													) ||
														ticket?.properties?.reviewers?.some(
															(company) => company === user.companyId
														) ||
														ticket?.properties?.owner?.agencyId === user.companyId ||
														((ticket?.properties?.owner?.userType === 'client' ||
															clients
																?.map((client) => client.id)
																?.includes(ticket?.properties?.owner?.agencyId) ||
															ticket?.properties?.companies?.includes('clients') ||
															ticket?.properties?.reviewers?.includes('clients')) &&
															user.key === 'clients'))
												}
											/>
										))}
								</div>
							</div>
						) : property.type === 'company' ? (
							<div className={'list'}>
								<p className={'subtitleDropDown'}>{t('translation.selectOneOrMoreCompany')}</p>
								<div style={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
									{property.options
										?.filter((company) =>
											normalizeString(company?.label).includes(normalizeString(research))
										)
										?.map(
											(option, index) =>
												option && (
													<CompanyOrPersonItem
														key={index}
														userId={option.key}
														company={true}
														name={option.label}
														isSelected={value?.includes(option.key)}
														onClick={() => {
															onChange(option.key);
															if (!property.multiple) {
																onClose();
															}
														}}
														disabled={
															option.key === ticket?.properties?.owner?.agencyId ||
															(property.key === 'reviewers' &&
																option.key === 'clients' &&
																(ticket?.properties?.owner?.userType === 'client' ||
																	clients
																		?.map((client) => client.id)
																		?.includes(
																			ticket?.properties?.owner?.agencyId
																		))) ||
															(property.key === 'companies' &&
																ticket?.properties?.reviewers?.includes(option.key)) ||
															(property.key === 'reviewers' &&
																ticket?.properties?.companies?.includes(option.key))
														}
													/>
												)
										)}
								</div>
							</div>
						) : (
							<div className={'list'}>
								{property.modifyModal ? (
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<p className={'subtitleDropDown'}>{t('translation.selectAnItemRelated')}</p>
										<p className={'editButton hover'} onClick={openModalLots}>
											{t('translation.modify')}
										</p>
									</div>
								) : (
									<p className={'subtitleDropDown'}>{t('translation.selectAnItemRelated')}</p>
								)}

								{!property.modifyModal && research.length > 0 && (
									<ItemOptionPropertyItem
										isNew={true}
										newName={research}
										handleCreateOption={() => {
											const id = generateUniqueFirestoreId();
											property.handleCreateOption(id, research.trim());
											onChange(id);
											setResearch('');
										}}
									/>
								)}

								{property.options
									?.filter(
										(option) =>
											!research ||
											research.length === '' ||
											normalizeString(option.label).includes(normalizeString(research))
									)
									?.map((option, index) => (
										<ItemOptionPropertyItem
											key={index}
											option={option}
											isSelected={value?.includes(option.key)}
											onChange={(value) => {
												onChange(value);
												if (!property.multiple) {
													onClose();
												}
											}}
											notModifiable={property.modifyModal}
											handleRenameOption={(text) => property.handleRenameOption(option.key, text)}
											handleDeleteOption={() => property.handleDeleteOption(option.key)}
										/>
									))}

								{property.modifyModal && (
									<div className={'buttonAddLot hover'} onClick={openModalLots}>
										<p>{t('translation.addNewLot')}</p>
									</div>
								)}
							</div>
						)}
					</div>
				) : (
					<input
						ref={inputRef}
						className="inputSimple"
						type={property.type}
						defaultValue={property.type === 'date' ? formatDateForDisplay(value) : value}
						min={
							property.type === 'date'
								? property.key === 'dueAt'
									? formatDateForDisplay(ticket?.properties?.issueAt)
									: undefined
								: undefined
						}
						onBlur={(e) => onChange(e.target.value.trim())}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.target.blur();
								if (!property.multiple) {
									onClose();
								}
							}
						}}
					/>
				)}
			</div>
		</div>
	);
}

InputModalTicket.propTypes = {
	onClose: PropTypes.func.isRequired,
	property: PropTypes.object.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	openModalLots: PropTypes.func.isRequired,
	ticket: PropTypes.object,
};
