import React, { useEffect, useState } from 'react';
import './_eventLogs.scss';
import ItemEventLog from './Item Event Log/ItemEventLog';
import { useTranslation } from 'react-i18next';
import { firestore } from 'src/firebase/config';
import { joinPaths } from 'src/firebase/utils';
import { useProjectContext } from 'src/contexts/projectContext';
import { Paths } from 'src/firebase/paths';
import PropTypes from 'prop-types';

EventLogs.propTypes = {
	ticket: PropTypes.object.isRequired,
};

export default function EventLogs({ ticket }) {
	const { t } = useTranslation();

	const [project] = useProjectContext();

	const [logs, setLogs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (project?.id && ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.LOGS))
				.orderBy('createdAt', 'desc')
				.onSnapshot((snapshot) => {
					if (snapshot.empty) {
						setLogs([]);
					} else {
						setLogs(snapshot.docs.map((doc) => doc.data()));
					}
					setIsLoading(false);
				});
			return () => unsubscribe();
		}
	}, [project?.id, ticket?.id]);

	return (
		<div className={'eventLogs'}>
			{logs.map((log, index) => (
				<div style={{ display: 'flex' }} key={index}>
					<ItemEventLog name={log.name} type={log.type} date={log.createdAt} />
				</div>
			))}
		</div>
	);
}
