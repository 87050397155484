import React, { useContext, useEffect, useState } from 'react';
import './_onboardingStep.scss';
import { BlueButton, CardAgencyProject, GoBack, HeaderStep, ProjectCardOnboarding } from '../components/ComponentsForm';
import { STEPS } from '../JoinProject';
import { useTranslation } from 'react-i18next';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import AuthDataContext from '../../../contexts/authDataContext';
import largerScreen from '../../../assets/largerScreen.svg';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import appStore from '../../../assets/appStore.png';
import playStore from '../../../assets/playStore.png';

export default function ProjectToJoin({
	projectId,
	projectName,
	agencyProject,
	diminutifAgencyProject,
	projectImg,
	projectPercentage,
	handleNextStep,
	marginTop,
	alreadyConnected,
	isLoading,
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={'onboardingStep projectToJoin'} style={{ marginTop: marginTop }}>
			<HeaderStep
				title={t('translation.joinProjectTitleStep1')}
				subtitle={
					isMobile
						? t('translation.joinProjectSubtitleStep1Mobile')
						: t('translation.joinProjectSubtitleStep1')
				}
			/>

			{!isMobile && (
				<CardAgencyProject diminutifAgencyProject={diminutifAgencyProject} agencyProject={agencyProject} />
			)}

			<ProjectCardOnboarding
				projectName={projectName}
				projectPercentage={projectPercentage}
				projectImg={projectImg}
				isMobile={isMobile}
			/>

			{!isMobile && (
				<BlueButton
					handleClick={() => {
						if (alreadyConnected) {
							navigate(`/projets`, {
								state: {
									fromJoinProject: true,
									projectId,
									projectName,
									projectImg,
									projectPercentage,
								},
							});
						} else {
							handleNextStep(STEPS.yourProfile);
						}
					}}
					text={isLoading ? t('common.loading') : t('translation.joinTheProjectOnOpus')}
					subtitle={alreadyConnected ? '' : t('translation.threeMinutes')}
					disabled={isMobile}
				/>
			)}

			{isMobile && (
				<div className={'needToBeOnLargerScreen'}>
					<div className={'containerTitlePoint'}>
						<div className={'number'}>1</div>
						<p className={'titlePoint'}>{t('translation.downloadOpus')}</p>
					</div>

					<div className={'containerStores'}>
						<a href="https://apps.apple.com/fr/app/opus-architecture-design/id1591807716">
							<img src={appStore} alt="" className={'store'} />
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.opusoft&hl=fr_CH&pli=1">
							<img src={playStore} alt="" className={'store'} />
						</a>
					</div>

					<div className={'containerTitlePoint'}>
						<div className={'number'}>2</div>
						<p className={'titlePoint'}>{t('translation.openInOpus')}</p>
					</div>

					<a href={`opusoft://join-project?id=${encodeURIComponent(projectId)}`}>
						<div className={'buttonOpenProject hover'}>{t('translation.openTheProjectInApp')}</div>
					</a>
				</div>
			)}
		</div>
	);
}
