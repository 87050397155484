const Colors = {
	PROGRESSION_GREEN: '#49a93e',
	PROGRESSION_VERY_LIGHT_GREEN: '#e5f1e5',
	PROGRESSION_BLUE: '#4766F5',
	PROGRESSION_VERY_LIGHT_BLUE: '#e5ebff',
	RED: '#d80000',
	LIGHT_RED: '#ffdddd',
};

export default Colors;
