import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import DocItem from './DocItem';
import { auth, firestore, storage } from '../../../../firebase/config';
import { generateUniqueFirestoreId, getPathStorageFromUrl } from '../../../../firebase/utils';
import AuthDataContext from '../../../../contexts/authDataContext';
import AgencyContext from '../../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import '../_finances.scss';
import mixpanel from 'mixpanel-browser';

export default function ModalModifPosteDepense({
	modalModifPosteDepense,
	setModalModifPosteDepense,
	projectId,
	expenseData,
	ttc,
	mandataire,
	setExpenses,
	categIndex,
	expenseIndex,
	setGlobalEstimate,
	setGlobalPaid,
	isNew,
	createExpense,
	type,
	currency,
	isModeLecture,
	canModify,
}) {
	const { t, i18n } = useTranslation();

	const uid = auth.currentUser?.uid;
	const authData = useContext(AuthDataContext);
	const [isAdministrator, setIsAdministrator] = useState(
		localStorage.getItem('administrator') === 'true' || authData?.subtype === 'administrator'
	);
	const agency = useContext(AgencyContext);

	const [newName, setNewName] = useState('');
	const [newEstimate, setNewEstimate] = useState('');
	const [newPaid, setNewPaid] = useState('');
	const [isConfidential, setIsConfidential] = useState(false);

	const [bytesTransferred, setBytesTransferred] = useState(0);
	const [totalBytes, setTotalBytes] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (expenseData) {
			setNewName(expenseData.name);
			setIsConfidential(expenseData.isConfidential);
			setNewEstimate(expenseData.estimate);
			setNewPaid(expenseData.paid);
		}
	}, [expenseData]);

	useEffect(() => {
		if (!isNew) {
			const subscriber = firestore
				.collection(`projects/${projectId}/expenses/${expenseData.id}/files`)
				.orderBy('date')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const files = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								files.push(documentSnapshot.data());
							}
						});
						setFiles(files);
					}
				});
			return () => subscriber();
		}
	}, [projectId, expenseData, isNew]);

	async function uploadFile(id, file) {
		setIsLoading(true);
		const storageRef = storage.ref(`projects/${projectId}/expenses/${id}`);
		const fileRef = storageRef.child(generateUniqueFirestoreId());
		const task = fileRef.put(file);
		task.on('state_changed', (taskSnapshot) => {
			if (taskSnapshot.bytesTransferred > bytesTransferred) {
				setBytesTransferred(taskSnapshot.bytesTransferred);
				setTotalBytes(taskSnapshot.totalBytes);
			}
		});
		task.then(async () => {
			const data = {
				name: file.name,
				url: await fileRef.getDownloadURL(),
				date: new Date().toISOString(),
				size: file.size,
				type: file.type,
				//creator: auth,
				uid: uid,
				agency,
				notes: '',
				seen: false,
			};
			const fichier = await firestore.collection(`projects/${projectId}/expenses/${id}/files`).add(data);
			await fichier.set({ id: fichier.id }, { merge: true });
			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		});
	}

	async function upload(e) {
		e.preventDefault();

		if (e.target && e.target.files && e.target.files.length > 0) {
			for (const file of e.target.files) {
				if (!isNew) {
					await uploadFile(expenseData.id, file);
				} else {
					setFiles([...files, file]);
				}
			}
			mixpanel.track('Upload Files', {
				'Project ID': projectId,
				Page: 'finances',
			});
		}
	}

	const handleEstimateChange = (e) => {
		const value = e.target.value;
		const regex = /^[0-9]*$/;

		if (value === '' || regex.test(value)) {
			setNewEstimate(value);
		}
	};

	const handlePaidChange = (e) => {
		const value = e.target.value;
		const regex = /^[0-9]*$/;

		if (value === '' || regex.test(value)) {
			setNewPaid(value);
		}
	};

	const handleSave = async () => {
		if (isNew) {
			const id = generateUniqueFirestoreId();
			const estimate = parseInt(newEstimate);
			const paid = parseInt(newPaid);
			setGlobalPaid((oldValue) => oldValue - expenseData.paid + paid);
			setGlobalEstimate((oldValue) => oldValue - expenseData.estimate + estimate);
			await createExpense(id, newName, paid, estimate, isConfidential);
			for (const file of files) {
				await uploadFile(id, file);
			}
			setModalModifPosteDepense(false);
			setNewName(expenseData.name);
			setNewEstimate(expenseData.estimate);
			setNewPaid(expenseData.paid);
			setIsConfidential(expenseData.isConfidential);
			setFiles([]);
		} else {
			setModalModifPosteDepense(false);
			const estimate = parseInt(newEstimate);
			const paid = parseInt(newPaid);
			if (
				newName !== expenseData.name ||
				estimate !== expenseData.estimate ||
				paid !== expenseData.paid ||
				isConfidential !== expenseData.isConfidential
			) {
				setGlobalPaid((oldValue) => oldValue - expenseData.paid + paid);
				setGlobalEstimate((oldValue) => oldValue - expenseData.estimate + estimate);
				setExpenses((oldValue) => {
					let newValue = [...oldValue];
					newValue[categIndex][expenseIndex].name = newName;
					newValue[categIndex][expenseIndex].estimate = estimate;
					newValue[categIndex][expenseIndex].paid = paid;
					newValue[categIndex][expenseIndex].isConfidential = isConfidential;
					return newValue;
				});
				await firestore.doc(`projects/${projectId}/expenses/${expenseData.id}`).update({
					estimate,
					paid,
					name: newName,
					isConfidential: isConfidential ? isConfidential : false,
				});

				if (estimate !== expenseData.estimate || paid !== expenseData.paid) {
					mixpanel.track('Expense Updated', {
						'Project ID': projectId,
						Page: 'finances',
					});
				}
			}
		}
	};

	async function handleCloseModal() {
		if (isNew) {
			setModalModifPosteDepense(false);
			setNewName(expenseData.name);
			setNewEstimate(expenseData.estimate);
			setNewPaid(expenseData.paid);
			setIsConfidential(expenseData.isConfidential);
			setFiles([]);
		} else {
			if (canModify) {
				await handleSave();
			} else {
				setModalModifPosteDepense(false);
			}
		}
	}

	return (
		<Modal
			isOpen={modalModifPosteDepense}
			className={'modalModifExpenseItem'}
			overlayClassName="noOverlay"
			onRequestClose={handleCloseModal}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<div className={'headerModalEditExpenseItem'}>
				<p className={'expenseName'}>{newName}</p>
				<img
					onClick={handleCloseModal}
					src={crossIcon}
					alt="crossIcon"
					className={'crossIconModalEditExpenseItem'}
				/>
			</div>

			<div className={'backBarExpenseItem'}>
				<div
					className={'frontBarExpenseItem'}
					style={{
						width: `calc(550px * ${
							newPaid > 0
								? (!newEstimate || newEstimate === 0) && newPaid > 0
									? 1
									: newPaid / newEstimate > 1
										? 1
										: newPaid / newEstimate
								: 0
						})`,
					}}
				/>
			</div>

			<div className={'scrollViewModifPhase'}>
				<div className={'containerEditExpenseItemInput'}>
					<div>
						<p className={'designant'}>{t('modal_edit_expense_item.expense_item_name')}</p>
						<input
							placeholder={t('modal_edit_expense_item.expense_item')}
							type={'text'}
							className={'inputFirst'}
							style={{ width: isAdministrator && mandataire ? 420 : 540 }}
							value={newName}
							onChange={(event) => setNewName(event.target.value)}
							disabled={!canModify}
						/>
					</div>
					{canModify && isAdministrator && mandataire && (
						<div>
							<p className={'designant'}>{t('modal_edit_expense_item.confidential')}</p>
							<div
								className={'choiceAcces'}
								style={{ backgroundColor: !isConfidential ? '#eeeeee' : '#64b651', marginLeft: 40 }}
								onClick={() => setIsConfidential(!isConfidential)}>
								<div className={'buttonChoice'} style={{ marginLeft: !isConfidential ? 3 : 21 }} />
							</div>
						</div>
					)}
				</div>

				<div className={'containerEditExpenseItemInput'}>
					<div>
						<p className={'designant'}>{t('modal_edit_expense_item.estimated_budget')}</p>
						<div style={{ display: 'flex' }}>
							<input
								placeholder={'100 000'}
								type={'text'}
								className={'inputFirst'}
								value={newEstimate}
								onChange={handleEstimateChange}
								disabled={!canModify}
							/>

							<div className={'withoutTaxContainer'}>
								<p>
									{currency && currency.length > 0 ? currency : '€'}{' '}
									{ttc ? t('modal_edit_expense_item.ttc') : t('modal_edit_expense_item.ht')}
								</p>
							</div>
						</div>
					</div>

					<div>
						<p className={'designant'}>{t('modal_edit_expense_item.amount_paid')}</p>
						<div style={{ display: 'flex' }}>
							<input
								placeholder={'50 000'}
								type={'text'}
								className={'inputFirst'}
								value={newPaid}
								onChange={handlePaidChange}
								disabled={!canModify}
							/>

							<div className={'withoutTaxContainer'}>
								<p>
									{currency && currency.length > 0 ? currency : '€'}{' '}
									{ttc ? t('modal_edit_expense_item.ttc') : t('modal_edit_expense_item.ht')}
								</p>
							</div>
						</div>
					</div>
				</div>

				<p className={'designant'}>{t('modal_edit_expense_item.liked_files')}</p>

				{canModify && (
					<label htmlFor={'inputFiles'}>
						<div className={'cliquezPourDoc'}>{t('modal_edit_expense_item.click_add_doc')}</div>
					</label>
				)}

				<input
					type="file"
					id="inputFiles"
					accept={'application/pdf'}
					multiple
					onInput={upload}
					style={{ display: 'none' }}
				/>

				<div style={{ marginTop: !canModify ? 25 : 40, marginBottom: !canModify ? 0 : -35 }}>
					{files.length > 0
						? files.map((item, index) => (
								<DocItem
									key={index}
									projectId={projectId}
									expenseId={expenseData.id}
									fileData={item}
									mandataire={mandataire}
									isNew={isNew}
									setFiles={setFiles}
									expenseData={expenseData}
									type={type}
								/>
							))
						: !canModify && (
								<div className={'finances_no_files'}>{t('modal_edit_expense_item.no_files')}</div>
							)}
				</div>

				{canModify && (
					<div
						style={{
							display: 'flex',
						}}>
						<div
							className={'buttonSupprimer'}
							onClick={async () => {
								if (isNew) {
									setModalModifPosteDepense(false);
									setNewName(expenseData.name);
									setNewEstimate(expenseData.estimate);
									setNewPaid(expenseData.paid);
									setIsConfidential(expenseData.isConfidential);
									setFiles([]);
								} else {
									if (window.confirm(t('modal_edit_expense_item.want_delete_expense_item'))) {
										setModalModifPosteDepense(false);
										setGlobalPaid((oldValue) => oldValue - expenseData.paid);
										setGlobalEstimate((oldValue) => oldValue - expenseData.estimate);
										setExpenses((oldValue) => {
											const newValue = [...oldValue];
											newValue[categIndex].splice(expenseIndex, 1);
											if (newValue[categIndex].length === 0) {
												newValue[categIndex].push({
													category: expenseData.category,
													dummy: true,
												});
											}
											return newValue;
										});
										firestore
											.collection(`projects/${projectId}/expenses/${expenseData.id}/files`)
											.get()
											.then((querySnapshot) => {
												if (querySnapshot) {
													querySnapshot.forEach(async (documentSnapshot) => {
														if (documentSnapshot && documentSnapshot.exists) {
															if (documentSnapshot.data().url) {
																await storage
																	.ref(
																		getPathStorageFromUrl(
																			documentSnapshot.data().url
																		)
																	)
																	.delete();
															}
															await firestore
																.doc(
																	`projects/${projectId}/expenses/${expenseData.id}/files/${documentSnapshot.id}`
																)
																.delete();
														}
													});
												}
											});
										await firestore
											.doc(`projects/${projectId}/expenses/${expenseData.id}`)
											.delete();
									}
								}
							}}>
							{isNew ? t('common.cancel') : t('modal_edit_expense_item.delete_expense_item')}
						</div>

						<div
							style={{
								color: '#4B5FB0',
								backgroundColor: '#D2E7F8',
							}}
							className={'buttonSupprimer'}
							onClick={handleSave}>
							{t('common.save')}
						</div>

						<div style={{ height: 10 }} />
					</div>
				)}
			</div>
		</Modal>
	);
}
