import selectPhotos from '../../../../assets/selectPhotos.svg';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { getPathStorageFromUrl } from '../../../../firebase/utils';
import { storage } from '../../../../firebase/config';
import playSymbolVideo from '../../../../assets/playSymbolVideo.svg';
import annotationIcon from '../../../../assets/annotationIcon.svg';
import '../_photos.scss';
import { useProjectContext } from '../../../../contexts/projectContext';
import TypeContext from '../../../../contexts/typeContext';

export default function PhotoItem({
	item,
	isSelectOption,
	selectedPhotos,
	setSelectedPhotos,
	setModalPhoto,
	setSelectedPhoto,
	setIndex,
	setDropZoneIsActive,
	photos,
	opacityPhoto,
	agencyData,
	partnerAgencies,
}) {
	const [thumbnailUrl, setThumbnailUrl] = useState('');
	const [project] = useProjectContext();
	const type = useContext(TypeContext);

	useEffect(() => {
		if (item.type && item.type.includes('video') && item.url && item.id && item.url.includes(item.id)) {
			storage
				.ref(getPathStorageFromUrl(item.url.replace(item.id, 'thumb_' + item.id + '.jpeg')))
				.getDownloadURL()
				.then((url) => {
					setThumbnailUrl(url);
				})
				.catch(() => {
					setThumbnailUrl('');
				});
		}
	}, [item]);

	return (
		<div
			style={{
				width: 150,
				height: 150,
				opacity: opacityPhoto,
				backgroundColor: item.type && item.type.includes('video') ? '#000' : null,
			}}
			className="cardPhoto"
			onClick={() => {
				if (isSelectOption) {
					if (
						item.createdBy?.agencyId === agencyData?.uid ||
						(item.createdBy?.userType === 'client' && type === 'clients')
					) {
						if (selectedPhotos.includes(item)) {
							setSelectedPhotos((oldValue) => {
								const newValue = [...oldValue];
								newValue.splice(selectedPhotos.indexOf(item), 1);
								return newValue;
							});
						} else {
							setSelectedPhotos((oldValue) => [...oldValue, item]);
						}
					}
				} else {
					setModalPhoto(true);
					setSelectedPhoto(item);
					setIndex(photos.indexOf(item));
					setDropZoneIsActive(false);
				}
			}}>
			{isSelectOption && selectedPhotos.includes(item) && (
				<p style={{ position: 'absolute' }}>
					<div
						style={{
							marginTop: -16,
							width: 150,
							height: 150,
							backgroundColor: 'rgba(255,255,255,0.39)',
						}}>
						<img
							src={selectPhotos}
							alt=""
							style={{
								position: 'absolute',
								top: 'calc((100% - 35px)/2)',
								left: 'calc((100% - 35px)/2)',
								width: 35,
								height: 35,
							}}
						/>
					</div>
				</p>
			)}

			{item.comments?.length > 0 && (
				<div
					style={{
						backgroundColor: '#FFF',
						height: 30,
						width: 30,
						borderRadius: 99,
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: 110,
						marginTop: 8,
					}}>
					<img
						src={annotationIcon}
						alt=""
						style={{
							width: 17,
							height: 17,
						}}
					/>
				</div>
			)}
			{item.type && item.type.includes('video') ? (
				<div
					style={{
						width: 150,
						height: 150,
					}}>
					{thumbnailUrl ? (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
							}}>
							<img
								src={thumbnailUrl}
								style={{ objectFit: 'cover', width: '100%', height: '100%' }}
								alt={''}
							/>

							<img
								src={playSymbolVideo}
								alt=""
								style={{
									position: 'absolute',
									top: 'calc((100% - 35px)/2)',
									left: 'calc((100% - 35px)/2)',
									width: 35,
									height: 35,
								}}
							/>
						</div>
					) : (
						<ReactPlayer
							url={item.url}
							width="100%"
							height="100%"
							style={{
								objectFit: 'cover',
							}}
							playing={false}
						/>
					)}
				</div>
			) : (
				<img
					src={item.thumbnailUrl ?? item.url}
					alt={'cardPhoto'}
					className={'photo'}
					style={{
						opacity: isSelectOption
							? type === 'clients'
								? item.createdBy?.userType === 'client'
									? 1
									: 0.4
								: item.createdBy?.agencyId === agencyData?.uid
									? opacityPhoto
									: 0.4
							: null,
					}}
				/>
			)}
			{type === 'clients' ? (
				item.createdBy?.agencyId === 'clients' ? null : !isSelectOption ? null : (
					<div style={{ display: 'flex', position: 'absolute', width: 150, justifyContent: 'flex-end' }}>
						<div
							style={{
								borderRadius: 13,
								marginTop: -36,
								height: 18,
								marginRight: 10,
								backgroundColor:
									item.createdBy?.userType === 'client'
										? '#328136'
										: item.createdBy?.agencyId === project?.creator
											? '#3f5cf6'
											: '#D77534',
								marginLeft: 0,
								color: '#FFF',
								fontSize: 14,
								padding: '3px 8px',
								letterSpacing: 1,
							}}>
							{item.createdBy?.userType === 'client'
								? 'MOA'
								: partnerAgencies.get(item.createdBy?.agencyId)?.diminutifAgency}
						</div>
					</div>
				)
			) : isSelectOption && item.createdBy?.agencyId !== agencyData?.uid ? (
				<div style={{ display: 'flex', position: 'absolute', width: 150, justifyContent: 'flex-end' }}>
					<div
						style={{
							borderRadius: 13,
							marginTop: -36,
							height: 18,
							marginRight: 10,
							backgroundColor: item.createdBy?.agencyId === 'clients' ? '#328136' : '#3f5cf6',
							marginLeft: 0,
							color: '#FFF',
							fontSize: 14,
							padding: '3px 8px',
							letterSpacing: 1,
						}}>
						{item.createdBy?.userType === 'clients'
							? 'MOA'
							: partnerAgencies.get(item.createdBy?.agencyId)?.diminutifAgency}
					</div>
				</div>
			) : null}
		</div>
	);
}
