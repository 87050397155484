import React, { useContext } from 'react';
import '../../messaging.scss';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../../../contexts/agencyContext';

export default function ItemParticipantGroup({ data, isAdded, onClick }) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);

	return (
		<div className={'itemParticipantGroup'}>
			<div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
				<div
					className={
						data?.userType === 'clients'
							? 'profilePictureVert'
							: data?.userAgencyId === agencyId
							  ? 'profilePictureBleu'
							  : 'profilePictureBleuClair'
					}>
					{data?.firstName?.at(0)?.toUpperCase() + data?.lastName?.at(0)?.toUpperCase()}
				</div>
				{data?.name}
			</div>

			<div className={isAdded ? 'buttonDelete hover' : 'buttonAdd hover'} onClick={onClick}>
				{isAdded ? t('translation.delete') : t('translation.add')}
			</div>
		</div>
	);
}
