import React, { useEffect, useState } from 'react';
import { transformString } from '../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../firebase/config';

export default function ProjetListItem({ id, projectsToAdd, setProjectsToAdd, research, setResearchNumber }) {
	const { t } = useTranslation();

	const [numberCollab, setNumberCollab] = useState(0);
	const [projectData, setProjectData] = useState();
	const [oldResearch, setOldResearch] = useState(false);

	useEffect(() => {
		firestore
			.doc(`projects/${id}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot) {
					setProjectData({ ...documentSnapshot.data(), id: documentSnapshot.id });
				}
			});
		firestore
			.collection(`projects/${id}/accounts`)
			.where('type', '==', 'collaborators')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					setNumberCollab(querySnapshot.size);
				}
			});
	}, [id]);

	useEffect(() => {
		if (projectData && projectData.name) {
			if (transformString(projectData.name).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(projectData.name).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, projectData]);

	return (
		<>
			{projectData && transformString(projectData.name).includes(transformString(research)) && (
				<div className={'cardProjetListItem'}>
					<img src={projectData.imgUrl} alt="" className={'imageProjet'} />
					<div style={{ width: 405 }}>
						<p className={'titleProjet'}>{projectData.name}</p>
						<p className={'subtitleProjet'}>
							{numberCollab} {t('common.collab')}
							{numberCollab > 1 && 's'}
						</p>
					</div>
					{!projectsToAdd.map((item) => item.id).includes(id) ? (
						<div
							className={'buttonAjouterVert'}
							onClick={() => {
								setProjectsToAdd((oldValue) => [...oldValue, { ...projectData, id }]);
							}}>
							<p>{t('common.add')}</p>
						</div>
					) : (
						<div
							className={'buttonAjouterRouge'}
							onClick={() => {
								setProjectsToAdd((oldValue) => oldValue.filter((item) => item.id !== id));
							}}>
							<p>{t('common.delete')}</p>
						</div>
					)}
				</div>
			)}
		</>
	);
}
