import React, { useContext, useEffect, useState } from 'react';
import { firestore } from '../firebase/config';
import AgencyContext from '../contexts/agencyContext';

export default function ParticipantImg({ uid, type, noBorder, big, withName, isEnCoursIntegration, missions }) {
	const [agency, setAgency] = useState();
	const [initiales, setInitiales] = useState();
	const agencyId = useContext(AgencyContext);

	useEffect(() => {
		if (type === 'collaborators') {
			firestore
				.doc(`accounts/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setAgency(documentSnapshot.data().agency);
					}
				});
		}
	}, [uid, type]);

	useEffect(() => {
		const path =
			type === 'collaborators'
				? firestore.doc(`agencies/${agency}/collaborators/${uid}`)
				: firestore.doc(`${type}/${uid}`);
		path.get().then((documentSnapshot) => {
			if (documentSnapshot && documentSnapshot.exists) {
				setInitiales(
					documentSnapshot.data().surname?.charAt(0)?.toUpperCase() +
						documentSnapshot.data().name?.charAt(0)?.toUpperCase()
				);
			}
		});
	}, [uid, type, agency]);

	return (
		<div
			style={{
				marginLeft: -15,
				width: 35,
				height: 35,
				borderRadius: 25,
				objectFit: 'cover',
				backgroundColor:
					type === 'clients'
						? '#83d98a'
						: type === 'collaborators'
						  ? agencyId !== agency
								? '#E7ECFE'
								: '#7FA3FC'
						  : null,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: type === 'collaborators' ? (agencyId !== agency ? '#5b84e8' : '#FFFFFF') : '#FFFFFF',
				fontWeight: 600,
				border: 'solid 2px #FFFFFF',
			}}>
			<p>{initiales}</p>
		</div>
	);
}
