import React from 'react';

export default function ItemProjectToChoose({ name, imgUrl, isAgency, diminutif, onClick, isChoosen }) {
	return (
		<div>
			<div className={'itemProjectToChoose'} onClick={onClick}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{isAgency ? (
							<div className={'agencyIcon'}>
								<p>{diminutif}</p>
							</div>
						) : (
							<img src={imgUrl} alt="" />
						)}
						{name}
					</div>
					<div className={'containerIndicChoose'}>{isChoosen && <div className={'indicChoose'} />}</div>
				</div>
			</div>
			<div className={'barProjectToChoose'} />
		</div>
	);
}
