import React, { useContext, useState, useEffect, useRef } from 'react';
import './_ticketTodolist.scss';
import { useTranslation } from 'react-i18next';
import emptyCheck from '../../../../../../assets/emptyCheck.svg';
import TodoItem from './TodoItem';
import { useProjectContext } from '../../../../../../contexts/projectContext';
import { auth } from '../../../../../../firebase/config';
import TypeContext from '../../../../../../contexts/typeContext';
import AgencyContext from '../../../../../../contexts/agencyContext';
import PropTypes from 'prop-types';
import { firestore } from '../../../../../../firebase/config';
import { joinPaths, generateUniqueFirestoreId } from '../../../../../../firebase/utils';
import { Paths } from '../../../../../../firebase/paths';

export default function TicketTodolist({ ticketId, ticketTodos, setLoadingStatus, rightPart }) {
	const { t } = useTranslation();

	const [project] = useProjectContext();

	const [todos, setTodos] = useState(ticketTodos || []);
	const [newTodo, setNewTodo] = useState('');

	const listRef = useRef(null);

	const scrollToBottom = () => {
		if (listRef.current) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		scrollToBottom();
	}, []);

	useEffect(() => {
		if (ticketTodos) {
			setTodos(ticketTodos);
		}
	}, [ticketTodos]);

	const updateTodos = async (todos) => {
		setLoadingStatus('loading');

		firestore
			.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticketId))
			.update({ todos: todos, modifiedAt: new Date().toISOString() })
			.then(() => {
				setTimeout(() => {
					setLoadingStatus('success');
				}, 1000);
			})
			.catch((error) => {
				console.error('Error updating todos:', error);
				setLoadingStatus('error');
			});
	};

	const addTodo = async () => {
		if (newTodo?.trim() && newTodo.trim().length > 1) {
			setTodos((prev) => {
				const newTodos = [
					...prev,
					{ id: generateUniqueFirestoreId(), text: newTodo.trim(), completed: false, subTodos: [] },
				];
				updateTodos(newTodos);
				return newTodos;
			});
			setNewTodo('');
		}
	};

	const toggleTodo = (id) => {
		setTodos((prev) => {
			const newTodos = prev.map((todo) => (todo.id === id ? { ...todo, completed: !todo.completed } : todo));
			updateTodos(newTodos);
			return newTodos;
		});
	};

	const deleteTodo = (id) => {
		setTodos((prev) => {
			const newTodos = prev.filter((todo) => todo.id !== id);
			updateTodos(newTodos);
			return newTodos;
		});
	};

	const addSubTodo = (todoId, subTodoText) => {
		setTodos((prev) => {
			const newTodos = prev.map((todo) =>
				todo.id === todoId
					? {
							...todo,
							subTodos: [
								...todo.subTodos,
								{ id: generateUniqueFirestoreId(), text: subTodoText, completed: false },
							],
						}
					: todo
			);
			updateTodos(newTodos);
			return newTodos;
		});
	};

	const toggleSubTodo = (todoId, subTodoId) => {
		setTodos((prev) => {
			const newTodos = prev.map((todo) =>
				todo.id === todoId
					? {
							...todo,
							subTodos: todo.subTodos.map((subTodo) =>
								subTodo.id === subTodoId ? { ...subTodo, completed: !subTodo.completed } : subTodo
							),
						}
					: todo
			);
			updateTodos(newTodos);
			return newTodos;
		});
	};

	const deleteSubTodo = (todoId, subTodoId) => {
		setTodos((prev) => {
			const newTodos = prev.map((todo) =>
				todo.id === todoId
					? { ...todo, subTodos: todo.subTodos.filter((subTodo) => subTodo.id !== subTodoId) }
					: todo
			);
			updateTodos(newTodos);
			return newTodos;
		});
	};

	const editTodoText = (id, newText, isSubTodo = false, parentId = null) => {
		setTodos(
			todos.map((todo) => {
				if (!isSubTodo && todo.id === id) {
					return { ...todo, text: newText };
				} else if (isSubTodo && todo.id === parentId) {
					return {
						...todo,
						subTodos: todo.subTodos.map((subTodo) =>
							subTodo.id === id ? { ...subTodo, text: newText } : subTodo
						),
					};
				}
				return todo;
			})
		);
	};

	function onSubmitNewTodo(e) {
		e.preventDefault();
		addTodo();
		scrollToBottom();
	}

	return (
		<div className={'ticketTodolist'} ref={listRef}>
			{todos.map((todo) => (
				<TodoItem
					key={todo.id}
					todo={todo}
					onToggle={toggleTodo}
					onDelete={deleteTodo}
					onAddSubTodo={addSubTodo}
					onToggleSubTodo={toggleSubTodo}
					onDeleteSubTodo={deleteSubTodo}
					editTodoText={editTodoText}
					updateTodos={() => updateTodos(todos)}
				/>
			))}
			<form
				style={{ marginTop: 14, display: 'flex', alignItems: 'center', marginLeft: 24.3 }}
				onSubmit={onSubmitNewTodo}>
				{newTodo.length > 0 && <img src={emptyCheck} alt="" className={'checkBox'} />}
				<input
					type="text"
					value={newTodo}
					onChange={(e) => setNewTodo(e.target.value)}
					placeholder={t('translation.addANewTodo')}
					className={'addANewTodo'}
					onBlur={onSubmitNewTodo}
				/>
				{newTodo.length > 0 && (
					<button className={'addButton'} style={{ display: 'none' }}>
						{t('translation.add')}
					</button>
				)}
			</form>
		</div>
	);
}

TicketTodolist.propTypes = {
	ticketId: PropTypes.string.isRequired,
	ticketTodos: PropTypes.array.isRequired,
	setLoadingStatus: PropTypes.func.isRequired,
	canModifyTicket: PropTypes.bool,
};
