import React from 'react';
import { useTranslation } from 'react-i18next';
import { TICKET_STATUS } from '../../../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';
import { useProjectContext } from '../../../../../contexts/projectContext';
import ItemEventLog from '../../../../Fiche Projet/Tickets/components/Modal Ticket/Event Logs/Item Event Log/ItemEventLog';

export default function LogsPage({ ticket, logs, justLogs, status }) {
	const { t, i18n } = useTranslation();
	const [project] = useProjectContext();

	return (
		<div className={'snagPage'}>
			{!justLogs && (
				<div
					className={'statusBar'}
					style={
						status === TICKET_STATUS.approved
							? {
									color: '#3CA504',
									backgroundColor: 'rgba(60,165,4,0.1)',
								}
							: { backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }
					}>
					<span className={'ticketNameWebkit'} style={{ width: 300 }}>
						{ticket.name}
					</span>
					<span>
						{new Date().toLocaleDateString(i18n.language, {
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
					<span style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>{project.name}</span>
				</div>
			)}

			<div className={'snagDescription'} style={{ height: 1100, overflow: 'hidden' }}>
				{!justLogs && (
					<div className={'containerSnagNameCreator'}>
						<span className={'titleReport'} style={{ fontSize: 25 }}>
							{t('translation.logsHistory')}
						</span>
					</div>
				)}
				{!justLogs && <span style={{ opacity: 0.6 }}>{t('translation.recentToOld')}</span>}

				<div style={{ marginTop: justLogs ? 0 : 30, display: 'flex', flexDirection: 'column', gap: 25 }}>
					{logs.map((log, index) => (
						<ItemEventLog name={log.name} type={log.type} date={log.createdAt} large={true} />
					))}
				</div>
			</div>
		</div>
	);
}
