import React, { useEffect, useState } from 'react';
import { stringSubtype } from '../../../useful/UsefulFunctions';
import { firestore } from '../../../firebase/config';
import { useTranslation } from 'react-i18next';

export default function WidgetAccount({ uid, type, collaboratorData, placeholder, agencyProjects }) {
	const { t } = useTranslation();

	const [accountData, setAccountData] = useState(collaboratorData);
	const [nbProject, setNbProject] = useState(0);

	useEffect(() => {
		if (type === 'collaborators') {
			setAccountData(collaboratorData);
		} else {
			firestore
				.doc(`${type}/${uid}`)
				.get()
				.then((doc) => {
					if (doc && doc.exists) {
						setAccountData({ ...doc.data(), id: doc.id });
					}
				});
			firestore
				.collection(`${type}/${uid}/projects`)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						let nb = 0;
						querySnapshot.forEach((doc) => {
							if (doc && doc.exists && agencyProjects.includes(doc.id)) {
								nb++;
							}
						});
						setNbProject(nb);
					}
				});
		}
	}, [uid, type, collaboratorData, agencyProjects]);

	if (placeholder) {
		return (
			<>
				<div className={'itemAccount'}>
					<div className={'profilePicture'} style={{ backgroundColor: '#eeeeee' }} />
					<div style={{ width: 170 }}>
						<div style={{ height: 10, width: 100, borderRadius: 10, backgroundColor: '#eeeeee' }} />
					</div>
					<div style={{ width: 115, display: 'flex', justifyContent: 'flex-end' }}>
						<div>
							<div
								className={'statut'}
								style={{
									backgroundColor: '#F8F8F8',
									borderColor: '#eeeeee',
									width: 80,
									height: 13,
								}}></div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				{accountData && (
					<div className={'itemAccount'}>
						{type === 'agencies' ? (
							<div className={'profilePictureBleuClair'} style={{ borderRadius: 7 }}>
								<p
									style={{
										letterSpacing: 1,
										fontSize: 14,
										margin: 0,
									}}>
									{accountData?.diminutifAgency?.charAt(0)}
								</p>
							</div>
						) : (
							<div className={type === 'clients' ? 'profilePictureVert' : 'profilePictureBleu'}>
								<p
									style={{
										letterSpacing: 1,
										fontSize: 14,
										margin: 0,
									}}>
									{accountData?.surname?.charAt(0).toUpperCase()}
									{accountData?.name?.charAt(0).toUpperCase()}
								</p>
							</div>
						)}

						<div style={{ width: 170 }}>
							<p className={'nom'}>
								{accountData.surname} {accountData.name}
							</p>
						</div>
						<div style={{ width: 115, display: 'flex', justifyContent: 'flex-end' }}>
							<div>
								{type === 'collaborators' ? (
									<div
										className={'statut'}
										style={
											accountData.deletion
												? {
														color: '#b04b4b',
														backgroundColor: '#faf4f4',
														borderColor: '#f8d2d2',
												  }
												: null
										}>
										{accountData.deletion
											? t('agency_part.agency_dashboard.widgets.widget_account.being_deleted')
											: stringSubtype(accountData.subtype, t)}
									</div>
								) : (
									<div className={'statut'}>
										{nbProject} {t('common.projet') + (nbProject > 1 ? 's' : '')}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}
