import React from 'react';
import { getOptionsListUAE } from './OptionsList';
import CategoryFunctionality from './CategoryFunctionality';
import { useTranslation } from 'react-i18next';

export default function TableauFunctionalities() {
	const { t } = useTranslation();
	const optionsListUAE = getOptionsListUAE(t);

	return (
		<div className={'tableauFunctionalities'}>
			{optionsListUAE.map((item) => (
				<CategoryFunctionality key={item.id} category={item} />
			))}
		</div>
	);
}
