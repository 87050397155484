export const ProgressionStatus = {
	COMPLETED: 'completed',
	IN_PROGRESS: 'in_progress',
	NOT_STARTED: 'not_started',
};

export function nextStatus(status) {
	switch (status) {
		case ProgressionStatus.COMPLETED:
			return ProgressionStatus.NOT_STARTED;
		case ProgressionStatus.IN_PROGRESS:
			return ProgressionStatus.COMPLETED;
		case ProgressionStatus.NOT_STARTED:
			return ProgressionStatus.IN_PROGRESS;
		default:
			return ProgressionStatus.NOT_STARTED;
	}
}
